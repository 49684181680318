import Axios from 'axios'
import { getPathName } from './helper';

type Contacto = {
    origen?: string;
    rubro?: string;
    isMobile?: boolean;
    tipoCotizador?: string;
    landingUrl?: string;
    refererUrl?: string;
    nombre?: string;
    razonSocial?: string;
    apellido?: string;
    FechaNacimiento?: { dia: string; mes: string; año: string },
    codigoArea?: string;
    tipoDoc?: {
        value?: string;
        label?: string;
    };
    cuit?: string;
    numeroDoc?: string;
    telefono?: {
        prefijo?: string;
        numero?: string;
    };
    email?: string;
    token?: string;
    captchaToken: string;
    parametrosIniciales?: {
        origen?: string,
    };
    provincia?: {
        value?: string;
        label?: string;
    };
    localidad?: {
        value?: string;
        label?: string;
    };
    localidadLanding?: string;
    codigoPostal?: string;
};

const guardarContactoGenerico = async (params: Contacto) => {
    let data: Record<string, any> = {
        origen: params.parametrosIniciales?.origen ?? null,
        landingUrl: params.landingUrl ?? null,
        refererUrl: params.refererUrl ?? null,
        rubro: params.rubro ?? null,

        nombre: params.nombre ?? null,
        apellido: params.apellido ?? null,
        razonSocial: params.razonSocial ?? null,

        FechaNacimiento: params.FechaNacimiento
            ? `${params.FechaNacimiento.dia}/${params.FechaNacimiento.mes}/${params.FechaNacimiento.año}`
            : null,
        codigoArea: params.telefono?.prefijo ?? null,
        telefono: {
            codigoArea: params.telefono?.prefijo ?? null,
            numero: params.telefono?.numero ?? null,
        },

        email: params.email ?? null,
        cuit: params.cuit ?? null,
        numeroDoc: params.numeroDoc ?? null,
        tipoDoc: params.tipoDoc?.value ?? null,

        provincia: params.provincia?.value ?? null,
        localidad: params.localidad?.value ?? params.localidadLanding,
        codigoPostal: params.codigoPostal ?? null,

        tipoCotizador: params.tipoCotizador ?? null,

        g_recaptcha_response: params.captchaToken ?? null,
        hash: process.env.REACT_APP_HASH ?? null
    };

    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    };

    return await Axios.post(getPathName() + "/SetCotizacionLanding", data, {
        headers: headers
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
};

export default guardarContactoGenerico;
