import React from 'react'
import googlePlay from "../../../Assets/img/es_badge_web_generic.png";
import appStore from "../../../Assets/img/badge_app-store_black_es.svg";
import appDoble from "../../../Assets/img/app_mi-segurarse_mock2.png";
import appSimple from "../../../Assets/img/app_mi-segurarse_mock1.png";

export const AppMiSegurarse = () => {
    return (
        <div className="py-5 bg-primary text-white mt-lg-5">
            <div className="container">
                <div className="row">

                    <div className="col-md-6 position-relative z-1">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40">Tu seguro en tu celular</h2>
                        <ul className="fs-15 fs-lg-20">
                            <li><b>Ver y descargar</b> tus pólizas</li>
                            <li><b>Tener</b> tu credencial digital siempre</li>
                            <li><b>Renovar o cotizar</b> un nuevo seguro</li>
                            <li><b>Denunciar</b> robo o siniestro, enviando mensaje de voz y fotos en tiempo real</li>
                            <li><b>Solicitar</b> asistencia mecánica</li>
                            <li><b>Autorizar</b> a un tercero</li>
                            <li><b>Cambiar</b> forma de pago</li>
                            <li><b>Realizar</b> consultas varias por chat</li>
                        </ul>

                        <h2 className="fs-15 fs-lg-20 fw-normal mb-3">Descargá gratis <b>MiSegurarse</b> y autogestioná tu seguro.</h2>
                        <ul className="list-inline mb-0 text-center text-sm-start">
                            <li className="list-inline-item me-sm-3"><a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                                <img className="badge-store-banner" src={googlePlay} /></a></li>
                            <li className="list-inline-item"><a href="https://itunes.apple.com/us/app/mi-segurarse/id1237342800?l=es&ls=1&mt=8" target="_blank">
                                <img className="badge-store-banner" src={appStore} /></a></li>
                        </ul>
                    </div>

                    <div className="col-md-6 position-relative order-md-first">
                        <div className="d-md-none mt-5 text-center">
                            <img className="mock1-img" src={appSimple} />
                        </div>

                        <div className="d-none d-md-block">
                            <img className="mock2-img" src={appDoble} data-animate="slideInLeft" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
