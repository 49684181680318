import federacionpatronal from "../Assets/img/logos/cias/logo_federacionpatronal_b.png"
import liberty from "../Assets/img/logos/cias/logo_liberty_b.png"
import rsa from "../Assets/img/logos/cias/logo_rsa_galicia.svg"
import allianz from "../Assets/img/logos/cias/logo_allianz.svg"
import experta from "../Assets/img/logos/cias/logo_experta.svg"
import sancristobal from "../Assets/img/logos/cias/logo_sancristobal.svg"
import zurich from "../Assets/img/logos/cias/logo_zurich.svg"
import mercantil from "../Assets/img/logos/cias/logo_mercantil.svg"
import meridional from "../Assets/img/logos/cias/logo_meridional.svg"
import provincia from "../Assets/img/logos/cias/logo_provinciaseguros.svg"
import galeno from "../Assets/img/logos/cias/logo_galeno.svg"
import sancor from "../Assets/img/logos/cias/logo_sancor.svg"
import libra from "../Assets/img/logos/cias/logo_libra.svg"
import mapfre from "../Assets/img/logos/cias/logo_mapfre.svg"
import smg from "../Assets/img/logos/cias/logo_SMG.svg"
import orbis from "../Assets/img/logos/cias/logo_orbis.svg"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "rsa",
        imgRuta: rsa,
        mostrar: true
    },
    {
        nombreCompania: "smg",
        imgRuta: smg,
        mostrar: true
    },
    {
        nombreCompania: "libra",
        imgRuta: libra,
        mostrar: false
    },
    {
        nombreCompania: "liberty",
        imgRuta: liberty,
        mostrar: false
    },
    {
        nombreCompania: "galeno",
        imgRuta: galeno,
        mostrar: false
    },
    {
        nombreCompania: "federacionpatronal",
        imgRuta: federacionpatronal,
        mostrar: false
    },
    {
        nombreCompania: "allianz",
        imgRuta: allianz,
        mostrar: true
    },
    {
        nombreCompania: "experta",
        imgRuta: experta,
        mostrar: true
    },
    {
        nombreCompania: "sancristobal",
        imgRuta: sancristobal,
        mostrar: true
    },
    {
        nombreCompania: "mercantil",
        imgRuta: mercantil,
        mostrar: true
    },
    {
        nombreCompania: "provincia",
        imgRuta: provincia,
        mostrar: true
    },
    {
        nombreCompania: "sancor",
        imgRuta: sancor,
        mostrar: true
    },
    {
        nombreCompania: "mapfre",
        imgRuta: mapfre,
        mostrar: true
    },
    {
        nombreCompania: "orbis",
        imgRuta: orbis,
        mostrar: false
    },
    {
        nombreCompania: "zurich",
        imgRuta: zurich,
        mostrar: true
    },
    {
        nombreCompania: "meridional",
        imgRuta: meridional,
        mostrar: true
    },
]

export const imagenesCompaniasFiltradas = (companiasDisponibles: any):Companias[] => {
    return companiasDisponibles.map( (compania: any) => {
        const imagen = imagesCompanias.find(image => 
            image.nombreCompania.toLowerCase() === compania.codigoCompania.toLowerCase()
        );
    
        return {
            ...compania,
            imgRuta: imagen ? imagen.imgRuta : null,
            mostrar: imagen ? imagen.mostrar : false
        };
    })
}