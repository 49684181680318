import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { IPasos } from "../../../../Pages/Caucion/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { SegRadioButton } from "../../../SegUI/SegRadioButton/SegRadioButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";

interface ICobertura {
  cobertura: string;
  tipoMoneda: string;
  sumaAsegurada: string;
}

interface CoberturaMonedaProps {
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const CoberturaMoneda = ({ setSteps, ...rest }: CoberturaMonedaProps) => {
  const nav = useNavigate();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const [cobertura, setCobertura] = useState<SegSelectOption>();
  const [openCobertura, setOpenCobertura] = useState<boolean>(false)

  const [tipoMoneda, setTipoMoneda] = useState<string>();

  const [sumaAsegurada, setSumaAsegurada] = useState<string>();
  const [errorCaptcha, setErrorCaptcha] = useState("");

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const radioRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<ICobertura>();

  const methods = useForm<ICobertura>();
  const { compania } = useParams();

  const coberturas = [
    { value: 'separator', label: 'Aduaneras' },
    { value: 'Aduaneras;Transito Terrestre', label: 'Tránsito terrestre' },
    { value: 'Aduaneras;Importacion Temporal', label: 'Importación temporal' },
    { value: 'Aduaneras;Falta de Documentacion', label: 'Falta de documentación' },
    { value: 'Aduaneras;Diferencia de Derechos', label: 'Diferencia de derechos' },
    { value: 'separator', label: 'Contractuales' },
    { value: 'Contractuales;Ofertas', label: 'Ofertas' },
    { value: 'Contractuales;Ejecucion de Contratos', label: 'Ejecución de contrato' },
    { value: 'Contractuales;Anticipo', label: 'Anticipo' },
    { value: 'Contractuales;Acopio', label: 'Acopio' },
    { value: 'Contractuales;Fondo de Reparo', label: 'Fondo de reparo' },
    { value: 'separator', label: 'Directores' },
    { value: 'Directores;S.A.', label: 'S.A.' },
    { value: 'Directores;SRL', label: 'SRL' },
    { value: 'separator', label: 'Otros' },
    { value: 'Otros;Alquiler', label: 'Alquiler' },
    { value: 'Otros;Judicial', label: 'Judicial' },
    { value: 'Otros;Turismo', label: 'Turismo' }
  ];

  const tipoMonedas = [
    { value: 'pesos', label: 'Pesos' },
    { value: 'dolares', label: 'Dolares' }
  ];

  useEffect(() => {
    if (cotizacionValues.cobertura) {
      setCobertura(cotizacionValues.cobertura)
    }
  }, []);

  useEffect(() => {
    if (cotizacionValues?.cobertura) {
      setCobertura(cotizacionValues?.cobertura)
      setValue("cobertura", cotizacionValues.cobertura?.value);
      setOpenCobertura(false)
    } else {
      setOpenCobertura(true)
    }
    if (cotizacionValues?.tipoMoneda) {
      setTipoMoneda(cotizacionValues.tipoMoneda);
      setValue("tipoMoneda", cotizacionValues.tipoMoneda)
    }
    if (cotizacionValues?.sumaAsegurada) {
      setValue("sumaAsegurada", cotizacionValues.sumaAsegurada)
    }
  }, []);

  const onSubmit = (data: ICobertura) => {
    setErrorCaptcha("");
    const { sumaAsegurada } = data;
    const key = process.env.REACT_APP_RECAPTCHA_KEY
    grecaptcha.ready(function () {
      grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
        setCotizacionValues({
          ...cotizacionValues,
          cobertura: cobertura,
          tipoMoneda: tipoMoneda,
          sumaAsegurada: sumaAsegurada.replace(/\./g, ''),
          captchaToken: token,
        });
        compania
          ? nav("/resultado-del-cotizador-de-caucion" + compania)
          : nav("/resultado-del-cotizador-de-caucion");
      })
    });
  };

  const handleRadioChange = (selectedValue: string) => {
    setTipoMoneda(selectedValue);
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');

  useEffect(() => {
    (!cotizacionValues.provincia || !cotizacionValues.localidad) && (compania ? nav("/caucion/cotizacion/" + compania + "/codigo-postal") : nav("/caucion/cotizacion/codigo-postal"))
    if (error === "El Captcha no es válido") {
      setErrorCaptcha(error);
    }
  }, [])

  const formatNumber = (value: string) => {
    const parts = value.split(',');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Añade puntos cada 3 dígitos
    return parts.join(',');
  };

  const unformatNumber = (value: string) => {
    return value.replace(/\./g, '');
  };

  useEffect(() => {
    if (cobertura) {
      if (tipoMoneda) {
        inputRef.current?.focus()
      }
      else {
        radioRef.current?.focus();
      }
    }
  }, [cobertura, tipoMoneda]);

  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01">
        <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
          <div className="cod-postal-column justify-content-center gap-4">
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <legend className="mb-0">Seleccioná el tipo cobertura</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={cobertura}
                    setSelected={setCobertura}
                    name="cobertura"
                    options={coberturas}
                    isGrupable
                    width={"100%"}
                    borderColor={errors.cobertura ? "red" : ""}
                    openDefault={openCobertura}
                    autoFoco={true}
                  />
                </FormProvider>
                <p>{errors.cobertura?.message}</p>
              </fieldset>
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-3" style={{ marginBottom: "1rem" }}>
              <legend>Seleccioná tipo de moneda</legend>
              <SegRadioButton
                options={tipoMonedas}
                onRadioChange={handleRadioChange}
                selected={tipoMoneda}
                width="100%"
                inputRef={radioRef}
              />
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <legend>Ingresá la suma asegurada</legend>
              <div className="m-auto d-flex justify-content-center">
                <Controller
                  name="sumaAsegurada"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => (!!value || "Ingresá la suma asegurada"),
                      pattern: (value) => /^[0-9,.]+$/.test(value) || "Suma asegurada inválida",
                      minSalario: (value) => {
                        const numericValue = parseFloat(unformatNumber(value).replace(/,/g, ''));
                        return !(numericValue <= 0) || "Suma asegurada inválida";
                      },
                      noLeadingZero: (value) => {
                        const numericValue = unformatNumber(value);
                        return numericValue[0] !== '0' || "El primer dígito no puede ser 0";
                      },
                      maxDecimals: (value) => {
                        const parts = value.split(',');
                        return parts.length <= 2 || "Solo se permite una coma"; // Asegura que solo haya una coma
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <SegInput
                      isGroup={true}
                      labelGroup={tipoMoneda === "pesos" ? "ARS" : "U$D"}
                      className=""
                      label=""
                      name="sumaAsegurada"
                      type="text"
                      inputMode="numeric"
                      width={100}
                      maxLenghtDigits={11}
                      placeHolder="0"
                      ref={inputRef}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9,]/g, ''); // Filtra letras y caracteres no deseados

                        // Permite solo una coma
                        const parts = value.split(',');
                        if (parts.length > 2) {
                          value = parts[0] + ',' + parts.slice(1).join('').replace(/,/g, '');
                        }

                        // Limita los decimales a 2
                        if (parts.length > 1) {
                          parts[1] = parts[1].substring(0, 2);
                          value = parts[0] + ',' + parts[1];
                        }

                        // Añade separadores de miles
                        value = formatNumber(value);
                        field.onChange(value);
                        setValue("sumaAsegurada", value);
                        trigger("sumaAsegurada");
                      }}
                      value={field.value}
                      leyendaError={fieldState?.error?.message}
                      borderColor={`${errors.sumaAsegurada
                        ? "var(--bs-form-invalid-border-color)"
                        : ""
                        }`}
                      focusBorderColor={`${errors.sumaAsegurada
                        ? "var(--bs-form-invalid-border-color)"
                        : "#2c7dfd"
                        }`}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="form-buttons text-center">
            {errorCaptcha &&
              <div style={{ margin: "0 auto 20px auto" }} className="alert alert-danger w-25" role="alert">
                {errorCaptcha}
              </div>
            }
            <SegButton
              label="Continuar"
              type="submit"
              disabled={cobertura?.value && tipoMoneda !== "" && sumaAsegurada !== "" ? false : true}
              ref={btnRef}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
