import { useEffect, useState } from 'react'
import { SegHeaderLogo } from '../../../SegUI/SegHeaderLogo/SegHeaderLogo'
import segurarse from "../../../../Assets/img/logo_segurarse.svg";
import { SegStepper } from '../../../SegUI/SegStepper/SegStepper';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import AppStoreLogo from '../../../../Assets/img/badge_app-store_black_es.svg';
import PlayStoreLogo from '../../../../Assets/img/playStoreLogo.png';
import TelefonoImg from '../../../../Assets/img/telefonosCruzados.png';
import TelefonoImg2 from '../../../../Assets/img/telefono-mobile.png';
import descargarPoliza from '../../../../Services/API/DescargarPoliza';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';

export const SolicitudEnviada2 = () => {
    const { emisionValues, resetEmisionValues } = useEmisionStore();
    const { resetCotizacionValues } = useCotizacionStore();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [poliza, setPoliza] = useState<boolean>(false);
    const [certificado, setCertificado] = useState<boolean>(false);
    const [linkPoliza, setLinkPoliza] = useState<string>("");
    const [linkCertificado, setLinkCertificado] = useState<string>("");
    const [esperando, setEsperando] = useState<boolean>(true);
    const nav = useNavigate()

    useEffect(() => {
        const handleBackNavigation = () => {
            resetCotizacionValues()
            resetEmisionValues()
            nav('/auto/cotizacion/patente', { replace: true })
            window.removeEventListener('popstate', handleBackNavigation)
        }
        window.addEventListener('popstate', handleBackNavigation)
    }, [nav]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchPoliza = async () => {
            try {
                const response = await descargarPoliza(emisionValues.id!);
                if (response.api_status) {
                    setEsperando(false);
                    if (response.api_status === 200) {
                        if (response.api_data.certificado) {
                            setCertificado(true);
                            setLinkCertificado(response.api_data.certificado);
                        }
                        if (response.api_data.poliza) {
                            setPoliza(true);
                            setLinkPoliza(response.api_data.poliza);
                        }
                    } else {
                        //console.log(response.api_status)
                    }
                } else {
                    setEsperando(false);
                    console.log("Error al comunicar con API")
                }
            } catch (error) {
                setEsperando(false);
                console.error("Error occurred while uploading the file:", error);
            }
        }
        fetchPoliza();
    }, [])

    return (
        <>
            <div className="container">
                <div className="row mt-3 mb-3">
                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                        <SegHeaderLogo img={segurarse} alt="Logo Segurarse" width="160px" />
                    </div>
                </div>
            </div>
            <div>
                <SegStepper
                    label={"¡Estamos muy felices de que utilices nuestros servicios!"}
                    isStepper={false}
                    className="fs-20 fw-bold bg-primary text-center px-3 pt-1 pb-1 mb-m13 minh-90p pt-lg-35 pb-lg-30 mb-lg-m30 minh-lg-125p"
                />
            </div>
            <div className='container bg-white rounded pt-5 fs-lg-20 p-5 container-constancia' style={{ width: "90%", justifyContent: "flex-end" }}>
                <div>
                    <p className='text-left fs-18'>
                        Ya enviamos tu solicitud de cobertura <span className='fw-bold'>{emisionValues.cotizacionDesc?.planName}</span> a la Aseguradora <span className='fw-bold'>{emisionValues.cotizacionDesc?.companyName}</span>.
                    </p>
                    <p className='text-left fs-18'>
                        <span className='fw-bold'>IMPORTANTE: Tené en cuenta que, en caso de corresponder, debes inspeccionar tu vehículo dentro de las 72hs hábiles.</span> Estarás recibiendo un correo electrónico con las instrucciones detalladas sobre cómo realizarla.
                    </p>
                    {esperando &&
                        <div style={{ marginBottom: "1rem" }}>
                            <div className="spinner-border spinner-border-sm" style={{ width: "25px", height: "25px" }}>
                            </div>
                            <span style={{ marginRight: "5px" }} role="status"></span>
                        </div>
                    }

                    {!poliza && certificado ? <p className='text-left fs-18'>
                        Te recordamos que estarás cubierto una vez que la Aseguradora <span className='fw-bold'>{emisionValues.cotizacionDesc?.companyName}</span> apruebe la inspección y recibas la póliza por mail.
                    </p> : ""}
                    {poliza || certificado ?
                        (
                            <div className='fs-18' style={{ marginBottom: "20px" }}>
                                <p>Descargá aquí la documentación de tu póliza. </p>
                                <ul>
                                    {certificado &&
                                        <div style={{ display: "flex" }}>
                                            <a style={{ paddingRight: "5px" }} href={`data:application/pdf;base64,${linkCertificado}`} download={"Certificado de Circulación Provisorio"}><li>Certificado de Circulación Provisorio</li></a>
                                            <div>
                                                <SimCardDownloadIcon style={{ color: "#2C7DFD" }} />
                                            </div>
                                        </div>
                                    }
                                    {poliza &&
                                        <div style={{ display: "flex" }}>
                                            <a style={{ paddingRight: "5px" }} href={`data:application/pdf;base64,${linkPoliza}`} download="Póliza electrónica"><li>Póliza electrónica</li></a>
                                            <div>
                                                <SimCardDownloadIcon style={{ color: "#2C7DFD" }} />
                                            </div>
                                        </div>
                                    }
                                </ul>
                            </div>
                        )
                        : ""}
                    <p className='text-left fs-18'>
                        Cualquier duda podés contactarnos al <span className='fw-bold'><a href="tel:0810-444-7348" target='_blank' rel="norreferer noreferrer" className="a-dark">0810 444 SEGUROS (7348)</a> / <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="norreferer noreferrer" className="a-dark">WhatsApp: +54 9 11 6956-8002</a></span> en nuestros horarios
                        de atención: lunes a viernes de 9hs a 18hs .
                    </p>
                    <p className='text-left fs-18' style={{ fontStyle: "italic" }}>
                        La protección de tus datos es nuestra prioridad, nunca te solicitaremos el código de seguridad de las tarjetas de crédito
                        por ningún motivo.
                    </p>
                </div>
            </div>
            {windowWidth >= 873 ?
                <div style={{ width: "100%", backgroundColor: "#2C7DFD", paddingTop: "3rem" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "44%", display: "flex", justifyContent: "flex-end" }}>
                            <img
                                style={{ position: "relative", right: "-125px" }}
                                src={TelefonoImg}
                                height="400"
                                alt="Smartphone"
                            />
                        </div>
                        <div style={{ paddingLeft: "1rem" }}>
                            <div className='cot-header tittle' style={{ paddingTop: "0px", fontSize: "40px", color: "#fff", fontWeight: "700" }}>Tu seguro en tu celular</div>
                            <div>
                                <ul style={{ fontSize: "20px", color: "#fff" }}>
                                    <li><strong>Ver y descargar</strong> tus pólizas</li>
                                    <li><strong>TENER</strong> tu credencial digital siempre</li>
                                    <li><strong>Renovar o cotizar</strong> un nuevo seguro</li>
                                    <li><strong>Denunciar</strong> robo o siniestro, enviando mensaje de voz y fotos en tiempo real</li>
                                    <li><strong>Solicitar</strong> asistencia mecánica</li>
                                    <li><strong>Autorizar</strong> a un tercero</li>
                                    <li><strong>Cambiar</strong> forma de pago</li>
                                    <li><strong>Realizar</strong> consultas varias por chat</li>
                                </ul>
                                <div className='subtitulo-add-app'>
                                    <div style={{ marginBottom: "1rem" }}>Descargá gratis <strong>MiSegurarse</strong> y autogestioná tu seguro.</div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "20px" }}>
                                            <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=es_AR&gl=US" target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={PlayStoreLogo}
                                                    height="50"
                                                    alt="Playstore"
                                                />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800" target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={AppStoreLogo}
                                                    height="50"
                                                    alt="AppStore"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div style={{ width: "100%", backgroundColor: "#2C7DFD" }}>
                    <div className='cot-header tittle' style={{ paddingTop: "3rem", fontSize: "30px", color: "#fff", fontWeight: "700", marginBottom: ".5rem", paddingLeft: ".7rem", paddingRight: ".7rem", paddingBottom: "0px" }}>Tu seguro en tu celular</div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: ".7rem", paddingRight: ".7rem" }}>
                        <div>
                            <ul style={{ fontSize: "15px", color: "#fff" }}>
                                <li><strong>Ver y descargar</strong> tus pólizas</li>
                                <li><strong>Tener</strong> tu credencial digital siempre</li>
                                <li><strong>Renovar o cotizar</strong> un nuevo seguro</li>
                                <li><strong>Denunciar</strong> robo o siniestro, enviando mensaje de voz y fotos en tiempo real</li>
                                <li><strong>Solicitar</strong> asistencia mecánica</li>
                                <li><strong>Autorizar</strong> a un tercero</li>
                                <li><strong>Cambiar</strong> forma de pago</li>
                                <li><strong>Realizar</strong> consultas varias por chat</li>
                            </ul>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div className='subtitulo-add-app-mobile' style={{ paddingLeft: "0px" }}>
                                <div style={{ marginBottom: "1rem" }}>Descargá gratis <strong>MiSegurarse</strong> y autogestioná tu seguro.</div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ marginRight: "10px" }}>
                                        <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=es_AR&gl=US" target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={PlayStoreLogo}
                                                height="40"
                                                alt="PlayStore"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800" target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={AppStoreLogo}
                                                height="40"
                                                alt="AppStore"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "3rem" }}>
                                <img
                                    src={TelefonoImg2}
                                    height="360"
                                    alt="Smartphone"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
