import { Header as NavBar } from '../Components/Header';
import shape02 from "../../../Assets/img/shape02.svg";
import shape05 from "../../../Assets/img/shape05.svg";
import shape06 from "../../../Assets/img/shape06.svg";
import { politicas_privacidad } from '../diccionario';

export const PoliticasPrivacidad = () => {
    return (
        <>
            <NavBar rubro='auto' />

            <main>
                <div className="position-relative pt-45 pb-25 pt-lg-80 pb-lg-35">
                    <img className="d-none d-lg-block pos02" src={shape02} alt='' />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-10">
                            Políticas de privacidad
                        </h2>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='gx-5 gx-lg-4 col-lg-9'>
                            {
                                politicas_privacidad.map(item => (
                                    <div key={item.titulo}>
                                        <h3 className="mb-1 fs-16 fw-bold text-primary">
                                            {item.titulo}
                                        </h3>
                                        <p className='text-black mb-25 text-justify'>
                                            {item.content}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>

                <div className="bg-f4 py-30 py-lg-50 position-relative">
                    <img className="d-none d-lg-block pos07 bottom-0" src={shape05} alt='' />
                    <img className="d-none d-lg-block pos06" src={shape06} alt='' />
                    <div className="container px-lg-5">
                        <div className="row justify-content-evenly">
                            <div className="col-lg-auto text-center text-lg-start">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-lg-1">
                                    ¿No resolvimos<br className="d-sm-none" />
                                    tu consulta?
                                </h2>
                                <p className="fw-bold fs-25 fs-lg-25 text-primary mb-18 mb-lg-0">¡Estamos para vos!</p>
                            </div>
                            <div className="col-lg-auto text-center text-lg-left">
                                <a href="/contacto" className="transition-base d-inline-block w-250p py-12 rounded-3 fs-22 fs-lg-24 fw-bold lh-1 text-center bg-primary bg-lg-gray-dark hover-bg-primary text-white">CONTACTO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
