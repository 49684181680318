import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { IPasos } from "../../../../Pages/AP/Cotizador";

type FormValues = {
  setNombre: string;
  setApellido: string;
};

interface NombreProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  nombreValue: string | undefined;
  apellidoValue: string | undefined;
}

export const NombreApellido = ({ setSteps, nombreValue, apellidoValue }: NombreProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setNombre, setApellido } = values;
    setCotizacionValues({
      ...cotizacionValues,
      nombre: setNombre,
      apellido: setApellido,
    });
    compania
      ? nav("/ap/individuo/cotizacion/" + compania + "/telefono") : nav("/ap/individuo/cotizacion/telefono");
  };



  useEffect(() => {
    setValue("setNombre", nombreValue ? nombreValue : ""); // Actualiza el valor en React Hook Form
    nombreValue && trigger();
    setValue("setApellido", apellidoValue ? apellidoValue : ""); // Actualiza el valor en React Hook Form
    nombreValue && trigger();
  }, [nombreValue, apellidoValue]);

  return (
    <div className="mt-4 bg-gray-light text-center">
      <div className="frm01">
        <legend>¿Cuál es tu nombre?</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="m-3">
            <Controller
              name="setNombre"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá el nombre",
                  pattern: (value) =>
                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                    "Nombre inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mw-sm-450 m-auto"
                  label=""
                  name="setNombre"
                  type="text"
                  width={100}
                  placeHolder="Ej: Juan"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setNombre", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setNombre");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setNombre
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setNombre
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <legend>¿Y tu apellido?</legend>
          <div className="m-3">
            <Controller
              name="setApellido"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá el apelldio",
                  pattern: (value) =>
                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                    "Nombre inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mw-sm-450 m-auto"
                  label=""
                  name="setApellido"
                  type="text"
                  width={100}
                  placeHolder="Ej: García"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setApellido", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setApellido");
                  }}
                  value={field.value}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setApellido
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setApellido
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary mb-4"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
