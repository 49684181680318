import { create } from 'zustand';
import { createJSONStorage, persist } from "zustand/middleware";

export interface Descripcion {
  Id: number;
  RubroId: number | null;
  RubroDescripcion: string;
  CompaniaId: number | null;
  CompaniaDescripcion: string;
  ProductoTipo2Id: number | null;
  ProductoTipo2Descripcion: string;
  CodigoCompania: string;
  ItemDescripcion2Id: number | null;
  ItemDescripcion2Nombre: string;
  Texto: string;
  Aclaraciones: string | null;
  TextoComparativa: string | null;
  TextoDefault: string;
}

interface DescripcionesStore {
  descripcionesValues: Descripcion[];
  setDescripcionesValues: (values: Descripcion[]) => void;
  resetDescripciones: () => void;
}

export const useDescripcionesStore = create<DescripcionesStore>()(
  persist(
    (set) => ({
      descripcionesValues: [],
      setDescripcionesValues: (values: Descripcion[]) => set({ descripcionesValues: { ...values } }),
      resetDescripciones: () => set({ descripcionesValues: [] })
    }),
    {
      name: "descripciones",
      storage: createJSONStorage(() => sessionStorage),
    }
  ));