import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";
import { SegToggle } from "../../../../SegUI/SegToggle/SegToggle";

type FormValues = {
  setMasaSalarial: string;
};

interface MasaSalarialProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  masaSalarialValue: string | undefined;
}

export const SueldoBrutoConyuge = ({ setSteps, masaSalarialValue, ...rest }: MasaSalarialProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [isIncluirHijos, setIncluirHijos] = useState<boolean>(false);


  const { handleSubmit, control, setValue, setError, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setMasaSalarial } = values;
    setCotizacionValues({
      ...cotizacionValues,
      hijos: isIncluirHijos,
      sueldoBrutoConyuge: setMasaSalarial.replace(/\./g, ''),
    });

    if (isIncluirHijos) {
      if (compania) {
        nav("/prepaga/individuo/cotizacion/" + compania + "/hijos")
      }
      else {
        nav("/prepaga/individuo/cotizacion/hijos");
      }
    }
    else {
      nav("/resultado-del-cotizador-de-salud");
    }
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');



  useEffect(() => {
    if (error) {
      setError("setMasaSalarial", {
        type: 'custom',
        message: error,
      });
    } else {
      setValue("setMasaSalarial", masaSalarialValue ? masaSalarialValue : "");
      masaSalarialValue && trigger()
    }
  }, []);

  useEffect(() => {
    !cotizacionValues.documentoConyuge && (compania ? nav("/prepaga/individuo/cotizacion/" + compania + "/documento-conyuge") : nav("/prepaga/individuo/cotizacion/documento-conyuge"))
  }, [])

  const formatNumber = (value: string) => {
    const newValues = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Añade puntos cada 3 dígitos
    return newValues;
  };

  const unformatNumber = (value: string) => {
    return value.replace(/\./g, '');
  };

  useEffect(() => {
    if (cotizacionValues.hijos) {
      setIncluirHijos(cotizacionValues.hijos)
    }
  }, []);

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>Sueldo bruto del cónyuge</legend>
        <h6>Solo números, sin puntos ni comas</h6>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setMasaSalarial"
              control={control}
              rules={{
                validate: {
                  required: (value) => (!!value || "Ingresá el sueldo bruto"),
                  pattern: (value) => /^[0-9.]+$/.test(value) || "Sueldo bruto inválid0",
                  minSalario: (value) => {
                    const numericValue = parseFloat(unformatNumber(value).replace(/,/g, ''));
                    return numericValue >= 0 || "Sueldo bruto inválido";
                  },
                  noLeadingZero: (value) => {
                    const numericValue = unformatNumber(value);
                    if (numericValue[0] === '0' && numericValue[1]) {
                      return "El primer dígito no puede ser 0"
                    }
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  isGroup={true}
                  labelGroup="$"
                  className="mt-3"
                  label=""
                  name="setMasaSalarial"
                  type="text"
                  width={92}
                  placeHolder="0"
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, '');
                    value = formatNumber(value);
                    field.onChange(value);
                    setValue("setMasaSalarial", value); // Actualiza el valor en React Hook Form
                    trigger("setMasaSalarial");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setMasaSalarial
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setMasaSalarial
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-center mt-18">
            <h5 className="fw-bold pe-20">Incluir hijos</h5>
            <SegToggle idLabel="reciboSueldo" label="No" isChecked={isIncluirHijos} onChange={setIncluirHijos} />
            <div style={{ marginLeft: "-8px" }}>Si</div>
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary mb-4"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
