const ROMANOS = [
    { valor: 1000, numeral: "M" },
    { valor: 900, numeral: "CM" },
    { valor: 500, numeral: "D" },
    { valor: 400, numeral: "CD" },
    { valor: 100, numeral: "C" },
    { valor: 90, numeral: "XC" },
    { valor: 50, numeral: "L" },
    { valor: 40, numeral: "XL" },
    { valor: 10, numeral: "X" },
    { valor: 9, numeral: "IX" },
    { valor: 5, numeral: "V" },
    { valor: 4, numeral: "IV" },
    { valor: 1, numeral: "I" }
];

export function convertirARomano(numero: number) {
    let resultado = '';

    for (let i = 0; i < ROMANOS.length; i++) {
        while (numero >= ROMANOS[i].valor) {
            resultado += ROMANOS[i].numeral;
            numero -= ROMANOS[i].valor;
        }
    }

    return resultado;
}