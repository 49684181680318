import { Controller, useForm } from 'react-hook-form';
import { RubroTypes } from '../../../types';
import { ICotizacionValues } from '../../../Services/Providers/CotizacionStore';
import { SegInput } from '../../SegUI/SegInput/SegInput';
import { useEffect, useState } from 'react';

type ReactFormData = {
    control: ReturnType<typeof useForm>['control'];
    setValue: ReturnType<typeof useForm>['setValue'];
    formState: ReturnType<typeof useForm>['formState'];
    trigger: ReturnType<typeof useForm>['trigger'];
    getValues: ReturnType<typeof useForm>['getValues'];
    setError: ReturnType<typeof useForm>['setError'];
    watch: ReturnType<typeof useForm>['watch'];
    clearErrors: ReturnType<typeof useForm>['clearErrors'];
};

type InputProps = {
    inputData: {
        stepId: string;
        nextStep: string;
        title?: string;
        subtitle?: string;
        section?: string;
        fields: RubroTypes.Field[];
        inputMode?: string;
    };
    ReactFormData?: ReactFormData;
    HandleInputChange?: (value: any) => any;
    currentValues?: any;
    onEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}


export const DefaultInput: React.FC<InputProps> = ({ inputData: { stepId, nextStep, title, subtitle, section, fields, ...rest }, ReactFormData, HandleInputChange, currentValues, onEnterPress }) => {

    useEffect(() => {
        if (ReactFormData) {
            fields.map((field) => {
                if (currentValues[field.name]) {
                    ReactFormData.setValue(field.name, currentValues[field.name]);
                    ReactFormData.trigger(field.name);
                }
            })
        }
    }, [])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onEnterPress(event);
        }
    };

    return (
        <>
            {ReactFormData ? fields.map((input, index) => (
                <div key={input.name}>
                    <div>
                        <legend>{input.label}</legend>
                        <div className="m-3">
                            <Controller
                                name={input.name}
                                control={ReactFormData.control}
                                rules={input.rules}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        className="mw-sm-450 m-auto"
                                        label={input.helper || ''}
                                        name={field.name}
                                        type={input.type}
                                        inputMode={input.inputMode ? input.inputMode : "text"}
                                        width={92}
                                        placeHolder={input.placeholder}
                                        onChange={(e) => {
                                            const regex = input.regex ? new RegExp(input.regex) : undefined;
                                            let newValue = e.target.value;

                                            if ((regex && regex.test(newValue)) || !regex || newValue === "") {
                                            if (HandleInputChange !== undefined) { HandleInputChange(e) }
                                            field.onChange(e);
                                                ReactFormData.setValue(input.name, newValue);
                                                ReactFormData.trigger(input.name);
                                            }

                                        }}
                                        onKeyDown={handleKeyDown}
                                        value={currentValues[input.name]}
                                        autoFocus={index === 0}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${ReactFormData.formState.errors[input.name] ? "var(--bs-form-invalid-border-color)" : ""}`}
                                        focusBorderColor={`${ReactFormData.formState.errors[input.name] ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}`}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            )) : <></>}
        </>
    )
}
