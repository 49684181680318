import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import logoblue from "../../Assets/img/logo_segurarse.svg";
import { SegSvg } from "../../Components/SegUI/SegSvg/SegSvg";
import { SegButton } from "../../Components/SegUI/SegButton/SegButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useConfiguracionEmpresa } from "../../Services/Providers/useConfiguracionEmpresa";
import useSEOConfig from "../../hooks/seo/useSEOConfig";

export const NotFound = () => {
  const { rubro } = useParams();
  const { config, origen } = useConfiguracionEmpresa();
  const nav = useNavigate();
  useSEOConfig({
    title: "Auto",
    marcaBlanca: (config.origen !== "" && config.origen !== "SEGURARSE") ? config.origen.charAt(0).toUpperCase() +
      config.origen.slice(1).toLowerCase() : undefined
  })


  const handleClick = () => {
    nav("/");
    window.location.reload();
  };


  useEffect(() => {
    if (origen !== "SEGURARSE") {
      document.body.style.background = config.colors.primary;
    } else {
      document.body.classList.add("bg-primary");
    }
    return () => {
      document.body.classList.remove("bg-primary");
      document.body.style.background = "";
    };

  }, [origen]);


  return (
    <>
      <div>
        <SegSvg />
        <header className="site-header sticky-lg-top">
          <div className="container">
            {
              origen !== "SEGURARSE" ?
                <div className="row">
                  <div className="col-md-6 offset-md-3 text-center d-flex justify-content-center gap-3">
                    {" "}
                    {/* Centra el contenido en la columna */}
                    <SegHeaderLogo
                      img={config.images.logo}
                      alt="Logo Segurarse"
                      width={window.innerWidth > 768 ? "150px" : "120px"}
                    />
                    <div style={{ height: "100%", width: "2px", backgroundColor: "gray" }}></div>
                    <SegHeaderLogo
                      img={logoblue}
                      alt="Logo Segurarse"
                      width={window.innerWidth > 768 ? "140px" : "120px"}
                      style={{ filter: "grayscale(100%)" }}
                    />
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-md-6 offset-md-3 text-center">
                    {" "}
                    {/* Centra el contenido en la columna */}
                    <SegHeaderLogo
                      img={logoblue}
                      alt="Logo Segurarse"
                      width="160px"
                    />
                  </div>
                </div>
            }

          </div>
        </header>
        <div className="text-center pt-5">
          <svg className="" style={{ width: "150px" }}>
            <use
              xlinkHref={
                rubro === "auto"
                  ? "#ico-auto"
                  : rubro === "moto"
                    ? "#ico-moto"
                    : rubro === "prepaga"
                      ? "#ico-prepaga"
                      : rubro === "art"
                        ? "#ico-art"
                        : rubro === "caucion"
                          ? "#ico-hombre"
                          : ""
              }
              fill="white"
            />
          </svg>
        </div>
        <div className="text-center d-flex flex-column">
          <h1 className="fs-2 text-white">
            ¡Ups! Lo sentimos, esta página no está asegurada por nosotros...
          </h1>
          <p className="mt-3 text-white fs-5">
            Pero... ¡no te vayas! tenemos muchas coberturas para que estés
            siempre seguro
          </p>
          <div className="mb-5 mt-4">
            <SegButton
              onClick={handleClick}
              label="Volver al cotizador"
              type="button"
              disabled={false}
              bgColor="white"
              textColor={origen !== "SEGURARSE" ? config.colors.secondary : "#2C7DFD"}
              hoverBgColor="#e8f1ff"
              hoverTextColor={origen !== "SEGURARSE" ? config.colors.secondary : "#0261f7"}
              borderColor={origen !== "SEGURARSE" ? config.colors.secondary : undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
};
