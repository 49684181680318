import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/Caucion/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler, FormProvider } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { SegSlider } from "../../../SegUI/SegSlider/SegSlider";
import { imagesCompanias } from "../../../../Assets/imageCompaniaCaucion";


interface IDocumento {
  tipoDoc: string;
  numero: string;
}

interface DocumentoProps {
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Documento = ({
  setSteps,
  ...rest
}: DocumentoProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
  const [openSelect, setOpenSelect] = useState<boolean>(false)
  const [errorNumero, setErrorNumero] = useState<boolean>(true)

  const [numero, setNumero] = useState<string>();
  const numeroInputRef = useRef<HTMLInputElement>(null);

  const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<IDocumento>();
  //const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubmit = (values: IDocumento) => {
    const { numero } = values;
    setCotizacionValues({
      ...cotizacionValues,
      tipoDoc: tipoDoc,
      cuit: numero,
    });
    if (tipoDoc?.value === "dni") {
      if (compania) {
        nav("/caucion/cotizacion/" + compania + "/nombre-y-apellido")
      }
      else {
        nav("/caucion/cotizacion/nombre-y-apellido")
      }
    }
    else {
      if (compania) {
        nav("/caucion/cotizacion/" + compania + "/cuit-y-razon-social")
      }
      else {
        nav("/caucion/cotizacion/cuit-y-razon-social")
      }
    }

  };

  const methods = useForm<IDocumento>();

  useEffect(() => {
    if (cotizacionValues?.tipoDoc) {
      setTipoDoc(cotizacionValues?.tipoDoc)
      setValue("tipoDoc", cotizacionValues.tipoDoc.value);
      setOpenSelect(false)
    } else {
      setOpenSelect(true)
    }
    if (cotizacionValues.cuit) {
      setNumero(cotizacionValues.cuit)
      setValue("numero", cotizacionValues.cuit)
      setErrorNumero(false)
    }
  }, []);

  useEffect(() => {
    if (numero) {
      setErrorNumero(false)
    }
  }, [numero])

  useEffect(() => {
    setValue("tipoDoc", tipoDoc ? tipoDoc.value : ""); // Actualiza el valor en React Hook Form
    setValue("numero", numero ? numero : "");
    // tipoDoc && trigger();
    // numero && trigger();
  }, [tipoDoc, numero]);

  useEffect(() => {
    if (tipoDoc?.value !== cotizacionValues.tipoDoc?.value) {
      setErrorNumero(true)
      setValue("numero", "");
      numero && trigger()
      numeroInputRef.current?.focus();
    }
  }, [tipoDoc]);

  const tiposDoc = [
    { value: 'dni', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' }
  ];

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>Ingresá el número de documento</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center">
            <div className="row gx-3 text-start mw-sm-450 m-1">
              <fieldset className="col-4">
                <FormProvider {...methods}>
                  <SegSelect
                    label="Tipo"
                    selected={tipoDoc}
                    setSelected={setTipoDoc}
                    name="tipoDoc"
                    options={tiposDoc}
                    isGrupable
                    width={"100%"}
                    borderColor={errors.tipoDoc ? "red" : ""}
                    openDefault={openSelect}
                    autoFoco={true}
                    placeholder=""
                    handleSubmit={() => {
                      if (cotizacionValues.razonSocial !== '')
                        setCotizacionValues({
                          ...cotizacionValues,
                          nombre: '',
                          apellido: '',
                        })
                      setNumero('')
                    }}
                  />
                </FormProvider>
                <p>{errors.tipoDoc?.message}</p>
              </fieldset>
              <Controller
                name="numero"
                control={control}
                rules={{
                  validate: {
                    required: (value) => {
                      if (!value) {
                        setErrorNumero(true)
                        return "Ingresá el Número"
                      } else {
                        setErrorNumero(false)
                      }
                    },
                    pattern: (value) => {
                      if (tipoDoc?.value === 'dni') {
                        if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                          setErrorNumero(false)
                        } else {
                          setErrorNumero(true)
                          return "CUIL inválido"
                        }
                      } else if (tipoDoc?.value === 'cuit') {
                        if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                          setErrorNumero(false)
                        } else {
                          setErrorNumero(true)
                          return "CUIT inválido"
                        }
                      }
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    disabled={!tipoDoc}
                    className="col-8"
                    label="Sin puntos, guiones ni espacios"
                    name="setNumero"
                    type="number"
                    width={66}
                    placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("numero", e.target.value); // Actualiza el valor en React Hook Form
                      trigger("numero");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${errors.numero
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${errors.numero
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                    ref={numeroInputRef}
                  />
                )}
              />
            </div>
          </div>
          {/*errorMessage && (
            <div className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )*/}
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={errorNumero}
            />
          </div>
        </form>
      </div>
      <div className="slide-cias" style={{ marginTop: adaptativeMargin }}>
        <SegSlider companias={imagesCompanias} />
      </div>
    </div>
  );
};
