import allianz from "../Assets/img/logos/cias/logo_allianz.svg"
import sancor from "../Assets/img/logos/cias/logo_sancor.svg"
import zurich from "../Assets/img/logos/cias/logo_zurich.svg"
import mapfre from "../Assets/img/logos/cias/logo_mapfre.svg"
import meridional from "../Assets/img/logos/cias/logo_meridional.svg"
import sanCristobal from "../Assets/img/logos/cias/logo_sancristobal.svg"
import swissMedical from "../Assets/img/logos/cias/logo_swiss_medical_medicina_b.png"
import chubb from "../Assets/img/logos/cias/logo_chubb_b.png"
import mercantil from "../Assets/img/logos/cias/logo_mercantil.svg"
import galicia from "../Assets/img/logos/cias/logo_rsa_b.png"
import orbis from "../Assets/img/logos/cias/logo_orbis.svg"
import galeno from "../Assets/img/logos/cias/logo_galeno.svg"
import prevencionArt from "../Assets/img/logos/cias/logo_prevencion_art_b.png"
import provincia from "../Assets/img/logos/cias/logo_provinciaseguros_art_b.png"
import cruzSuiza from "../Assets/img/logos/cias/logo_cruzsuiza_b.png"
import universalAssistance from "../Assets/img/logos/cias/logo_universal_b.png"
import prevencionSalud from "../Assets/img/logos/cias/logo_prevencionsalud_b.png"
import premiar from "../Assets/img/logos/cias/logo_premiar_b.png"
import insur from "../Assets/img/logos/cias/logo_Insur.png"
import cyc from "../Assets/img/logos/cias/logo_cyc.png"


export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "allianz",
        imgRuta: allianz,
        mostrar: true
    },
    {
        nombreCompania: "sancor", 
        imgRuta: sancor,
        mostrar: true
    },
    {
        nombreCompania: "zurich", 
        imgRuta: zurich,
        mostrar: true
    },
    {
        nombreCompania: "mapfre", 
        imgRuta: mapfre,
        mostrar: true
    },
    /*{
        nombreCompania: "meridional",
        imgRuta: meridional,
        mostrar: true
    },*/
    {
        nombreCompania: "san cristobal", 
        imgRuta: sanCristobal,
        mostrar: true
    },
    {
        nombreCompania: "swiss medical", 
        imgRuta: swissMedical,
        mostrar: true
    },
    {
        nombreCompania: "chubb", 
        imgRuta: chubb,
        mostrar: true
    },
    {
        nombreCompania: "mercantil", 
        imgRuta: mercantil,
        mostrar: true
    },
    /*{
        nombreCompania: "galicia",
        imgRuta: galicia,
        mostrar: true
    },
    {
        nombreCompania: "orbis",
        imgRuta: orbis,
        mostrar: true
    },*/
    {
        nombreCompania: "galeno", 
        imgRuta: galeno,
        mostrar: true
    }, 
    /*{
        nombreCompania: "prevencion art",
        imgRuta: prevencionArt,
        mostrar: true
    },*/
    {
        nombreCompania: "provincia seguros", 
        imgRuta: provincia,
        mostrar: true
    },
    /*{
        nombreCompania: "cruz suiza",
        imgRuta: cruzSuiza,
        mostrar: true
    }, 
    {
        nombreCompania: "universalAssistance",
        imgRuta: universalAssistance,
        mostrar: true
    }, 
    {
        nombreCompania: "prevencion salud",
        imgRuta: prevencionSalud,
        mostrar: true
    },*/
    {
        nombreCompania: "premiar", 
        imgRuta: premiar,
        mostrar: true
    }, 
    {
        nombreCompania: "Crédito y caución", 
        imgRuta: cyc,
        mostrar: true
    },
    {
        nombreCompania: "Insur", 
        imgRuta: insur,
        mostrar: true
    },
]