import { useEffect, Dispatch, SetStateAction, useState } from "react";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import obtenerAnios from "../../../../Services/API/Anios";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

interface AnioProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Anio = ({ config, setSteps, ...rest }: AnioProps) => {
  const [anioSelected, setAnioSelected] = useState<SegSelectOption>();
  const [anios, setAnios] = useState<SegSelectOption[]>();
  const nav = useNavigate();
  const methods = useForm();
  const { compania } = useParams();

  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  /*const isYearValid = () => {
    return anioSelected ? parseInt(anioSelected.value, 10) <= 1985 : false;
  };*/

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await obtenerAnios(cotizacionValues?.marca, cotizacionValues?.modelo);
      const formattedData: SegSelectOption[] = response.map((item: any, index: any) => ({
        value: item,
        label: item,
      }));
      setAnios(formattedData);
    };
    fetchData().then(() => {
      if (cotizacionValues?.año) {
        setAnioSelected(cotizacionValues.año)
      }
    });
  }, []);

  const handleSubmit = (anioSelecte: any) => {
    setCotizacionValues({
      ...cotizacionValues,
      año: anioSelecte,
      version: anioSelecte === cotizacionValues?.año ? cotizacionValues?.version : undefined,

    })
    // nav("/auto/cotizacion/version")
    compania ? nav("/auto/cotizacion/" + compania + "/version") : nav("/auto/cotizacion/version");
  }

  useEffect(() => {
    !cotizacionValues.modelo && (compania ? nav("/auto/cotizacion/" + compania + "/modelo") : nav("/auto/cotizacion/modelo"))
  }, [])

  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01">
        <legend className="text-center">{"¿De qué año es?"}</legend>
        <div className="d-flex justify-content-center">
          <div className="col-10 col-sm-8 col-md-5 col-lg-3">
            <FormProvider {...methods}>
              {anios ? (
                <SegSelect
                  options={anios}
                  width={"100%"}
                  height={(window.innerHeight <= 700) ? "sm" : (window.innerHeight <= 910) ? "md" : "lg"}
                  selected={anioSelected}
                  setSelected={setAnioSelected}
                  name="anio"
                  handleSubmit={handleSubmit}
                  hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                  focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                  empresaColor={config.origen !== "SEGURARSE" ? {
                    selected: config.colors.accent,
                    hover: config.colors.tertiary
                  } : undefined}
                />
              ) : (<div className='d-flex justify-content-center'>
                <div className="spinner-border text-center" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>)}
            </FormProvider>
          </div>
        </div>
        {/* <div className="form-buttons">
          <SegButton
            loading={loading}
            label="Continuar"
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!anioSelected ? true : false}
          />
        </div> */}
      </div>
    </div>
  );
};
