import { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/ART/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";

type FormValues = {
  setEmpleados: string;
};

interface EmpleadosProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  empleadosValue: string | undefined;
}

export const Empleados = ({ setSteps, empleadosValue, ...rest }: EmpleadosProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();


  const { handleSubmit, control, setValue, setError, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setEmpleados } = values;
    setCotizacionValues({
      ...cotizacionValues,
      empleados: setEmpleados,
    });
    compania
      ? nav("/art/cotizacion/" + compania + "/masa-salarial")
      : nav("/art/cotizacion/masa-salarial");
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');



  useEffect(() => {
    if (error) {
      setError("setEmpleados", {
        type: 'custom',
        message: error,
      });
    } else {
      setValue("setEmpleados", empleadosValue ? empleadosValue : "");
      empleadosValue && trigger()
    }
  }, []);

  useEffect(() => {
    !cotizacionValues.razonSocial && (compania ? nav("/cotizador-de-seguros-art/" + compania + "/cuit-y-razon-social") : nav("/cotizador-de-seguros-art/cuit-y-razon-social"))
  }, [])

  const formatNumber = (value: string) => {
    const newValues = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Añade puntos cada 3 dígitos
    return newValues;
  };

  const unformatNumber = (value: string) => {
    return value.replace(/\./g, '');
  };

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>Cantidad de empleados</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setEmpleados"
              control={control}
              rules={{
                validate: {
                  required: (value) => (!!value || "Ingresá la cantidad de empleados"),
                  pattern: (value) => /^[0-9.]+$/.test(value) || "Cantidad de empleados inválida",
                  minEmpleados: (value) => {
                    const numericValue = parseFloat(unformatNumber(value).replace(/,/g, ''));
                    return !(numericValue <= 0) || "Cantidad de empleados inválida"
                  },
                  noLeadingZero: (value) => {
                    const numericValue = unformatNumber(value);
                    return numericValue[0] !== '0' || "El primer dígito no puede ser 0";
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mt-3"
                  label=""
                  name="setEmpleados"
                  type="text"
                  inputMode="numeric"
                  width={92}
                  placeHolder="Ej:50"
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^0-9]/g, '');
                    value = formatNumber(value);
                    field.onChange(value);
                    setValue("setEmpleados", value); // Actualiza el valor en React Hook Form
                    trigger("setEmpleados");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setEmpleados
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setEmpleados
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
