import { useEffect, useState } from 'react'
import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import logoblue from "../../Assets/img/logo_segurarse.svg"
import styled from '@emotion/styled'
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore'
import { useNavigate, useParams } from 'react-router-dom'
import guardarContactoCaucion from '../../Services/API/GuardarContactoCaucion'

interface GraciasProps {
    nombre?: string;
    telefono?: string;
    email?: string;
    razonSocial?: string;
    tipoCobertura?: string;
    sumaAsegurada?: string;
}

const StyledDiv = styled.div`
height: 63vh;
@media (max-width: 458px) {
    height: 85vh;
}
@media (max-width: 278px) {
    height: 90vh;
}
`
const StyledSvgContainer = styled.div`
height: 100%;
min-height: 25vh;
@media (max-width: 458px) {
    min-height: 20vh;
}
`
const StyledWppContact = styled.a`
p{
margin-top: .5rem;
}
@media (max-width: 458px) {
flex-direction: column;
align-items: center;
p{
margin-bottom: 0;
}
.final-text{
    margin-top: 0;
margin-bottom: 16px;
}
}
`

export const GraciasCaucion = ({  }: GraciasProps) => {
    const nav = useNavigate();
    const { cotizacionValues, setCotizacionValues, resetCotizacionValues } = useCotizacionStore();
    const { compania } = useParams();
    const [dataPrevia, setDataPrevia] = useState<GraciasProps>({
        email: "",
        razonSocial: "",
        telefono: "",
        nombre: "",
        sumaAsegurada: "",
        tipoCobertura: ""
    });


    /*useEffect(() => {
        (!cotizacionValues.telefono || !cotizacionValues.email || !cotizacionValues.cobertura || !cotizacionValues.sumaAsegurada) && (compania ? nav("/caucion/cotizacion/" + compania + "/cobertura-y-moneda") : nav("/caucion/cotizacion/cobertura-y-moneda"))
    }, [])*/

    const setDatosContacto = () => {
        const data: GraciasProps = {
            nombre: cotizacionValues.nombre + " " + cotizacionValues.apellido,
            telefono: cotizacionValues.telefono?.prefijo && cotizacionValues.telefono?.numero ? cotizacionValues.telefono?.prefijo + cotizacionValues.telefono?.numero : "",
            email: cotizacionValues.email,
            razonSocial: cotizacionValues.razonSocial,
            tipoCobertura: cotizacionValues.cobertura?.label,
            sumaAsegurada: cotizacionValues.sumaAsegurada
        }
        setDataPrevia(data);
        sessionStorage.setItem("vistaGracias", JSON.stringify(data))
    }

    useEffect(() => {
        const dataPrevia = sessionStorage.getItem("vistaGracias")
        if (dataPrevia) {
            const data: GraciasProps = JSON.parse(dataPrevia);
            setDataPrevia({
                razonSocial: data.razonSocial,
                telefono: data.telefono,
                email: data.email,
                nombre: data.nombre,
                sumaAsegurada: data.sumaAsegurada,
                tipoCobertura: data.tipoCobertura,
            })
        } else {
            setDataPrevia({
                nombre: cotizacionValues.nombre + " " + cotizacionValues.apellido,
                telefono: cotizacionValues.telefono?.prefijo && cotizacionValues.telefono?.numero ? cotizacionValues.telefono?.prefijo + cotizacionValues.telefono?.numero : "",
                email: cotizacionValues.email,
                razonSocial: cotizacionValues.razonSocial,
                tipoCobertura: cotizacionValues.cobertura?.label,
                sumaAsegurada: cotizacionValues.sumaAsegurada
            })
        }

    }, [])

    useEffect(() => {
        if (cotizacionValues.parametrosIniciales && !cotizacionValues?.contactoId) {
            const fetchData = async () => {
                const response: any = await guardarContactoCaucion(
                    //cotizacionValues?.parametrosIniciales.rubro,
                    cotizacionValues?.parametrosIniciales.origen,
                    cotizacionValues?.parametrosIniciales.tipoCotizador,
                    cotizacionValues?.landingUrl,
                    cotizacionValues?.referer,
                    cotizacionValues?.nombre,
                    cotizacionValues?.apellido,
                    cotizacionValues?.telefono?.prefijo,
                    cotizacionValues?.telefono?.numero,
                    cotizacionValues?.email,
                    cotizacionValues?.tipoDoc?.value,
                    cotizacionValues?.cuit,
                    cotizacionValues?.razonSocial,
                    cotizacionValues?.provincia?.value,
                    cotizacionValues?.localidad?.value,
                    cotizacionValues?.cobertura?.value,
                    cotizacionValues?.tipoMoneda,
                    cotizacionValues?.sumaAsegurada,
                    cotizacionValues?.captchaToken
                );
                if (response.data) {
                    if (response.data.api_status === 200 && response.data.api_data !== null) {
                        setCotizacionValues({
                            ...cotizacionValues,
                            contactoId: response.data.api_data.idContacto,
                            sumaAseguradaIA: response.data.api_data.sumaAseguradaIA,
                            token: response.data.api_data.token
                        });
                        setDatosContacto()
                    } else if (response.data.api_data === "El Mail informado no es un mail válido") {
                        compania ? nav("/caucion/cotizacion/" + compania + "/email?error=Por favor, ingresá una dirección de correo electrónico válida") : nav("/caucion/cotizacion/email?error=Por favor, ingresá una dirección de correo electrónico válida")
                    } else if (response.data.api_data === "El Captcha no es válido") {
                        compania ? nav("/caucion/cotizacion/" + compania + "/cobertura-y-moneda?error=El Captcha no es válido") : nav("/caucion/cotizacion/cobertura-y-moneda?error=El Captcha no es válido")
                    } else if (response.data.api_data === "El Teléfono informado no es válido") {
                        compania ? nav("/caucion/cotizacion/" + compania + "/telefono?error=Por favor, ingresá un n° de teléfono válido") : nav("/caucion/cotizacion/telefono?error=Por favor, ingresá un n° de teléfono válido")
                    } else if (response.data.api_data === "La localidad no es válida.") {
                        compania ? nav("/caucion/cotizacion/" + compania + "/codigo-postal?error=Por favor, ingresá nuevamente la localidad") : nav("/caucion/cotizacion/codigo-postal?error=Por favor, ingresá nuevamente la localidad")
                    }
                }
            }
            fetchData();
        }
    }, [])

    useEffect(() => {
        const handleBackNavigation = () => {
            resetCotizacionValues()
            nav('/', { replace: true })
            window.removeEventListener('popstate', handleBackNavigation)
        }
        window.addEventListener('popstate', handleBackNavigation)
    }, [nav])

    const formatearSumaAsegurada = (value: string) => {
        const numericValue = parseInt(value.replace(/[^0-9]/g, ""), 10);

        if (cotizacionValues.tipoMoneda === "pesos") {
            return `ARS ${new Intl.NumberFormat("es-AR").format(numericValue)}`;
        } else {
            return `USD ${new Intl.NumberFormat("es-AR").format(numericValue)}`;
        }
    };

    return (
        <div>
            <SegSvg />
            <div className=''>
                <div className="container d-flex align-items-center justify-content-center" style={{ height: "7vh" }}>
                    <div className="row">
                        <div className=""> {/* Centra el contenido en la columna */}
                            <SegHeaderLogo img={logoblue} alt="Logo Segurarse" width="160px" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-primary'>
                <StyledSvgContainer className="bg-primary text-center d-flex justify-content-center align-items-center pb-3">
                    <svg className="" style={{ maxWidth: "30vw", height: "12vh", alignSelf: "self-end" }}>
                        <use href="#ico-hombre" fill='white' />
                    </svg>
                </StyledSvgContainer>
                <StyledDiv className='bg-primary text-center d-flex flex-column'>
                    <h1 className='fs-2 text-white'>¡Recibimos tu solicitud!</h1>
                    <p className='mx-4 mt-3 text-white fs-5'>En breve nos comunicaremos con vos para asesorarte en la mejor cobertura</p>
                    <div className='mx-3 mb-5 mt-4 ps-3 pe-3'>
                        <div className='bg-white rounded container h-100 d-flex flex-column justify-content-between' style={{ minHeight: "200px" }}>
                            <div>
                                <div className='d-flex flex-wrap'>
                                    {cotizacionValues.tipoDoc?.value == 'dni' ?
                                        <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                            <div className="d-flex align-items-start gap-2">
                                                <div className=''>
                                                    <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                        <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                                    </svg>
                                                </div>
                                                <span>Nombre:</span>
                                                <span className='fw-bold'>{dataPrevia.nombre}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                            <div className="d-flex align-items-start gap-2">
                                                <div className=''>
                                                    <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                        <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                                    </svg>
                                                </div>
                                                <span>Razon social:</span>
                                                <span className='fw-bold'>{dataPrevia.razonSocial}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#tel-lin" fill='#2C7DFD' />
                                                </svg>
                                            </div>
                                            <span>Telefono:</span>
                                            <span className='fw-bold'>{dataPrevia.telefono}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#envelope-lin" fill='#2C7DFD' />
                                                </svg>
                                            </div>
                                            <span>Email:</span>
                                            <span className='fw-bold text-truncate'>{dataPrevia.email}</span>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                                </svg>
                                            </div>
                                            <span>Cobertura:</span>
                                            <span className='fw-bold'>{dataPrevia.tipoCobertura}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#salario-lin" fill='#2C7DFD' />
                                                </svg>
                                            </div>
                                            <span>Suma asegurada:</span>
                                            <span className='fw-bold'>{formatearSumaAsegurada(dataPrevia.sumaAsegurada || "")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StyledWppContact href="https://wa.me/5491169568002?text=Hola, quiero cotizar seguro de Caución" target='_blank' rel='noreferrer' className='text-black mt-2 d-flex justify-content-center gap-2' >
                                <p className='fw-bold'>¿Querés continuar por WhatsApp?</p>
                                <p className='d-flex gap-1 align-items-center final-text'>
                                    <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                        <use xlinkHref="#whatsapp" />
                                    </svg>
                                    <span className='fw-normal'>
                                        Chatéanos</span></p>
                            </StyledWppContact>
                        </div>
                    </div>

                </StyledDiv>
            </div>
        </div>
    )
}
