interface ModalDescripcionProps {
    nombre: string;
    descripcion: string;
    isOpen: boolean;
    toggleDesplegable: () => void;
}

export const ModalDescripcion = ({ nombre, descripcion, isOpen, toggleDesplegable }: ModalDescripcionProps) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                    className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleDesplegable}
                >
                    {nombre}
                </button>
            </h2>
            <div
                className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                data-bs-parent="#accDetalle"
            >
                <div className="accordion-body">
                    <p>{descripcion}</p>
                </div>
            </div>
        </div>
    );
};