import React, { useEffect, useState } from 'react'
import { SegHeaderLogo } from '../../../SegUI/SegHeaderLogo/SegHeaderLogo'
import segurarse from "../../../../Assets/img/logo_segurarse.svg";
import { SegStepper } from '../../../SegUI/SegStepper/SegStepper';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import subirArchivo from '../../../../Services/API/CertificadoNoRodamiento';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import SendIcon from '@mui/icons-material/Send';
import AppStoreLogo from '../../../../Assets/img/badge_app-store_black_es.svg';
import PlayStoreLogo from '../../../../Assets/img/playStoreLogo.png';
import TelefonoImg from '../../../../Assets/img/telefonosCruzados.png';
import TelefonoImg2 from '../../../../Assets/img/telefono-mobile.png';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

export const SolicitudEnviada = () => {
    const { cotizacionValues, resetCotizacionValues } = useCotizacionStore();
    const { emisionValues, resetEmisionValues} = useEmisionStore();
    const [is0Km, setIs0Km] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [extensionArchivo, setExtensionArchivo] = useState<string>("");
    const [nombreArchivo, setNombreArchivo] = useState<string>("");
    const [dataBase64Archivo, setDataBase64Archivo] = useState<string>("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [enviando, setEnviando] = useState<boolean>(false);
    const [enviado, setEnviado] = useState<boolean>(false);
    const [fallo, setFallo] = useState<boolean>(false);
    const nav = useNavigate()

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (fallo) {
            setFallo(false)
        }
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            if (selectedFile) {
                setFile(selectedFile);
                const extension = selectedFile.name.split('.').pop()?.toLowerCase();
                if (extension) {
                    setExtensionArchivo(extension);
                }
                const nombre = selectedFile.name.split('.').shift()?.toLowerCase();
                if (nombre) {
                    setNombreArchivo(nombre);
                }
                const reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onload = async function () {
                    const dataBase64 = reader.result as string;
                    const base64 = dataBase64?.split(',').pop();
                    if (base64) {
                        setDataBase64Archivo(base64);
                    }
                };
                reader.onerror = function (error) {
                    console.error("Error occurred while reading the file:", error);
                };
            }
        }
    };

    const handleDeleteFile = () => {
        setFile(null);
        setNombreArchivo("");
        setExtensionArchivo("");
        setDataBase64Archivo("");
        setFallo(false)
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    }

    const handleUpload = async () => {
        if (file) {
            if (!emisionValues.id) {
                setFallo(true);
                return
            }
            setEnviando(true);
            try {
                const response = await subirArchivo(nombreArchivo, extensionArchivo, dataBase64Archivo, emisionValues.id!);
                if (response.api_status === 200) {
                    setEnviando(false);
                    setEnviado(true);
                    setFallo(false)
                } else {
                    setEnviando(false);
                    setFallo(true);
                    setEnviado(false)
                }
            } catch (error) {
                console.error("Error occurred while uploading the file:", error);
            }
        }
    };


    useEffect(() => {
        cotizacionValues.año?.value === "0 Km" ? setIs0Km(true) : setIs0Km(false);
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleBackNavigation = () => {
            resetCotizacionValues()
            resetEmisionValues()
            nav('/auto/cotizacion/patente', {replace: true})
            window.removeEventListener('popstate', handleBackNavigation)
        }
        window.addEventListener('popstate', handleBackNavigation)
    }, [nav])

    return (
        <>
            <div className="container">
                <div className="row mt-3 mb-3">
                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                        <SegHeaderLogo img={segurarse} alt="Logo Segurarse" width="160px" />
                    </div>
                </div>
            </div>
            <div>
                <SegStepper
                    label={"¡Estamos muy felices de que utilices nuestros servicios!"}
                    isStepper={false}
                    className="fs-20 fw-bold bg-primary text-center px-3 pt-1 pb-1 mb-m13 minh-90p pt-lg-35 pb-lg-30 mb-lg-m30 minh-lg-125p"

                />
            </div>
            {is0Km ? <div className='container bg-white rounded shadow mt-4 mb-5 t-20'>
                <div className='ps-5 pe-5 pt-5 pb-3'>
                    <p className='fs-5'>Ya enviamos tu solicitud de cobertura {emisionValues.cotizacionDesc?.planName} a la Aseguradora <span className='fw-bold'>{emisionValues.cotizacionDesc?.companyName}</span> para que esta última evalúe tu solicitud.</p>
                    <p className='fs-5'>Al ser un vehículo 0km para la emisión de tu póliza necesitamos el certificado de no rodamiento otorgado por tu concesionario. El certificado debe tener: </p>
                    <ul className='fs-5 mt-4'>
                        <li className='li-body'>Membrete de la concesionaria </li>
                        <li className='li-body'>Fecha del día de contratación del seguro </li>
                        <li className='li-body'>Datos del vehículo </li>
                        <li className='li-body'>Sello y firma </li>
                    </ul>
                    <p className='fs-5 pt-3'>Si tenes el certificado a mano adjúntalo <span role={enviado ? "" : "button"} className={enviado ? "text-secondary" : "text-primary"} onClick={() => document.getElementById('fileInput')?.click()}>AQUI</span><input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} disabled={enviado ? true : false} accept=".pdf, .docx, .doc" />, sino, no te preocupes en breve estarás recibiendo un mail con los pasos a seguir. Te recordamos que recién estarás cubierto una vez que la Aseguradora Provincia Seguros acepte la solicitud de cobertura, valide la documentación y una vez que te haya enviado por mail la póliza.  </p>
                    <p className='fs-5 pt-3 pb-3'>Cualquier duda podés contactarnos al <a href="tel:0810-444-7348" target='_blank' rel="norreferer noreferrer" className="fw-bold a-dark">0810 444 SEGUROS (7348)</a><span className='fw-bold'> / </span><a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="norreferer noreferrer" className="fw-bold a-dark">WhatsApp: +54 9 11 6956-8002</a> en nuestros horarios de atención: lunes a viernes de 9hs a 18hs  La protección de tus datos es nuestra prioridad, nunca te solicitaremos el código de seguridad de las tarjetas de crédito por ningún motivo.</p>
                    {file &&
                        (
                            <div className='pb-4 datos-archivo'>
                                <span className='fs-4 fw-bold text-primary cambiar-certificado col-lg-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100%" }}>{file.name}</span>
                                <div className='cambiar-certificado '><SegButton className='fs-5 certificado-button' disabled={enviado || fallo ? true : false} activeBgColor='#2c7dfd' type={'button'} icon={DeleteOutlineIcon} label={'Eliminar'} onClick={handleDeleteFile} iconSide='right' /></div>
                                <div className='enviar-certificado '><SegButton className='fs-5 certificado-button' disabled={enviado || fallo ? true : false} activeBgColor='#2c7dfd' type={'button'} icon={SendIcon} label={enviando ? "" : 'Enviar'} onClick={handleUpload} iconSide='right' loading={enviando ? true : false} spinnerSize='small' /></div>
                                {enviado &&
                                    <div>
                                        <CheckOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "green" }} />
                                        <span style={{ verticalAlign: "-webkit-baseline-middle", color: "green" }}> Documento enviado </span>
                                    </div>
                                }
                                {fallo &&
                                    <div>
                                        <CancelOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }} />
                                        <span style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }}> Falló el envío. Volvé a intentar. </span>
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
            </div> : <div className='container bg-white rounded pt-5 fs-lg-20 p-5' style={{ width: "90%", justifyContent: "flex-end" }}>
                <div>
                    <p className='text-center'>
                        Ya enviamos tu solicitud de cobertura {emisionValues.cotizacionDesc?.planName} a la Aseguradora <span className='fw-bold'>{emisionValues.cotizacionDesc?.companyName}</span>. Para que esta última evalúe
                        tu solicitud, debés realizar la inspección lo antes posible. En breve estarás recibiendo un mail con el detalle de cómo realizarla.
                    </p>
                    <p className='text-center fw-bold '>
                        Te recordamos que recién estarás cubierto una vez que la Aseguradora Provincia Seguros acepte la solicitud de cobertura luego de aprobada la inspección y una vez que te haya enviado por mail la póliza.
                    </p>
                    <p className='text-center'>
                        Cualquier duda podés contactarnos al <a href="tel:0810-444-7348" target='_blank' rel="norreferer noreferrer" className="a-dark">0810 444 SEGUROS (7348)</a><span className='fw-bold'> / </span><a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="norreferer noreferrer" className="a-dark">WhatsApp: +54 9 11 6956-8002</a> en nuestros horarios
                        de atención: lunes a viernes de 9hs a 18hs .
                    </p>
                </div>
                <div className='container'>
                    <p className='text-center text-primary ms-5 me-5 pb-5'>
                        La protección de tus datos es nuestra prioridad, nunca te solicitaremos el código de seguridad de las tarjetas de crédito
                        por ningún motivo.
                    </p>
                </div>
            </div>}
            {windowWidth >= 873 ?
                <div style={{ width: "100%", backgroundColor: "#2C7DFD", paddingTop: "3rem" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "44%", display: "flex", justifyContent: "flex-end" }}>
                            <img
                                style={{ position: "relative", right: "-125px" }}
                                src={TelefonoImg}
                                height="400"
                                alt="Smartphone"
                            />
                        </div>
                        <div style={{ paddingLeft: "1rem" }}>
                            <div className='cot-header tittle' style={{ paddingTop: "0px", fontSize: "40px", color: "#fff", fontWeight: "700" }}>Tu seguro en tu celular</div>
                            <div>
                                <ul style={{ fontSize: "20px", color: "#fff" }}>
                                    <li><strong>Ver y descargar</strong> tus pólizas</li>
                                    <li><strong>TENER</strong> tu credencial digital siempre</li>
                                    <li><strong>Renovar o cotizar</strong> un nuevo seguro</li>
                                    <li><strong>Denunciar</strong> robo o siniestro, enviando mensaje de voz y fotos en tiempo real</li>
                                    <li><strong>Solicitar</strong> asistencia mecánica</li>
                                    <li><strong>Autorizar</strong> a un tercero</li>
                                    <li><strong>Cambiar</strong> forma de pago</li>
                                    <li><strong>Realizar</strong> consultas varias por chat</li>
                                </ul>
                                <div className='subtitulo-add-app'>
                                    <div style={{ marginBottom: "1rem" }}>Descargá gratis <strong>MiSegurarse</strong> y autogestioná tu seguro.</div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "20px" }}>
                                            <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=es_AR&gl=US" target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={PlayStoreLogo}
                                                    height="50"
                                                    alt="Playstore"
                                                />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800" target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={AppStoreLogo}
                                                    height="50"
                                                    alt="AppStore"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div style={{ width: "100%", backgroundColor: "#2C7DFD" }}>
                    <div className='cot-header tittle' style={{ paddingTop: "3rem", fontSize: "30px", color: "#fff", fontWeight: "700", marginBottom: ".5rem", paddingLeft: ".7rem", paddingRight: ".7rem", paddingBottom: "0px" }}>Tu seguro en tu celular</div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: ".7rem", paddingRight: ".7rem" }}>
                        <div>
                            <ul style={{ fontSize: "15px", color: "#fff" }}>
                                <li><strong>Ver y descargar</strong> tus pólizas</li>
                                <li><strong>Tener</strong> tu credencial digital siempre</li>
                                <li><strong>Renovar o cotizar</strong> un nuevo seguro</li>
                                <li><strong>Denunciar</strong> robo o siniestro, enviando mensaje de voz y fotos en tiempo real</li>
                                <li><strong>Solicitar</strong> asistencia mecánica</li>
                                <li><strong>Autorizar</strong> a un tercero</li>
                                <li><strong>Cambiar</strong> forma de pago</li>
                                <li><strong>Realizar</strong> consultas varias por chat</li>
                            </ul>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div className='subtitulo-add-app-mobile' style={{ paddingLeft: "0px" }}>
                                <div style={{ marginBottom: "1rem" }}>Descargá gratis <strong>MiSegurarse</strong> y autogestioná tu seguro.</div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ marginRight: "10px" }}>
                                        <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=es_AR&gl=US" target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={PlayStoreLogo}
                                                height="40"
                                                alt="PlayStore"
                                            />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="https://apps.apple.com/ar/app/mi-segurarse/id1237342800" target="_blank" rel="noopener noreferrer">
                                            <img
                                                src={AppStoreLogo}
                                                height="40"
                                                alt="AppStore"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "3rem" }}>
                                <img
                                    src={TelefonoImg2}
                                    height="360"
                                    alt="Smartphone"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
