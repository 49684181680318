import React, { forwardRef } from "react";
import styled from "@emotion/styled";

interface SegInputProps {
  width: number;
  label: string;
  isGroup?: boolean;
  labelGroup?: string;
  type: string;
  name: string;
  id?: string;
  placeHolder?: string;
  labelColor?: string;
  labelWidth?: string;
  labelGroupColor?: string;
  inputTextColor?: string;
  borderColor?: string;
  focusBorderColor?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  value?: string;
  autoFocus?: boolean;
  leyendaError?: string;
  disabled?: boolean;
  maxLenghtDigits?: number;
  minLenghtDigits?: number;
  min?: string | number | undefined;
  inputMode?: "search" | "text" | "none" | "email" | "tel" | "url" | "numeric" | "decimal" | undefined,
  absolut?:boolean;
}

const StyledDiv = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;
const Container = styled.div<{ width: number }>`
  width: ${({ width }) => width}% !important;
`;

const Label = styled.label<{ labelColor: string, labelWidth: string }>`
  color: ${({ labelColor }) => labelColor} !important;
  width:${({ labelWidth }) => labelWidth} !important;
`;

const Span = styled.span<{ labelGroupColor: string }>`
  border: none;
  color: ${({ labelGroupColor }) => labelGroupColor};
`;

const Input = styled.input<{ leyendaError: string; inputTextColor: string }>`
  border: none;
  color: ${({ inputTextColor }) => inputTextColor} !important;
  &:focus-within {
    box-shadow: none;
  }
`;

const InputGroup = styled.div<{
  borderColor: string;
  focusBorderColor: string;
}>`
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ borderColor }) => borderColor} !important;
  &:focus-within {
    border-color: ${({ focusBorderColor }) => focusBorderColor} !important;
  }
`;

export const SegInput = forwardRef<HTMLInputElement, SegInputProps>(
  (
    {
      width = 100,
      label,
      isGroup = false,
      labelGroup = "0",
      type = "text",
      name,
      id,
      placeHolder,
      labelColor = "#000",
      labelWidth = 'auto',
      labelGroupColor = "#2c7dfd",
      inputTextColor = "#000",
      borderColor = "#000",
      focusBorderColor = "#2c7dfd",
      className,
      onChange,
      onBlur,
      onKeyDown,
      value = "",
      autoFocus = false,
      leyendaError = "",
      disabled = false,
      maxLenghtDigits,
      minLenghtDigits,
      min,
      inputMode,
      absolut = false
    }, ref) => {
    return (
      <Container width={width} className={className}>
        {label !== "" ? (
          <Label className="form-label" labelColor={labelColor} labelWidth={labelWidth}>
            {label}
          </Label>
        ) : (
          ""
        )}
        <InputGroup
          className="input-group"
          borderColor={borderColor}
          focusBorderColor={focusBorderColor}
        >
          {isGroup ? (
            <Span className="input-group-text" labelGroupColor={labelGroupColor}>
              {labelGroup}
            </Span>
          ) : (
            ""
          )}
          <Input
            type={type}
            className="form-control"
            name={name}
            id={id}
            placeholder={placeHolder}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            value={value}
            autoFocus={autoFocus}
            leyendaError={leyendaError}
            inputTextColor={inputTextColor}
            ref={ref}
            disabled={disabled}
            maxLength={maxLenghtDigits}
            minLength={minLenghtDigits}
            min={min}
            inputMode={inputMode}
          />
        </InputGroup>
        {leyendaError !== "" ? (
          <StyledDiv className="error" 
          style={ (absolut && window.innerWidth >= 768) ? {position: "absolute"} : {}}
          >{leyendaError}</StyledDiv>
        ) : null}
      </Container>
    );
  }
)
