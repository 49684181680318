import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { imagesCompanias } from '../../../Assets/imageCompania'
import { Hero } from '../Components/Hero'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes'
import { PasosCotizar } from '../Components/PasosCotizar'
import { CompaniasSlider } from '../Components/CompaniasSlider'

export const Auto = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Es obligatorio tener un seguro de auto?',
            subTitulo: 'Sí, es obligatorio contar con al menos un seguro de responsabilidad civil para poder circular legalmente.'
        },
        {
            titulo: '¿Qué diferencia hay entre un seguro a terceros y un todo riesgo?',
            subTitulo: 'El seguro a terceros cubre únicamente los daños que puedas causar a otros, mientras que el seguro de todo riesgo es más completo y también puede proteger contra daños propios al vehículo.'
        },
        {
            titulo: '¿Cómo elegir el mejor seguro de auto para mí?',
            subTitulo: 'Evaluá tus necesidades, el valor de tu vehículo y tu presupuesto. Un asesor de Segurarse puede ayudarte a encontrar la opción más adecuada para vos, no dudes en consultar con nuestro equipo, ¡estamos a disposición!'
        },
        {
            titulo: '¿Cómo denuncio un siniestro si soy cliente de Segurare? ',
            subTitulo: 'Si tuviste un siniestro, ingresá a nuestra App MiSegurarse y seguí los pasos para informar debidamente el hecho. Si aún no la tenés, descargala haciendo clic en el botón “Mi Segurarse” del menú y luego en el botón de Google Play/App Store según tu sistema operativo.'
        }
    ];

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de auto'
                    subTitulo={{ __html: 'Sabemos lo importante que es tu auto para vos, cotizá y emití 100% online el seguro que necesitás. Sólo te pedimos unos datos y te mostramos en pocos segundos múltiples coberturas a los mejores precios del mercado.<br /><br />¿Empezamos?' }}
                    url='/cotizador-de-seguros-auto'
                    icon='#icon-seg-auto'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Un seguro de auto ofrece diferentes tipos de protección para que manejes con tranquilidad. La cobertura más básica, es la de responsabilidad civil, obligatoria por ley y se encarga de cubrir los daños materiales o lesiones que puedas causar a otras personas en un accidente de tránsito.
                                <br /><br />
                                Además, existen coberturas más completas que protegen tu propio vehículo. Algunas pólizas incluyen reparaciones en caso de choque, vuelco, incendio, y hasta daños ocasionados por eventos naturales como granizo o inundaciones. También podés optar por una cobertura que incluya robo y hurto, que te protege tanto en casos de robo total del auto como en robos parciales, como el de autopartes o accesorios.
                                <br /><br />
                                Muchos seguros de auto también incluyen servicios de asistencia en ruta, que pueden ser de gran ayuda en situaciones inesperadas. Este tipo de cobertura puede ofrecer servicios como remolque, cambio de neumáticos, recarga de batería, envío de combustible, y asistencia mecánica de urgencia.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                ¡Elegí la cobertura para vos!
                            </p>
                        </div>
                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Responsabilidad Civil
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Terceros Completo
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            Terceros Completo Premium
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            Todo Riesgo
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PasosCotizar />
                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Somos un bróker, trabajamos con las mejores aseguradoras del país
                            </h2>
                        </div>
                        <CompaniasSlider companias={imagesCompanias}/>
                    </div>
                </div>
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
            </div>
        </>
    )
}
