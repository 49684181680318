import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';

export const TodoRiesgoCorporativo = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro para Todo Riesgo Corporativo'
                    url='#formulario'
                    icon='#icon-seg-tr-corp'
                    subTitulo={{ __html: "Protegé el patrimonio de tu empresa ante un reclamo de terceros a causa de daños físicos o materiales.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Protege el patrimonio de tu empresa y/o industria resguardando los riesgos específicos de la actividad y procesos industriales.
                                Es un seguro que permite respaldar en forma íntegra las operaciones comerciales e industriales de tu empresa.<br />
                                Es la opción más idónea para reducir el impacto de múltiples riesgos de tu actividad, posibilitando el diseño de una póliza ajustada a las características y necesidades de tu empresa.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños a la propiedad:</b> Cubre los daños materiales o pérdidas que pueda sufrir la propiedad de la empresa, como edificios, estructuras, equipos electrónicos, maquinarias, inventarios y otros activos, debido a diversos eventos, como incendios, inundaciones, robos, vandalismo, explosiones, terremotos, entre otros.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> Cubre los gastos legales y las indemnizaciones a terceros por lesiones personales o daños a la propiedad que puedan ocurrir como resultado de las operaciones comerciales de la empresa asegurada.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Interrupción del negocio:</b> En caso de que las operaciones comerciales se vean interrumpidas debido a eventos cubiertos, el seguro puede cubrir la pérdida de ingresos y los gastos fijos durante el período de interrupción. Esto ayuda a compensar las pérdidas económicas y permite que la empresa continúe funcionando mientras se resuelven los problemas.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b> Gastos adicionales:</b> Cubre los gastos adicionales y razonables que la empresa pueda incurrir para minimizar o mitigar los daños después de un evento cubierto.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='TRO'
                    titulo='Cotizá seguro para Todo Riesgo Corporativo'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
