import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

type FormValues = {
  setNombre: string;
};

interface NombreProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>;
  nombreValue: string | undefined;
}

export const Nombre = ({ config, setSteps, nombreValue }: NombreProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setNombre } = values;
    setCotizacionValues({
      ...cotizacionValues,
      nombre: setNombre,
    });
    compania
      ? nav("/auto/cotizacion/" + compania + "/apellido") : nav("/auto/cotizacion/apellido");
    // nav("/auto/cotizacion/apellido");
  };



  useEffect(() => {
    (!cotizacionValues.patente && (!cotizacionValues.marca || !cotizacionValues.version || !cotizacionValues.año || !cotizacionValues.modelo)) && (compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente"))
  }, [])

  useEffect(() => {
    setValue("setNombre", nombreValue ? nombreValue : ""); // Actualiza el valor en React Hook Form
    nombreValue && trigger();
  }, [nombreValue]);
  return (
    <div className="mt-4 bg-gray-light text-center">
      <div className="frm01">
        <legend>¿Cuál es tu nombre?</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setNombre"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá el nombre",
                  pattern: (value) =>
                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                    "Nombre inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput

                  className="mt-3"
                  label=""
                  name="setNombre"
                  type="text"
                  width={92}
                  placeHolder="Ej: Juan"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setNombre", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setNombre");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setNombre
                    ? "var(--bs-form-invalid-border-color)"
                    : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                    }`}
                focusBorderColor={`${formState.errors.setNombre
                    ? "var(--bs-form-invalid-border-color)"
                    : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
              bgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent: undefined}
              hoverOpacity={config.origen !== "SEGURARSE"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
