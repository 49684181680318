import Axios from 'axios'
import { getPathName } from './helper';

const subirArchivo = async (nombre: string, extension: string, dataBase64: string, idPoliza: number) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        idPoliza: idPoliza,
        archivo:
        {
            nombre: nombre,
            extension: extension,
            dataBase64: dataBase64
        }

    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/SetCertificadoNoRodamiento", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return error;
        });
};

export default subirArchivo;