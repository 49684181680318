import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/Caucion/Cotizador";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { RazonSocial } from '../../Prepaga/Cotizacion/Corporativo/RazonSocial';

type FormValues = {
  setRazonSocial: string;
};

interface CuitRazonSocialProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  razonSocialValue: string | undefined;
}

export const CuitRazonSocial = ({ setSteps, razonSocialValue }: CuitRazonSocialProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();


  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setRazonSocial } = values;

    const RazonSocialArreglo = setRazonSocial.trim().split(/\s+/);

    setCotizacionValues({
      ...cotizacionValues,
      razonSocial: setRazonSocial,
      nombre: RazonSocialArreglo.length === 1 ? setRazonSocial : RazonSocialArreglo.slice(0, -1).join(' '),
      apellido: RazonSocialArreglo.length === 1 ? "-" : RazonSocialArreglo[RazonSocialArreglo.length - 1]
    });
    compania
      ? nav("/caucion/cotizacion/" + compania + "/telefono") : nav("/caucion/cotizacion/telefono");
  };

  useEffect(() => {
    !cotizacionValues.tipoDoc && !cotizacionValues.cuit && (compania ? nav("/caucion/cotizacion/" + compania + "/documento") : nav("/caucion/cotizacion/documento"))
  }, [])
  useEffect(() => {
    const cuitInput = document.getElementById("cuit");
    if (cuitInput && cuitInput.tagName.toLowerCase() === "input") {
      cuitInput.focus();
    }
  }, [])

  useEffect(() => {
    setValue("setRazonSocial", razonSocialValue ? razonSocialValue : "");
    razonSocialValue && trigger();
  }, [razonSocialValue]);
  return (
    <div className="mt-4 bg-gray-light text-center">
      <div className="frm01">
        <form onSubmit={handleSubmit(onSubmit)}>
          <legend className="mb-4">¿Cuál es la Razón Social?</legend>
          <div className="m-3">
            <Controller
              name="setRazonSocial"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá la razon social",
                  // pattern: (value) =>
                  //   /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                  //   "Nombre inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mw-sm-450 m-auto"
                  label=""
                  name="setRazonSocial"
                  type="text"
                  width={100}
                  placeHolder="Perez S.A."
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setRazonSocial", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setRazonSocial");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div >
    </div >
  );
};
