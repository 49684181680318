import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler, FormProvider } from "react-hook-form";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";
import { SegSelect, SegSelectOption } from "../../../../SegUI/SegSelect/SegSelect";

type FormValues = {
  setHijos: string;
};

interface HijosProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  hijosValue: SegSelectOption | undefined;
}

export const Hijos = ({ setSteps, hijosValue, ...rest }: HijosProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [cantHijos, setCantHijos] = useState<SegSelectOption>();
  const [openSelect, setOpenSelect] = useState<boolean>(false)

  const { handleSubmit, control, setValue, setError, formState: { errors, isValid }, trigger } =
    useForm<FormValues>();

  const methods = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    setCotizacionValues({
      ...cotizacionValues,
      cantHijos: cantHijos,
    });
    nav("/resultado-del-cotizador-de-salud");
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');



  useEffect(() => {
    if (error) {
      setError("setHijos", {
        type: 'custom',
        message: error,
      });
    } else {
      setValue("setHijos", cantHijos ? cantHijos.value : "");
      cantHijos && trigger()
    }
  }, [cantHijos]);

  useEffect(() => {
    if (!cotizacionValues.hijos) {
      nav("/resultado-del-cotizador-de-salud")
    }
  }, [])

  useEffect(() => {
    if (cotizacionValues?.cantHijos) {
      setCantHijos(cotizacionValues?.cantHijos)
      setValue("setHijos", cotizacionValues.cantHijos.value);
      setOpenSelect(false)
    } else {
      setOpenSelect(true)
    }
  }, []);

  const cuantosHijos = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
  ];

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>Cantidad de hijos</legend>
        <h6>Menores de 21 años</h6>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <fieldset className="m-auto mt-2 d-flex justify-content-center">
              <FormProvider {...methods}>
                <SegSelect
                  label=""
                  selected={cantHijos}
                  setSelected={setCantHijos}
                  name="cantHijos"
                  options={cuantosHijos}
                  isGrupable
                  width={"100%"}
                  borderColor={errors.setHijos ? "red" : ""}
                  openDefault={openSelect}
                  autoFoco={true}
                  placeholder=""
                />
              </FormProvider>
              <p>{errors.setHijos?.message}</p>
            </fieldset>
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
