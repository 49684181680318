// import guardarContactoArt from "./GuardarContactoArt"
// import guardarContactoCaucion from "./GuardarContactoCaucion"
// import guardarContactoPrepaga from "./GuardarContactoPrepaga"
// import guardarContactoCelular from "./GuardarContactoCelular"
import { imagesCompanias as imagesCompaniasCelular } from './imageCompaniaCelular';

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const ImagenesCompanias = (rubro: string) => {
    switch (rubro) {
        // case "AUTO":
        //     return guardarContacto(data)
        // case "ART":
        //     return guardarContactoArt(data)
        // case "CAUCION":
        //     return guardarContactoCaucion(data)
        case "CELULAR":
            return imagesCompaniasCelular;
        // case "INTEGRAL-COMERCIO":
        //     return guardarContactoIntegralComercio(data)
        default:
            break;
    }
}