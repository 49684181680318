import { AxiosError } from 'axios';
const DEFAULT_ATTEMPT: number = 2;

export async function retryRequest<T>(
  axiosRequest: () => Promise<T>,
  retries: number = DEFAULT_ATTEMPT
): Promise<T> {
  let attempt = 0;

  while (attempt <= retries) {
    try {
      const response = await axiosRequest();
      return response; // Si la solicitud tiene éxito, retorna el resultado
    } catch (error: any) {
      attempt++;
      if (attempt > retries || !isRetryableError(error)) {
        throw error; // Si se alcanzan los reintentos, lanza el error
      }
    }
  }

  throw new Error('Max retries reached'); // Solo por seguridad, aunque no debería llegar aquí
}

function isRetryableError(error: AxiosError): boolean {
  // Puedes definir qué errores son "reintetables"
  return error.response?.status === 500 || error.response?.status === 503;
}


export const getPathName = (): string => {

  const baseUrl = window.location.origin;

  if (process.env.REACT_APP_DEV_MODE === "true" || window.location.hostname === "localhost") {
    return process.env.REACT_APP_LOCAL_HOSTNAME + "" + process.env.REACT_APP_ENDPOINT;
  }

  return baseUrl + process.env.REACT_APP_ENDPOINT;
}
