import Axios from 'axios'
import { getPathName } from './helper';

const obtenerTiposTarjeta = async (compania: string) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        compania: compania,
    }
    const headers = {
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getTiposTarjetaJSON", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data.api_data;
        }).catch(function (error) {
            return error;
        });
};

export default obtenerTiposTarjeta;