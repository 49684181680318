import { useSearchParams } from 'react-router-dom';
import { useCotizacionStore, ICotizacionValues } from '../../Services/Providers/CotizacionStore';
import { useForm } from "react-hook-form";
import { SegButton } from "../SegUI/SegButton/SegButton";
import { RubroTypes } from '../../types';
import { imagesCompanias } from "../../Assets/imageCompania";
import { SegSlider } from '../SegUI/SegSlider/SegSlider';
import { useEffect, useState } from 'react';
import { TelefonoInput } from './Slides/TelefonoInput';
import { ProvinciaLocalidadInput } from './Slides/LocalidadProvinciaInput';
import { DefaultInput } from './Slides/DefaultInput';
import { Companias } from '../../Assets/imageCompaniaCelular';
import { TipoDocumento } from './Slides/TipoDocumento';

type FormStepProps = {
    stepData: RubroTypes.Step;
    handleContinue: () => void;
    handleOnSubmit: (e: any) => void;
    values: ICotizacionValues
    isLoading: boolean;
    companias: Companias[];
};

const fixSteps = ["telefono-celular", "provincia-localidad", "gracias", "tipo-documento"]

const checkTypeOfStep = (stepsList: string[], stepName: string): boolean => {
    return stepsList.includes(stepName);
}

const FormStep: React.FC<FormStepProps> = ({ stepData: { stepId, nextStep, title, subtitle, section, fields, brand_carrousel, ...rest }, handleContinue, handleOnSubmit, values, isLoading, companias }) => {
    const [searchParams] = useSearchParams();
    const [errorCaptcha, setErrorCaptcha] = useState("")
    const isDefault = !checkTypeOfStep(fixSteps, stepId)
    const isSubmitButton = (nextStep === "gracias" || nextStep === "loader");

    useEffect(() => {
        // setIsDefault(!checkTypeOfStep(fixSteps, stepId))
    }, [stepId])


    useEffect(() => {
        const errorMessage = searchParams.get('error');
        if (errorMessage) {
            setErrorCaptcha(decodeURIComponent(errorMessage));
        }
    }, [searchParams]);


    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const { handleSubmit, control, setValue, setError, formState, trigger, getValues, watch, clearErrors } =
        useForm();

    const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setCotizacionValues({
            ...cotizacionValues,
            [name]: value
        });
    };

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (formState.isValid) {
            if (isSubmitButton) {
                handleOnSubmit(event);
            } else {
                event.preventDefault();
                handleContinue();
            }
        } else {
            event.preventDefault();
        }
    };

    return (
        <div className="bg-gray-light text-center" {...rest}>
            <h3 style={{ fontWeight: "bold", marginTop: "30px" }}>{title}</h3>
            <legend style={{ fontWeight: "bold", marginBottom: "30px", lineHeight: "25px" }}>{subtitle}</legend>
            <div className="frm01">
                <form onSubmit={handleOnSubmit}>
                    <legend className='mb-4 mx-auto'
                        style={{ fontSize: "18px", maxWidth: "80%", fontWeight: 500 }}>
                        {section}
                    </legend>
                    {isDefault ?
                        <DefaultInput inputData={{ stepId, nextStep, fields }} HandleInputChange={handleInputChange} ReactFormData={{ control, setValue, setError, formState, trigger, getValues, watch, clearErrors }} currentValues={cotizacionValues} onEnterPress={handleEnterPress}
                        />
                        : <></>}

                    {stepId === "telefono-celular" ? (
                        <TelefonoInput
                            inputData={{ stepId, section, fields }}
                            ReactFormData={{ control, setValue, trigger, formState, getValues, setError, watch, clearErrors }}
                            HandleInputChange={handleInputChange}
                            currentValues={cotizacionValues}
                            onEnterPress={handleEnterPress}
                        />
                    ) : <></>}
                    {stepId === "provincia-localidad" ? (
                        <ProvinciaLocalidadInput
                            inputData={{ stepId, section, fields }}
                            ReactFormData={{ control, setValue, trigger, formState, getValues, setError, watch, clearErrors }}
                            HandleInputChange={handleInputChange}
                            currentValues={cotizacionValues} />
                    ) : <></>}

                    {stepId === "tipo-documento" ? (
                        <TipoDocumento
                            inputData={{ stepId, section, fields }}
                            ReactFormData={{ control, setValue, trigger, formState, getValues, setError, watch, clearErrors }}
                            HandleInputChange={handleInputChange}
                            currentValues={cotizacionValues} />
                    ) : <></>}


                    <div className="form-buttons">
                        {errorCaptcha &&
                            <div style={{ margin: "0 auto 20px auto" }} className="alert alert-danger w-25" role="alert">
                                {errorCaptcha}
                            </div>
                        }
                        <SegButton
                            label="Continuar"
                            type={isSubmitButton && formState.isValid ? "submit" : "button"}
                            className="btn btn-primary"
                            onClick={handleContinue}
                            disabled={
                                !formState.isValid
                            }
                            loading={isLoading ? true : false}
                            spinnerSize={isLoading ? "small" : "normal"}
                        />
                    </div>
                </form>
            </div>
            {brand_carrousel ?
                <div className="slide-cias" style={{ marginTop: adaptativeMargin, marginBottom: 0 }}>
                    <SegSlider companias={companias} />
                </div> :
                <></>}
        </div>
    );
};

export default FormStep;