import Axios from 'axios';
import { getPathName } from './helper';

const ARR_IIBB: { value: string; label: string }[] = [
    { value: "2", label: "C Local" },
    { value: "3", label: "No Inscripto" },
    { value: "4", label: "Exento" },
    { value: "5", label: "Convenio Multilateral" },
];


export const obtenerCondicionesIIBB = async () => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        provincia: ""
    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    /*return await Axios.post(getPathName() + "/getcondicioniibb", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data.api_data.map( function(cond:any) {
                return {
                    value: cond.codigo,
                    label: cond.descripcion
                }
            })
        }).catch(function (error) {
            return ARR_IIBB
            //return error;
    });*/
    return ARR_IIBB
}