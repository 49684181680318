import { Controller, useForm } from 'react-hook-form';
import { RubroTypes } from '../../../types';
import { SegInput } from '../../SegUI/SegInput/SegInput';
import { useEffect, useState } from 'react';
import CodigosDeArea from '../../../Assets/CodigosDeArea.json';

type ReactFormData = {
    control: ReturnType<typeof useForm>['control'];
    setValue: ReturnType<typeof useForm>['setValue'];
    formState: ReturnType<typeof useForm>['formState'];
    trigger: ReturnType<typeof useForm>['trigger'];
    getValues: ReturnType<typeof useForm>['getValues']
    setError: ReturnType<typeof useForm>['setError'];
    watch: ReturnType<typeof useForm>['watch'];
    clearErrors: ReturnType<typeof useForm>['clearErrors'];
};

type InputProps = {
    inputData: {
        stepId: string;
        title?: string;
        subtitle?: string;
        section?: string;
        fields: RubroTypes.Field[];
    };
    ReactFormData?: ReactFormData;
    HandleInputChange?: (value: any) => any;
    currentValues: any;
    onEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
type PhoneValues = {
    prefijo: string | undefined;
    numero: string | undefined;
};

const validarTelefono = (prefijo: string, numero: string) => {
    const numeroCompleto = prefijo + numero;

    if (numeroCompleto.length === 10) {
        return true;
    } else {
        return false;
    }
};

export const TelefonoInput: React.FC<InputProps> = ({ inputData: { stepId, title, subtitle, section, fields, ...rest }, ReactFormData, HandleInputChange, currentValues, onEnterPress }) => {
    const [values, setValues] = useState<PhoneValues>({ numero: undefined, prefijo: undefined })

    const prefijoConfig = fields.find((field) => field.name === "prefijo")
    const numeroConfig = fields.find((field) => field.name === "numero")

    useEffect(() => {
        if (ReactFormData && currentValues?.telefono) {
            const { prefijo, numero } = currentValues.telefono;

            let newValues = { ...values };

            if (prefijo !== undefined) {
                ReactFormData.setValue("prefijo", prefijo);
                ReactFormData.trigger("prefijo");
                newValues.prefijo = prefijo;
            }

            if (numero !== undefined) {
                ReactFormData.setValue("numero", numero);
                ReactFormData.trigger("numero");
                newValues.numero = numero;
            }

            setValues(newValues);
        }
    }, [])

    useEffect(() => {
        if (HandleInputChange !== undefined) {
            const targetValues = {
                target: { name: "telefono", value: values }
            };
            HandleInputChange(targetValues);
        }
    }, [values]);

    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onEnterPress(event);
        }
    };

    return (
        <div className="d-flex justify-content-center">
            <div className="row gx-3 text-start mw-sm-450 m-1">
                {ReactFormData ?
                    <>
                        <Controller
                            name="prefijo"
                            control={ReactFormData?.control}
                            rules={prefijoConfig?.rules}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    isGroup={true}
                                    labelGroup="0"
                                    className="col-4"
                                    label={prefijoConfig?.helper || ''}
                                    name="prefijo"
                                    type="number"
                                    inputMode='numeric'
                                    width={33}
                                    placeHolder={prefijoConfig?.placeholder || ''}
                                    value={field.value}
                                    autoFocus={true}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setValues({ ...values, prefijo: (e.target.value).toString() });
                                        if (HandleInputChange !== undefined) { handleLocalChange(e) }
                                        ReactFormData.setValue("prefijo", e.target.value);
                                        ReactFormData.trigger("prefijo");
                                        if (ReactFormData.getValues("numero") && ReactFormData.getValues("numero") && !validarTelefono(ReactFormData.getValues("prefijo"), ReactFormData.getValues("numero"))) {
                                            ReactFormData.setError("numero", { type: "validate", message: "Número inválido" })
                                        } else {
                                            ReactFormData.clearErrors("numero")
                                        }
                                    }}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${ReactFormData.formState.errors.prefijo
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${ReactFormData.formState.errors.prefijo
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                        <Controller
                            name="numero"
                            control={ReactFormData.control}
                            rules={{
                                validate: {
                                    ...numeroConfig?.rules.validate,
                                    validateCombination: (value) => {
                                        const prefijo = ReactFormData.watch("prefijo");
                                        if ((prefijo + value).length !== 10)
                                            return "Número inválido"
                                    }
                                }

                            }}
                            render={({ field, fieldState }) => (
                                <SegInput
                                    isGroup={true}
                                    labelGroup="15"
                                    className="col-8"
                                    label={numeroConfig?.helper || ''}
                                    name="numero"
                                    type="number"
                                    inputMode='numeric'
                                    width={66}
                                    placeHolder={numeroConfig?.placeholder || ''}
                                    value={field.value}
                                    autoFocus={false}
                                    onChange={(e) => {
                                        handleLocalChange(e)
                                        field.onChange(e);
                                        setValues({ ...values, numero: (e.target.value).toString() });
                                        ReactFormData.setValue("numero", e.target.value);
                                        ReactFormData.trigger("numero");
                                    }}
                                    onKeyDown={handleKeyDown}
                                    leyendaError={fieldState?.error?.message}
                                    borderColor={`${ReactFormData.formState.errors.numero
                                        ? "var(--bs-form-invalid-border-color)"
                                        : ""
                                        }`}
                                    focusBorderColor={`${ReactFormData.formState.errors.numero
                                        ? "var(--bs-form-invalid-border-color)"
                                        : "#2c7dfd"
                                        }`}
                                />
                            )}
                        />
                    </>
                    : <></>}
            </div>
        </div>
    )
}
