import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/ART/Cotizador";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { imagesCompanias } from "../../../../Assets/imageCompaniaArt";
import { SegSlider } from "../../../SegUI/SegSlider/SegSlider";
import styled from "@emotion/styled";

type FormValues = {
  setCuit: string;
  setRazonSocial: string;
};

interface CuitRazonSocialProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  cuitValue: string | undefined;
  razonSocialValue: string | undefined;
}

const StyledDiv = styled.div`
@media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
}
 `

export const CuitRazonSocial = ({ setSteps, cuitValue, razonSocialValue }: CuitRazonSocialProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setCuit, setRazonSocial } = values;
    setCotizacionValues({
      ...cotizacionValues,
      cuit: setCuit,
      razonSocial: setRazonSocial
    });
    compania
      ? nav("/art/cotizacion/" + compania + "/empleados") : nav("/art/cotizacion/empleados");
  };
  const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`

  useEffect(() => {
    const cuitInput = document.getElementById("cuit");
    if (cuitInput && cuitInput.tagName.toLowerCase() === "input") {
      cuitInput.focus();
    }
  }, [])

  useEffect(() => {
    setValue("setCuit", cuitValue ? cuitValue : ""); // Actualiza el valor en React Hook Form
    setValue("setRazonSocial", razonSocialValue ? razonSocialValue : "");
    cuitValue && trigger();
    razonSocialValue && trigger();
  }, [cuitValue, razonSocialValue]);
  return (
    <div className="mt-4 bg-gray-light text-center">
      <StyledDiv>
        <h2 className="category-title" >Cotizá online y encontrá la mejor oferta para tu empresa</h2>
        <h4 className="category-subtitle">Sitio exclusivo para empleadores (empresas y particulares inscriptos en AFIP) con empleados en relación de dependencia.</h4>
      </StyledDiv>
      <div className="frm01">
        <form onSubmit={handleSubmit(onSubmit)}>
          <legend>Ingresá el CUIT</legend>
          <div className="m-3" style={{ marginBottom: "18px" }}>
            <Controller
              name="setCuit"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá el CUIT",
                  pattern: (value) =>
                    /^\d{11}$/.test(value) && /^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value) ||
                    "CUIT inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  id="cuit"
                  className="mw-sm-450 m-auto"
                  label={`Sólo números, sin puntos ni guiones ni espacios`}
                  name="cuit"
                  type="number"
                  width={100}
                  placeHolder="Ej: 20999999995"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setCuit", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setCuit");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setCuit
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setCuit
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <legend>¿Cuál es la Razón Social?</legend>
          <div className="m-3">
            <Controller
              name="setRazonSocial"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá la razon social",
                  // pattern: (value) =>
                  //   /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                  //   "Nombre inválido",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mw-sm-450 m-auto"
                  label=""
                  name="setRazonSocial"
                  type="text"
                  width={100}
                  placeHolder="Perez S.A."
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setRazonSocial", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setRazonSocial");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div >
      <div className="slide-cias" style={{ marginTop: adaptativeMargin }}>
        <SegSlider companias={imagesCompanias} />
      </div>
    </div >
  );
};
