import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface SegTooltipProps {
  text: string;
  fillColor?: string;
  className?: string;
  placement?: "left" | "right" | "top" | "bottom" | "top-start" | "top-end" | "bottom-end" | "bottom-start";
}

export const SegTooltip = ({
  text,
  fillColor = "#2c7dfd",
  className,
  placement = "right",
}: SegTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      if (tooltipRef.current && isVisible) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const touchY = event.touches[0].clientY;

        // Verifica si el evento de desplazamiento está fuera del área del tooltip
        if (
          touchY < tooltipRect.top ||
          touchY > tooltipRect.bottom
        ) {
          setIsVisible(false);
        }
      }
    };

    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isVisible]);

  const StyledSvg = styled.svg`
    width: 18px;
    height: 18px;
    fill: ${fillColor};
    display: inline-block;
    vertical-align: 0;
    margin-left: 0.25rem !important;
    --bs-tooltip-bg: ${fillColor};
    --bs-tooltip-font-size: 14px;
    &:focus {
      outline: none;
    }
  `;

  const StyledTippy = styled(Tippy)`
    background: ${fillColor};
    .tippy-arrow {
      color: ${fillColor};
    }
  `;

  const handleTouchStart = () => {
    setIsVisible(true);
  };

  return (
    <StyledTippy
      content={text}
      placement={placement}
      theme="segurarse"
      onClickOutside={() => setIsVisible(false)}
      onHide={() => setIsVisible(false)}
      ref={tooltipRef}
    >
      <StyledSvg
        className={className}
        onTouchStart={handleTouchStart}
      >
        <use xlinkHref="#info-circle" />
        <symbol id="info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </symbol>
      </StyledSvg>
    </StyledTippy>
  );
};
