import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IPasos } from "../../../Pages/AP/Cotizador";
import { useCotizacionStore } from "../../../Services/Providers/CotizacionStore";
import { SegSelect, SegSelectOption } from "../../SegUI/SegSelect/SegSelect";
import { FormProvider, useForm } from "react-hook-form";
import obtenerProvincias from "../../../Services/API/Provincias";
import obtenerLocalidaXProvincia from "../../../Services/API/Localidades";
import { SegButton } from "../../SegUI/SegButton/SegButton";

interface ICodigoPostalManual {
    provincia: string;
    localidad: string;
    cuandoContrata: string;
}

interface CodigoPostalManualProps {
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const CodigoPostalManual = ({ setSteps, ...rest }: CodigoPostalManualProps) => {
    const nav = useNavigate();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const [localidadDis, setLocalidadDis] = useState<boolean>(false);
    const [provinciaDis, setProvinciaDis] = useState<boolean>(false);

    const [localidadLoad, setLocalidadLoad] = useState<boolean>(false);
    const [provinciaLoad, setProvinciaLoad] = useState<boolean>(false);

    const [provincias, setProvincias] = useState<SegSelectOption[]>([]);
    const [localidades, setLocalidades] = useState<SegSelectOption[]>([]);

    const [provincia, setProvincia] = useState<SegSelectOption>();
    const [localidad, setLocalidad] = useState<SegSelectOption>();

    const [openProvincia, setOpenProvincia] = useState<boolean>(false)
    const [openLocalidad, setOpenLocalidad] = useState<boolean>(false)

    const btnRef = useRef<HTMLButtonElement | null>(null);

    const {
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ICodigoPostalManual>();

    const methods = useForm<ICodigoPostalManual>();
    const { compania } = useParams();
    const fetchProvincias = async () => {
        setProvinciaDis(true);
        setProvinciaLoad(true);
        const response: any = await obtenerProvincias();
        setProvinciaDis(false);
        setProvinciaLoad(false);
        const formattedData = response.map((item: any) => ({
            value: item,
            label: item,
        }));
        setProvincias(formattedData)
    }

    const handleLocalidadChange = (e: any) => {
        setLocalidad(e)
        setOpenLocalidad(false)
    }
    const handleProvinciaChange = (e: any) => {
        setProvincia(e)
        setOpenLocalidad(true)
    }


    const fetchLocalidades = async () => {
        if (provincia !== null && provincia !== undefined) {

            setLocalidadLoad(true);
            setLocalidadDis(true);
            const response: any = await obtenerLocalidaXProvincia(provincia?.value);
            setLocalidadDis(false);
            setLocalidadLoad(false);
            const formattedData = response.map((item: any) => ({
                value: item,
                label: item,
            }));
            setLocalidades(formattedData);
            if (localidad) {
                if (
                    !formattedData.some((loc: any) => {
                        return loc.value === localidad?.value;
                    })
                ) {
                    setValue("localidad", "");
                    setLocalidad({
                        value: "",
                        label: ""
                    })
                } else {
                    setLocalidad(cotizacionValues.localidad)
                    setValue("localidad", localidad?.value);
                }
            }
            if (cotizacionValues?.localidad) {
                if (
                    !formattedData.some((loc: any) => {
                        return loc.value === cotizacionValues.localidad?.value;
                    })
                ) {
                    setValue("localidad", "");
                    setLocalidad({
                        value: "",
                        label: ""
                    })
                } else {
                    setLocalidad(cotizacionValues?.localidad)
                    setValue("localidad", cotizacionValues?.localidad?.value);
                }
            }

        }
    };

    useEffect(() => {
        fetchProvincias().then(() => {
            if (cotizacionValues?.provincia) {
                setProvincia(cotizacionValues?.provincia)
                setValue("provincia", cotizacionValues.provincia?.value);
                setOpenProvincia(false)
            } else {
                setOpenProvincia(true)
            }
        });
        
        if (cotizacionValues.provincia && cotizacionValues.localidad) {
            setOpenProvincia(false)
            setOpenLocalidad(false)
        }
    }, []);

    useEffect(() => {
        fetchLocalidades();
    }, [provincia]);

    const onSubmit = (data: ICodigoPostalManual) => {
        setCotizacionValues({
            ...cotizacionValues,
            localidad: localidad,
            provincia: provincia,
        });
        compania
            ? nav("/ap/cotizacion/" + compania + "/condicion-iva")
            : nav("/ap/cotizacion/condicion-iva");
    };


    return (
        <div className="mt-4 bg-gray-light" {...rest}>
            <div className="frm01">
                <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                    <div className="cod-postal-column justify-content-center gap-4">
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">Seleccioná tu provincia</legend>
                                <FormProvider {...methods}>
                                    <SegSelect
                                        selected={provincia}
                                        setSelected={handleProvinciaChange}
                                        name="provincia"
                                        options={provincias}
                                        width={"100%"}
                                        disabled={provinciaDis}
                                        loading={provinciaLoad}
                                        borderColor={errors.provincia ? "red" : ""}
                                        openDefault={openProvincia}
                                    />
                                </FormProvider>
                                <p>{errors.provincia?.message}</p>
                            </fieldset>
                        </div>
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">Seleccioná tu localidad</legend>
                                <FormProvider {...methods}>
                                    <SegSelect
                                        selected={localidad}
                                        setSelected={handleLocalidadChange}
                                        name="localidad"
                                        options={localidades}
                                        width={"100%"}
                                        disabled={localidadDis}
                                        loading={localidadLoad}
                                        borderColor={errors.localidad ? "red" : ""}
                                        openDefault={openLocalidad}
                                    />
                                </FormProvider>
                                <p>{errors.localidad?.message}</p>
                            </fieldset>
                        </div>
                    </div>
                    <div className="form-buttons text-center">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            disabled={localidad?.value && provincia?.value ? false : true}
                            ref={btnRef}
                            className="mb-4"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
