import { ChangeEvent, useEffect, useState } from 'react'
import { SegInput } from '../../../SegUI/SegInput/SegInput'
import { SegTooltip } from '../../../SegUI/SegTooltip/SegTooltip'
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer'
import { SegButton } from '../../../SegUI/SegButton/SegButton'
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { useNavigate } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useCertificadoNoRodamiento } from './hooks/useCertificadoNoRodamiento'

const StyledLink = styled(Link)`
    padding: 10px;
    padding-left:25px;
    padding-right:25px;
    width: 100%;
    color: #2C7DFD;
    background-color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    border: 0;
    border-radius: 5px;
`

interface IDatosAuto {
    patente: string;
    numMotor: string;
    numChasis: string;
}

export const DatosAuto = () => {

    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { cotizacionValues } = useCotizacionStore();
    const { renderInputCertificadoNoRodamiento, validarCNR } = useCertificadoNoRodamiento({ es0km: (cotizacionValues.año?.value === "0 Km") ? true : false });
    const [esSinPatente, setEsSinPatente] = useState<boolean>((cotizacionValues.año?.value === "0 Km") ? true : false);
    const nav = useNavigate();

    const { handleSubmit, control, setValue, formState, trigger } = useForm<IDatosAuto>();

    const onSubmit: SubmitHandler<IDatosAuto> = (values: IDatosAuto) => {
        const { patente, numMotor, numChasis } = values;

        setEmisionValues({
            ...emisionValues,
            numMotor,
            numChasis,
            patente: esSinPatente ? "A/D" : patente.toUpperCase()
        });

        nav("/auto/emision/datostarjeta");
    }

    useEffect(() => {
        cotizacionValues.patente && setValue("patente", cotizacionValues.patente);
        cotizacionValues.patente && trigger("patente")
        emisionValues.numMotor ? setValue("numMotor", emisionValues.numMotor) : setValue("numMotor", "")
        emisionValues.numMotor && trigger("numMotor")
        emisionValues.numChasis ? setValue("numChasis", emisionValues.numChasis) : setValue("numChasis", "")
        emisionValues.numChasis && trigger("numChasis")

        if (cotizacionValues.patente === undefined) {
            if (cotizacionValues.año?.value === "0 Km") {
                const nroMotorInput = document.getElementById("nroMotor");
                if (nroMotorInput && nroMotorInput.tagName.toLowerCase() === "input") {
                    nroMotorInput.focus();
                }
            } else {
                const patenteInput = document.getElementById("patente");
                if (patenteInput && patenteInput.tagName.toLowerCase() === "input") {
                    patenteInput.focus();
                }
            }
        } else {
            const nroMotorInput = document.getElementById("nroMotor");
            if (nroMotorInput && nroMotorInput.tagName.toLowerCase() === "input") {
                nroMotorInput.focus();
            }
        }

    }, []);

    const handleSetEsSinPatente = async (e: ChangeEvent<HTMLInputElement>) => {
        setEsSinPatente(e.target.checked);
        setValue("patente", "");
        await new Promise(resolve => setTimeout(resolve, 0)); // Espera un ciclo de evento
        trigger("patente");
    }


    return (
        <div>
            <div className="px-20 pb-40 pb-lg-60 maxw-1170p mx-auto">
                {/* <div className="bg-gray-light py-lg-1 mb-3 mb-lg-2 rounded-5p"> */}
                <div className="d-lg-none">
                    <StyledLink to="" data-bs-target="#offcanvas" data-bs-toggle="collapse" className='btn1-md d-block fs-15 maxw-md-475p mx-md-auto' >Ver detalle de cobertura</StyledLink>
                </div>
                <div className="d-none d-lg-block">
                    <SegCoberturaInfo emisionValues={emisionValues} />
                </div>
                {/* <div className="d-none d-lg-flex align-items-center justify-content-center">
                        <span className="me-3 fs-16 fw-bold text-primary">Vehículo cotizado <span className="fs-20 fw-bold text-gray-dark">&gt;</span></span>
                        <div className="fs-16 fw-bold text-gray-aux">{cotizacionValues.marca?.label + " " + cotizacionValues.modelo?.label + " " + cotizacionValues.año?.label} | {cotizacionValues.version?.label} | <span className="fw-normal">Zona de guardado:</span> {cotizacionValues.localidad?.label}</div>
                    </div> */}
                {/* </div> */}
                {/* <SegCoberturaInfoEmision emisionValues={emisionValues} /> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Datos del auto'>
                        <div className="col-md-4">
                            <Controller
                                name="patente"
                                control={control}
                                rules={{
                                    validate: {
                                        pattern: (value) => {
                                            if (esSinPatente) return true;
                                            return /^[A-Za-z]{3}\d{3}$|^[A-Za-z]{2}\d{3}[A-Za-z]{2}$/.test(
                                                value
                                            ) || "Patente inválida"
                                        },
                                        required: (value) => {
                                            if (!esSinPatente) {
                                                return !!value || "Ingresá la patente";
                                            } else {
                                                return true
                                            }
                                        },

                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <div><label style={{ fontWeight: 'bold' }}>Patente </label>
                                        <SegInput
                                            className='mt-2'
                                            id='patente'
                                            placeHolder={esSinPatente ? "" : "Ej: FUH384"}
                                            name="patente"
                                            type="text"
                                            width={100}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("patente", e.target.value);
                                                trigger("patente");
                                            }}
                                            label=""
                                            value={field.value}
                                            leyendaError={fieldState?.error?.message}
                                            autoFocus={true}
                                            disabled={esSinPatente}
                                            borderColor={`${formState.errors.patente
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.patente
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    </div>
                                )}
                            />
                            {/* <SegInput label='Patente' name='patente' type='text' width={100} disabled={patenteDisabled} /> */}
                            {(cotizacionValues.año?.value === "0 Km") &&
                                <div className='d-flex align-items-center'>
                                    <input
                                        id='checkPatente'
                                        className='mx-2'
                                        type="checkbox"
                                        checked={esSinPatente}
                                        onChange={(e) => handleSetEsSinPatente(e)}
                                    />
                                    <label htmlFor='checkPatente' className="label-color" style={{ cursor: "pointer" }}>A declarar</label>
                                </div>
                            }
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="numMotor"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá Nro. de motor",
                                        withoutSpecialCaracters: (value) =>
                                            /^[a-zA-Z0-9]+$/.test(
                                                value
                                            ) || "No se permiten caracteres especiales.",
                                        min5: (value) => {
                                            if (value.length < 5) {
                                                return "Minimo 5 caracteres"
                                            }
                                            else {
                                                return true
                                            }
                                        },
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    // <div><label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>N° de motor <SegTooltip text="Lo encontrás en la cédula verde." /></label>
                                    <div><div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}><label>N° de motor <SegTooltip text="Lo encontrás en la cédula verde." /></label></div>
                                        <SegInput
                                            id="nroMotor"
                                            className='mt-2'
                                            placeHolder="Ej: CDJA48161179"
                                            name="numMotor"
                                            type="text"
                                            width={100}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("numMotor", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("numMotor");
                                            }}
                                            label=""
                                            value={field.value}
                                            leyendaError={fieldState?.error?.message}
                                            autoFocus={true}

                                            borderColor={`${formState.errors.numMotor
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.numMotor
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    </div>
                                )}
                            />
                            {/* <SegInput label='N° de motor' name='numMotor' type='text' width={100} /> */}
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="numChasis"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá Nro. de chasis",
                                        withoutSpecialCaracters: (value) =>
                                            /^[a-zA-Z0-9]+$/.test(
                                                value
                                            ) || "No se permiten caracteres especiales.",
                                        min5: (value) => {
                                            if (value.length < 5) {
                                                return "Minimo 5 caracteres"
                                            }
                                            else {
                                                return true
                                            }
                                        },
                                        max17: (value) => {
                                            if (value.length > 17) {
                                                return "Maximo 17 caracteres"
                                            }
                                            else {
                                                return true
                                            }
                                        },
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <div>
                                        <label style={{ fontWeight: 'bold' }}>N° de chasis </label>
                                        <SegTooltip text="Lo encontrás en la cédula verde." />
                                        <SegInput
                                            maxLenghtDigits={17}
                                            className='mt-2'
                                            placeHolder="Ej: 9BFZF16N748161179"
                                            name="numChasis"
                                            type="text"
                                            width={100}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("numChasis", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("numChasis");
                                            }}
                                            label=""
                                            value={field.value}
                                            leyendaError={fieldState?.error?.message}
                                            autoFocus={true}
                                            borderColor={`${formState.errors.numChasis ? "var(--bs-form-invalid-border-color)" : ""}`}
                                            focusBorderColor={`${formState.errors.numChasis ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}`}
                                        />
                                    </div>
                                )}
                            />
                            {/* <SegInput label='N° de chasis' name='numChasis' type='text' width={100} /> */}
                        </div>
                        <div style={(window.innerWidth > 768) ? { display: "grid", gridTemplateColumns: "80% 20%", alignItems: "end" } : {}}>
                            {renderInputCertificadoNoRodamiento()}
                            <div className={`d-flex align-items-end mt-3 ${window.innerWidth < 768 ? 'justify-content-center' : 'justify-content-end'}`}>
                                <div style={window.innerWidth < 768 ? { width: "100%" } : {}}>
                                    <SegButton label='Continuar'
                                        type='submit' disabled={!formState.isValid || validarCNR()}
                                        className={`${window.innerWidth > 768 && "fs-15"}`}
                                        style={{ width: window.innerWidth < 768 ? "100%" : "" }} />
                                    <div className="text-center" style={{ paddingTop: "5px" }}>
                                        <a href={encodeURI("https://wa.me/5491169568002?text=Hola, necesito ayuda con la emisión de mi seguro " + (emisionValues?.cotizacionDesc?.planName! || null) + " de la aseguradora " + (emisionValues?.cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer' className="fs-14 fw-medium fs-md-17 a-dark">
                                            <span className="hover-border-underline">Continuar por WhatsApp</span>
                                            <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5, verticalAlign: "middle" }}><use xlinkHref="#whatsapp"></use></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <div className="col-md-4">
                                <div className="d-flex align-items-center">
                                <div className="me-3 mb-1">
                                <svg className="icon" width="23" height="29"><use xlinkHref="#lock"></use></svg>
                                </div>
                                <div className="fs-13 lh-sm">
                                <b>Pago 100% seguro</b> con todas las tarjetas de crédito
                                </div>
                                </div>
                            </div> */}
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div>
    )
}