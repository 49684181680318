import mapfre from "../Assets/img/logos/cias/logo_mapfre.svg"
import integrity from "../Assets/img/logos/cias/logo_liberty_b.png"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "mapfre",
        imgRuta: mapfre,
        mostrar: true
    },
    {
        nombreCompania: "integrity",
        imgRuta: integrity,
        mostrar: true
    },
]