import chevrolet from "../Assets/img/logos/autos/logo_chevrolet.svg";
import fiat from "../Assets/img/logos/autos/logo_fiat.svg";
import citroen from "../Assets/img/logos/autos/logo_citroen.svg";
import ford from "../Assets/img/logos/autos/logo_ford.svg";
import honda from "../Assets/img/logos/autos/logo_honda.svg";
import nissan from "../Assets/img/logos/autos/logo_nissan.svg";
import peugeot from "../Assets/img/logos/autos/logo_peugeot.svg";
import renault from "../Assets/img/logos/autos/logo_renault.svg";
import toyota from "../Assets/img/logos/autos/logo_toyota.svg";
import volkswagen from "../Assets/img/logos/autos/logo_volkswagen.svg";
import segurarse from "../Assets/img/logo_segurarse.svg";
import WhiteSegurarse from "../Assets/img/logo_segurarse_blanco.svg";
import Garex from '../Assets/img/logos/aseguradoras/logo_garex_assist.png';

export interface ImageMarca {
  marca: string
  image: string
}

export const images: ImageMarca[] = [
  {
    marca: "volkswagen",
    image: volkswagen,
  },
  {
    marca: "renault",
    image: renault,
  },
  {
    marca: "chevrolet",
    image: chevrolet,
  },
  {
    marca: "ford",
    image: ford,
  },
  {
    marca: "fiat",
    image: fiat,
  },
  {
    marca: "peugeot",
    image: peugeot,
  },
  {
    marca: "toyota",
    image: toyota,
  },
  {
    marca: "citroen",
    image: citroen,
  },
  {
    marca: "honda",
    image: honda,
  },
  {
    marca: "nissan",
    image: nissan,
  },
];

export const logosImage = [
  {
    image: segurarse,
  },
  {
    image: WhiteSegurarse,
  },
  {
    image: Garex
  }
];
