import React, { useEffect, useRef, useState } from 'react'
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';
import { IFechaNacimiento, ITelefono, useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { SegInput } from '../../../SegUI/SegInput/SegInput';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import CodigosDeArea from '../../../../Assets/CodigosDeArea.json';
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'



const StyledLink = styled(Link)`
    padding: 10px;
    padding-left:25px;
    padding-right:25px;
    width: 100%;
    color: #2C7DFD;
    background-color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    border: 0;
    border-radius: 5px;
`

interface IDatosPersonales {
    nombre: string;
    apellido: string;
    email: string;
    numDoc: string;
    cuil: string;
    fechaNac: IFechaNacimiento;
    celular: ITelefono;
    telAdicional: ITelefono;
}

export const DatosPersonales = () => {

    const nav = useNavigate();
    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { cotizacionValues } = useCotizacionStore();
    // const [isTelefonoValido, setIsTelefonoValido] = useState<boolean>(false);
    const [isCelularValido, setIsCelularValido] = useState<boolean>(false)
    const [isTelAdicionalValido, setIsTelAdicionalValido] = useState<boolean>(true)

    const { handleSubmit, control, setValue, formState, trigger, watch, getValues, setFocus, setError, clearErrors } = useForm<IDatosPersonales>();
    // const methods = useForm<IDatosPersonales>();
    const cuilRef = useRef<HTMLInputElement | null>(null);



    const onSubmit: SubmitHandler<IDatosPersonales> = (values: IDatosPersonales) => {
        const { nombre, apellido, email, numDoc, celular, cuil, fechaNac, telAdicional } = values;
        setEmisionValues({
            ...emisionValues,
            nombre: nombre,
            apellido: apellido,
            email: email,
            numDoc: numDoc,
            celular: celular,
            cuil: cuil,
            fechaNac: fechaNac,
            telAdicional: telAdicional,
        });
        nav("/auto/emision/radicacionvehiculo");
    }

    useEffect(() => {
        const numeroDocumento = document.getElementById("numeroDocumento");
        if (numeroDocumento && numeroDocumento.tagName.toLowerCase() === "input") {
            numeroDocumento.focus();
        }
    }, []);

    useEffect(() => {
        emisionValues.nombre ? setValue("nombre", emisionValues.nombre) : cotizacionValues.nombre ? setValue("nombre", cotizacionValues.nombre) : setValue("nombre", "")
        emisionValues.nombre ? trigger("nombre") : cotizacionValues.nombre && trigger('nombre')
        emisionValues.apellido ? setValue("apellido", emisionValues.apellido) : cotizacionValues.apellido ? setValue("apellido", cotizacionValues.apellido) : setValue("apellido", "")
        emisionValues.apellido ? trigger("apellido") : cotizacionValues.apellido && trigger('apellido')
        emisionValues.celular?.cod ? setValue("celular.cod", emisionValues.celular?.cod) : cotizacionValues.telefono ? setValue("celular.cod", cotizacionValues.telefono.prefijo) : setValue("celular.cod", "")
        emisionValues.celular?.cod ? trigger("celular.cod") : cotizacionValues.telefono && trigger('celular.cod')
        emisionValues.celular?.numero ? setValue("celular.numero", emisionValues.celular?.numero) : cotizacionValues.telefono?.numero ? setValue("celular.numero", cotizacionValues.telefono.numero) : setValue("celular.numero", "")
        emisionValues.celular?.numero ? trigger("celular.numero") : cotizacionValues.telefono && trigger('celular.numero')
        cotizacionValues.telefono && validarYActualizarTelefono("celular", setIsCelularValido);

        emisionValues.email ? setValue("email", emisionValues.email) : cotizacionValues.email ? setValue("email", cotizacionValues.email) : setValue("email", "")
        emisionValues.email ? trigger("email") : cotizacionValues.email && trigger('email')

        emisionValues.numDoc ? setValue("numDoc", emisionValues.numDoc) : setValue("numDoc", "")
        emisionValues.numDoc && trigger("numDoc")
        emisionValues.cuil ? setValue("cuil", emisionValues.cuil) : setValue("cuil", "")
        emisionValues.cuil && trigger("cuil")

        emisionValues.telAdicional && setValue("telAdicional.cod", emisionValues.telAdicional.cod)
        emisionValues.telAdicional?.cod && trigger("telAdicional.cod")
        emisionValues.telAdicional && setValue("telAdicional.numero", emisionValues.telAdicional.numero)
        emisionValues.telAdicional?.numero && trigger("telAdicional.numero")

    }, [])


    // Metodos validadores
    const validarTelefono = (prefijo: string, numero: string) => {
        const numeroCompleto = prefijo + numero;
        if (numeroCompleto.length === 10) {
            return true;
        } else {
            return false;
        }
    };

    const validarYActualizarTelefono = (val: string, setter: (value: React.SetStateAction<boolean>) => void) => {
        const { celular, telAdicional } = getValues();
        if (val === "celular") {
            const resultadoValidarTelefono = validarTelefono(celular.cod, celular.numero);
            setter(resultadoValidarTelefono);
        }
        else {
            if (telAdicional.cod || telAdicional.numero) {
                const resultadoValidarTelefono = validarTelefono(telAdicional.cod, telAdicional.numero);
                setter(resultadoValidarTelefono);
            } else {
                setter(true);
            }
        }
    };


    function calcularCuil(dni: string, genero: string) {
        // Paso 1 y 2
        let cuilBase = genero === 'M' ? '20' : '27';
        cuilBase += dni.toString().padStart(8, '0');
        // Paso 3
        let suma = 0;
        const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        for (let i = 0; i < cuilBase.length; i++) {
            suma += parseInt(cuilBase[i]) * coeficientes[i];
        }
        let verificador = 11 - (suma % 11);
        verificador = verificador === 11 ? 0 : verificador;
        // Construir el CUIL final
        return cuilBase + verificador;
    }

    const handleOnChangeDNI = (dni: string, cuil: string) => {
        if (!cotizacionValues.genero) return;

        if (dni && dni.length > 6) {
            const cuil = calcularCuil(dni, cotizacionValues.genero?.value!)
            setValue("cuil", cuil)
            trigger("cuil")
        } else if (cuil !== "") {
            setValue("cuil", "")
        }
        if (dni.length >= 8) {
            setFocus("cuil")
            cuilRef.current?.focus()
        }
    }

    const limpiarNumeroDocumento = (value: any) => {
        return value.replace(/[^\d-]/g, '');
    };

    return (
        <div>
            <div className="px-20 pb-40 pb-lg-60 maxw-1170p mx-auto">
                <div className="d-lg-none">
                    <StyledLink to="" data-bs-target="#offcanvas" data-bs-toggle="collapse" className='btn1-md d-block fs-15 maxw-md-475p mx-md-auto' >Ver detalle de cobertura</StyledLink>
                </div>
                <div className="d-none d-lg-block">
                    <SegCoberturaInfo emisionValues={emisionValues} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Datos personales'>
                        <div className="col-md-4">
                            <Controller
                                name="nombre"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el nombre",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Nombre inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Nombre"
                                        name="nombre"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Juan"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("nombre");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="apellido"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el apellido",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Apellido inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Apellido"
                                        name="apellido"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: López"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("apellido");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <SegInput label='Correo electrónico' name='email' type='email' width={100} disabled={true} value={cotizacionValues?.email} />
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="numDoc"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el Documento",
                                        pattern: (value) =>
                                            /^(\d{7,8})$/.test(value) ||
                                            "Documento inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        id='numeroDocumento'
                                        label="Número de documento"
                                        name="numDoc"
                                        type="number"
                                        width={100}
                                        placeHolder="Ej: 44444444"
                                        onChange={(e) => {
                                            const numDocLimpio = limpiarNumeroDocumento(e.target.value)
                                            handleOnChangeDNI(numDocLimpio, watch("cuil"));
                                            field.onChange(numDocLimpio);
                                            setValue("numDoc", numDocLimpio); // Actualiza el valor en React Hook Form
                                            trigger("numDoc");
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "+" || e.key === "-") {
                                                e.preventDefault()
                                            }
                                        }}
                                        value={field.value}
                                        autoFocus={false}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                        min={"1"}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="cuil"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el CUIL",
                                        pattern: (value) =>
                                            /^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value) ||
                                            "CUIL inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        ref={cuilRef}
                                        label="CUIL"
                                        name="cuil"
                                        type="number"
                                        width={100}
                                        placeHolder="Ej: 20999999995"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("cuil", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("cuil");
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "+" || e.key === "-") {
                                                e.preventDefault()
                                            }
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.cuil
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.cuil
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Fecha de nacimiento</label>
                            <div className='d-flex align-items-end gap-1 justify-content-between'>
                                <div className='col-md-4'>
                                    <SegInput label='' name='dia' type='number' width={100} value={cotizacionValues.fechaNac?.dia} disabled />
                                </div>
                                <div className='col-md-4'>
                                    <SegInput label='' name='mes' type='number' width={100} value={cotizacionValues.fechaNac?.mes} disabled />
                                </div>
                                <div className='col-md-4'>
                                    <SegInput label='' name='anio' type='number' width={100} value={cotizacionValues.fechaNac?.año} disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label fw-bold'>Celular</label>
                            <div className='d-flex gap-1'>
                                <Controller
                                    name="celular.cod"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá el Cód. Área",
                                            isInValidCodeArea: (value) =>
                                                CodigosDeArea.includes(value) ||
                                                "Cód. Área inválido",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup="0"
                                            className="col-4"
                                            label="Cód. Área"
                                            name="codigo"
                                            type="number"
                                            width={33}
                                            placeHolder="11"
                                            value={field.value}
                                            autoFocus={true}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("celular.cod", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("celular.cod");
                                                if (getValues("celular.numero") !== "" && !validarTelefono(getValues("celular.cod"), getValues("celular.numero"))) {
                                                    setError("celular.numero", { type: "validate", message: "Número inválido" })
                                                } else {
                                                    clearErrors("celular.numero")
                                                }
                                                validarYActualizarTelefono("celular", setIsCelularValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.celular?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.celular?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                                <Controller
                                    name="celular.numero"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá Número de Celular",
                                            pattern: (value) =>
                                                /^[0-9]{6,9}$/.test(value) || "Número inválido",
                                            validateCombination: (value) => {
                                                const prefijo = watch("celular.cod");
                                                if ((prefijo + value).length !== 10)
                                                    return "Número inválido"
                                            }
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup="15"
                                            className="col-8"
                                            label="Número de Celular"
                                            name="numeroCel"
                                            type="number"
                                            width={66}
                                            placeHolder="33669955"
                                            value={field.value}
                                            autoFocus={false}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("celular.numero", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("celular.numero");
                                                validarYActualizarTelefono("celular", setIsCelularValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.celular?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.celular?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label fw-bold'>Teléfono Adicional (Opcional)</label>
                            <div className='d-flex gap-1'>
                                <Controller
                                    name="telAdicional.cod"
                                    control={control}
                                    rules={{
                                        shouldUnregister: false,
                                        validate: {
                                            isInValidCodeArea: (value) => {
                                                const { telAdicional } = getValues()
                                                if (!value && !telAdicional.numero) {
                                                    return true;
                                                }
                                                else {
                                                    return CodigosDeArea.includes(value) || value === '' || "Cód. Área inválido";
                                                }
                                            },
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup="0"
                                            className="col-4"
                                            label="Cód. Área"
                                            name="codigoArea"
                                            type="number"
                                            width={33}
                                            placeHolder="11"
                                            value={field.value}
                                            autoFocus={true}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("telAdicional.cod", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("telAdicional.cod");
                                                if (getValues("telAdicional.numero") !== "" && !validarTelefono(getValues("telAdicional.cod"), getValues("telAdicional.numero"))) {
                                                    setError("telAdicional.numero", { type: "validate", message: "Número inválido" })
                                                } else {
                                                    clearErrors("telAdicional.numero")
                                                }
                                                validarYActualizarTelefono("telAdicional", setIsTelAdicionalValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.telAdicional?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.telAdicional?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                                <Controller
                                    name="telAdicional.numero"
                                    control={control}
                                    rules={{
                                        shouldUnregister: false,
                                        validate: {
                                            optionalValidation: (value) => {
                                                // Verificar si el valor está presente antes de aplicar la validación
                                                const { telAdicional } = getValues()
                                                if (!value && !telAdicional.cod) {
                                                    return true; // Permitir que sea opcional
                                                }

                                                const prefijo = watch("telAdicional.cod");
                                                if ((prefijo + value).length !== 10)
                                                    return "Número inválido"

                                                // Aplicar la validación solo si hay un valor
                                                return /^[0-9]{6,9}$/.test(value) || value === '' || "Número inválido";

                                            },
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup="15"
                                            className="col-8"
                                            label="Número de Celular"
                                            name="telAdicional"
                                            type="number"
                                            width={66}
                                            placeHolder="33669955"
                                            value={field.value}
                                            autoFocus={false}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("telAdicional.numero", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("telAdicional.numero");
                                                validarYActualizarTelefono("telAdicional", setIsTelAdicionalValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.telAdicional?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.telAdicional?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-left mt-57">
                            <div style={window.innerWidth < 768 ? { width: "100%" } : { marginTop: '1%' }}>
                                <SegButton
                                    label='Continuar'
                                    type='submit'
                                    disabled={formState.isValid && isCelularValido && isTelAdicionalValido ? false : true}
                                    style={window.innerWidth < 768 ? { width: "100%" } : {}}
                                    className={window.innerWidth < 768 ? '' : 'fs-15'}
                                />
                                <div className="text-center" style={{ paddingTop: "3px" }}>
                                    <a href={encodeURI("https://wa.me/5491169568002?text=Hola, necesito ayuda con la emisión de mi seguro " + (emisionValues?.cotizacionDesc?.planName! || null) + " de la aseguradora " + (emisionValues?.cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer' className="fs-14 fw-medium fs-md-17 a-dark">
                                        <span className="hover-border-underline">Continuar por WhatsApp</span>
                                        <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5, verticalAlign: "middle" }}><use xlinkHref="#whatsapp"></use></svg>
                                    </a>
                                </div>
                            </div>
                            {/* <div>
                                <SegButton label='Continuar' type='submit'
                                    disabled={
                                        !formState.isValid || !isCelularValido  // Verifica si el formulario es válido
                                    } />
                                <div className="text-center" style={{ paddingTop: "5px" }}>
                                    <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="norreferer noreferrer" className="fs-14 fw-medium fs-md-17 a-dark">
                                        <span className="hover-border-underline">Continuar por WhatsApp</span>
                                        <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5, verticalAlign: "middle" }}><use xlinkHref="#whatsapp"></use></svg>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className={`d-flex align-items-end mt-5 ${window.innerWidth < 768 ? 'justify-content-center' : 'justify-content-end'}`}>
                            <div style={window.innerWidth < 768 ? { width: "100%" } : {}}>
                                <SegButton
                                    label='Continuar'
                                    type='submit'
                                    disabled={formState.isValid && isCelularValido && isTelAdicionalValido ? false : true}
                                    style={window.innerWidth < 768 ? { width: "100%" } : {}}
                                />
                                <div className="text-center" style={{ paddingTop: "5px" }}>
                                    <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="norreferer noreferrer" className="fs-14 fw-medium fs-md-17 a-dark">
                                        <span className="hover-border-underline">Continuar por WhatsApp</span>
                                        <svg className="icon" style={{ width: 20, height: 20, marginLeft: 5, verticalAlign: "middle" }}><use xlinkHref="#whatsapp"></use></svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div>
    )
}
