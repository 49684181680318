import lifeSeguros from "../Assets/img/logos/cias/logo_metlife_b.png"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "life seguros",
        imgRuta: lifeSeguros,
        mostrar: true
    },
]