import { useEffect, useState } from 'react'
import { useGraciasStore } from '../../../../Services/Providers/GraciasStore';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { useNavigate, useParams } from 'react-router-dom';
import { OffCanvas } from '../../Auto/Precios/OffCanvas';
import { ModalDescripcion } from './ModalDescripciones';

interface ModalPreciosProps {
    cotizacionDesc: any;//CotizacionDescPrepaga
    sumaAsegurada: string;
    isEmision?: boolean
}


export const ModalPrecios = ({ cotizacionDesc, isEmision = false, sumaAsegurada = "" }: ModalPreciosProps) => {
    const { setGraciasValues } = useGraciasStore();
    const { emisionValues } = useEmisionStore();
    const { compania } = useParams();
    const [indiceAbierto, setIndiceAbierto] = useState<number | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        setIndiceAbierto(-1)
    }, [cotizacionDesc]);


    const handleClick = (indice: number) => {
        setIndiceAbierto(prevIndice => (prevIndice === indice ? null : indice));
    };

    const handleSubmit = (e: any, direccion: string, gracias: boolean = false) => {
        e.preventDefault();
        document.body.style.cssText = "";
        if (gracias) {
            setGraciasValues(cotizacionDesc?.companyName)
        }
        navigate(direccion);
    }


    return (
        <div>
            <OffCanvas abrirPrimeraCarga={emisionValues.cotizacionDesc ? true : false} className={"offcanvas offcanvas-end oc-cob"}>
                <div className="oc-sum ">
                    <div className="cobertura">{cotizacionDesc?.planName}</div>
                    <div className="empresa">
                        <img src={cotizacionDesc?.companyLogo} alt={cotizacionDesc?.companyName} loading="lazy" />
                    </div>

                    <div className="precio">
                        <span className="monto">{"$" + cotizacionDesc?.premio}</span>
                        <span className="periodo"> /mes</span>
                    </div>
                    {
                        !isEmision &&
                        <div className="cta">
                            <a href={compania ? ("/ap/emision" + compania) : "/ap/emision"} type="button" onClick={(e) => handleSubmit(e, (compania ? ("/ap/emision" + compania) : "/ap/emision"), true)} className="btn btn-primary">Contratar</a>

                        </div>
                    }
                    <div className='text-center'>
                        <p className='mt-3 mb-0'>La cobertura incluye</p>
                        <p className='mt-0' style={{ color: "#ff9800", fontWeight: "600" }}>{`Suma asegurada: ${sumaAsegurada}`}</p>
                    </div>
                    {
                        !isEmision &&
                        <div className="cta-wa d-none d-lg-block">
                            <p>¿Tenés dudas sobre esta cobertura?</p>
                            <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                        </div>
                    }
                    <div className="datafiscal d-none d-lg-flex">
                        <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt='Información fiscal' /></div>
                        <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                    </div>
                </div>
                <div className={"oc-det"}>


                    <div className="accordion accordion-flush acc-01" id="accDetalle">
                        {cotizacionDesc?.descList && cotizacionDesc?.descList.map((desc: any, index: number) =>
                            <ModalDescripcion
                                key={index}
                                id={desc.id}
                                descripcion={desc.descripcion}
                                monto={desc.monto}
                                isOpen={index === indiceAbierto}
                                toggleDesplegable={() => handleClick(index)}
                            />
                        )}
                    </div>
                    <div className="cta-wa d-lg-none">
                        <p>¿Tenés dudas sobre esta cobertura?</p>
                        <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                    </div>
                    <div className="datafiscal d-lg-none">
                        <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt="Información fiscal" /></div>
                        <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                    </div>
                </div>

            </OffCanvas>
        </div>
    )
}
