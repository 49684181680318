import { useEffect } from "react";

export interface SeoProps {
    title?: string;
    description?: string;
    keywords?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    twitterCard?: string;
    canonicalUrl?: string;
    robots?: string;
    marcaBlanca?: string;
}

const useSEOConfig = (props: SeoProps) => {
    useEffect(() => {
        // Actualizar el título
        if (props.title) {
            document.title = `${props.marcaBlanca ? props.marcaBlanca : "Segurarse"} | ${props.title}`;
        }

        // Actualizar o crear meta tags
        const updateMetaTag = (name: string, content?: string) => {
            if (content) {
                let meta = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;
                if (!meta) {
                    meta = document.createElement('meta');
                    meta.name = name;
                    document.head.appendChild(meta);
                }
                meta.content = content;
            }
        };

        updateMetaTag('description', props.description);
        updateMetaTag('keywords', props.keywords);
        updateMetaTag('og:title', props.ogTitle);
        updateMetaTag('og:description', props.ogDescription);
        updateMetaTag('og:image', props.ogImage);
        updateMetaTag('twitter:card', props.twitterCard);

        // Actualizar el tag robots
        if (props.robots) {
            updateMetaTag('robots', props.robots);
        }

        // Actualizar el canonical URL
        if (props.canonicalUrl) {
            let link = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'canonical';
                document.head.appendChild(link);
            }
            link.href = props.canonicalUrl;
        }
    }, [props]);
};

export default useSEOConfig;