import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";
import { SegToggle } from "../../../../SegUI/SegToggle/SegToggle";

interface IDocumento {
  numero: string;
}

interface DocumentoProps {
  setSteps: Dispatch<SetStateAction<IPasos>>
  docConyuge: string | undefined;
}

export const DocumentoConyuge = ({
  setSteps,
  docConyuge,
  ...rest
}: DocumentoProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [numero, setNumero] = useState<string>();
  const [isUnificarAportes, setUnificarAportes] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useForm<IDocumento>();
  //const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubmit = (values: IDocumento) => {
    const { numero } = values;
    setCotizacionValues({
      ...cotizacionValues,
      documentoConyuge: numero,
      unificarAportes: isUnificarAportes
    });

    if (isUnificarAportes) {
      if (compania) {
        nav("/prepaga/individuo/cotizacion/" + compania + "/sueldo-bruto-conyuge");
      }
      else {
        nav("/prepaga/individuo/cotizacion/sueldo-bruto-conyuge");
      }
    }
    else {
      if (cotizacionValues.hijos) {
        if (compania) {
          nav("/prepaga/individuo/cotizacion/" + compania + "/hijos");
        }
        else {
          nav("/prepaga/individuo/cotizacion/hijos");
        }
      }
      else {
        nav("/resultado-del-cotizador-de-salud");
      }
    }
  };

  useEffect(() => {
    !cotizacionValues.conyuge && (compania ? nav("/prepaga/individuo/cotizacion/" + compania + "/observaciones") : nav("/prepaga/individuo/cotizacion/observaciones"))
  }, [])

  useEffect(() => {
    if (cotizacionValues.documentoConyuge) {
      setNumero(cotizacionValues.documentoConyuge)
      setValue("numero", cotizacionValues.documentoConyuge)
    }
  }, []);

  useEffect(() => {
    if (cotizacionValues.unificarAportes) {
      setUnificarAportes(cotizacionValues.unificarAportes)
    }
  }, []);

  useEffect(() => {
    setValue("numero", docConyuge ? docConyuge : "");
    docConyuge && trigger();
  }, []);

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>N° de documento del cónyuge</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center">
            <div className="row gx-3 text-start mw-sm-450 m-1">
              <Controller
                name="numero"
                control={control}
                rules={{
                  validate: {
                    required: (value) => !!value || "Ingresá el Número",
                    pattern: (value) => {
                      return /^(\d{7,8})$/.test(value) || "Documento inválido"

                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    label="Sólo números, sin puntos ni guiones ni espacios"
                    name="setNumero"
                    type="number"
                    width={200}
                    placeHolder=""
                    value={field.value}
                    autoFocus={true}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("numero", e.target.value); // Actualiza el valor en React Hook Form
                      trigger("numero");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${errors.numero
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${errors.numero
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-18">
            <h5 className="fw-bold pe-20">Unificar aportes</h5>
            <SegToggle idLabel="reciboSueldo" label="No" isChecked={isUnificarAportes} onChange={setUnificarAportes} />
            <div style={{ marginLeft: "-8px" }}>Si</div>
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary mb-4"
              disabled={!isValid}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
