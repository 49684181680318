import Axios from 'axios'
import { getPathName } from './helper';

const guardarContactoArt = async (origen, tipoCotizador, landingUrl, refererUrl, cuit, razonSocial, masaSalarial, empleados, email, codigoArea, telefono, provincia, localidad, nombreApellido, apellido, captchaToken) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        isMobile: "false",
        rubro: "ART",
        origen: origen,
        tipoCotizador: tipoCotizador,
        landingUrl: landingUrl,
        refererUrl: refererUrl,
        cuit: cuit,
        razonSocial: razonSocial,
        empleados: empleados,
        masaSalarial: masaSalarial,
        nombreApellido: nombreApellido,
        apellido: apellido,
        email: email,
        codigoArea: codigoArea,
        telefono: telefono,
        
        provincia: provincia,
        localidad: localidad,
        
        g_recaptcha_response: captchaToken
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/SetCotizacionArtJSON", params,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
    });
};

export default guardarContactoArt;