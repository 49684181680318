import React from 'react'
import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos';
import PropuestaValor from '../Components/PropuestaValor';
import Asesoramiento from '../Components/Asesoramiento';
import { Splide, SplideSlide } from "@splidejs/react-splide";

export const Caucion = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Caución'
                    subTitulo={{ __html: "Protegé tu patrimonio ante eventuales incumplimientos de pago.<br/><br/>¿Empezamos?" }}
                    url='/cotizador-de-seguros-pymes-caucion'
                    icon='#icon-seg-caucion'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Un Seguro de Caución garantiza el cumplimiento de las obligaciones contractuales de una persona o entidad asegurada hacia un tercero. Este seguro tiene como objetivo garantizar el cumplimiento de obligaciones financieras o legales.<br />
                                La aseguradora se obliga a abonar al asegurado y/o beneficiario una suma determinada en virtud de los perjuicios que le haya ocasionado el tomador del seguro como consecuencia de su incumplimiento.
                                <br /><br />
                                <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                                    <div className="row gx-lg-80">
                                        <div className="col">
                                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">Los principales actores son:</p>
                                            <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                                <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                                    <b>Tomador:</b> Es quién solicita el seguro y debe cumplir con las obligaciones garantizadas.
                                                </li>
                                                <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                                    <b>Asegurado:</b> Es el tercero a favor de quien se emite la póliza.
                                                </li>
                                                <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                                    <b>Asegurador:</b> Es el encargado de emitir la póliza.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Cumplimiento de contratos:</b> si una persona o entidad tiene un contrato con un tercero y no cumple con sus obligaciones contractuales, el seguro de caución puede cubrir las pérdidas sufridas por el tercero debido a dicho incumplimiento.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Licencias y permisos:</b> en ciertos sectores y actividades, las autoridades pueden requerir una garantía de cumplimiento antes de otorgar una licencia o permiso. El seguro de caución puede proporcionar esta garantía en lugar de tener que proporcionar un depósito o garantía en efectivo.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Aduanas y comercio internacional:</b> las autoridades aduaneras pueden requerir una garantía de cumplimiento de las obligaciones fiscales y aduaneras.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Reclamaciones judiciales:</b> en determinados casos legales, las autoridades pueden requerir una garantía para cubrir posibles indemnizaciones en caso de que el fallo sea desfavorable para la parte asegurada.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}