interface FAQItem {
    titulo: string;
    subTitulo: string | { __html: string };
}

interface PreguntasFrecuentesProps {
    faqs: FAQItem[];  // Cambia a un array de objetos FAQItem
}

export const PreguntasFrecuentes = ({ faqs }: PreguntasFrecuentesProps) => {



    return (
        <div className="py-5 bg-f4">
            <div className="container">
                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-5">
                    Preguntas Frecuentes
                </h2>
                <div className="px-3 py-2 bg-white rounded-10 mb-5">
                    <div className="accordion accordion-flush accordion-faq" id="faqAccordion">
                        {faqs.map((faq, index) => (
                            <div className="accordion-item" key={index}>
                                <h3 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${index}`}
                                    >
                                        {faq.titulo}
                                    </button>
                                </h3>
                                <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body">
                                        <p className="fs-15 fs-lg-16 lh-16 mb-20">
                                            {typeof faq.subTitulo === 'string' ? (
                                                faq.subTitulo
                                            ) : (
                                                <span dangerouslySetInnerHTML={faq.subTitulo} />
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
