import React from 'react'
import bgHero from '../../../Assets/img/bg-hero-landing-prods.svg'

interface HeroProps {
    titulo: string;
    subTitulo?: string | { __html: string };
    url: string;
    icon: string;
}

export const Hero = ({
    titulo,
    subTitulo,
    url,
    icon
}: HeroProps) => {
    return (
        <div className="position-relative pt-40 py-lg-55 z-1 landing-white-circle">
            <div className="container">
                <div className="row align-items-lg-center">
                    <div className="col-10 col-md-8 col-lg-6 col-xl-4 mx-auto mx-lg-0 mb-30 mb-lg-0 text-center text-lg-start">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-50 lh-11 text-black mb-12 mb-lg-32">
                            {titulo}
                        </h2>
                        <p className="fs-16 fs-lg-20 lh-12 text-black mb-16 mb-lg-50">
                            {typeof subTitulo === 'string' ? (
                                subTitulo
                            ) : (
                                <span dangerouslySetInnerHTML={subTitulo} />
                            )}
                        </p>
                        <a
                            href={url}
                            className="transition-base d-inline-block w-180p w-lg-220p py-10 py-lg-14 rounded-2 fs-16 fs-lg-20 fw-bold lh-1 text-center bg-primary text-white"
                        >
                            Cotizá ahora
                        </a>
                    </div>
                    <div className="col-lg-6 ms-auto">
                        <div className="position-relative w-128p w-lg-360p mx-auto mb-n5 mb-lg-0">
                            <img src={bgHero} alt="Background Hero" />
                            <div className="position-absolute d-flex start-0 end-0 top-0 bottom-0 justify-content-center align-items-center text-white">
                                <svg className="svg-icon" width="45%" height="45%">
                                    <use xlinkHref={icon} />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
