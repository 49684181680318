import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import obtenerProvincias from "../../../../Services/API/Provincias";
import obtenerLocalidaXProvincia from "../../../../Services/API/Localidades";
import { IPasos } from "../../../../Pages/ART/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { FormProvider, useForm } from "react-hook-form";
import { useEmisionStore } from "../../../../Services/Providers/EmisionStore";

interface ICodigoPostalManual {
  provincia: string;
  localidad: string;
  cuandoContrata: string;
}

interface CodigoPostalManualProps {
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const CodigoPostalManual = ({ setSteps, ...rest }: CodigoPostalManualProps) => {
  const nav = useNavigate();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const [localidadDis, setLocalidadDis] = useState<boolean>(false);
  const [provinciaDis, setProvinciaDis] = useState<boolean>(false);

  const [localidadLoad, setLocalidadLoad] = useState<boolean>(false);
  const [provinciaLoad, setProvinciaLoad] = useState<boolean>(false);

  const [provincias, setProvincias] = useState<SegSelectOption[]>([]);
  const [localidades, setLocalidades] = useState<SegSelectOption[]>([]);

  const [provincia, setProvincia] = useState<SegSelectOption>();
  const [localidad, setLocalidad] = useState<SegSelectOption>();

  const [cuandoContrata, setCuandoContrata] = useState<SegSelectOption>();

  const [errorCaptcha, setErrorCaptcha] = useState("");

  const [openProvincia, setOpenProvincia] = useState<boolean>(false)
  const [openLocalidad, setOpenLocalidad] = useState<boolean>(false)
  const [openCuandoContrata, setOpenCuandoContrata] = useState<boolean>(false)

  const btnRef = useRef<HTMLButtonElement | null>(null);

  const {
    handleSubmit,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useForm<ICodigoPostalManual>();

  const methods = useForm<ICodigoPostalManual>();
  const { compania } = useParams();
  const fetchProvincias = async () => {
    setProvinciaDis(true);
    setProvinciaLoad(true);
    const response: any = await obtenerProvincias();
    setProvinciaDis(false);
    setProvinciaLoad(false);
    const formattedData = response.map((item: any) => ({
      value: item,
      label: item,
    }));
    setProvincias(formattedData)
  }

  const handleLocalidadChange = (e: any) => {
    setLocalidad(e)
    setOpenCuandoContrata(true)
    setOpenLocalidad(false)
  }
  const handleProvinciaChange = (e: any) => {
    setProvincia(e)
    setOpenLocalidad(true)
    setOpenCuandoContrata(false)
  }


  const fetchLocalidades = async () => {
    if (provincia !== null && provincia !== undefined) {

      setLocalidadLoad(true);
      setLocalidadDis(true);
      const response: any = await obtenerLocalidaXProvincia(provincia?.value);
      setLocalidadDis(false);
      setLocalidadLoad(false);
      const formattedData = response.map((item: any) => ({
        value: item,
        label: item,
      }));
      setLocalidades(formattedData);
      if (localidad) {
        if (
          !formattedData.some((loc: any) => {
            return loc.value === localidad?.value;
          })
        ) {
          setValue("localidad", "");
          setLocalidad({
            value: "",
            label: ""
          })
        } else {
          setLocalidad(cotizacionValues.localidad)
          setValue("localidad", localidad?.value);
        }
      }
      if (cotizacionValues?.localidad) {
        if (
          !formattedData.some((loc: any) => {
            return loc.value === cotizacionValues.localidad?.value;
          })
        ) {
          setValue("localidad", "");
          setLocalidad({
            value: "",
            label: ""
          })
        } else {
          setLocalidad(cotizacionValues?.localidad)
          setValue("localidad", cotizacionValues?.localidad?.value);
        }
      }

    }
  };

  useEffect(() => {
    fetchProvincias().then(() => {
      if (cotizacionValues?.provincia) {
        setProvincia(cotizacionValues?.provincia)
        setValue("provincia", cotizacionValues.provincia?.value);
        setOpenProvincia(false)
      } else {
        setOpenProvincia(true)
      }
    });
    if (cotizacionValues?.cuandoContrata) {
      setCuandoContrata(cotizacionValues?.cuandoContrata)
      setValue("cuandoContrata", cotizacionValues?.cuandoContrata.value)
    }

    if (cotizacionValues.provincia && cotizacionValues.localidad && cotizacionValues.cuandoContrata) {
      setOpenProvincia(false)
      setOpenLocalidad(false)
      setOpenCuandoContrata(false)
    }
  }, []);

  useEffect(() => {
    fetchLocalidades();
  }, [provincia]);

  const onSubmit = (data: ICodigoPostalManual) => {
    setErrorCaptcha("");
    const key = process.env.REACT_APP_RECAPTCHA_KEY
    grecaptcha.ready(function () {
      grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
        setCotizacionValues({
          ...cotizacionValues,
          localidad: localidad,
          provincia: provincia,
          captchaToken: token,
        });
        compania
          ? nav("/resultado-del-cotizador-de-art" + compania)
          : nav("/resultado-del-cotizador-de-art");
      })
    });
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');

  useEffect(() => {
    !cotizacionValues.telefono && (compania ? nav("/art/cotizacion/" + compania + "/telefono") : nav("/art/cotizacion/telefono"))
    if (error && error !== "El Captcha no es válido") {
      setError("provincia", {
        type: 'custom',
        message: error,
      });
    } else if (error === "El Captcha no es válido") {
      setErrorCaptcha(error);
    } else {
      setValue("provincia", cotizacionValues.provincia?.value ? cotizacionValues.provincia?.value : "");
      cotizacionValues.provincia?.value && trigger()
    }
  }, [])

  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01">
        <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
          <div className="cod-postal-column justify-content-center gap-4">
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <legend className="mb-1">Seleccioná tu provincia</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={provincia}
                    setSelected={handleProvinciaChange}
                    name="provincia"
                    options={provincias}
                    width={"100%"}
                    disabled={provinciaDis}
                    loading={provinciaLoad}
                    borderColor={errors.provincia ? "red" : ""}
                    openDefault={openProvincia}
                  />
                </FormProvider>
                <p>{errors.provincia?.message}</p>
              </fieldset>
            </div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <legend className="mb-1">Seleccioná tu localidad</legend>
                <FormProvider {...methods}>
                  <SegSelect
                    selected={localidad}
                    setSelected={handleLocalidadChange}
                    name="localidad"
                    options={localidades}
                    width={"100%"}
                    disabled={localidadDis}
                    loading={localidadLoad}
                    borderColor={errors.localidad ? "red" : ""}
                    openDefault={openLocalidad}
                  />
                </FormProvider>
                <p>{errors.localidad?.message}</p>
              </fieldset>
            </div>
          </div>
          <div className="form-buttons text-center">
            {errorCaptcha &&
              <div style={{ margin: "0 auto 20px auto" }} className="alert alert-danger w-25" role="alert">
                {errorCaptcha}
              </div>
            }
            <SegButton
              label="Continuar"
              type="submit"
              disabled={localidad?.value && provincia?.value ? false : true}
              ref={btnRef}
              className="mb-4"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
