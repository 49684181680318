import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';

export const RCMedioambiental = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Responsabilidad Civil Medioambiental'
                    url='#formulario'
                    icon='#icon-seg-rc-medioambiental'
                    subTitulo={{ __html: "Protegé a tu empresa contratando un seguro de responsabilidad medioambiental a medida de tu negocio.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Esta cobertura está diseñada para proteger a las empresas y organizaciones contra los riesgos y costos asociados con los daños al medio ambiente. <b>Este seguro tiene como objetivo garantizar la disponibilidad de fondos necesarios para recomponer el daño ambiental</b> de incidencia colectiva, causado en forma accidental, independientemente de que dicho daño se manifieste. La contratación del riesgo es obligatoria para aquellas personas físicas o jurídicas, públicas o privadas, que realicen actividades riesgosas para el ambiente, los ecosistemas y sus elementos constitutivos.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños al medio ambiente:</b> costos asociados con la limpieza, restauración y rehabilitación del medio ambiente afectado por contaminación o daños.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> protege a la empresa u organización asegurada en caso de reclamaciones o demandas presentadas por terceros que sufren daños o perjuicios debido a la contaminación o los daños ambientales causados por la actividad de la asegurada.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Gastos de defensa legal:</b> El seguro de responsabilidad medioambiental puede cubrir los gastos legales, como honorarios de abogados y costos judiciales, relacionados con la defensa de reclamaciones o demandas de responsabilidad medioambiental.</li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Daños a terceros:</b> Cubre los daños causados a terceros, como clientes, proveedores o la comunidad local, como resultado de incidentes relacionados con el medio ambiente.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='RC'
                    titulo='Cotizá seguro de Responsabilidad Civil Medioambiental'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
