import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';

export const Consorcio = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro para Consorcio'
                    url='#formulario'
                    icon='#icon-seg-consorcio'
                    subTitulo={{ __html: "Asegurá tu consorcio y protegé tu edificio antes cualquier eventualidad.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                El seguro integral de consorcio es un tipo de póliza de seguro diseñada para brindar cobertura a los consorcios de edificios o conjuntos residenciales.
                                Por ello, ponemos a tu disposición todo el asesoramiento profesional y experiencia en el riesgo.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> protege al consorcio y a los propietarios individuales en caso de que sean considerados legalmente responsables de causar daños o lesiones a terceros. Esto puede incluir daños a la propiedad de terceros, lesiones personales, gastos legales y costos de defensa.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños a la propiedad común:</b> cubre los daños causados a las áreas comunes del edificio, como pasillos, escaleras, áreas recreativas y sistemas de servicios comunes (electricidad, agua, gas, etc.), debido a eventos como incendios, inundaciones, explosiones u otros riesgos cubiertos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños a las unidades individuales:</b> cubre los daños causados a las unidades de los propietarios, como los interiores, electrodomésticos, muebles y pertenencias personales, debido a eventos cubiertos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Robo y vandalismo:</b> brinda protección ante robos y actos de vandalismo en las áreas comunes y en unidades individuales.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Responsabilidad civil del administrador:</b> proporciona cobertura al administrador del consorcio en caso de que sea demandado por errores, omisiones o negligencia en el desempeño de sus funciones.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Piletas de natación, ascensores, entre otros.</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='CONSORCIO'
                    titulo='Cotizá seguro para Consorcio'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
