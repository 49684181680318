import { Dispatch, SetStateAction, useEffect } from "react";
import { SegPatente } from "../../../SegUI/SegPatente/SegPatente";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { persist } from 'zustand/middleware';
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

interface ResultadoPatenteProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const ResultadoPatente = ({ config, setSteps, ...rest }: ResultadoPatenteProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const handleSubmit = () => {
    compania ? nav("/auto/cotizacion/" + compania + "/nombre") : nav("/auto/cotizacion/nombre");
    // nav("/auto/cotizacion/nombre")
  }
  const vehiculoAntiguo = cotizacionValues.esAntiguo
  const añoMaximo = new Date().getFullYear() - parseInt(process.env.REACT_APP_ANIO_GRACIAS!) + 1

  const patenteIncorrecta = (e: any) => {
    e.preventDefault();
    setCotizacionValues({
      ...cotizacionValues,
      marca: undefined,
      modelo: undefined,
      version: undefined,
      año: undefined,
      patente: undefined
    })
    compania ? nav("/auto/cotizacion/" + compania + "/marca") : nav("/auto/cotizacion/marca");
  };

  const IngresarNuevaPatente = (e: any) => {
    e.preventDefault();
    setCotizacionValues({
      ...cotizacionValues,
      marca: undefined,
      modelo: undefined,
      version: undefined,
      año: undefined,
      patente: undefined
    })
    compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente");
  };

  useEffect(() => {
    !cotizacionValues.patente && (compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente"))
  }, [])


  return (
    <div {...rest}>
      <div className="cot-heading02 text-center">
        <div className="container">
          <div className="title mt-3">¿Es este el auto?</div>
          <SegPatente text={cotizacionValues?.patente ?? ""} marginBottom="-37px" />
        </div>
      </div>
      <div className="cot-body bg-gray-light text-center">
        <div className="container">
          <div className="frm01">
            <fieldset>
              <div className="mw-sm-450 m-auto">
                <div className="fs-24 fw-medium">{cotizacionValues?.marca?.label}</div>
                <div className="fs-20">{cotizacionValues?.version?.label}</div>
              </div>

              <div className="form-buttons">
                <SegButton
                  label="Continuar"
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={vehiculoAntiguo}
                  bgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
                  hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
                  hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent: undefined}
                  hoverOpacity={config.origen !== "SEGURARSE"}
                />
              </div>
              <div className="form-alt">
                <a href="/#" onClick={patenteIncorrecta}>¿Nos equivocamos? </a>
              </div>
              {vehiculoAntiguo ? <>
                <div style={{ margin: "20px auto" }} className="alert alert-danger" role="alert">
                  <div className="mw-sm-450 m-auto">
                    <div className="fs-20 fw-medium"> Modelo anterior a {añoMaximo} </div>
                    <div className="fs-16">Lo sentimos, pero en este momento no tenemos coberturas disponibles para ofrecerte. ¡Gracias por tu comprensión!
                    </div>
                  </div>
                </div>
                <div className="form-alt">
                  <p>¿Querés cotizar otro auto? Haz click <span><a href="/auto/cotizacion/patente" onClick={IngresarNuevaPatente}>Aquí</a></span></p>
                  
                </div>
              </>
                : <></>}
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
