import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";

type FormValues = {
  setRazonSocial: string;
};

interface RazonSocial {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  razonSocial: string | undefined;
}

export const RazonSocial = ({ setSteps, razonSocial }: RazonSocial) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const { handleSubmit, control, setValue, formState, trigger } =
    useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setRazonSocial } = values;

    const RazonSocialArreglo = setRazonSocial.trim().split(/\s+/);

    setCotizacionValues({
      ...cotizacionValues,
      razonSocial: setRazonSocial,
      nombre: RazonSocialArreglo.length === 1 ? setRazonSocial : RazonSocialArreglo.slice(0, -1).join(' '),
      apellido: RazonSocialArreglo.length === 1 ? "-" : RazonSocialArreglo[RazonSocialArreglo.length - 1]
    });
    compania
      ? nav("/prepaga/corporativo/cotizacion/" + compania + "/empleados") : nav("/prepaga/corporativo/cotizacion/empleados");
  };



  useEffect(() => {
    !cotizacionValues.tipoDoc && (compania ? nav("/cotizador-de-seguros-prepaga/" + compania + "/documento") : nav("/cotizador-de-seguros-prepaga/documento"))
  }, [])

  useEffect(() => {
    setValue("setRazonSocial", razonSocial ? razonSocial : "");
    razonSocial && trigger();

  }, [razonSocial]);
  return (
    <div className="mt-4 bg-gray-light text-center">
      <div className="frm01">
        <legend>¿Cuál es la Razón Social?</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setRazonSocial"
              control={control}
              rules={{
                validate: {
                  required: (value) => !!value || "Ingresá la razón social",
                  // pattern: (value) =>
                  //   /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                  //   "Razon social inválida",
                },
              }}
              render={({ field, fieldState }) => (
                <SegInput
                  className="mt-3"
                  label=""
                  name="setNombre"
                  type="text"
                  width={100}
                  placeHolder="Ej: Pérez S.A."
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setRazonSocial", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setRazonSocial");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setRazonSocial
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
