import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegTooltip } from "../../../SegUI/SegTooltip/SegTooltip";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

interface IGenero {
  genero: string;
}

interface GeneroProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Genero = ({ config, setSteps, ...rest }: GeneroProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [genero, setGenero] = useState<SegSelectOption>();

  const generos = [
    { value: "F", label: "FEMENINO" },
    { value: "M", label: "MASCULINO" },
  ];

  const { handleSubmit, formState: { errors } } = useForm<IGenero>()
  const methods = useForm<IGenero>();

  useEffect(() => {
    if (cotizacionValues.genero) {
      setGenero(cotizacionValues.genero)
    }
  }, []);

  const onSubmit = (values: any) => {
    setCotizacionValues({
      ...cotizacionValues,
      genero: values
    });
    compania ? nav("/auto/cotizacion/" + compania + "/codigo-postal") : nav("/auto/cotizacion/codigo-postal");
    // nav("/auto/cotizacion/codigo-postal");
  };

  useEffect(() => {
    !cotizacionValues.fechaNac && (compania ? nav("/auto/cotizacion/" + compania + "/fecha-nacimiento") : nav("/auto/cotizacion/fecha-nacimiento"))
  }, [])

  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01 ps-3 pe-3">
        <legend className="text-center">
          {"¿Tu género? "}
          <SegTooltip text="Las compañías aseguradoras lo solicitan." placement="bottom-end" />
        </legend>
        <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
          <div className="cod-postal-column justify-content-center gap-4">
            <div className="col-10 col-sm-10 col-md-5 col-lg-3">
              <fieldset className="">
                <FormProvider {...methods}>
                  <SegSelect
                    selected={genero}
                    setSelected={setGenero}
                    name="genero"
                    options={generos}
                    width={"100%"}
                    borderColor={errors.genero ? "red" : ""}
                    handleSubmit={onSubmit}
                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                    empresaColor={config.origen !== "SEGURARSE" ? {
                      selected: config.colors.accent,
                      hover: config.colors.tertiary
                    } : undefined}
                  />
                </FormProvider>
              </fieldset>
            </div>
          </div>
        </form>
      </div>
    </div >
  );
};
