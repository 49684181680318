import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';

export const MalaPraxis = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Mala Praxis'
                    url='#formulario'
                    icon='#icon-seg-mala-praxis'
                    subTitulo={{ __html: "Protegé tu patrimonio ante reclamos de terceros que puedan desarrollarse durante el desempeño de tu actividad profesional.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Brinda cobertura ante un acto, omisión o inexactitud culposa por imprudencia, impericia o negligencia no intencional en el ejercicio de su profesión, para la cual deberá estar legal y administrativamente habilitado.<br />
                                Puede contratar este seguro todo profesional de la salud, en sus distintas especialidades. Odontólogos, nutricionistas, psicólogos, fonoaudiólogos, enfermeros, kinesiólogos, entre otros.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil profesional:</b> cubre los daños y perjuicios reclamados por terceros, como clientes o pacientes, debido a errores, omisiones o negligencia en la prestación de servicios profesionales.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Gastos legales y defensa:</b> cobertura sobre los gastos legales y los honorarios de abogados asociados con la defensa del asegurado en caso de una reclamación o demanda por mala praxis.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Indemnizaciones y acuerdos:</b> si se determina que el asegurado es responsable de un acto de mala praxis, el seguro puede cubrir las indemnizaciones y acuerdos acordados con el demandante como resultado de la reclamación. Esto incluye el pago de daños y perjuicios, gastos médicos, pérdida de ingresos y otros costos relacionados.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Reclamaciones anteriores y posteriores:</b> este seguro puede cubrir tanto reclamaciones presentadas durante la vigencia de la póliza como reclamaciones que se presenten después de la cancelación o finalización del seguro, pero que estén relacionadas con actos cometidos durante el período cubierto.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='MALAPRAXIS'
                    titulo='Cotizá seguro de Mala Praxis'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
