import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";
import { SegCardPrice } from "../../../SegUI/SegCardPrice/SegCardPrice";
import { Cotizacion } from "../../../../Pages/Auto/Precios";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import styled from "@emotion/styled";
import { SegSinCotis } from "../../../SegUI/SegSinCotis/SegSinCotis";
import { useConfiguracionEmpresa } from "../../../../Services/Providers/useConfiguracionEmpresa";
import { config } from "process";


interface SliderProps {
    cotizaciones: Cotizacion[]
    setDescripciones: (descripciones: any) => void;
    cotizando: boolean;
}

export const Slider = ({ cotizaciones, setDescripciones, cotizando, ...rest }: SliderProps) => {

    const CardDesk = styled.div`
    margin: 8px 12px 8px 8px;
    padding: 5px 15px 12px;
    width: 200px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    background: #FFFEFC;
    box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
    border-radius: 5px;
    color: #363636;
    display: none;
    @media (min-width: 992px) {
      display:block;
    }
  `

    const [opcionFiltrado, setOpcionFiltrado] = useState<string>("");
    const [cotizacionesAjustadas, setCotizacionesAjustadas] = useState<Cotizacion[]>([])
    const { config, origen } = useConfiguracionEmpresa();

    const handleDescripcion = (event: React.MouseEvent, coti: Cotizacion) => {
        event.preventDefault();
        if (coti) {
            const nuevaDesc: any = {
                id: coti.id,
                amountMonth: coti.premio,
                companyLogo: coti.LogoCompania,
                companyName: coti.NombreCompania,
                descList: coti.descripciones,
                discountAmount: coti.PrecioSinDescuento,
                discountPerc: coti.DescuentoPorcentaje,
                fiscalInfoImg: coti.Afip,
                nombreCompania: coti.NombreCompania,
                planName: coti.nombreCobertura,
                sumaAsegurada: coti.SumaAsegurada
            }
            setDescripciones(nuevaDesc);
        }
    }



    useEffect(() => {
        const ordenPrioridad: Record<string, number> = { C: 1, B: 2, D: 3, A: 4 };

        if (cotizaciones) {
            const ordenado = cotizaciones.sort((a, b) => {
                const prioridadA = ordenPrioridad[a.columna];
                const prioridadB = ordenPrioridad[b.columna];

                return prioridadA - prioridadB;
            })

            /*const ordenadoYMapeado = ordenado.map(objeto => {
                // Copiar el objeto original para no modificar el array existente
                const nuevoObjeto = objeto;
                // Cambiar propiedadAFiltrar a false si no cumple con la 
                if (opcionFiltrado === "") {
                    nuevoObjeto.Mostrar = true;
                } else if (nuevoObjeto.columna !== opcionFiltrado) {
                    nuevoObjeto.Mostrar = false;
                } else {
                    nuevoObjeto.Mostrar = true;
                }
                return nuevoObjeto;
            })*/

            const ordenadoYMapeado:Cotizacion[] = [];
            
            ordenado.forEach( cot => {
                if( opcionFiltrado === "" || cot.columna === opcionFiltrado){
                    ordenadoYMapeado.push(cot);
                }
            })

            setCotizacionesAjustadas(ordenadoYMapeado)
        } else {
            setCotizacionesAjustadas(cotizaciones)
        }
    }, [opcionFiltrado, cotizaciones])

    useEffect(() => {
        if (origen !== "SEGURARSE") {
            setTimeout(() => {
                const arrows = document.querySelectorAll('.splide__arrow');
                arrows.forEach((arrow) => {
                    (arrow as HTMLElement).style.backgroundColor = config.colors.primary;  
                    (arrow as HTMLElement).style.border = 'none';
                });
            }, 500)
        }
    }, [])
    return (
        <>
            <div className="cob d-none d-lg-block">
                <ul className="nav nav-tabs gap-2" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-5" onClick={(e) => setOpcionFiltrado("A")} data-bs-toggle="tab" data-bs-target="#tab-5-pane" type="button" role="tab" aria-controls="tab-5-pane" aria-selected="true">Responsabilidad Civil</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-6" onClick={(e) => setOpcionFiltrado("B")} data-bs-toggle="tab" data-bs-target="#tab-6-pane" type="button" role="tab" aria-controls="tab-6-pane" aria-selected="false">Terceros Completo</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-7" onClick={(e) => setOpcionFiltrado("C")} data-bs-toggle="tab" data-bs-target="#tab-7-pane" type="button" role="tab" aria-controls="tab-7-pane" aria-selected="false">Terceros Completo Full</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-8" onClick={(e) => setOpcionFiltrado("D")} data-bs-toggle="tab" data-bs-target="#tab-8-pane" type="button" role="tab" aria-controls="tab-8-pane" aria-selected="false">Todo Riesgo</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="tab-9" onClick={(e) => setOpcionFiltrado("")} data-bs-toggle="tab" data-bs-target="#tab-9-pane" type="button" role="tab" aria-controls="tab-9-pane" aria-selected="false">Todas las coberturas</button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show" id="tab-5-pane" role="tabpanel" aria-labelledby="tab-5" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio (No todas las compañías)</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-6-pane" role="tabpanel" aria-labelledby="tab-6" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-7-pane" role="tabpanel" aria-labelledby="tab-7" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cristales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cerraduras</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Granizo</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-8-pane" role="tabpanel" aria-labelledby="tab-8" tabIndex={0}>
                        <b>La cobertura incluye:</b>
                        <ul className="d-inline list-inline ms-2">
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Responsabilidad Civil</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cobertura en Mercosur</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Auxilio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Robo/Hurto</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Incendio</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Parciales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cristales</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Cerraduras</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Granizo</li>
                            <li className="list-inline-item"><svg className="bi tick-pri"><use xlinkHref="#tick" /></svg> Daños parciales por accidente</li>
                        </ul>
                    </div>
                    <div className="tab-pane fade" id="tab-9-pane" role="tabpanel" aria-labelledby="tab-9" tabIndex={0}>Todas las coberturas</div>
                </div>
            </div>
            <div className="cards1-wrapper d-none d-lg-flex">
                <div className="cards-header">
                    <SlideHeader
                        color={origen !== "SEGURARSE" ? config.colors.secondary : undefined}
                    />
                </div>
                {/*<SlideHeader />*/}
                <div className="cards-slider">
                    <Splide aria-label="grilla-cotizaciones" options={{
                        perMove: 1,
                        perPage: cotizacionesAjustadas &&
                            (cotizacionesAjustadas?.filter((c) => c.Mostrar).length < 5) ?
                            (cotizacionesAjustadas?.filter((c) => c.Mostrar).length === 0 ? 1
                                : (cotizando) ? cotizacionesAjustadas?.filter((c) => c.Mostrar).length + 1 : cotizacionesAjustadas?.filter((c) => c.Mostrar).length)
                            : 5,
                        gap: 1,
                        arrows: true,
                    }}>
                        {cotizacionesAjustadas && cotizacionesAjustadas.map((coti, index) =>
                            coti && coti.Mostrar && 
                            (<SplideSlide key={`${coti.NombreCompania}-${coti.nroCotizacion}-${index}`}>
                                <SegCardPrice
                                    rubro={"AUTO"}
                                    companyName={coti.NombreCompania}
                                    companyLogo={coti.LogoCompania}
                                    planName={coti.nombreCobertura}
                                    discountAmount={"$" + coti.PrecioSinDescuento}
                                    discountPerc={"-" + coti.DescuentoPorcentaje + "%"}
                                    amountMonth={"$" + coti.premio}
                                    descList={coti.DescResumen}
                                    className=""
                                    fiscalInfoImg={coti.Afip}
                                    onClick={(e) => handleDescripcion(e, coti)}
                                    configEmpresa={origen !== "SEGURARSE" ? config : undefined}
                                />
                            </SplideSlide>)
                        )}

                        {
                            ["A", "B", "C", "D", ""].map(col => {
                                if (cotizando && opcionFiltrado === col) {
                                    return (
                                        <SplideSlide key={"skeleton-slider-" + col}>
                                            <CardDesk>
                                                <Skeleton
                                                    containerClassName="flex-1"
                                                    count={1}
                                                    circle={true}
                                                    style={{ borderRadius: "100%", width: "56px", height: "56px" }} />
                                                <Skeleton
                                                    containerClassName="flex-1"
                                                    count={8}
                                                    circle={false}
                                                    style={{ height: "18px", margin: "10px 0" }}
                                                />
                                            </CardDesk>
                                        </SplideSlide>
                                    )
                                } else if (!cotizando && opcionFiltrado === col && opcionFiltrado !== "" && cotizacionesAjustadas.filter((coti) => coti.columna === col).length === 0) {
                                    return <SegSinCotis mostrarWpp={origen === "SEGURARSE"} />
                                }
                            })
                        }
                        {
                            (!cotizando && opcionFiltrado === "" && cotizacionesAjustadas.length === 0) &&
                            <SegSinCotis mostrarWpp={origen === "SEGURARSE"}/>
                        }
                    </Splide>
                </div>
            </div>
        </>
    )
}


const SlideHeader = ({ color = "#2C7DFD" }: any) => {
    const CardDesk = styled.div`
    margin: 8px 12px 8px 8px;
    padding: 5px 15px 12px;
    width: 145px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    
    display: none;
    @media (min-width: 992px) {
      display:block;
    }
  `

    const ItemHeader = styled.div`
    border-top: 1px solid #D9D8D6;
    padding: 6px 0;
    font-size: 12px;
    font-weight: 700;
  `

    const Precio = styled.div`
    padding: 14px 0;
    border-top: 1px solid #D9D8D6;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content:center;
    align-items:center;
    gap: 2px;
  `
    const Empresa = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 6px 0 !important;
    font-size: 12px;
    font-weight: 700;
  `;

    const MoneyIcon = styled.div`
    height: 18px; 
    width: 18px;
    border: 1px solid #000;
    border-radius: 50%; 
    display: flex;
    justify-content:center;
    align-items:center;

    span{
        font-weight: bold;
        text-align: center;
    }
  `

    return (
        <div style={{ width: "145px" }}>
            <CardDesk>
                <Empresa>
                    <span>Empresa</span>
                </Empresa>

                <ItemHeader>cobertura</ItemHeader>

                <ItemHeader>Precio</ItemHeader>

                <Precio style={{ color: color }}>Precio Online
                    <MoneyIcon style={{ borderColor: color }}>
                        <span>$</span>
                    </MoneyIcon>
                </Precio>
                <ItemHeader style={{ padding: "8px 0" }}>Cristales</ItemHeader>
                <ItemHeader >Franquicia</ItemHeader>
                <ItemHeader style={{ fontSize: "11px", padding: "2px 0" }}>Cobertura en exterior</ItemHeader>
                <ItemHeader>Auxilios</ItemHeader>
                <ItemHeader >Granizo</ItemHeader>
            </CardDesk>
        </div>
    )
}