import { useEffect, useRef, useState } from "react";
import { SegButton } from "../SegUI/SegButton/SegButton";


const SegCookiesConsent = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [showConsent, setShowConsent] = useState(false);
    const [backdropClass, setBackdropClass] = useState("fade-in");
    const [modalAnimationClass, setModalAnimationClass] = useState("fade-in-top");


    const cerrarModal = () => {
        setBackdropClass("fade-out");
        setModalAnimationClass("fade-out-top");
        setTimeout(() => {
            setShowConsent(false);
        }, 500);
    };

    const handleConsent = () => {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 5);
        document.cookie = `cookies=yes; max-age=${5 * 365 * 24 * 60 * 60}`;
        setShowConsent(false);
    };


    useEffect(() => {
        const handleScrollLock = (showConsent: boolean) => {
            if (showConsent) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        };
        handleScrollLock(showConsent);

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showConsent]);

    useEffect(() => {
        const checkCookieConsent = () => {
            const cookieExists = document.cookie
                .split(";")
                .some((item) => item.trim().startsWith("cookies="));
            if (!cookieExists) {
                setShowConsent(true);
            } else {
                const cookieValue = document.cookie.replace(
                    /(?:(?:^|.*;\s*)cookies\s*=\s*([^;]*).*$)|^.*$/,
                    "$1"
                );
                if (cookieValue !== "yes") {
                    setShowConsent(true);
                }
            }
        };

        checkCookieConsent();
        return () => { };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                cerrarModal();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showConsent]);

    return (
        <>
            {showConsent && (
                <div className={`modal-backdrop show ${backdropClass}`}></div>
            )}
            {showConsent && (
                <div ref={modalRef}>
                    <div
                        className={`fixed-bottom cookie-consent ${modalAnimationClass}`}
                        id="cookieconsent"
                        tabIndex={-1}
                        aria-labelledby="modal"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        aria-label="Close"
                                        onClick={cerrarModal}
                                    ></button>
                                </div>
                                <div className="row align-items-center pb-lg-2 pt-2">
                                    <div className="col-lg-9">
                                        <div className="ff-mukta fs-16 fs-lg-30 fw-bold lh-sm text-primary">
                                            ¡Bienvenido a Segurarse.com.ar!
                                        </div>
                                        <p className="my-2 fs-13 fs-lg-14 lh-sm">
                                            En este sitio utilizamos cookies. Las cookies son
                                            necesarias para el correcto funcionamiento y la
                                            seguridad de la página, además nos ayudan a
                                            proporcionarte una mejor experiencia de navegación. Para
                                            más información consulta nuestra{" "}
                                            <a
                                                href={
                                                    "https://segurarse.com.ar/politicas-de-privacidad"
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                className="policy-button"
                                            >
                                                Políticas de Privacidad
                                            </a>
                                            . <br /> Segurarse.com.ar nunca te solicitará
                                            información confidencial, datos bancarios o de tarjetas
                                            de crédito ya sea por mail, teléfono, WhatsApp o redes
                                            sociales. Ante cualquier llamado o mensaje dudoso
                                            comunicate con tu asesor o a través de los canales
                                            indicados en la sección de Contacto.
                                        </p>
                                    </div>
                                    <div className="col-lg-3 py-3 py-lg-0">
                                        <SegButton
                                            className="fs-14 fs-lg-16 btn-primary btn-modal"
                                            label="Acepto"
                                            type="submit"
                                            onClick={() => {
                                                handleConsent();
                                            }}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default SegCookiesConsent
