import React, { useState } from 'react';
import styled from '@emotion/styled';
import { SegSelectOption } from '../SegSelect/SegSelect';

interface SegCardMarcaProps {
    onClick: (value: string) => void;
    img: string;
    alt: string;
    label: string;
    textColor?: string;
    borderColor?: string;
    backgroundColor?: string;
    selected?: SegSelectOption;
    selectedBackgroundColor?: string;
    className?: string;
}

export const SegCardMarca = ({
    onClick,
    img,
    alt,
    label,
    textColor = '#000',
    borderColor = '#000',
    backgroundColor = '#fff',
    selected,
    selectedBackgroundColor = '#2C7DFD',
    className,
}: SegCardMarcaProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const Card = styled.div`
    border: ${selected?.label.toLowerCase() === label.toLowerCase() ? "2px solid" : "1px solid" };
    border-radius: 5px;
    width: 152px;
    border-color: ${selected?.label.toLowerCase() === label.toLowerCase() ? selectedBackgroundColor : borderColor};
    cursor: ${isHovered ? 'pointer' : 'default'};
  `;

    const Img = styled.img`
    width: 100%;
  `;

    const Label = styled.span`
    font-size: 14px;
    margin-bottom: 10px;
    color: ${textColor};
  `;

    const onCardClick = () => {
        onClick(label.toUpperCase());
    };

    return (
        <Card
            onClick={onCardClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`d-flex flex-column justify-content-center align-items-center ${className}`}
        >
            <Img src={img} alt={alt} loading='lazy'/>
            <Label>{label}</Label>
        </Card>
    );
};
