import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import Asesoramiento from '../Components/Asesoramiento'
import { PreguntasFrecuentes } from '../Components/PreguntasFrecuentes';
import FormularioIndividuo from '../Components/FormularioIndividuo';

export const BolsoProtegido = () => {

    const preguntasFrecuentes = [
        {
            titulo: '¿Qué cubre un seguro de bolso protegido?',
            subTitulo: 'Un seguro de bolso protegido generalmente cubre el robo o hurto del bolso y su contenido (como dispositivos electrónicos, documentos personales y dinero en efectivo), la reposición de documentos personales (DNI, pasaporte, tarjetas de crédito), el reemplazo de llaves y cerraduras, entre otros. La cobertura específica depende de la póliza contratada.'
        },
        {
            titulo: '¿Puedo asegurar cualquier tipo de bolso?',
            subTitulo: 'Sí, generalmente se puede asegurar cualquier tipo de bolso que uses diariamente, como carteras, mochilas, bolsos de mano, o bolsos para computadora. Sin embargo, la cobertura se centra en los objetos contenidos dentro del bolso y no en el bolso en sí. '
        },
        {
            titulo: '¿Puedo contratar un seguro de bolso protegido en cualquier momento?',
            subTitulo: 'Sí, podés contratar un seguro de bolso protegido en cualquier momento, aunque algunas aseguradoras pueden requerir un período de espera antes de que la cobertura entre en vigencia, especialmente para ciertas coberturas específicas como el hurto o la pérdida.'
        },
        {
            titulo: '¿Cubre el seguro de bolso protegido los daños accidentales a los objetos dentro del bolso?',
            subTitulo: 'Generalmente, el seguro de bolso protegido no cubre daños accidentales a los objetos dentro del bolso, como caídas o golpes. Sin embargo, algunas pólizas pueden ofrecer esta cobertura adicional a través de cláusulas específicas o mediante una prima adicional. Es importante revisar los términos y condiciones de la póliza para saber qué está cubierto.'
        },
    ];

    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro de Bolso Protegido'
                    subTitulo={{ __html: 'Conocemos la importancia de tu hogar para vos y tu familia, entendemos los riesgos a los que se expone tu vivienda, por eso, te acercamos los mejores planes para proteger tu inversión.<br/><br/>¿Empezamos?' }}

                    url='#formulario'
                    icon='#icon-seg-bolso'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué coberturas ofrecemos?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Este seguro está diseñado para brindar cobertura en caso de robo, hurto, o pérdida de un bolso, mochila o cartera y sus contenidos. Es especialmente útil para proteger objetos de valor personal que llevas contigo diariamente, como documentos, dinero en efectivo, dispositivos electrónicos, y otros artículos. Las coberturas más comunes que suelen incluirse son:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Robo del bolso y su contenido</b>: cubre el robo del bolso y los artículos que se encuentran en su interior, como dispositivos electrónicos (celulares, notebooks, tablets), documentos personales (DNI, pasaporte, tarjetas de crédito o débito), dinero en efectivo, y otros objetos de valor. La cobertura suele estar sujeta a una suma asegurada, que varía según la póliza.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Reposición de documentos personales</b>: reembolsa los costos asociados con la reposición de documentos personales robados o perdidos, como el DNI, pasaporte, licencia de conducir, tarjetas de crédito o débito, y otros documentos importantes. Este beneficio puede incluir el pago de tasas administrativas y otros gastos relacionados con la tramitación.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Reposición de llaves</b>: cubre los costos de reemplazo de llaves (del hogar, del vehículo, etc.) que se encontraban dentro del bolso robado o perdido. Algunas pólizas también incluyen la cobertura de cambio de cerraduras, especialmente si las llaves robadas pertenecen a tu domicilio o automóvil.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Robo o daño de dispositivos electrónicos</b>: Ofrece cobertura específica para dispositivos electrónicos como celulares, tablets, laptops, cámaras, y otros equipos electrónicos que llevabas en el bolso al momento del robo o hurto. La cobertura puede incluir reparación o reemplazo del dispositivo, hasta el límite máximo establecido en la póliza.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-md-8 col-xl-6 mx-auto text-center mt-3">
                            <p className="fs-18 fs-lg-20 lh-13 text-black mb-20 text-center text-white">
                                Si tenés dudas, contas con nuestro equipo de especialistas para asesorarte en todo momento.
                            </p>
                            <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                                <span className="fs-18 fs-lg-20 text-black fw-bold text-white">Chatéanos</span>
                                <svg className="svg-icon ms-2 w-12em">
                                    <use xlinkHref="#whatsapp" />
                                </svg>
                            </a>
                        </div>
                </div>
                <FormularioIndividuo
                    titulo='Cotizá seguro de Bolso Protegido'
                    rubro='BOLSO'
                />
                <PreguntasFrecuentes
                    faqs={preguntasFrecuentes}
                />
                <Asesoramiento />
            </div>
        </>
    )
}
