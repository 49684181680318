import guardarContacto from "./GuardarContacto"
import guardarContactoArt from "./GuardarContactoArt"
import guardarContactoCaucion from "./GuardarContactoCaucion"
import guardarContactoPrepaga from "./GuardarContactoPrepaga"
import guardarContactoCelular from "./GuardarContactoCelular"
import guardarContactoIntegralComercio from "./GuardarContactoIntegralComercio"
import guardarContactoGenerico from "./GuardarContactoGenerico"


export const guardarContactoIndex = (rubro: string, data: any) => {
    switch (rubro) {
        case "AUTO":
            return guardarContacto(data)
        case "ART":
            return guardarContactoArt(data)
        case "CAUCION":
            return guardarContactoCaucion(data)
        case "CELULAR":
            return guardarContactoCelular(data)
        case "INTEGRAL-DE-COMERCIO":
            return guardarContactoIntegralComercio(data)
        default:
            return guardarContactoGenerico(data) 
    }
}