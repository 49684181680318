import React from "react";
import styled from "@emotion/styled";

interface SegPatenteProps {
  text: string;
  className?: string;
  marginBottom?: string;
}

export const SegPatente = ({
  text = "AA876ABC",
  className,
  marginBottom,
}: SegPatenteProps) => {
  const StyledDiv = styled.div`
    position: relative;
    display: flex;
    margin-bottom: ${marginBottom};
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 17px;
      width: 100%;
      height: 1px;
      background: #1e1e1e;
      z-index: -1;
    }
  `;

  const StyledSpan = styled.span`
    display: inline-block;
    padding: 4px 10px;
    background: #000;
    border-radius: 3px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0 auto;
  `;

  const StyledContainer = styled.div`
    line-height: 1.25;
    width: 100%;
    display: contents;
    @media (min-width: 1400px) {
      max-width: 1320px;
    }

    @media (min-width: 1200px) {
      max-width: 1144px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 576px) {
      max-width: 540px;
    }
  `;

  return (
    <StyledContainer>
      <StyledDiv>
        <StyledSpan className={className}>{text}</StyledSpan>
      </StyledDiv>
    </StyledContainer>
  );
};
