import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { EmpresaProps } from '../../../Services/Providers/useConfiguracionEmpresa';


export interface SegCardPriceProps {
  rubro: string;
  companyLogo: string,
  companyName: string,
  planName: string,
  discountAmount: string,
  discountPerc: string,
  amountMonth: string,
  descList: string[]
  className?: string
  fiscalInfoImg: string
  amountInsured?:string
  onClick: (e: React.MouseEvent) => void;
  configEmpresa?: EmpresaProps; 
}

export const SegCardPrice = ({
  companyLogo = "",
  companyName = "",
  planName = "Terceros Completos",
  discountAmount = "$4127",
  discountPerc = "-40%",
  amountMonth = "$22.476",
  descList = [],
  className,
  configEmpresa,
  onClick
}: SegCardPriceProps) => {

  const CardDesk = styled.div`
    margin: 8px 12px 8px 8px;
    padding: 5px 15px 12px;
    width: 200px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    background: #FFFEFC;
    box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
    border-radius: 5px;
    color: #363636;
    display: none;
    @media (min-width: 992px) {
      display:block;
    }
  `

  const Empresa = styled.div`
    padding: 8px 0 ;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const EmpresaImg = styled.img`
    max-width: 85%;
  `

  const Cobertura = styled.div`
    border-top: 1px dotted #D9D8D6;
    padding: 6px 0 !important;
    font-size: 12px;
    font-weight: 700;
  `

  const Antes = styled.span`
      color: var(--se-gray-dark);
      padding: 0 5px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        height: 2px;
        background: #B8C2C9;
      }
  `

  const Descuento = styled.div`
    padding: 9px 0;
    border-top: 1px dotted #D9D8D6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
  `

  const Monto = styled.span`
    font-size: 26px;
    color: #000;
    font-weight: 700;
    line-height: 1;
  `

  const Periodo = styled.span`
    font-size: 15px;
    color: var(--se-gray-dark);
    font-weight: 400;
  `

  const Precio = styled.div`
    padding: 9px 0;
    border-top: 1px dotted #D9D8D6;
  `

  const Item = styled.div`
    border-top: 1px dotted #D9D8D6;
    padding: 7px 15px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
  const StyledLink = styled(Link)`
    padding: 5px 26px;
    color: #fff;
    background-color: var(--bs-primary);
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 0;
    border-radius: 5px;
  `

  const StyledSvg = styled.svg`
    display: inline-block;
    fill: currentcolor;
    width: 10px;
    height: 10px;
    margin: 0 0 2px 12px;
  `

  const CardMob = styled.div`
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #363636;
    background: #fff;
    box-shadow: 0px 0px 4px #DCE0E2;
    border-radius: 5px;
    display: block;
    @media (min-width: 992px) {
      display:none;
    }
  `

  const Header = styled.div`
    background: #D9D9D9;
    border-radius: 5px 5px 0 0;
    font-size: 16px;
    color: #000;
    line-height: 26px;
  `

  const AntesMob = styled.span`
    color: var(--se-gray-dark);
    padding: 0 5px;
    position: relative;
    font-size: 15px;
    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      right: 0;
      height: 2px;
      background: #a5a5a5;
    }
  `

  const DescuentoMob = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
  `

  const MontoMob = styled.span`
    font-size: 30px;
    color: #000;
  `

  const PeriodoMob = styled.span`
    font-size: 15px;
    color: var(--se-gray-dark);
    font-weight: 400;
  `


  const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 18px 0;
  `

  const Rating = styled.div`
    font-size: 12px;
    color: var(--bs-primary);
    &:before {
      content: "";
      display: block;
      width: 90%;
      margin: 0 auto 5px;
      border: 1px solid #ccc;
    }
  `

  const Cont = styled.div`
    display: inline-block;
    max-width: 95%;
  `

  const PrecioMob = styled.div`
    padding-bottom: 10px;
    font-weight: 700;
    line-height: 1;
  `

  const StyledLinkMob = styled(Link)<any>`
    padding: 6px;
    width: 100%;
    color: #fff;
    background-color: var(--bs-primary);
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    border: 0;
    border-radius: 5px;
  `

  return (
    <div className={className}>
      <CardDesk>
        <Empresa>
          <img src={companyLogo}
            height="50"
            style={{ maxWidth: '150px', height: 'auto', maxHeight: '50px' }}
            alt={companyName}
            loading='lazy' />
        </Empresa>

        <Cobertura style={{color: configEmpresa ? configEmpresa.colors.secondary : ""}}>{planName}</Cobertura>

        <Descuento>
          <Antes>{discountAmount}</Antes>
          {/* <AntesPorc>{discountPerc}</AntesPorc> */}
        </Descuento>

        <Precio>
          <Monto>{amountMonth}</Monto>
          <Periodo> /mes</Periodo>
        </Precio>

        {descList.map((element, index) => (
          <Item key={index}>{element}</Item>
        ))}

        <StyledLink onClick={(e) => onClick(e)} data-bs-toggle="offcanvas" 
        to={"#offcanvas"} role="button" style={{background: configEmpresa ? configEmpresa.colors.primary : ""}}>Ver detalle
          <StyledSvg>
            <use xlinkHref="#arrowright2"></use>
            <symbol id="arrowright2" viewBox="0 0 10 10">
              <path d="M3.7124 8.29995L6.42907 5.58328C6.7499 5.26245 6.7499 4.73745 6.42907 4.41662L3.7124 1.69995" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            </symbol>
          </StyledSvg>
        </StyledLink>
      </CardDesk>
      <CardMob>
        <Header>{planName}</Header>
        <Body>
          <div className="col-6">
            <Empresa><EmpresaImg src={companyLogo} alt={companyName} /></Empresa>
            <Rating>Excelente 8.0</Rating>
          </div>

          <div className="col-6">
            <Cont>
              <DescuentoMob>
                <AntesMob>{discountAmount}</AntesMob>
                {/* <AntesPorcMob>{discountPerc}</AntesPorcMob> */}
              </DescuentoMob>
              <PrecioMob>
                <MontoMob>{amountMonth}</MontoMob>
                <PeriodoMob> /mes</PeriodoMob>
              </PrecioMob>
              <StyledLinkMob
                onClick={(e:any) => onClick(e)}
                data-bs-toggle="offcanvas"
                to={"#offcanvas"}
                role="button"
                >
                Ver detalle
                <StyledSvg>
                  <use xlinkHref="#arrowright2"></use>
                  <symbol id="arrowright2" viewBox="0 0 10 10">
                    <path d="M3.7124 8.29995L6.42907 5.58328C6.7499 5.26245 6.7499 4.73745 6.42907 4.41662L3.7124 1.69995" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                  </symbol>
                </StyledSvg>
              </StyledLinkMob>
            </Cont>
          </div>
        </Body>
      </CardMob>
    </div>
  )
}