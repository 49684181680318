import sancor from "../Assets/img/logos/cias/logo_sancor_b.png"
import meridional from "../Assets/img/logos/cias/logo_meridional_b.png"
import cruzSuiza from "../Assets/img/logos/cias/logo_cruzsuiza_b.png"
import allianz from "../Assets/img/logos/cias/logo_allianz_b.png"
import mapfre from "../Assets/img/logos/cias/logo_mapfre_b.png"
import liberty from "../Assets/img/logos/cias/logo_liberty_b.png"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "sancor",
        imgRuta: sancor,
        mostrar: true
    },
    {
        nombreCompania: "meridional",
        imgRuta: meridional,
        mostrar: true
    },
    {
        nombreCompania: "cruz suiza",
        imgRuta: cruzSuiza,
        mostrar: true
    },
    {
        nombreCompania: "allianz",
        imgRuta: allianz,
        mostrar: true
    },
    {
        nombreCompania: "mapfre",
        imgRuta: mapfre,
        mostrar: true
    },
    {
        nombreCompania: "liberty",
        imgRuta: liberty,
        mostrar: true
    },   
]