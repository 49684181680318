import { SegSvg } from "../../Components/SegUI/SegSvg/SegSvg";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import logoblue from "../../Assets/img/logo_segurarse.svg";
import { SegButton } from "../../Components/SegUI/SegButton/SegButton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const COTIZADORES = [
  {
    titulo: "Auto",
    link: "/auto/cotizacion/patente"
  },
  {
    titulo: "ART",
    link: "/cotizador-de-seguros-art-v2/cuit-y-razon-social"
  },
  {
    titulo: "Caucion",
    link: "/cotizador-de-seguros-caucion-v2/documento"
  },
  {
    titulo: "Prepaga",
    link: "/cotizador-de-seguros-prepaga/documento"
  },
  {
    titulo: "Celular",
    link: "/v2/celular/cotizacion"
  },
  {
    titulo: "Integral Comercio",
    link: "/v2/integral-comercio/cotizacion"
  }
]

const LANDINGS = [
  {
    titulo: "Home",
    link: "/",
  },
  {
    titulo: "Contacto",
    link: "/contacto",
  },
  {
    titulo: "Sustentabilidad",
    link: "/sustentabilidad",
  },
  {
    titulo: "Quienes Somos",
    link: "/quienes_somos"
  },
  {
    titulo: "#One Team",
    link: "/nuestro-oneteam"
  },
  {
    titulo: "Politicas de privacidad",
    link: "/politicas-privacidad"
  },
  {
    titulo: "Prepaga",
    link: "/seguros-individuos-prepaga",
  },
  {
    titulo: "Individuos",
    link: "/seguros-individuos",
  },
  {
    titulo: "Auto",
    link: "/seguros-individuos-auto",
  },
  {
    titulo: "Embarcaciones",
    link: "/seguros-individuos-embarcacion",
  },
  {
    titulo: "Moto",
    link: "/seguros-individuos-moto",
  },
  {
    titulo: "Hogar",
    link: "/seguros-individuos-hogar",
  },
  {
    titulo: "Asistencia al viajero",
    link: "/seguros-individuos-asistencia",
  },
  {
    titulo: "Celular",
    link: "/seguros-individuos-celular",
  },
  {
    titulo: "Bolso Protegido",
    link: "/seguros-individuos-bolso",
  },
  {
    titulo: "AP",
    link: "/seguros-individuos-AP"
  },
  {
    titulo: "Pymes",
    link: "/seguros-corporativos",
  },
  {
    titulo: "Pymes Caucion",
    link: "/seguros-corporativos-caucion",
  },
  {
    titulo: "Pymes ART",
    link: "/seguros-corporativos-ART",
  },
  {
    titulo: "Integral de comercio",
    link: "/seguros-corporativos-integral-comercio"
  },
  {
    titulo: "Responsabilidad Civil",
    link: "/seguros-corporativos-RC"
  },
  {
    titulo: "Flota de vehiculos",
    link: "/seguros-corporativos-flota-vehiculos"
  },
  {
    titulo: "Incendio corporativo",
    link: "/seguros-corporativos-incendio"
  },
  {
    titulo: "Mala praxis",
    link: "/seguros-corporativos-mala-praxis"
  },
  {
    titulo: "RC Medioambiental",
    link: "/seguros-corporativos-RC-medioambiental"
  },
  {
    titulo: "Todo riesgo corporativo",
    link: "/seguros-corporativos-todo-riesgo"
  },
  {
    titulo: "Construcción",
    link: "/seguros-corporativos-construccion"
  },
  {
    titulo: "Transporte",
    link: "/seguros-corporativos-transporte"
  },
  {
    titulo: "Consorcio",
    link: "/seguros-corporativos-consorcio"
  },
  {
    titulo: "Credito",
    link: "/seguros-corporativos-credito"
  },
  {
    titulo: "Agro",
    link: "/seguros-corporativos-agro"
  },
  {
    titulo: "Vida corporativa",
    link: "/seguros-corporativos-vida"
  },
  {
    titulo: "Preguntas Frecuentes",
    link: "/preguntas-frecuentes"
  }
]


export const Institucional = () => {
  const nav = useNavigate();

  const handleClick = (path: string) => {
    //nav("/auto/cotizacion/patente");
    nav(path);
  };

  useEffect(() => {
    document.title = "Segurarse | Seguros online"
  }, [])

  return (
    <section>
      <div className="w-100 bg-primary">
        <SegSvg />
        <div className="bg-white">
          <div
            className="container d-flex align-items-center justify-content-center"
            style={{ height: "7vh" }}
          >
            <div className="row">
              <div className="">
                {" "}
                {/* Centra el contenido en la columna */}
                <SegHeaderLogo
                  img={logoblue}
                  alt="Logo Segurarse"
                  width="160px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-75 pt-5 m-auto" style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
          <div className="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
            <h1 className="fs-24 text-white">Institucional</h1>
              <div className="my-2">
                <SegButton
                  onClick={() => handleClick("/cotizador-de-seguros-auto")}
                  label="Comenzar cotizacion auto"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/cotizador-de-seguros-art")
                  }
                  label="Comenzar cotizacion ART"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/cotizador-de-seguros-pymes-caucion")
                  }
                  label="Comenzar cotizacion Caucion"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/cotizador-de-seguros-salud")
                  }
                  label="Comenzar cotizacion Prepaga"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/cotizador-de-seguros-salud-corporativo")
                  }
                  label="Comenzar cotizacion Prepaga Corporativa"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/celular/cotizacion")
                  }
                  label="Comenzar cotizacion celular"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/integral-comercio/cotizacion")
                  }
                  label="Comenzar cotizacion Integral comercio"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
              </div>
              <div className="my-2">
                <SegButton
                  onClick={() =>
                    handleClick("/cotizador-de-accidentes-personales")
                  }
                  label="Comenzar cotizacion Accidentes Personales"
                  type="button"
                  disabled={false}
                  bgColor="white"
                  textColor="#2C7DFD"
                  hoverBgColor="#e8f1ff"
                  hoverTextColor={"#0261f7"}
                />
            </div>
          </div>
          <div className="w-100 mt-5">
            <h1 className="fs-32 text-white">Landings</h1>
            <ul style={{ display: "grid", gridTemplateColumns: "50% 50%", width: "100%", color: "white" }}>
              {
                LANDINGS.map(landing => (
                  <li key={landing.titulo}>
                    <a className="fs-18" style={{ color: "white" }} href={landing.link}>{"Landing " + landing.titulo}</a>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
