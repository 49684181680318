import Axios from 'axios';
import { getPathName } from './helper';

export const obtenerCondicionesIVA = async () => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        provincia: ""
    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getcondicionivajson", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data.api_data.map( function(cond:any) {
                return {
                    value: cond.codigo,
                    label: cond.descripcion
                }
            })
        }).catch(function (error) {
            return error;
        });
}