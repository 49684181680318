import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegP } from "../../../SegUI/SegP/SegP";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegLink } from "../../../SegUI/SegLink/SegLink";
import { SegSlider } from "../../../SegUI/SegSlider/SegSlider";
import { imagesCompanias, imagenesCompaniasFiltradas } from "../../../../Assets/imageCompania";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import obtenerPatente from "../../../../Services/API/Patente";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  TelefonosCompañia,
  telefonos,
} from "../../../../Assets/telefonosCompanias";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

type FormValues = {
  setPatente: string;
};

interface InicioNormalProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>;
}

export const InicioNormal = ({ config, setSteps, ...rest }: InicioNormalProps) => {
  const { compania } = useParams();
  const nav = useNavigate();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState,
    trigger,
    setError,
  } = useForm<FormValues>();

  const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setPatente } = values;
    setLoading(true);
    const key = process.env.REACT_APP_RECAPTCHA_KEY;
    grecaptcha.ready(function () {
      grecaptcha.execute(key!, { action: "datacar" }).then(function (token) {
        const fetchData = async () => {
          const response: any = await obtenerPatente(values.setPatente, token);
          if (response != null) {
            setCotizacionValues({
              ...cotizacionValues,
              marca: { label: response.marca, value: response.marca },
              modelo: { label: response.modelo, value: response.modelo },
              version: { label: response.version, value: response.version },
              año: { label: response.anio, value: response.anio },
              esAntiguo: response.esAntiguo,
              patente: setPatente,
            });
            compania
              ? nav("/auto/cotizacion/" + compania + "/resultado-patente")
              : nav("/auto/cotizacion/resultado-patente");
          } else {
            compania
              ? nav("/auto/cotizacion/" + compania + "/marca")
              : nav("/auto/cotizacion/marca");
          }
        };
        fetchData().then(() => {
          setLoading(false);
        });
      });
    });
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get("error");

  const tel = telefonos.filter((t: TelefonosCompañia) => {
    if (t.clave === compania) {
      return t;
    }
  });

  useEffect(() => {
    if (cotizacionValues?.parametrosIniciales?.dataCarHabilitado === false) {
      compania ? nav("/auto/cotizacion/" + compania + "/marca") : nav("/auto/cotizacion/marca")
    }
  }, [compania, cotizacionValues.parametrosIniciales?.dataCarHabilitado]);

  useEffect(() => {
    setValue(
      "setPatente",
      cotizacionValues?.patente ? cotizacionValues.patente : ""
    ); // Actualiza el valor en React Hook Form
    cotizacionValues?.patente && trigger("setPatente");
  }, [cotizacionValues?.patente]);

  useEffect(() => {
    if (error) {
      setError("setPatente", {
        type: "custom",
        message: error,
      });
    } else {
      setValue(
        "setPatente",
        cotizacionValues?.patente ? cotizacionValues?.patente : ""
      );
      cotizacionValues?.patente && trigger();
    }
  }, []);


  return (
    <div className="text-center mt-5" {...rest}>
      <div className="bg-gray-light text-center sec-patente">
        <div className="container">
          <form className="frm01" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <SegP
                text="Ingresá tu patente"
                fontSize={22}
                backgroundColor="#F8F8F8"
                fontWeight={"bold"}
                margin="0 0 18px 0"
              />
              <SegP
                text="Sin espacios así podremos encontrar más rápido el auto"
                fontSize={15}
                backgroundColor="#F8F8F8"
                fontWeight={500}
                margin="-4px 0 18px"
                lineHeight={1.25}
              />
              <div className="mw-sm-450 m-auto">
                <Controller
                  name="setPatente"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => !!value || "Ingresá la patente",
                      pattern: (value) =>
                        /^[A-Za-z]{3}\d{3}$|^[A-Za-z]{2}\d{3}[A-Za-z]{2}$/.test(
                          value
                        ) || "Patente inválida",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <SegInput
                      placeHolder="Ej: FUH384"
                      name="patente"
                      type="text"
                      width={100}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue("setPatente", e.target.value); // Actualiza el valor en React Hook Form
                        trigger("setPatente");
                      }}
                      label=""
                      value={field.value}
                      leyendaError={fieldState?.error?.message}
                      autoFocus={true}
                      borderColor={`${formState.errors.setPatente
                        ? "var(--bs-form-invalid-border-color)"
                        : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                        }`}
                      focusBorderColor={`${formState.errors.setPatente
                        ? "var(--bs-form-invalid-border-color)"
                        : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                        }`}
                    />
                  )}
                />
              </div>
              <div className="form-buttons">
                <SegButton
                  label="Buscar auto"
                  type="submit"
                  disabled={
                    !formState.isValid // Verifica si el formulario es válido
                  }
                  spinnerSize='small'
                  loading={loading}
                  bgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                  hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary : undefined}
                  hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                  hoverOpacity={config.origen !== "SEGURARSE"}
                />
              </div>
              <div className="form-alt">
                <SegLink
                  hoverUnderlined
                  text="Cotizar sin patente >"
                  link={
                    compania
                      ? "/auto/cotizacion/" + compania + "/marca"
                      : "/auto/cotizacion/marca"
                  }
                  underlinedText={false}
                  hoverTextColor="black"
                />
              </div>
              {compania && tel.length > 0 && (
                <p className="fs-12 mb-0 mt-2 text-dark">
                  ¿Buscás <b>{tel[0]?.txtMostrar}</b>? <b>Compará</b> las
                  mejores coberturas de las aseguradores líderes.
                </p>
              )}
            </fieldset>
          </form>
        </div>
        <div className="slide-cias" style={{ marginTop: adaptativeMargin }}>
          <SegSlider

            companias={
              config.origen !== "SEGURARSE" ?
                imagenesCompaniasFiltradas(cotizacionValues.parametrosIniciales.companias)
                :
                imagesCompanias
            }

          />
        </div>
      </div>
    </div>
  );
};
