import { useEffect, useState } from "react";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import segurarse from "../../Assets/img/logo_segurarse.svg";
import { SegStepper } from "../../Components/SegUI/SegStepper/SegStepper";
import "../../Assets/css/emision.css";
import { RevisionDatos } from "../../Components/Slides/Auto/Emision/RevisionDatos";
import { ModalPreciosEmision } from "../../Components/Slides/Auto/Precios/ModalPreciosEmision";
import { useEmisionStore } from "../../Services/Providers/EmisionStore";
import { ModalSinEOL } from "../../Components/Slides/Auto/Emision/ModalSinEOL";
import { DatosPersonales } from "../../Components/Slides/Auto/Emision/DatosPersonales";
import { RadicacionVehiculo } from "../../Components/Slides/Auto/Emision/RadicacionVehiculo";
import { DatosAuto } from "../../Components/Slides/Auto/Emision/DatosAuto";
import { DatosTarjeta } from "../../Components/Slides/Auto/Emision/DatosTarjeta";
import useSEOConfig from "../../hooks/seo/useSEOConfig";

export interface IPasosEmision {
  step?: number;
  subStep: number;
}

export interface EmisionProps {
  step?: number;
  subStep?: number;
}

export const Emision = ({ step = 0, subStep = 1 }: EmisionProps) => {
  const [steps, setSteps] = useState<IPasosEmision>({ step, subStep });
  const { emisionValues } = useEmisionStore();

  // useEffect(() => {
  //     emisionValues.cotizacionDesc && nav("/auto/cotizacion/patente")
  // }, [])

  useSEOConfig({
    title: "Auto",
  })

  useEffect(() => {
    setSteps((prev) => {
      return {
        ...prev,
        step: step,
        subStep: subStep,
      };
    });
  }, [step, subStep]);

  const renderVista = () => {
    switch (steps?.step) {
      case 0:
        switch (steps?.subStep) {
          case 1:
            return <RevisionDatos />;
        }
        break;
      case 1:
        switch (steps?.subStep) {
          case 1:
            return <DatosPersonales />;
          case 2:
            return <RadicacionVehiculo />;
          case 3:
            return <DatosAuto />;
          case 4:
            return <DatosTarjeta />;
        }
        break;
    }
  };

  return (
    <>
      <div>
        <ModalSinEOL />
        <ModalPreciosEmision
          cotizacionDesc={emisionValues.cotizacionDesc!}
          isEmision={true}
        />
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-md-6 offset-md-3 text-center">
              {" "}
              {/* Centra el contenido en la columna */}
              <SegHeaderLogo
                img={segurarse}
                alt="Logo Segurarse"
                width="160px"
              />
            </div>
          </div>
        </div>
        <div>
          <SegStepper
            icon="auto"
            label={
              steps?.step! > 0
                ? "Estás emitiendo tu póliza"
                : "Estás por emitir la siguiente póliza"
            }
            isStepper={false}
            className="fs-20 fw-bold bg-primary text-center px-3 pt-1 pb-1 mb-m13 minh-90p pt-lg-35 pb-lg-30 mb-lg-m30 minh-lg-125p"
          />
        </div>
        <div>{renderVista()}</div>
      </div>
    </>
  );
};
