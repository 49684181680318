import Axios from 'axios'
import { getPathName } from './helper';

type ContactoIntegralComercioParams = {
    origen?: string;
    rubro?: string;
    isMobile?: boolean;
    tipoCotizador?: string;
    landingUrl?: string;
    refererUrl?: string;
    nombre?: string;
    apellido?: string;
    codigoArea?: string;
    telefono: {
        prefijo?: string;
        numero?: string;
    }
    provincia: {
        value?: string;
        label?: string;
    }
    localidad: {
        value?: string;
        label?: string;
    }
    email?: string;
    actividad?: string;
    token?: string;
    captchaToken: string;
    parametrosIniciales: {
        origen?: string,
    };
};

const guardarContactoIntegralComercio = async (params: ContactoIntegralComercioParams) => {
    let data: Record<string, any> = {
        hash: process.env.REACT_APP_HASH,
        isMobile: "false",
        rubro: "COMERCIO",
        origen: params.parametrosIniciales.origen,

        landingUrl: params.landingUrl,
        refererUrl: params.refererUrl,

        provincia: params.provincia.value,
        localidad: params.localidad.value,

        actividad: params.actividad,

        nombre: params.nombre,
        apellido: params.apellido,

        codigoArea: params.telefono.prefijo ?? null,
        telefono: params.telefono.numero ?? null,

        email: params.email ?? null,

        g_recaptcha_response: params.captchaToken
    };
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    return await Axios.post(getPathName() + "/SetCotizacionIntegralComercioJSON", data,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
};

export default guardarContactoIntegralComercio;