import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/ART/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import CodigosDeArea from '../../../../Assets/CodigosDeArea.json';


type FormValues = {
  setPrefijo: string;
  setNumero: string;
};

interface TelefonoProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  telefonoValue: { prefijo: string; numero: string } | undefined;
}

const validarTelefono = (prefijo: string, numero: string) => {
  const numeroCompleto = prefijo + numero;

  if (numeroCompleto.length === 10) {
    return true;
  } else {
    return false;
  }
};

export const Telefono = ({
  setSteps,
  telefonoValue,
  ...rest
}: TelefonoProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [isTelefonoValido, setIsTelefonoValido] = React.useState<boolean>(false);

  const { handleSubmit, control, setValue, formState, trigger, getValues, setError, watch, clearErrors } =
    useForm<FormValues>();
  //const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setPrefijo, setNumero } = values;
    setCotizacionValues({
      ...cotizacionValues,
      telefono: {
        prefijo: setPrefijo,
        numero: setNumero,
      },
    });
    compania
      ? nav("/art/cotizacion/" + compania + "/codigo-postal")
      : nav("/art/cotizacion/codigo-postal");

  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');

  const validarYActualizarTelefono = () => {
    const { setPrefijo, setNumero } = getValues();
    const resultadoValidarTelefono = validarTelefono(setPrefijo, setNumero);
    setIsTelefonoValido(resultadoValidarTelefono);
  };

  useEffect(() => {
    validarYActualizarTelefono();
  }, [telefonoValue]);

  useEffect(() => {
    !cotizacionValues.email && (compania ? nav("/art/cotizacion/" + compania + "/email") : nav("/art/cotizacion/email"))
    if (error) {
      setError("setNumero", {
        type: 'custom',
        message: error,
      });
    } else {
      setValue("setNumero", cotizacionValues.telefono?.numero ? cotizacionValues.telefono?.numero : "");
      cotizacionValues.telefono?.numero && trigger()
    }
  }, [])

  useEffect(() => {
    setValue("setPrefijo", telefonoValue?.prefijo ? telefonoValue.prefijo : ""); // Actualiza el valor en React Hook Form
    setValue("setNumero", telefonoValue?.numero ? telefonoValue.numero : "");
    telefonoValue?.prefijo && trigger();
    telefonoValue?.numero && trigger();
  }, [telefonoValue]);

  useEffect(() => {
    sessionStorage.setItem('telefonoValue', JSON.stringify(telefonoValue));
  }, [telefonoValue]);

  useEffect(() => {
    const storedTelefonoValue = sessionStorage.getItem('telefonoValue');
    if (storedTelefonoValue) {
      try {
        const parsedTelefonoValue = JSON.parse(storedTelefonoValue);
        setCotizacionValues({
          ...cotizacionValues,
          telefono: {
            prefijo: parsedTelefonoValue.prefijo,
            numero: parsedTelefonoValue.numero,
          },
        });
      } catch (e) {
      }
    }
  }, []);

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>¿Tu teléfono celular?</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center">
            <div className="row gx-3 text-start mw-sm-450 m-1">
              <Controller
                name="setPrefijo"
                control={control}
                rules={{
                  validate: {
                    required: (value) => !!value || "Ingresá el Cód. Área",
                    isInValidCodeArea: (value) =>
                      CodigosDeArea.includes(value) ||
                      "Cód. Área inválido",
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput

                    isGroup={true}
                    labelGroup="0"
                    className="col-4"
                    label="Cód. Área"
                    name="setPrefijo"
                    type="number"
                    width={33}
                    placeHolder="11"
                    value={field.value}
                    autoFocus={true}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("setPrefijo", e.target.value); // Actualiza el valor en React Hook Form
                      trigger("setPrefijo");
                      if (getValues("setNumero") !== "" && !validarTelefono(getValues("setPrefijo"), getValues("setNumero"))) {
                        setError("setNumero", { type: "validate", message: "Número inválido" })
                      } else {
                        clearErrors("setNumero")
                      }
                      validarYActualizarTelefono();

                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setPrefijo
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${formState.errors.setPrefijo
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
              <Controller
                name="setNumero"
                control={control}
                rules={{
                  validate: {
                    required: (value) => !!value || "Ingresá Número de Celular",
                    pattern: (value) =>
                      /^[0-9]{6,9}$/.test(value) || "Número inválido",
                    validateCombination: (value) => {
                      const prefijo = watch("setPrefijo");
                      if ((prefijo + value).length !== 10)
                        return "Número inválido"
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    isGroup={true}
                    labelGroup="15"
                    className="col-8"
                    label="Número de Celular"
                    name="setNumero"
                    type="number"
                    width={66}
                    placeHolder="33669955"
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("setNumero", e.target.value); // Actualiza el valor en React Hook Form
                      trigger("setNumero");
                      validarYActualizarTelefono();
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setNumero
                      ? "var(--bs-form-invalid-border-color)"
                      : ""
                      }`}
                    focusBorderColor={`${formState.errors.setNumero
                      ? "var(--bs-form-invalid-border-color)"
                      : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
          </div>
          {/*errorMessage && (
            <div className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )*/}
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid || !isTelefonoValido// Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
