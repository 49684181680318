import Axios from 'axios'
import { getPathName } from './helper';

const obtenerMarcas = async () => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        marca: ""
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getmarcasjson", params,
        {
            headers: headers
        }).then(function (response) {
            return response?.data?.marcas;
        }).catch(function (error) {
            return error;
    });
};

export default obtenerMarcas;