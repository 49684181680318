import { useState } from 'react';
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo';
import segurarse from "../../Assets/img/logo_segurarse.svg";
import { SegStepper } from '../../Components/SegUI/SegStepper/SegStepper';
import { DatosTomador } from '../../Components/Slides/AP/emision/DatosTomador';
import { useEmisionStore } from '../../Services/Providers/EmisionStore';
import { DatosAsegurado } from '../../Components/Slides/AP/emision/DatosAsegurado';
import { DatosBeneficiario } from '../../Components/Slides/AP/emision/DatosBeneficiario';
import { DatosTarjeta } from '../../Components/Slides/AP/emision/DatosTarjeta';
import { ModalPrecios } from '../../Components/Slides/AP/precios/ModalPrecios';
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';


export interface IPasosEmision {
  step?: number;
  subStep: number;
}

export const Emision = ({ step = 0, subStep = 1 }: IPasosEmision) => {
  const [steps, setSteps] = useState<IPasosEmision>({ step, subStep });
  const { emisionValues, setEmisionValues } = useEmisionStore();
  const {cotizacionValues} = useCotizacionStore()

  const renderVista = () => {
    switch (step) {
      case 0: {
        switch (subStep) {
          case 1: return <DatosTomador emisionValues={emisionValues} setEmisionValues={setEmisionValues} />
          case 2: return <DatosAsegurado emisionValues={emisionValues} setEmisionValues={setEmisionValues} />
          case 3: return <DatosBeneficiario emisionValues={emisionValues} setEmisionValues={setEmisionValues} />
        }
      }
        break;
      case 1: {
        switch (subStep) {
          case 1: return <DatosTarjeta emisionValues={emisionValues} />
        }
      }
        break;
      default: return <></>
    }
    return (
      <div>
        <p className=''>Cobertura</p>
      </div>
    )
  }

  const formatearSumaAsegurada = (value: string = "") => {
    const numericValue = parseInt(value.replace(/[^0-9]/g, ""), 10);
    return `$ ${new Intl.NumberFormat("es-AR").format(numericValue)}`;
  };

  return (
    <div>
      <ModalPrecios sumaAsegurada={formatearSumaAsegurada(cotizacionValues.sumaMuerte?.toString())} cotizacionDesc={emisionValues.cotizacionDescAP} isEmision/>
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-md-6 offset-md-3 text-center">
            {" "}
            {/* Centra el contenido en la columna */}
            <SegHeaderLogo
              img={segurarse}
              alt="Logo Segurarse"
              width="160px"
            />
          </div>
        </div>
      </div>
      <div>
        <SegStepper
          icon="hombre"
          label={
            steps?.step! > 0
              ? "Estás emitiendo tu póliza"
              : "Estás por emitir tu póliza de accidentes personales"
          }
          isStepper={false}
          className="fs-20 fw-bold bg-primary text-center px-3 pt-1 pb-1 mb-m13 minh-90p pt-lg-35 pb-lg-30 mb-lg-m30 minh-lg-125p"
        />
      </div>
      <div style={{ paddingTop: "24px" }}>
        {renderVista()}
      </div>
    </div>
  )
}
