import React, { useEffect, useState } from 'react'
import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import logoblue from "../../Assets/img/logo_segurarse.svg"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore'
import { telefonos } from '../../Assets/telefonosCompanias';
import guardarContactoPrepaga from '../../Services/API/GuardarContactoPrepaga'

interface GraciasProps {
    nombre?: string;
    apellido?: string;
    dni?: string;
    telefono?: string;
    email?: string;
}

const StyledDiv = styled.div`
height: 63vh;
@media (max-width: 458px) {
    height: 85vh;
}
@media (max-width: 278px) {

    height: 90vh;
}
`

export const GraciasIndividuoPrepaga = ({ nombre, apellido, dni, telefono, email }: GraciasProps) => {
    const nav = useNavigate();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const { compania } = useParams();
    /*const [dataPrevia, setDataPrevia] = useState<GraciasProps>({
        nombre: "",
        apellido: "",
        dni: "",
        telefono: "",
        email: "",
    });*/

    nombre = nombre ? nombre : cotizacionValues.nombre;
    apellido = apellido ? apellido : cotizacionValues.apellido;
    telefono = telefono ? telefono : (
        (cotizacionValues.telefono?.prefijo || cotizacionValues.telefono?.numero)
            ? `${cotizacionValues.telefono?.prefijo ?? ''}${cotizacionValues.telefono?.numero ?? ''}`
            : undefined
    );
    dni = dni ? dni : cotizacionValues.numeroDoc;
    email = email ? email : cotizacionValues.email;

    /*const setDatosContacto = () => {
        const data: GraciasProps = {
            nombre: nombre ? nombre : cotizacionValues.nombre,
            apellido: apellido ? apellido : cotizacionValues.apellido,
            telefono: telefono ? telefono : (
                (cotizacionValues.telefono?.prefijo || cotizacionValues.telefono?.numero)
                    ? `${cotizacionValues.telefono?.prefijo ?? ''}${cotizacionValues.telefono?.numero ?? ''}`
                    : undefined
            ),
            dni: dni ? dni : cotizacionValues.numeroDoc,
            email: email ? email : cotizacionValues.email,
        }
        setDataPrevia(data);
        sessionStorage.setItem("vistaGracias", JSON.stringify(data))
    }*/

    /*useEffect(() => {
        const dataPrevia = sessionStorage.getItem("vistaGracias")
        if (dataPrevia) {
            const data: GraciasProps = JSON.parse(dataPrevia);
            setDataPrevia({
                nombre: data.nombre,
                apellido: data.apellido,
                telefono: data.telefono,
                email: data.email,
                dni: data.dni
            })
        } else {
            setDataPrevia({
                nombre: nombre ? nombre : cotizacionValues.nombre,
                apellido: apellido ? apellido : cotizacionValues.apellido,
                telefono: telefono ? telefono : (
                    (cotizacionValues.telefono?.prefijo || cotizacionValues.telefono?.numero)
                        ? `${cotizacionValues.telefono?.prefijo ?? ''}${cotizacionValues.telefono?.numero ?? ''}`
                        : undefined
                ),
                dni: dni ? dni : cotizacionValues.numeroDoc,
                email: email ? email : cotizacionValues.email,
            })
        }

    }, [])*/

    useEffect(() => {
        (!cotizacionValues.nombre || !cotizacionValues.apellido || !cotizacionValues.telefono || !cotizacionValues.email || !cotizacionValues.numeroDoc) && (compania ? nav("/prepaga/cotizacion/" + compania + "/individuo/localidad-provincia") : nav("/prepaga/cotizacion/localidad-provincia"))
    }, [])

    useEffect(() => {
        if (cotizacionValues.parametrosIniciales && !cotizacionValues?.contactoId) {
            const fetchData = async () => {
                const response: any = await guardarContactoPrepaga({
                    origen: cotizacionValues?.parametrosIniciales.origen,
                    tipoCotizador: cotizacionValues?.parametrosIniciales.tipoCotizador,
                    landingUrl: cotizacionValues?.landingUrl,
                    refererUrl: cotizacionValues?.referer,
                    // 1
                    tipoDoc: cotizacionValues?.tipoDoc?.value,
                    cuit: cotizacionValues?.cuit,
                    numeroDoc: cotizacionValues?.numeroDoc,
                    razonSocial: cotizacionValues?.razonSocial,
                    nombre: cotizacionValues?.nombre,
                    apellido: cotizacionValues?.apellido,
                    email: cotizacionValues?.email,
                    codigoArea: cotizacionValues?.telefono?.prefijo,
                    telefono: cotizacionValues?.telefono?.numero,
                    provincia: cotizacionValues?.provincia?.value,
                    localidad: cotizacionValues?.localidad?.value,
                    FechaNacimiento: cotizacionValues?.fechaNac,
                    DerivaAportes: cotizacionValues?.derivaAportes,
                    SueldoBruto: isNaN(Number(cotizacionValues?.masaSalarial)) ? 0 : Number(cotizacionValues?.masaSalarial),

                    //2
                    Observaciones: cotizacionValues?.observaciones,
                    Conyuge: cotizacionValues?.conyuge,
                    DocumentoConyuge: cotizacionValues?.documentoConyuge,
                    UnificaAportes: cotizacionValues?.unificarAportes,
                    SueldoBrutoConyuge: isNaN(Number(cotizacionValues?.sueldoBrutoConyuge)) ? 0 : Number(cotizacionValues?.sueldoBrutoConyuge),
                    CantidadHijos: isNaN(Number(cotizacionValues?.cantHijos?.value)) ? 0 : Number(cotizacionValues?.cantHijos?.value),
                    empleados: isNaN(Number(cotizacionValues?.empleados)) ? 0 : Number(cotizacionValues?.empleados),
                    captchaToken: cotizacionValues?.captchaToken,
                });
                if (response.data) {
                    if (response.data.api_status === 200 && response.data.api_data !== null) {
                        setCotizacionValues({
                            ...cotizacionValues,
                            contactoId: response.data.api_data.idContacto,
                            sumaAseguradaIA: response.data.api_data.sumaAseguradaIA,
                            token: response.data.api_data.token
                        });
                    } else if (response.data.api_data === "El Captcha no es válido") {
                        compania ? nav("/prepaga/cotizacion/" + compania + "/corporativo/localidad-provincia?error=El Captcha no es válido") : nav("/prepaga/cotizacion/localidad-provincia?error=El Captcha no es válido")
                    } else {
                        nav("/prepaga/error");
                    }
                }
            }
            fetchData();
        }
        //setDatosContacto()
    }, [])

    return (
        <div>
            <SegSvg />
            <div className=''>
                <div className="container d-flex align-items-center justify-content-center" style={{ height: "7vh" }}>
                    <div className="row">
                        <div className=""> {/* Centra el contenido en la columna */}
                            <SegHeaderLogo img={logoblue} alt="Logo Segurarse" width="160px" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary text-center pt-5" style={{ height: "25vh" }}>
                <svg className="" style={{ width: "150px" }}>
                    <use href="#ico-prepaga" fill='white' />
                </svg>
            </div>
            <StyledDiv className='bg-primary text-center d-flex flex-column'>
                <h1 className='fs-2 text-white'>¡Recibimos tu solicitud!</h1>
                <p className='mt-3 text-white fs-5'>En breve nos comunicaremos para asesorarte en la mejor cobertura para tu empresa.</p>
                <div className='mb-5 mt-4 ps-3 pe-3'>
                    <div className='d-flex flex-column bg-white rounded container'>
                        <div className='d-flex flex-column flex-grow-2 justify-content-center m-3 gap-2'>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4 pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#card-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Nombre y Apellido:</span>
                                        <span className='fw-bold'>{nombre + " " + apellido}</span>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#tel-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Telefono:</span>
                                        <span className='fw-bold'>{telefono}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                                <use xlinkHref='#people-lin' fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>CUIL:</span>
                                        <span className='fw-bold '>{dni}</span>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                    <div className="d-flex align-items-start gap-2">
                                        <div className=''>
                                            <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                <use xlinkHref="#envelope-lin" fill='#2C7DFD' />
                                            </svg>
                                        </div>
                                        <span>Email:</span>
                                        <span className='fw-bold text-truncate'>{email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="https://api.whatsapp.com/send?phone=5491169568002&text=%C2%A1Hola!%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Prepaga%20M%C3%A9dica" target='_blank' rel='noreferrer' className='text-black mt-4' >
                            <div className='d-flex justify-content-center gap-2 align-items-start'>
                                <div>
                                    <p className='fw-bold'>¿Querés continuar por WhatsApp? <span className='fw-normal'>Chatéanos</span></p>
                                </div>
                                <div>
                                    <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                        <use xlinkHref="#whatsapp" />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </StyledDiv>
        </div>
    )
}
