import React from 'react';
import shape12 from '../../../Assets/img/shape12.svg';
import figura11 from '../../../Assets/img/figura11.svg';

export const PorQueElegirnos = () => {
    return (
        <div className="py-40 py-lg-70 position-relative">
            <img className="d-none d-lg-block pos15 blend-multipy" src={shape12} alt="Fondo decorativo" />
            <div className="container px-25 px-lg-12">
                <div className="row">
                    <div className="d-none d-lg-block col-lg-6 text-center">
                        <img className="img-fluid w-380p pt-50" src={figura11} alt="Figura decorativa" />
                    </div>
                    <div className="col-lg-6 ms-auto">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center text-lg-start mb-35 mb-lg-50">
                            ¿Por qué elegirnos?
                        </h2>
                        <ul className="list-unstyled fs-16 fs-lg-20 lh-14 mb-2">
                            <li className="bullet-circle-primary ps-4 mb-25">
                                <b>Brindamos asesoramiento integral de coberturas</b> y soluciones ajustadas a las
                                necesidades de protección de la actividad económica de comercios, PyMES e industrias.
                            </li>
                            <li className="bullet-circle-primary ps-4 mb-25">
                                <b>Ofrecemos servicios</b> durante y post contratación, solicitud, emisión y control de
                                pólizas, cobranzas y atención, asesoramiento y seguimiento de siniestros y análisis de
                                PML.
                            </li>
                            <li className="bullet-circle-primary ps-4 mb-0">
                                <b>Nuestra trayectoria y experiencia en el mercado</b> asegurador nos posiciona como un
                                socio estratégico, preparado para brindar una solución integral.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
