import { useEffect, useRef, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useFormContext } from "react-hook-form";
import styled from "@emotion/styled";


export interface SegSelectOption {
  value: string;
  label: string;
}

export interface SegSelectGrupedOption {
  label: string;
  options: any[]
}

interface SegSelectProps {
  options: SegSelectOption[];
  isGrupable?:boolean;
  width?: string;
  placeholder?: string;
  isMulti?: boolean;
  borderColor?: string;
  hoverBorderColor?: string;
  focusBorderColor?: string;
  selected?: SegSelectOption | null;
  setSelected?: (selected: SegSelectOption) => void;
  className?: string;
  id?: string;
  name: string;
  disabled?: boolean;
  loading?: boolean;
  openDefault?: boolean;
  handleSubmit?: (value: SegSelectOption) => void
  autoFoco?: boolean;
  label?: string;
  labelColor?: string;
  isClearable?: boolean;
  height?: "sm"|"md"|"lg";
  empresaColor?: {
    selected: string;
    hover: string;
  } | null; 
}

const Label = styled.label<{ labelColor: string }>`
color: ${({ labelColor }) => labelColor} !important;
`;


export const SegSelect = ({
  options = [],
  isGrupable = false,
  width = "18em",
  label,
  labelColor,
  placeholder = "Escribí aquí",
  isMulti = false,
  borderColor = "black",
  hoverBorderColor = "black",
  focusBorderColor = "#2C7DFD",
  selected = null,
  setSelected,
  className,
  name,
  id,
  disabled = false,
  loading = false,
  openDefault = true,
  handleSubmit,
  autoFoco = false,
  isClearable = false,
  height = "lg",
  empresaColor = null
}: SegSelectProps) => {
  const { setValue } = useFormContext(); // Obtén la instancia del formulario usando useFormContext
  const [isOpen, setIsOpen] = useState<boolean>()
  const handleChange = (option: any) => {
    setValue(name, option ? option.value : null); // Usa setValue para actualizar el valor del campo
    setSelected && setSelected(option);
    setIsOpen(false)
    if (handleSubmit) {
      handleSubmit(option)
    }
  };
  const toggleMenu = () => {
    setIsOpen(current => !current)
  };

  const optionsRef = useRef<any>(null);
  useEffect(() => {

    setIsOpen(openDefault)
  }, [openDefault])

  useEffect(() => {
    if (selected != null && isClearable && optionsRef.current) {
      optionsRef.current.clearValue();
      if (options.length === 1) {
        setValue(name, options[0] ? options[0].value : null);
        setSelected && setSelected(options[0]);
      }
    }
  }, [options])

  // useEffect(() => {
  //   const def:SegSelectOption = {value: '' , label: ''}
  //   if (selected && selected !== def ) {
  //     setIsOpen(false)
  //   }
  // }, [selected])

  //const style: StylesConfig = {
  const style: StylesConfig<SegSelectOption, boolean, any>  = {
    menuList: (base: any) => ({
      ...base,
      maxHeight: height === "sm" ? "120px" : height === "md" ? "170px" : "300px"
    }),
    menu: (base: any) => ({
      ...base,
      scrollbarColor: "white",
    }),
    control: (base: any) => ({
      ...base,
      boxShadow: "none",
      borderColor: borderColor,
      width: width,
      "&:hover": {
        borderColor: hoverBorderColor,
      },
      "&:focus-within": {
        borderColor: focusBorderColor,
      },
      "&::-webkit-scrollbar": {
        width: 12,
        borderRadius: 20,
      },
    }),
    option: (base: any, state) => ({
      ...base,
      paddingLeft: isGrupable && state.data.value !== "separator" && "24px",
      color: state.data.value === "separator" && "#000",
      fontWeight: state.data.value === "separator" && 600,
      background: empresaColor && state.data.value === selected?.value && empresaColor.selected,
      backgroundColor: state.data.value === "OtraActividad" && "#ffd332"
      /*"&:hover": {
        background: empresaColor && empresaColor.hover,
      },*/
      
    }),
    input: (base: any) => ({
      gridArea: "1/1/2/3",
    }),
  };


  const isOptionDisabled = (option: SegSelectOption) => {
    return option.value === 'separator';
  };

 
  return (
    <>
      {label !== "" ? (
        <Label className="form-label" labelColor={labelColor!}>
          {label}
        </Label>
      ) : (
        ""
      )}
      <Select
        ref={optionsRef}
        className={className}
        isMulti={isMulti}
        styles={style}
        options={options ? options : []}
        value={selected}
        onChange={handleChange}
        isSearchable={true}
        placeholder={placeholder}
        id={id}
        name={name}
        isClearable={isClearable}
        isDisabled={disabled}
        isLoading={loading}
        menuIsOpen={isOpen}
        onMenuOpen={toggleMenu}
        onMenuClose={() => { setIsOpen(false) }}
        isOptionDisabled={(option) => isOptionDisabled(option as SegSelectOption)}
        autoFocus={autoFoco}
        menuPlacement="auto"
      />
    </>
  );
};
