import Axios from 'axios'
import { getPathName } from './helper';

const obtenerParametros = async ({rubro = "AUTO"}) => {

    const params = {
        hash: process.env.REACT_APP_HASH,
        rubro
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post( getPathName() + "/getparametroscotizacionjson", params,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
    });
};

export default obtenerParametros;