import { create } from 'zustand';
import { SegSelectOption } from '../../Components/SegUI/SegSelect/SegSelect';
import { createJSONStorage, persist } from "zustand/middleware";
import { Cotizacion } from '../../Pages/Auto/Precios';
import { CotizacionPrepaga } from '../../Pages/Prepaga/Individuo/Precios';
import { CotizacionesAP } from '../../Pages/AP/Precios';

export interface ICotizacionValues {
  patente?: string;
  marca?: SegSelectOption;
  modelo?: SegSelectOption;
  año?: SegSelectOption;
  esAntiguo?: boolean;
  version?: SegSelectOption;
  nombre?: string;
  apellido?: string;
  email?: string;
  telefono?: { prefijo: string; numero: string };
  fechaNac?: { dia: string; mes: string; año: string };
  genero?: SegSelectOption;
  localidad?: SegSelectOption;
  provincia?: SegSelectOption;
  cuandoContrata?: SegSelectOption;
  parametrosIniciales?: any;
  contactoId?: string;
  sumaAseguradaIA?: string;
  sumaAsegurada?: string;
  cotizaciones?: Cotizacion[];
  cotizacionesAP?: CotizacionesAP[];
  cotizacionesPrepaga?: CotizacionPrepaga[];
  fechaConsultaCotizaciones?: Date;
  captchaToken?: string;
  landingUrl?: string,
  referer?: string,
  token?: string;
  cuit?: string;
  empleados?: string;
  masaSalarial?: string;
  razonSocial?: string;
  cobertura?: SegSelectOption;
  tipoMoneda?: string;
  tipoDoc?: SegSelectOption;
  numeroDoc?: string;
  derivaAportes?: boolean;
  conyuge?: boolean;
  hijos?: boolean;
  documentoConyuge?: string;
  sueldoBrutoConyuge?: string;
  cantHijos?: SegSelectOption;
  observaciones?: string;
  unificarAportes?: boolean;
  codigoPostal?: string;
  localidadLanding?: string;
  rubro?: string;
  tipoPersona?: string;
  condicionIVA?: string;
  condicionIIBB?: string;
  profesion?:SegSelectOption;
  actividadDedicada?:string|null;
  cantidadDeVidas?:number;
  sumaMuerte?: number;
  fechaInicio?: string;
  fechaFin?: string;
  profesiones?:any[]
}

interface CotizacionStore {
  cotizacionValues: ICotizacionValues;
  setCotizacionValues: (values: ICotizacionValues) => void;
  resetCotizacionValues: () => void;
  vaciarCotizaciones: () => void;
}

export const useCotizacionStore = create<CotizacionStore>()(
  persist(
    (set) => ({
      cotizacionValues: {},
      setCotizacionValues: (values: ICotizacionValues) => set({ cotizacionValues: { ...values } }),
      resetCotizacionValues: () => set({ cotizacionValues: {} }),
      vaciarCotizaciones: () => set((state) => ({
        ...state,  // Mantén las otras propiedades del objeto padre
        cotizaciones: []
      })),
    }),
    {
      name: "cotizacion",
      storage: createJSONStorage(() => sessionStorage),
    }
  ));