import React from "react";
import styled from "@emotion/styled";

interface SegBackButtonProps {
  marca: string;
  version?: string;
  anio?: string;
  modelo?: string;
  backgroundColor?: string;
  fontSize?: number;
  fontWeight?: "bold" | "normal" | 500 | 700;
  textColor?: string;
  href?: string;
  className?: string;
  onClick?: () => void,
}

export const SegBackButton = ({
  marca,
  version = "Berlingo 2015 - 1.6 VTI Multispace XTR",
  anio = "2016",
  modelo = "Berlingo",
  backgroundColor = "#A5A5A5",
  fontSize = 100,
  fontWeight = 700,
  textColor = "#FFF",
  href = "#",
  className,
  onClick,

}: SegBackButtonProps) => {
  const StyledDiv = styled.div`
    background-color: ${backgroundColor};
    padding-top: 12px;
    padding-right: 30px;
    padding-bottom: 12px;
    padding-left: 30px;
    font-size: ${fontSize}%;
    color: ${textColor};
    line-height: 1.25;
    border-radius: 5px;
    text-align: left;
    display: block;
    cursor: pointer;
  `;

  const StyledSpan = styled.span`
    font-weight: ${fontWeight};
  `;

  const StyledA = styled.span`
    color: inherit;
    text-decoration: inherit;
  `

  const stringLink = () => {
    if (modelo && anio && version) {
      return " - " + modelo + " " + anio + " - " + version; 
    } else if (modelo && anio) {
      return " - " + modelo + " " + anio
    } else if (modelo) {
      return " - " + modelo
    } else {
      return ""
    }
  }

  return (
    <StyledDiv onClick={() => { onClick && onClick() }}>
      <StyledA className={className}>
        {marca ?
        <StyledSpan>{"< " + marca}</StyledSpan> :
        <StyledSpan>{"< VOLVER"}</StyledSpan>
        }
        { stringLink() }
      </StyledA>
    </StyledDiv>
  );
};
