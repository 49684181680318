import { useEffect, useState } from "react";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import logoblue from "../../Assets/img/logo_segurarse.svg";
import { SegSvg } from "../../Components/SegUI/SegSvg/SegSvg";
import { useEmisionStore } from "../../Services/Providers/EmisionStore";
import { useCotizacionStore } from "../../Services/Providers/CotizacionStore";
import { useNavigate } from "react-router-dom";

export const ErrorEmision = () => {
  const nav = useNavigate();

  const { emisionValues, resetEmisionValues } = useEmisionStore();
  const { cotizacionValues, resetCotizacionValues } = useCotizacionStore();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Segurarse | Seguros online"
  }, [])

  useEffect(() => {
    const handleBackNavigation = () => {
      resetCotizacionValues();
      resetEmisionValues();
      nav("/auto/cotizacion/patente", { replace: true });
      window.removeEventListener("popstate", handleBackNavigation);
    };
    window.addEventListener("popstate", handleBackNavigation);
  }, [nav]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div>
        <SegSvg />
        <div>
          <div
            className="container d-flex align-items-center justify-content-center"
            style={{ height: "7vh" }}
          >
            <div className="row">
              <div className="">
                {" "}
                {/* Centra el contenido en la columna */}
                <SegHeaderLogo
                  img={logoblue}
                  alt="Logo Segurarse"
                  width="160px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary text-center pt-5" style={{ height: "5vh" }}>
          {/* <img
                    style={{ width: "150px" }}
                    src={errorIcon}
                    alt="Error"
                /> */}
        </div>
        <div className="bg-primary text-center d-flex flex-column py-lg-60">
          <h1 className="fs-2 text-white">¡Estamos procesando tu solicitud!</h1>
          <p className="mt-3 text-white fs-5 container">
            Un asesor de nuestro equipo se va a poner en contacto con vos para
            completar el proceso. Tené en cuenta que nuestro horario de atención
            es de lunes a viernes de 09:00 a 18:00hs.
          </p>
          <div className="mb-5 mt-4 ps-3 pe-3">
            <div
              className={
                windowWidth < 768
                  ? "bg-white rounded container px-3"
                  : "bg-white rounded container"
              }
            >
              <div className={windowWidth < 768 ? "" : "px-5 "}>
                <div
                  className={
                    windowWidth < 991
                      ? "gap-2 d-flex flex-wrap"
                      : "d-flex flex-wrap"
                  }
                >
                  <div
                    className="col-lg-6 d-flex"
                    style={{ justifyContent: "center" }}
                  >
                    <div>
                      <div className={windowWidth < 768 ? "pt-4 " : "pt-5 "}>
                        <div className="d-flex align-items-start gap-2">
                          <div className="">
                            <svg
                              className=""
                              style={{ width: "1.5em", height: "1.5em" }}
                            >
                              <use xlinkHref="#card-lin" fill="#2C7DFD" />
                            </svg>
                          </div>
                          <span>Cobertura:</span>
                          <span className="fw-bold">
                            {emisionValues.cotizacionDesc?.planName}
                          </span>
                        </div>
                      </div>
                      <div
                        className={windowWidth < 768 ? "pt-4 " : "pt-4 pb-5"}
                      >
                        <div className="d-flex align-items-start gap-2">
                          <div className="">
                            <svg
                              className=""
                              style={{ width: "1.5em", height: "1.8em" }}
                            >
                              <use xlinkHref={"#card-lin"} fill="#2C7DFD" />
                            </svg>
                          </div>
                          <span>Aseguradora:</span>
                          <span className="fw-bold ">
                            {emisionValues.cotizacionDesc?.companyName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 d-flex"
                    style={{ justifyContent: "center" }}
                  >
                    <div>
                      <div className={windowWidth < 768 ? "pt-4 " : "pt-5"}>
                        <div className="d-flex align-items-start gap-2">
                          <div className="">
                            <svg
                              className=""
                              style={{ width: "1.5em", height: "1.5em" }}
                            >
                              <use xlinkHref="#tel-lin" fill="#2C7DFD" />
                            </svg>
                          </div>
                          <span>Telefono:</span>
                          <span className="fw-bold">
                            {emisionValues.celular
                              ? emisionValues.celular?.cod +
                              emisionValues.celular?.numero
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div
                        className={
                          windowWidth < 768 ? "pt-4  pb-5" : "pt-4 mr-5"
                        }
                      >
                        <div className="d-flex align-items-start gap-2">
                          <div className="">
                            <svg
                              className=""
                              style={{ width: "1.5em", height: "1.5em" }}
                            >
                              <use xlinkHref="#envelope-lin" fill="#2C7DFD" />
                            </svg>
                          </div>
                          <span>Email:</span>
                          <span className="fw-bold text-truncate">
                            {cotizacionValues.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href={encodeURI(
                  "https://wa.me/5491169568002?text=Hola, necesito ayuda con la emisión de mi seguro " +
                  (emisionValues?.cotizacionDesc?.planName! || null) +
                  " de la aseguradora " +
                  (emisionValues?.cotizacionDesc?.companyName! || null) +
                  ". Quiero hablar con un asesor."
                )}
                target="_blank"
                rel="noreferrer"
                className="text-black"
              >
                <div className="d-flex justify-content-center gap-2 align-items-start">
                  <div className="hover-underline">
                    <p className="fw-bold">
                      ¿Querés continuar por WhatsApp?{" "}
                      <span className="fw-normal">Chatéanos</span>
                    </p>
                  </div>
                  <div>
                    <svg
                      className=""
                      style={{ width: "1.5em", height: "1.8em" }}
                    >
                      <use xlinkHref="#whatsapp" />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="bg-primary text-center pt-5"
          style={{ height: "5vh" }}
        />
      </div>
    </>
  );
};
