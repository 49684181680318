import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

interface Props {
    testimonios: {
        texto: string;
        nombre: string;
        calificacion: number;
    }[]
}

export const TestimoniosSlider = ({ testimonios }: Props) => {
    return (
        <div className="container">
            <Splide
                options={{
                    type: 'loop',
                    focus: 0,
                    drag: true,
                    flickMaxPages: 1,
                    flickPower: 50,
                    perPage: 1,
                    perMove: 1,
                    autoplay: true,
                    interval: 4000,
                    arrows: false,
                    mediaQuery: 'min',
                    gap: '0.5rem',
                    padding: '2rem',
                    breakpoints: {
                        576: {
                            perPage: 1,
                            padding: '0',
                        },
                        768: {
                            perPage: 2,
                            gap: '1.5rem',
                        },
                        992: {
                            perPage: 3,
                        },
                    }
                }}
            >
                {testimonios
                    .map((testimonio, index) => (
                        <SplideSlide key={"testimonio-" + index}>
                            <div className="comms-wrapper" style={{ minHeight: "160px" }}>
                                <p className="fs-14 fs-lg-18 lh-sm mb-2"><i>{testimonio.texto}</i></p>
                                <div>
                                    <span className="fs-14 fs-lg-14">{testimonio.nombre}</span>
                                    <div>

                                        {
                                            Array.from({ length: 5 }, (_, i) => i + 1).map(item => (
                                                <svg className={`svg-icon icon-star ${item <= testimonio.calificacion && "icon-star-on"}`}><use xlinkHref="#icon-rating-star"></use></svg>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}

            </Splide>
        </div>
    )
}