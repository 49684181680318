interface Props {
    titulo?: string;
    coberturas: { label: string; icon: string; link: string }[];
    CoberturaEmpresas: boolean;
    children?: any;
}

export const GridCoberturas = ({ titulo, coberturas, children, CoberturaEmpresas }: Props) => {
    return (
        <div className="py-5">
            <div className="container">
                {
                    (titulo && !children) &&
                    <div className="col-10 mx-auto">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-30 mb-lg-35">
                            {titulo}
                        </h2>
                    </div>
                }
                {children}
                {CoberturaEmpresas === true ?
                    <div className="d-flex flex-wrap column-gap-md-3 row-gap-30p justify-content-center mx-auto text-center">
                        {
                            coberturas.map(cob => (
                                <a key={cob.label} className="item-seguro-alt" href={cob.link}>
                                    <span className="icon-seguros-alt-wrapper">
                                        <svg className="svg-icon icon-seguros-alt"><use xlinkHref={cob.icon}></use></svg>
                                    </span>
                                    <span className="d-block fs-13 lh-1 fw-bold text-black">
                                        {cob.label}
                                    </span>
                                </a>
                            ))
                        }
                    </div>
                    :
                    <div className="d-flex flex-wrap column-gap-md-3 column-gap-lg-5 row-gap-30p justify-content-center mx-auto text-center maxw-lg-920p">
                        {
                            coberturas.map(cob => (
                                <a key={cob.label} className="item-seguro-alt" href={cob.link}>
                                    <span className="icon-seguros-alt-wrapper">
                                        <svg className="svg-icon icon-seguros-alt"><use xlinkHref={cob.icon}></use></svg>
                                    </span>
                                    <span className="d-block fs-13 fs-lg-18 lh-1 fw-bold text-black">
                                        {cob.label}
                                    </span>
                                </a>
                            ))
                        }
                    </div>}
            </div>
        </div>
    )
}
