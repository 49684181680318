import React from 'react'
import { SegTooltip } from '../SegUI/SegTooltip/SegTooltip'

interface SegFormEmisionContainerProps {
    children: JSX.Element[] | JSX.Element;
    children2?: JSX.Element[] | JSX.Element;
    title: string;
    tooltip?: string;
}

export const SegFormEmisionContainer = ({ children, title, tooltip, children2 }: SegFormEmisionContainerProps) => {

    return (
        <div className="form-wrapper">
            <div className="container-fluid g-0 g-md-5">
                <div className="row row-gap-3">
                    <div className="col-12">
                        {
                            children2 ?
                                <div className="border-bottom border-2 border-primary d-flex align-items-center">
                                    <div className='col-6 fs-18 fs-lg-24 fw-bold text-primary'>
                                        {title}
                                        {tooltip &&
                                            <SegTooltip text={tooltip} />
                                        }
                                    </div>
                                    <div className='col-6 d-flex align-items-end justify-content-end'>
                                        {children2}
                                    </div>
                                </div>
                                :
                                <div className="fs-18 fs-lg-24 fw-bold text-primary border-bottom border-2 border-primary d-flex align-items-center">{title}
                                    {tooltip &&
                                        <SegTooltip text={tooltip} />
                                    }
                                </div>
                        }
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}
