import { useEffect, useState, Suspense } from "react";
import { SegHeaderLogo } from "../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import { SegStepper } from "../../Components/SegUI/SegStepper/SegStepper";
import segurarse from "../../Assets/img/logo_segurarse.svg";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import obtenerParametros from "../../Services/API/Parametros";
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';
import { useDescripcionesStore } from "../../Services/Providers/DescripcionesStore";
import { TelefonosCompañia, telefonos } from "../../Assets/telefonosCompanias";
import obtenerDescripciones from "../../Services/API/Descripciones";
import useRubroStore from "../../Services/Providers/RubrosStore";
import FormStep from "../../Components/Multicotizador/FormStep";
import { RubroTypes } from '../../types';
import SegCookiesConsent from "../../Components/SegCookiesConsent/SegCookiesConsent";
import { Gracias } from "../../Components/Multicotizador/Gracias";
import { guardarContactoIndex } from "../../Services/API/IndexGuardadoContacto";
import useSEOConfig from "../../hooks/seo/useSEOConfig";
import { ImagenesCompanias, Companias } from '../../Assets/indexCompanias';

export interface IPasos {
  step: number;
  subStep: number;
}

export interface CotizadorProps {
  step?: number;
  subStep?: number;
}

const PageContainer = styled.div`
`;

const Content = styled.div`
`;

export const Cotizador = (props: any) => {
  const nav = useNavigate();
  const [rubro, setRubro] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const { compania, tipoPersona, rubroURL, stepId } = useParams();
  const { getFormularioPorRubroYTipoPersona, getRubroPorNombre } = useRubroStore()
  const [stepActual, setStepActual] = useState<RubroTypes.Step>()
  const [vistaGracias, setVistaGracias] = useState<RubroTypes.GraciasViewDetail>()
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [flowData, setFlowData] = useState<RubroTypes.Step[]>();
  const [companias, setCompanias] = useState<Companias[]>();
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [paramsCargando, setParamsCargando] = useState(false);
  const [phones, setPhones] = useState<TelefonosCompañia[]>(telefonos);
  const [justifyContent, setJustifyContent] = useState(
    "justify-content-center"
  );
  const {
    descripcionesValues,
    setDescripcionesValues,
  } = useDescripcionesStore();
  const Rubro = getRubroPorNombre(rubro?.toLocaleUpperCase() || '');

  const Icon = styled.svg`
    color: #343434 !important;
  `;
  const referer = document.referrer;
  const landingUrl = document.location.href;

  useEffect(() => {
    if (props.rubro || rubroURL) {
      setRubro(props.rubro || rubroURL);
    }
  }, [props.rubro, rubroURL])


  useEffect(() => {
    if (!cotizacionValues.parametrosIniciales) {
      setParamsCargando(true);
      const fetchDataParams = async () => {
        const response: any = await obtenerParametros({ rubro: rubro?.toLocaleUpperCase() || "" });
        if (response) {
          setCotizacionValues({
            ...cotizacionValues,
            parametrosIniciales: response.data,
            referer: referer,
            landingUrl: landingUrl,
          });
        }
      };

      fetchDataParams().then(() => {
        setParamsCargando(false);
      });
    }
  }, []);

  useSEOConfig({
    title: Rubro?.title,
    canonicalUrl: cotizacionValues.landingUrl,
  });

  useEffect(() => {
    if (rubro) {
      const data = getFormularioPorRubroYTipoPersona(rubro, tipoPersona);
      if (data.length < 1) {
        nav(`/${rubro}/error`);
      } else {
        setFlowData(data);
        setCompanias(ImagenesCompanias(rubro.toLocaleUpperCase()))
      }
    }
  }, [rubro, tipoPersona]);

  useEffect(() => {
    if (flowData && flowData.length > 0) {
      let IndexFromUrlStepId = stepId ? flowData.findIndex(step => step.stepId === stepId.toLowerCase()) : 0;

      if (IndexFromUrlStepId === -1 && stepId !== 'gracias') {
        console.warn(`StepId ${stepId} no encontrado. Se establece el índice a 0.`);
        IndexFromUrlStepId = 0;
      }

      if (!checkPreviousStepCompletion(IndexFromUrlStepId)) {
        const previousStepId = flowData[IndexFromUrlStepId - 1].stepId;
        nav(`/${rubro}/cotizacion/${tipoPersona ? tipoPersona : "individuo"}/${previousStepId}`);
        return;
      }

      const IndexCurrentStep = flowData.findIndex(step => step.stepId === stepActual?.stepId)

      if (IndexCurrentStep !== IndexFromUrlStepId) {
        setCurrentStepIndex(IndexFromUrlStepId);
        setStepActual(flowData[IndexFromUrlStepId]);
      }
    }
  }, [stepId, flowData]);


  const updateUrl = (index: number) => {
    if (flowData && flowData[index]) {
      const currentStepId = flowData[index].stepId;
      const newUrl = `/${rubro}/cotizacion/${tipoPersona ? tipoPersona : "individuo"}/${currentStepId}`
      nav(newUrl)
    }
  };

  const isFieldComplete = (previousStepId: string, fieldName: string, value: any): boolean => {
    if (typeof value === 'object' && value !== null) {

      switch (previousStepId) {
        case 'telefono-celular':
          return value.prefijo && value.numero && value.prefijo.trim() !== '' && value.numero.trim() !== '';
        case 'fechaNacimiento':
          return value.dia && value.mes && value.anio && value.dia.trim() !== '' && value.mes.trim() !== '' && value.anio.trim() !== '';
        default:
          return Object.values(value).every(v => v !== null && v !== undefined && v !== '');
      }
    }
    return value !== null && value !== undefined && value !== '';
  };

  const checkPreviousStepCompletion = (currentIndex: number) => {
    if (currentIndex === 0 || !flowData) return true;

    const previousStep = flowData[currentIndex - 1];
    const requiredFields = previousStep?.fields.filter(field => field.rules.validate.required);

    if (requiredFields) {
      for (const field of requiredFields) {
        let fieldValue;
        if (field.name === 'telefono' || field.name === 'prefijo' || field.name === 'numero') {
          fieldValue = cotizacionValues.telefono;
        } else {
          fieldValue = (cotizacionValues as any)[field.name];
        }

        if (!isFieldComplete(previousStep.stepId, field.name, fieldValue)) {
          return false;
        }
      }
      return true;
    }
    return true;
  };
  const goToNextStep = () => {
    if (flowData && currentStepIndex < flowData.length - 1) {
      const nextIndex = currentStepIndex + 1;

      if (!checkPreviousStepCompletion(nextIndex)) {
        const previousStepId = flowData[nextIndex - 1]?.stepId;
        nav(`/${rubro}/cotizacion/${tipoPersona ? tipoPersona : "individuo"}/${previousStepId}`);
        return;
      }

      setCurrentStepIndex(nextIndex);
      setStepActual(flowData[nextIndex]);
      updateUrl(nextIndex);
    }
  };

  const obtenerTokenRecaptcha = (key: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
          setCotizacionValues({
            ...cotizacionValues,
            captchaToken: token,
          })
          resolve(token)
        });
      });
    });
  };

  const onSubmit = (e: Event) => {
    e.preventDefault()
    
    setIsLoading(true)
    const key = process.env.REACT_APP_RECAPTCHA_KEY
    obtenerTokenRecaptcha(key).then((token: string) => {
      if (!cotizacionValues?.contactoId && Rubro) {
        const fetchData = async () => {
          const response: any = await guardarContactoIndex(Rubro?.id, {
            ...cotizacionValues, captchaToken: token,
          });
          if (response?.data) {
            if (response.data.api_status === 200 && response.data.api_data !== null) {
              setCotizacionValues({
                ...cotizacionValues,
                contactoId: response.data.api_data.idContacto,
                token: response.data.api_data.token,
                captchaToken: token,
              });

              if (flowData && (flowData[currentStepIndex].nextStep === "gracias" || flowData[currentStepIndex].nextStep === "contactoGuardado")) {
                const GraciasData: RubroTypes.GraciasView = Rubro?.vistaGracias || {};
                const tipoPersonaKey: RubroTypes.TipoPersona = (tipoPersona ? tipoPersona.toLocaleUpperCase() : "FISICA") as RubroTypes.TipoPersona;
                const isValidKey = (key: string): key is keyof RubroTypes.GraciasView =>
                  key === "FISICA" || key === "JURIDICA";

                if (isValidKey(tipoPersonaKey) && GraciasData[tipoPersonaKey]) {
                  setVistaGracias(GraciasData[tipoPersonaKey]!);  // Non-null assertion
                  localStorage.setItem('vistaGracias', JSON.stringify({ viewDetail: GraciasData[tipoPersonaKey] }));
                  // nav(`/${rubro}/cotizacion/${tipoPersona || "individuo"}/gracias`);
                  nav(`/resultado-del-cotizador-de-${rubro}`);
                  setIsLoading(false)
                } else {
                  if (GraciasData.FISICA) {
                    setVistaGracias(GraciasData.FISICA);
                    localStorage.setItem('vistaGracias', JSON.stringify({ viewDetail: GraciasData.FISICA }));
                    nav(`/resultado-del-cotizador-de-${rubro}`);
                    setIsLoading(false)
                  }
                }
              }
            } else if (response.data.api_data === "El Captcha no es válido") {
              setIsLoading(false)
              const newUrl = `/${Rubro.id}/cotizacion/${tipoPersona ? tipoPersona : "individuo"}/${stepActual?.stepId}?error=El Captcha no es válido`
              compania ? nav(`${newUrl}/${compania}`) : nav(newUrl)
            }
          } else {
            console.error("Error al guardar contacto")
          }
        }

        fetchData();
      }
    })
  };



  useEffect(() => {
    function ajustarJustifyContent() {
      if (window.innerWidth >= 768) {
        setJustifyContent("col-md-3 text-md-end align-self-center");
      } else {
        setJustifyContent(
          "d-flex align-items-center justify-content-center mt-3"
        );
      }
    }

    ajustarJustifyContent();
    window.addEventListener("resize", ajustarJustifyContent);

    return () => {
      window.removeEventListener("resize", ajustarJustifyContent);
    };
  }, []);

  useEffect(() => {
    if (
      cotizacionValues.parametrosIniciales &&
      descripcionesValues?.length === 0
    ) {
      const fetchDataDesc = async (
        rubro: string,
        origen: string,
        tipoCotizador: string
      ) => {
        const response: any = await obtenerDescripciones(
          rubro,
          origen,
          tipoCotizador
        );
        if (response) {
          setDescripcionesValues(response.data.api_data);
        }
      };
      fetchDataDesc(
        cotizacionValues.parametrosIniciales.rubro,
        cotizacionValues.parametrosIniciales.origen,
        cotizacionValues.parametrosIniciales.tipoCotizador
      );
    }
  }, [cotizacionValues.parametrosIniciales]);

  const tel = phones.filter((t: TelefonosCompañia) => {
    if (t.clave === compania) {
      return t;
    }
  });

  if (!flowData) return <div>Cargando...</div>;

  return (
    <>
      {stepId === "gracias" || props.resultados ?
        <Gracias viewDetail={vistaGracias} />
        :
        <PageContainer>
          {/* {(stepId !== "gracias" && props.resultados !== true) ? <> */}
          <div className="sticky-top bg-white">
            <div className="container pt-3 pb-3">
              <div className="row">
                <div className="col-md-6 offset-md-3 text-center">
                  {/* Centra el contenido en la columna */}
                  <SegHeaderLogo
                    img={segurarse}
                    alt="Logo Segurarse"
                    width="160px"
                  />
                </div>
                {compania && tel.length > 0 && (
                  <div className={`${justifyContent}`}>
                    <a
                      href={"tel:" + tel[0]?.telefono}
                      className="tel align-items-center gap-2"
                    >
                      <Icon className="icon">
                        <use xlinkHref="#tel" />
                      </Icon>
                      {tel[0]?.telefono}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <SegStepper
              icon={Rubro?.iconoStepper || ''}
              finalStep={Rubro?.iconoFinalStepper || '$'}
              label={Rubro?.headerTitle || ''}
              stepActive={flowData[currentStepIndex] ? flowData[currentStepIndex].step_position : 1}
              isStepper={true}
            />
          </div>
          {/* </> */}
          {/* : <></>} */}
          {paramsCargando ? (
            <div className="d-flex justify-content-center py-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <Content>
              <div className="">
                <Suspense fallback={<div>Cargando...</div>}>
                  {stepActual ?
                    <FormStep stepData={stepActual} handleContinue={goToNextStep} handleOnSubmit={onSubmit} values={cotizacionValues} isLoading={isLoading} companias={companias || []} /> : <></>}
                </Suspense>

              </div>
            </Content>
          )}
          <SegCookiesConsent />
        </PageContainer >
      };
    </>
  );
};

