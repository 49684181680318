import Axios from 'axios'
import { getPathName } from './helper';

const obtenerBancos = async () => {
    const params = {
        hash: process.env.REACT_APP_HASH,
    }
    const headers = {
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getBancosJSON", params,{headers: headers})
            .then(function (response) {
            return response.data.bancos;
        }).catch(function (error) {
            return error;
        });
};

export default obtenerBancos;