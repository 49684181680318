import { ReactElement } from 'react'
import { convertirARomano } from '../../../../helpers/numeroRomanos';

const textosTermiosYCondiciones: { titulo: string; contenido: ReactElement<any> }[] = [
    {
        titulo: "Términos y Condiciones",
        contenido: <>
            El solicitante (en adelante, el “Solicitante”) declara conocer que el sitio <a href={"https://segurarse.com.ar"} target="_blank" rel="noreferrer" className="policy-button">Segurarse.com.ar</a> (en adelante, el “Sitio Web”) pertenece a SEGURARSE Asesores de Seguros S.A., con domicilio fiscal en Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos Aires, República Argentina, con Matrícula Nro. 1174, expedida por la Superintendencia de Seguros de la Nación (<a href={"http://www.ssn.gob.ar"} target="_blank" rel="noreferrer" className="policy-button">http://www.ssn.gob.ar</a>) (en adelante, “SEGURARSE”).<br /><br />
            El Solicitante declara conocer que la actividad de SEGURARSE se encuadra dentro del régimen de la Ley Nro. 22.400 de Regulación de la Actividad del Productor Asesor de Seguros. En consecuencia, el Solicitante manifiesta conocer que SEGURARSE no reviste el carácter de compañía de seguros. El Solicitante declara conocer que las coberturas solicitadas inician su vigencia recién a partir de la recepción por mail de la póliza emitida por la aseguradora escogida y luego de aprobada la inspección del riesgo asegurable. Asimismo, declara conocer que la póliza emitida por la Compañía de Seguros podrá ser modificada y/o anulada por la aseguradora por decisión exclusiva de ésta, sin que ello implique responsabilidad alguna, bajo ningún concepto, de SEGURARSE.<br /><br />
            El Solicitante declara y garantiza que los datos, afirmaciones, estado y características del bien sobre el cual efectúa la Solicitud de Cobertura y demás particularidades denunciadas a través de los formularios puestos a su disposición a través del Sitio Web y/u otros datos que podría haber facilitado el Solicitante a SEGURARSE por cualquier otro medio, son completos, veraces, exactos, exhaustivos y actualizados en todos sus aspectos.<br /><br />
            El Solicitante declara y garantiza que no ha incurrido en omisiones, ni mentido, respecto de ninguna de las declaraciones y/o circunstancias conocidas por él al momento de efectuar la Solicitud de Cobertura. Cualquier omisión o falsedad, aún hecha de buena fe, de circunstancias conocidas por el Solicitante al momento de efectuar la Solicitud de Cobertura, será considerada reticencia en los términos previstos en el Artículo 5 y subsiguientes de la Ley 17.418, y será pasible de hacer nula a la Póliza. Asimismo, el Solicitante deberá informar cualquier cambio en el riesgo asegurado con posterioridad a la aceptación de cobertura, también bajo pena de nulidad de la Póliza, de acuerdo a lo previsto en el Artículo 37 y siguientes de la Ley 17418. SEGURARSE no será responsable por los datos y/o informaciones y/o declaraciones erróneas y/o falsas y/o incorrectas y/o imprecisas que le comunique el Solicitante, debiendo el Solicitante mantener indemne e indemnizar a SEGURARSE por cualquier daño que SEGURARSE pudiera sufrir como consecuencia de la utilización de los datos y/o informaciones y/o declaraciones erróneas y/o falsas y/o incorrectas y/o imprecisas.<br /><br />
            Se deja expresamente constancia de que, en el supuesto en que la Póliza solicitada por el Solicitante recayere sobre un vehículo, la cobertura brindada por la Póliza respectiva no contemplará, ni alcanzará, a ninguna clase de elemento y/o accesorio y/o dispositivo y/o agregado que no fuese el original de fábrica.<br /><br />
            El Solicitante deja constancia que al enviar la Solicitud de Cobertura con los datos informados (incluyéndose, a modo de ejemplo, nombre, D.N.I., datos de tarjetas de crédito, etc.) autoriza expresamente a SEGURARSE a aportar dichos datos al Asegurador. Consecuentemente, el Solicitante declara conocer que esos datos serán almacenados y/o tratados exclusivamente por el Asegurador, por lo que SEGURARSE no se hace responsable de la seguridad y/o tratamiento que el Asegurador le brinde a esos datos.<br /><br />
            Los seguros a ser contratados por el Solicitante se encuentran sujetos a los términos y condiciones de sus pólizas respectivas. En este sentido, el Solicitante declara que, habiendo sido suficientemente informado acerca de las alternativas de cobertura que se le ofrecen a través del Sitio Web y/u otros medios que pudiese haber puesto a su disposición SEGURARSE, las ha entendido, y declara conocer las Condiciones Generales, las Condiciones Particulares y las Condiciones Especiales que regirán el seguro solicitado contenidos en la Póliza, así como el alcance de su cobertura, limitaciones, franquicias, deducibles, cargas y exclusiones, y de los derechos y obligaciones que asumirá en caso de que la presente Solicitud de Cobertura sea aceptada por el Asegurador.<br /><br />
            La Póliza entregada por SEGURARSE al Solicitante no surtirá sus efectos sino hasta que sea aceptada por parte de los Aseguradores. Dicha aceptación se encontrará subordinada a los resultados de la revisión y/o inspección y/o verificación, por parte de los Aseguradores, del bien o bienes sobre los cuales fue efectuada la Solicitud de Cobertura, y/o la comprobación de otras cuestiones que pudiesen requerir los Aseguradores, según los datos y/o informaciones y/o declaraciones efectuadas por el Solicitante al momento de efectuar la Solicitud de Cobertura. El método de revisión y/o inspección y/o verificación y/o comprobación será el que establezcan los Aseguradores para cada caso, no teniendo SEGURARSE responsabilidad alguna al respecto.<br /><br />
            Las Póliza podrá ser rechazada y/o sufrir modificaciones por los Aseguradores en base al resultado de la revisión y/o inspección y/o verificación. En cualquier caso, el Solicitante declara conocer que tanto el rechazo, como la modificación de la Póliza, no traerán aparejada responsabilidad alguna de SEGURARSE, de ningún tipo, bajo ningún concepto.<br /><br />
            Asimismo, el Solicitante declara conocer y aceptar que cualquier error que se produzca en el sistema al efectuar la Solicitud de Cobertura, que impida que el proceso de emisión de la Póliza o certificado de cobertura sea completado, no traerán aparejada responsabilidad alguna de SEGURARSE, de ningún tipo, bajo ningún concepto. De ocurrir este supuesto, y como consecuencia de él, hasta tanto el Solicitante no complete satisfactoriamente el proceso de Solicitud de Cobertura, el Solicitante no gozará de cobertura alguna, manifestando tener pleno conocimiento de tal situación.<br /><br />
        </>
    },
    {
        titulo: "Misceláneas",
        contenido: <>
            Si alguna disposición de este Aviso Legal fuera declarada por inválida, ilegal o inaplicable por una autoridad competente, tal disposición será considerada por separado de este Aviso Legal y las demás disposiciones permanecerán en plena vigencia.<br /><br />
            SEGURARSE podrá modificar el contenido del presente Aviso Legal en cualquier momento, con la finalidad de adecuarlo a futuros cambios legislativos o tecnológicos.<br /><br />
            Estas modificaciones se considerarán que han sido eficazmente notificadas y son válidas desde su publicación en el Sitio Web.<br /><br />
            La contratación de los servicios o productos sobre los que se informa en el Sitio Web, sólo puede realizarse por personas mayores de edad.<br /><br />
            La demora u omisión de SEGURARSE en exigir el estricto cumplimiento de este Aviso Legal no podrá interpretarse como renuncia a sus derechos.<br /><br />
        </>
    },
    {
        titulo: "Ley Aplicable y Jurisdicción",
        contenido: <>
            El presente Aviso Legal se regirá e interpretará de acuerdo con las leyes de la República Argentina.<br /><br />
            En caso de conflicto, dificultad o controversia respecto de cualquier materia del presente Aviso Legal, éste será sometido a la jurisdicción de los Tribunales y Juzgados de la Ciudad Autónoma de Buenos Aires, con expresa renuncia de cualquier otra que pudiere corresponder
        </>
    },

]

interface Props {
    children: JSX.Element;
    showChildren?: boolean;
}

export const ModalTerminosYCondiciones = ({ children, showChildren = true }: Props) => {
    return (
        <>
            <a data-bs-toggle="modal" href={"#termModal"} role="button" className='label-color hover-underline'>
                Acepto los Términos y Condiciones
            </a>
            <div className="modal" id="termModal" aria-hidden="true" tab-index="-1">
                <div className="modal-dialog modal-dialog modal-dialog-scrollable modal-xl">
                    <div className={`modal-content ${window.innerWidth < 768 ? "p-1" : "p-4"}`}>
                        <div className="modal-header pb-2">
                            <div className='w-100 d-flex justify-content-between modal-wpp'>
                                <h4 className={`ff-mukta ${window.innerWidth < 768 ? "fs-18" : "fs-16 mb-2"} fs-lg-30 fw-bold lh-sm text-primary`}>Términos y condiciones</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>

                        <div className="modal-body">
                            {textosTermiosYCondiciones.map((item, i) => (
                                <div key={item.titulo}>
                                    <h5>{
                                        convertirARomano(i + 1)
                                        + ".- " +
                                        item.titulo
                                    }</h5>
                                    <p className="my-2 fs-13 fs-lg-14 lh-sm">
                                        {item.contenido}
                                    </p>
                                </div>
                            ))}

                            {showChildren &&
                                <div>
                                    {children}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
