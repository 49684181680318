import React, { useState } from 'react'


interface SegToggleProps {
  label: string,
  idLabel: string
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

export const SegToggle = ({
  label,
  idLabel,
  isChecked,
  onChange
}: SegToggleProps) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };


  return (
    <div className="form-check form-switch form-check-reverse form-check-inline">
      <input className="form-check-input" type="checkbox"
        id={idLabel}
        checked={isChecked}
        onChange={handleChange}
      />
      <label className="form-check-label fs-14" htmlFor={idLabel}>{label}</label>
    </div>
  )
}