import Axios from "axios";
import { getPathName } from "./helper";

const obtenerDescripciones = async (rubro: string, origen: string, tipoCotizador: string) => {
  const params = {
    hash: process.env.REACT_APP_HASH,
    rubro: rubro,
    origen: origen,
    tipoCotizador: tipoCotizador
  };
  const headers = {
    "content-type": "application/json",
    Authorization: "Bearer " + process.env.REACT_APP_TOKEN,
  };

  return await Axios.post(
    getPathName() +
      "/getdescripcionesjson?random=" +
      (Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000),
    params,
    {
      headers: headers,
      //timeout: process.env.REACT_APP_TIMEOUT
    }
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // Manejar el error aquí
      console.error("Error en la solicitud", error);
    });
};

export default obtenerDescripciones;
