import { useEffect, useState } from 'react'
import { IEmisionValues, IFechaNacimiento } from '../../../../Services/Providers/EmisionStore';
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SegInput } from '../../../SegUI/SegInput/SegInput';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import styled from '@emotion/styled';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';


const DivError = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

const StyledLink = styled(Link)`
    padding: 10px;
    padding-left:25px;
    padding-right:25px;
    width: 100%;
    color: #2C7DFD;
    background-color: #ffffff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 5px;
`;
interface Props {
    emisionValues: IEmisionValues;
    setEmisionValues: (values: IEmisionValues) => void;
}

interface IDatosDelAsegurado {
    nombre: string;
    apellido: string;
    profesion: string;
    fechaNac: IFechaNacimiento;
    genero: string;
    numDoc: string;
}

interface IDocumento {
    tipoDoc: string;
    numero: string;
    profesion: string;
}
const tiposDoc = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuil', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' },
];

const tiposGeneros = [
    { value: 'masculino', label: 'Masculino' },
    { value: 'femenino', label: 'Femenino' },
];


export const DatosBeneficiario = ({ emisionValues, setEmisionValues }: Props) => {
    const { handleSubmit, control, setValue, formState, trigger } = useForm<IDatosDelAsegurado>();
    const { cotizacionValues } = useCotizacionStore();
    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [errorNumero, setErrorNumero] = useState<boolean>(true)
    const nav = useNavigate();
    const { compania } = useParams();
    const methods = useForm<IDocumento>();


    useEffect(() => {
        if (emisionValues.accidentesPersonales?.beneficiario) {
            const { beneficiario } = emisionValues.accidentesPersonales

            setValue("nombre", beneficiario.nombre);
            setValue("apellido", beneficiario.apellido);
            setTipoDoc(tiposDoc.find(t => t.value === beneficiario.tipoDoc));
            setValue("numDoc", beneficiario.numDoc);
            setValue("fechaNac.dia", beneficiario.fechaNac.dia);
            setValue("fechaNac.mes", beneficiario.fechaNac.mes);
            setValue("fechaNac.anio", beneficiario.fechaNac.anio);
            trigger()
        }
    }, [])

    const onSubmit: SubmitHandler<IDatosDelAsegurado> = (values: IDatosDelAsegurado) => {
        const { nombre, apellido, numDoc, fechaNac } = values;
        setEmisionValues({
            ...emisionValues,
            accidentesPersonales: {
                ...emisionValues.accidentesPersonales,
                beneficiario: {
                    nombre,
                    apellido,
                    numDoc,
                    tipoDoc: tipoDoc?.value as any,
                    fechaNac
                }
            }
        })
        compania
            ? nav("/ap/emision/" + compania + "/datos-tarjeta") : nav("/ap/emision/datos-tarjeta");
    }

    return (
        <div>
            <div className="d-lg-none">
                <StyledLink to="" data-bs-target="#offcanvas" data-bs-toggle="collapse" className='btn1-md d-block fs-15 maxw-md-475p mx-md-auto' >Ver detalle de cobertura</StyledLink>
            </div>
            <div className="d-none d-lg-block">
                <SegCoberturaInfo emisionValues={emisionValues} tipo='ACCIDENTES PERSONALES' />
            </div>
            <div className='mt-2 px-20 pb-40 pb-lg-60 maxw-1170p mx-auto'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Datos del beneficiario' tooltip='A definir'>
                        <div className="col-md-6">
                            <Controller
                                name="nombre"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el nombre",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Nombre inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Nombre"
                                        name="nombre"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Juan"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("nombre");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-6">
                            <Controller
                                name="apellido"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el apellido",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Apellido inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Apellido"
                                        name="apellido"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: López"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("apellido");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Tipo documento</label>
                            <FormProvider {...methods}>
                                <SegSelect
                                    label=""
                                    selected={tipoDoc}
                                    setSelected={setTipoDoc}
                                    name="tipoDoc"
                                    options={tiposDoc}
                                    isGrupable
                                    width={"100%"}
                                    //borderColor={errors.tipoDoc ? "red" : ""}
                                    openDefault={false}
                                    autoFoco={true}
                                    placeholder=""
                                />
                            </FormProvider>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Número de documento</label>
                            <Controller
                                name="numDoc"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                setErrorNumero(true)
                                                return "Ingresá el Número"
                                            } else {
                                                setErrorNumero(false)
                                            }
                                        },
                                        pattern: (value) => {
                                            if (tipoDoc?.value === 'dni') {
                                                if (/^\d{7,8}$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "DNI inválido"
                                                }
                                            } else if (tipoDoc?.value === 'cuit' || tipoDoc?.value === 'cuil') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return `${tipoDoc?.label} inválido`
                                                }
                                            }
                                        }
                                    },
                                }}
                                render={({ field, fieldState }) => (

                                    <SegInput
                                        disabled={!tipoDoc}
                                        className="col-8"
                                        label=""
                                        name="setNumero"
                                        type="number"
                                        width={100}
                                        placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                        value={field.value}
                                        autoFocus={false}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("numDoc", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("numDoc");
                                        }}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors?.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Fecha de nacimiento</label>
                            <div className='d-flex align-items-end gap-1 justify-content-between'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.dia"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    if (!value) {
                                                        return "Día debe incluir 2 dígitos";
                                                    }
                                                    const parsedValue = parseInt(value, 10);
                                                    if (isNaN(parsedValue) || parsedValue > 31) {
                                                        return "Día no puede ser mayor a 31";
                                                    }
                                                    if (isNaN(parsedValue) || parsedValue <= 0) {
                                                        return "Día no puede ser 0";
                                                    }
                                                    const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                    if (isSingleDigitWithoutZero) {
                                                        return "Día debe incluir 2 dígitos";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Dia"
                                                type="number"
                                                width={100}
                                                placeHolder="Día"
                                                value={field.value}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.dia", trimmedValue);
                                                    trigger("fechaNac.dia");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.mes"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    if (!value) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    const parsedValue = parseInt(value, 10);
                                                    if (isNaN(parsedValue) || parsedValue > 12) {
                                                        return "Mes no puede ser mayor a 12";
                                                    }
                                                    if (isNaN(parsedValue) || parsedValue <= 0) {
                                                        return "Día no puede ser 0";
                                                    }
                                                    const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                    if (isSingleDigitWithoutZero) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Mes"
                                                type="number"
                                                width={100}
                                                placeHolder="Mes"
                                                value={field.value}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.mes", trimmedValue);
                                                    trigger("fechaNac.mes");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.anio"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) =>
                                                    !!value || "Ingresá el año de nacimiento",
                                                pattern: (value) =>
                                                    /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Anio"
                                                type="number"
                                                placeHolder="Año"
                                                value={field.value}
                                                autoFocus={false}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.anio", trimmedValue);
                                                    trigger("fechaNac.anio");
                                                }}
                                                width={100}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {formState.errors.fechaNac &&
                                <DivError>
                                    {[formState.errors.fechaNac.dia?.message, formState.errors.fechaNac.mes?.message, formState.errors.fechaNac.anio?.message]
                                        .filter(Boolean) // Filtramos valores nulos o indefinidos
                                        .join(', ')}
                                </DivError>
                            }

                        </div>
                        <div className={"col-12"}>
                            <SegButton label='Siguiente' type='submit' disabled={!formState.isValid} className={window.innerWidth < 768 ? 'w-100' : 'fs-15'} />
                        </div>
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div>
    )
}
