import CodigosDeArea from '../../Assets/CodigosDeArea.json';

const rubros = [
    {
        "id": "CELULAR",
        "nombre": "Celular",
        "steps": {
            "FISICA": [
                    {
                    "stepId": "nombre-apellido",
                    "step_position": 1,
                    "nextStep": "telefono-celular",
                    "section": "",
                    "subtitle": "Contratá tu seguro fácil y rápido al mejor precio",
                    "brand_carrousel": "true",
                    "fields": [
                        {
                            "name": "nombre",
                            "type": "text",
                            "label": "¿Cúal es tu nombre?",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el nombre",
                                    "pattern": (value) => /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) || "Nombre inválido"
                                }
                            }
                        },
                        {
                            "name": "apellido",
                            "type": "text",
                            "label": "¿Y tu apellido?",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el apellido",
                                    "pattern": (value) => /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) || "Apellido inválido"
                                }
                            }
                        }
                    ]
                }, {
                    "stepId": "telefono-celular",
                    "step_position": 2,
                    "nextStep": "email",
                    "section": "¿Tu teléfono celular?",
                    "subtitle": "",
                    "fields": [
                        {
                            "name": "prefijo",
                            "type": "number",
                            "label": "",
                            "helper": "Cód. área",
                            "placeholder": "11",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el Cód. Área",
                                    "isInValidCodeArea": (value) =>
                                      CodigosDeArea.includes(value) ||
                                      "Cód. Área inválido",
                                  },
                            }
                        },
                        {
                            "name": "numero",
                            "type": "number",
                            "label": "",
                            "helper": "Teléfono (cel sin 15)",
                            "placeholder": "33669955",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá Número de Celular",
                                    "pattern": (value) =>
                                      /^[0-9]{6,9}$/.test(value) || "Número inválido"
                                  },
                            }
                        }
                    ]
                }, {
                    "stepId": "email",
                    "step_position": 2,
                    "nextStep": "gracias",
                    "section": "¿Tu e-mail?",
                    "subtitle": "",
                    "fields": [
                        {
                            "name": "email",
                            "type": "email",
                            "label": "",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => (!!value || "Ingresá el email"),
                                    "pattern": (value) =>
                                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                            value
                                        ) || "Email inválido",
                                }
                            }
                        }
                    ]
                },
            ],
        },
        "vistaGracias": {
            "FISICA": {"campos":{
                "Nombre y apellido": "nombre,apellido",
                "Teléfono": "prefijo,numero",
                "Email": "email",
            }, "contactoWpp": {
                "numero": 5491169568002, 
                "mensaje": "¡Hola! quiero más información del seguro de celular",
                },
                "icon":"ico-smartphone-ok"
        } },
        "title": "Celular",
        "iconoStepper": "",
        "iconoFinalStepper": "art",
        "headerTitle": "Cotizá tu Seguro de Celular",
        "tipoPersona": "FISICA",
    },
    {
        "id": "INTEGRAL-DE-COMERCIO",
        "nombre": "integral-de-comercio",
        "steps": {
            "FISICA": [
                {
                    "stepId": "cuit",
                    "step_position": 1,
                    "nextStep": "actividad",
                    "section": "Protegé tu comercio ante cualquier imprevisto, tenemos planes para que cuides tu inversión y tu actividad comercial.",
                    "subtitle": "Contratá tu seguro fácil y rápido al mejor precio",
                    "brand_carrousel": "true",
                    "fields": [
                        {
                            "name": "cuit",
                            "type": "number",
                            "label": "Ingresá el CUIT",
                            "helper": "Sólo números, sin puntos ni guiones ni espacios",
                            "inputMode": "numeric",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el CUIT",
                                    "pattern": (value) => (/^\d{11}$/.test(value) && /^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) || "CUIT inválido",
                                }
                            }
                        }
                    ]
                }, {
                    "stepId": "actividad",
                    "step_position": 1,
                    "nextStep": "provincia-localidad",
                    "section": "",
                    "subtitle": "¿Qué actividad desarrolla tu comercio?",
                    "fields": [
                        {
                            "name": "actividad",
                            "type": "text",
                            "label": "",
                            "helper": "",
                            "regex": "^[a-zA-Z ]+$",
                            "rules": {
                                "validate": {
                                    "required": (value) => (!!value || "Ingresá la actividad"),
                                    "pattern": (value) =>
                                        /^[a-zA-Z ]+$/.test(
                                            value
                                        ) || "",
                                }
                            }
                        }
                    ]
                }, {
                    "stepId": "provincia-localidad",
                    "step_position": 1,
                    "nextStep": "nombre-apellido",
                    "section": "",
                    "subtitle": "",
                    "fields": [
                        {
                            "name": "provincia",
                            "type": "select",
                            "label": "Seleccioná tu provincia",
                            "helper": "",
                            "placeholder": "Provincia",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá la provincia",
                                },
                            }
                        },
                        {
                            "name": "localidad",
                            "type": "select",
                            "label": "Seleccioná tu localidad",
                            "helper": "",
                            "placeholder": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá la localidad",
                                },
                            }
                        }
                    ]
                }, {
                    "stepId": "nombre-apellido",
                    "step_position": 1,
                    "nextStep": "telefono-celular",
                    "section": "",
                    "subtitle": "",
                    "fields": [
                        {
                            "name": "nombre",
                            "type": "text",
                            "label": "¿Cúal es tu nombre?",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el nombre",
                                    "pattern": (value) => /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) || "Nombre inválido"
                                }
                            }
                        },
                        {
                            "name": "apellido",
                            "type": "text",
                            "label": "¿Y tu apellido?",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el apellido",
                                    "pattern": (value) => /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) || "Apellido inválido"
                                }
                            }
                        }
                    ]
                }, {
                    "stepId": "telefono-celular",
                    "step_position": 2,
                    "nextStep": "email",
                    "section": "",
                    "subtitle": "¿Tu teléfono celular?",
                    "fields": [
                        {
                            "name": "prefijo",
                            "type": "number",
                            "label": "",
                            "helper": "Cód. área",
                            "placeholder": "11",
                            "inputMode": "numeric",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá el Cód. Área",
                                    "isInValidCodeArea": (value) =>
                                      CodigosDeArea.includes(value) ||
                                      "Cód. Área inválido",
                                  },
                            }
                        },
                        {
                            "name": "numero",
                            "type": "number",
                            "label": "",
                            "helper": "Teléfono (cel sin 15)",
                            "placeholder": "33669955",
                            "inputMode": "numeric",
                            "rules": {
                                "validate": {
                                    "required": (value) => !!value || "Ingresá Número de Celular",
                                    "pattern": (value) =>
                                      /^[0-9]{6,9}$/.test(value) || "Número inválido"
                                  },
                            }
                        }
                    ]
                }, {
                    "stepId": "email",
                    "step_position": 2,
                    "nextStep": "gracias",
                    "section": "",
                    "subtitle": "¿Tu e-mail?",
                    "fields": [
                        {
                            "name": "email",
                            "type": "text",
                            "label": "",
                            "helper": "",
                            "rules": {
                                "validate": {
                                    "required": (value) => (!!value || "Ingresá el email"),
                                    "pattern": (value) =>
                                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                            value
                                        ) || "Email inválido",
                                }
                            }
                        }
                    ]
                },
            ],
        },
        "vistaGracias": {
            "FISICA": {
                "campos": {
                "Nombre y apellido": "nombre,apellido",
                "Teléfono": "prefijo,numero",
                "Email": "email",
            }, "contactoWpp": {
                "numero": 5491169568002, 
                "mensaje": "¡Hola! quiero más información del seguro de integral de comercio",
            },
                "icon":"ico-shop"

        } },
        "title": "Integral de comercio",
        "headerTitle": "Cotizá seguro Integral de Comercio",
        "tipoPersona": "JURIDICA",
        "iconoStepper": "",
        "iconoFinalStepper": "art"
    },
    {
        "id": "ACIDENTES-PERSONALES",
        "nombre": "acidentes-personales",
        "steps": {
          "FISICA": [
            {
              "stepId": "tipo-documento",
              "nextStep": "nombre-apellido",
              "title": "Contratá tu seguro fácil y rapido al mejor precio",
              "subtitle": "",
              "section": "Ingresá el numero de documento",
              "brand_carrousel": "",
              "fields": [
                {
                  "name": "tipo",
                  "type": "number",
                  "label": "",
                  "helper": "",
                  "inputMode": "numeric",
                  "rules": {
                    "validate": {}
                  }
                }
              ],
              "step_position": 1
            },
            {
              "stepId": "nombre-apellido",
              "nextStep": "loader",
              "title": "",
              "subtitle": "",
              "section": "",
              "brand_carrousel": "",
              "fields": [
                {
                  "name": "nombre",
                  "type": "text",
                  "label": "¿Cúal es tu nombre?",
                  "helper": "",
                  "tooltip": "Prueba",
                  "rules": {
                    "validate": {}
                  }
                },
                {
                  "name": "apellido",
                  "type": "text",
                  "label": "¿Y tu apellido?",
                  "helper": "",
                  "rules": {
                    "validate": {}
                  }
                }
              ],
              "step_position": 1
            },
          ]
        },
        "title": "Acidentes Personales",
        "iconoStepper": "prepaga",
        "iconoFinalStepper": "art",
        "headerTitle": "Cotizá tu seguro de Accidente Personales",
        "tipoPersona": "AMBOS"
      }
]



export default rubros