import React, { RefObject, useEffect, useState } from "react";
import styled from "@emotion/styled";

interface SegRadioButtonProps {
  classNameContainer?: string;
  classNameUl?: string;
  classNameLi?: string;
  classNameInput?: string;
  classNameLabel?: string;
  inputBorderColor?: string;
  inputBackgroundColor?: string;
  options: { value: string; label: string }[];
  onRadioChange: (selectedValue: string) => void;
  selected?: string;
  width?: string;
  inputRef?: RefObject<HTMLInputElement>;
}

export const SegRadioButton = ({
  width,
  classNameContainer = width ? "m-auto text-start" : "mw-sm-350 m-auto text-start",
  classNameUl = "list-group",
  classNameLi = "list-group-item",
  classNameInput = "form-check-input me-1",
  classNameLabel = "form-check-label",
  inputBorderColor,
  inputBackgroundColor,
  options = [],
  onRadioChange,
  selected,
  inputRef,
}: SegRadioButtonProps) => {
  const StyledInput = styled.input`
    &:checked {
      background-color: ${inputBackgroundColor};
      border-color: ${inputBorderColor};
    }
  `;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    if (selected) {
      setSelectedOption(selected);
      onRadioChange(selected);
    }
  }, [selected])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const selectedValues = event.target.value;
    setSelectedOption(selectedValues);
    onRadioChange(selectedValues);
  };



  return (
    <div className={classNameContainer} style={{ width: width }}>
      <ul className={classNameUl}>
        {options.map(({ value, label }) => (
          <li className={classNameLi} key={value}>
            <StyledInput
              className={classNameInput}
              type="radio"
              value={value}
              name="option"
              checked={selectedOption === value || selected === value}
              onChange={handleRadioChange}
              id={label}
              ref={inputRef}
            />
            <label htmlFor={label} className={classNameLabel} style={{ width: "90%" }}>{label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};
