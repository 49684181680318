import { Link } from 'react-router-dom'
import styled from '@emotion/styled'


interface SegLinkProps {
  text: string
  textColor?: string
  hoverTextColor?: string
  underlinedText: boolean
  hoverUnderlined: boolean
  link: string,
  fontSize?: string,
  fontWeight?: string
  className?:string
}

export const SegLink = ({
  text = "Cotizar sin patente",
  textColor = "rgb(52, 52, 52)",
  hoverTextColor = "#fff",
  underlinedText = false,
  hoverUnderlined = true,
  link = "/",
  fontSize = "16px",
  fontWeight= "500",
  className
}: SegLinkProps) => {

  const StyledLink = styled(Link)`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    cursor: 'pointer';
    color: ${textColor};
    border-bottom: ${underlinedText ? ("1px solid " + textColor) : "none" };
    line-height: 24px;

      &:hover {
        border-bottom: ${hoverUnderlined ? ("1px solid " + textColor) : "none" };
        color: ${hoverTextColor};
      }
    
  `
  return (
      <StyledLink to={link} className={className}>{text}</StyledLink>
  )
}
