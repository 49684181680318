import Axios from 'axios'
import { getPathName } from './helper';

type ContactoPrepagoParams = {
    origen?: string;
    tipoCotizador?: string;
    landingUrl?: string;
    refererUrl?: string;
    tipoDoc?: string;
    cuit?: string;
    numeroDoc?: string;
    razonSocial?: string;
    empleados?: number;
    nombre?: string;
    apellido?: string;
    codigoArea?: string;
    telefono?: string;
    email?: string;
    provincia?: string;
    localidad?: string;
    FechaNacimiento?: { dia: string; mes: string; año: string },
    DerivaAportes?: boolean,
    Conyuge?: boolean,
    SueldoBruto?: number,
    Observaciones?: string,
    DocumentoConyuge?: string,
    UnificaAportes?: boolean,
    SueldoBrutoConyuge?: number
    CantidadHijos?: number
    captchaToken?: string;
};

const guardarContactoPrepaga = async (params: ContactoPrepagoParams) => {
    let data: Record<string, any> = {
        hash: process.env.REACT_APP_HASH,
        cotizacion:
        {
            Nombre: params.nombre ?? null,
            Apellido: params.apellido ?? null,
            TipoDocumento: params.tipoDoc?.toLocaleUpperCase(),
            NroDocumento: params.tipoDoc === "cuit" ? params.cuit : params.numeroDoc,
            EsEmpresa: params.tipoDoc === "cuit" ? true : false,
            RazonSocial: params.razonSocial ?? "",
            CUIT: params.cuit,
            CantidadEmpleados: params.empleados ?? 0,
            Telefono:
            {
                CodigoArea: params.codigoArea ?? null,
                Numero: params.telefono ?? null,
            },
            Email: params.email ?? null,
            FechaNacimiento: params.FechaNacimiento ? params.FechaNacimiento?.dia + "/" + params.FechaNacimiento?.mes + "/" + params.FechaNacimiento?.año : null,
            Provincia: params.provincia,
            Localidad: params.localidad,
            CantidadHijos: params.CantidadHijos ?? 0,
            EsObligatorio: true,
            Observaciones: params.Observaciones ?? "No tiene observaciones adicionales.",
            SueldoBruto: params.SueldoBruto ? 0 : params.SueldoBruto,
            IncluirConyuge: params.Conyuge ?? false,
            NombreConyuge: "",
            ApellidoConyuge: "",
            FechaNacimientoConyuge: null,
            NroDocumentoConyuge: params.DocumentoConyuge ?? null,
            UnificarSueldos: params.UnificaAportes ?? false,
            SueldoBrutoConyuge: params.SueldoBrutoConyuge ?? 0,
            RefererUrl: params.landingUrl
        },
        g_recaptcha_response: params.captchaToken
    };
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/SetCotizacionPlanMedicoJSON", data,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
};

export default guardarContactoPrepaga;