import React, { useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { RubroTypes } from '../../../types';
import { SegSelect, SegSelectOption } from '../../SegUI/SegSelect/SegSelect';
import { SegInput } from '../../SegUI/SegInput/SegInput';

type ReactFormData = {
    control: ReturnType<typeof useForm>['control'];
    setValue: ReturnType<typeof useForm>['setValue'];
    formState: ReturnType<typeof useForm>['formState'];
    trigger: ReturnType<typeof useForm>['trigger'];
    getValues: ReturnType<typeof useForm>['getValues']
    setError: ReturnType<typeof useForm>['setError'];
    watch: ReturnType<typeof useForm>['watch'];
    clearErrors: ReturnType<typeof useForm>['clearErrors'];
};

type InputProps = {
    inputData: {
        stepId: string;
        title?: string;
        subtitle?: string;
        section?: string;
        fields: RubroTypes.Field[];
    };
    ReactFormData?: ReactFormData;
    HandleInputChange?: (value: any) => any;
    currentValues: any;
    //onEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface IDocumento {
    tipoDoc: string;
    numero: string;
}

const tiposDoc = [
    { value: 'dni', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' }
];

export const TipoDocumento = ({
    inputData: { stepId, title, subtitle, section, fields, ...rest },
    ReactFormData, HandleInputChange, currentValues, /*onEnterPress*/
}: InputProps) => {


    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [openSelect, setOpenSelect] = useState<boolean>(false)
    const [errorNumero, setErrorNumero] = useState<boolean>(true)
    const numeroInputRef = useRef<HTMLInputElement>(null);

    const methods = useForm<IDocumento>();

    return (
        <div className="d-flex justify-content-center">
            <div className="row gx-3 text-start mw-sm-450 m-1">
                {
                    ReactFormData ?
                        <>
                            <fieldset className="col-4">
                                <FormProvider {...methods}>
                                    <SegSelect
                                        label="Tipo"
                                        selected={tipoDoc}
                                        setSelected={setTipoDoc}
                                        name="tipoDoc"
                                        options={tiposDoc}
                                        isGrupable
                                        width={"100%"}
                                        //borderColor={errors.tipoDoc ? "red" : ""}
                                        openDefault={openSelect}
                                        autoFoco={true}
                                        placeholder=""
                                    /*handleSubmit={() => {
                                        if (cotizacionValues.razonSocial !== '')
                                            setCotizacionValues({
                                                ...cotizacionValues,
                                                nombre: '',
                                                apellido: '',
                                            })
                                        setNumero('')
                                    }}*/
                                    />
                                </FormProvider>

                            </fieldset>
                            <Controller
                                name="numero"
                                control={ReactFormData.control}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                setErrorNumero(true)
                                                return "Ingresá el Número"
                                            } else {
                                                setErrorNumero(false)
                                            }
                                        },
                                        pattern: (value) => {
                                            if (tipoDoc?.value === 'dni') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "CUIL inválido"
                                                }
                                            } else if (tipoDoc?.value === 'cuit') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "CUIT inválido"
                                                }
                                            }
                                        }
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        disabled={!tipoDoc}
                                        className="col-8"
                                        label="Sin puntos, guiones ni espacios"
                                        name="setNumero"
                                        type="number"
                                        width={66}
                                        placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                        value={field.value}
                                        autoFocus={false}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            ReactFormData.setValue("numero", e.target.value); // Actualiza el valor en React Hook Form
                                            ReactFormData.trigger("numero");
                                        }}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${ReactFormData.formState.errors?.numero
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${ReactFormData.formState.errors.numero
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                        ref={numeroInputRef}
                                    />
                                )}
                            />
                        </>
                        :
                        <></>
                }
            </div>
        </div>
    )
}
