import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';
import { Header as NavBar } from '../Components/Header';
import 'animate.css';
import shape02 from "../../../Assets/img/shape02.svg";
import shape03 from "../../../Assets/img/shape03.svg";
import shape04 from "../../../Assets/img/shape04.svg";
import figura07 from "../../../Assets/img/figura_07.png";
import figura08 from "../../../Assets/img/figura08.svg";
import figura09 from "../../../Assets/img/figura09.svg";
import apertura from "../../../Assets/img/logos-apertura-elcronista.png";
import { useEffect } from 'react';
import { one_team_espiritu } from '../diccionario';

const animateCSS = (element: any, animation: any, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = element;

        node.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event: any) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });

let options = {
    root: null,
    threshold: 0, //0.1 = 10% is visible. 1.0 = 100% is visible
};

let callback = (entries: any, observer: any) => {
    entries.forEach((entry: any) => {

        if (entry.isIntersecting) {
            entry.target.classList.remove('invisible');
            animateCSS(entry.target, entry.target.dataset.animate);
            observer.unobserve(entry.target); //run once
        }
    });
};

export const OneTeam = () => {

    useEffect(() => {
        let observer = new IntersectionObserver(callback, options);

        let targets = document.querySelectorAll("[data-animate]");

        targets.forEach((target) => {
            observer.observe(target);
        });
    }, []);


    return (
        <>
            <SegSvg />
            <NavBar rubro="auto" />
            <main className='bg-white'>
                <div className="bg-f9 pt-45 pb-240 py-lg-80 position-relative bg-hero-04">
                    <img className="d-none d-lg-block pos02" src={shape02} alt='' />

                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-16 mb-lg-25">
                            Acompañanos a {" "}

                            <div className="word-slider word-slider--sm px-2 bg-orange1 rounded text-center text-white">
                                <ul className="word-slider__words word-slider__words--6w">
                                    <li className="word-slider__word word-slider__word--sm">transformar</li>
                                    <li className="word-slider__word word-slider__word--sm">simplificar</li>
                                    <li className="word-slider__word word-slider__word--sm">mejorar</li>
                                    <li className="word-slider__word word-slider__word--sm">potenciar</li>
                                    <li className="word-slider__word word-slider__word--sm">aumentar</li>
                                    <li className="word-slider__word word-slider__word--sm">valorizar</li>
                                    <li className="word-slider__word word-slider__word--sm">transformar</li>
                                </ul>
                            </div>

                            <br />la experiencia aseguradora.</h2>

                        <div className="col-11 col-md-10 col-lg-7 mx-auto mx-lg-0">
                            <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-18">Con la tecnología de nuestro lado, tenemos la misión de facilitar la contratación de seguros y hacer fácil, lo difícil.</p>
                            <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-0">Buscamos perfiles curiosos y apasionados por los desafíos, porque en Segurarse siempre vamos por más.</p>
                        </div>
                    </div>
                </div>

                <div className="pt-30 pt-lg-50 bg-white">
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-12 mb-lg-20">Espíritu Segurarse</h2>
                        <div className="col-11 col-md-10 col-lg-8 mx-auto mb-30 mb-lg-50">
                            <p className="fs-16 fs-lg-20 lh-13 text-black text-center mb-0">Son nuestros valores, los pilares en los cuales nos apoyamos todos los días para dar el mejor servicio, y sobre los cuales nos reconocemos como equipo.</p>
                        </div>
                    </div>
                    <div className="bg-grad-02 pt-3 pt-lg-0 pb-5">
                        <div className="container">
                            <Splide
                                options={{
                                    type: 'loop',
                                    focus: 0,
                                    drag: true,
                                    flickMaxPages: 1,
                                    flickPower: 50,
                                    perPage: 1,
                                    perMove: 1,
                                    // autoplay: true,
                                    // interval: 4000,
                                    arrows: false,
                                    mediaQuery: 'min',
                                    gap: '30px',
                                    padding: '55px',
                                    breakpoints: {
                                        576: {
                                            perPage: 1,
                                            padding: '0',
                                        },
                                        768: {
                                            perPage: 2,
                                        },
                                        992: {
                                            perPage: 4,
                                            drag: false,
                                        },
                                    }
                                }}
                                style={window.innerWidth < 780 ? { width: "100vw" } : {}}
                            >
                                {
                                    one_team_espiritu.map((item) =>
                                        <SplideSlide key={item.titulo}>
                                            <div className="border border-primary border-3 rounded-18 shadow-3 px-20 py-25 bg-white text-center h-100 w-100">
                                                <svg className="svg-icon mb-16" width="90" height="90"><use xlinkHref={item.icon}></use></svg>
                                                <h3 className="fw-bold fs-24 text-black">Somos <br />{item.titulo}</h3>
                                                <p className="fs-18 lh-12 mb-10 ">{item.descripcion}</p>
                                            </div>
                                        </SplideSlide>
                                    )
                                }
                            </Splide>
                        </div>
                    </div>
                </div>


                <div className="pt-30 pt-lg-50 pb-lg-50">
                    <div className="container">

                        <div className="row align-items-end gx-lg-5">
                            <div className="col-11 mx-auto col-lg-6">
                                <h2 className="fw-bold fs-30 fs-lg-40 text-black text-center text-lg-start mb-16">Hay equipo</h2>
                                <p className="fw-bold fs-20 fs-lg-22 text-primary text-center text-lg-start lh-13 mb-18">​Nuestro talento humano nos define como organización.</p>
                                <p className="fs-16 fs-lg-18 text-black text-center text-lg-start mb-20 mb-lg-12"><b>Promovemos el trabajo</b> priorizando el bienestar y una cultura inclusiva. <b>Fomentamos experiencias de aprendizaje</b> innovadoras que permitan a nuestro <span className="fw-bold text-oneteam">#OneTeam</span> adquirir nuevas habilidades para contribuir con el plan de carrera. <b>Nos desafiamos todos los días</b> para dar nuestra mejor versión.</p>
                            </div>
                            <div className="col-lg-6 order-lg-first text-center text-lg-start">
                                <img src={figura08} className="maxw-400p maxw-lg-100"/>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="py-40 py-lg-70 position-relative overflow-hidden">
                    <img className="d-none d-lg-block pos13" src={shape03} />
                    <img className="d-none d-lg-block pos14" src={shape04} />
                    <div className="container">
                        <div className="col-10 col-md-10 col-lg-9 mx-auto mb-30 mb-lg-50">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-12 mb-lg-20">¿Por qué sumarte a nuestro <span className="fw-bold text-oneteam">#OneTeam?</span></h2>
                            <p className="fs-16 fs-lg-20 lh-13 text-black text-center mb-18 mb-lg-50">Buscamos que nuestros colaboradores nos sigan eligiendo y que cada vez más personas se sumen al equipo, por eso te ofrecemos...</p>
                        </div>

                        <div className="d-flex flex-wrap column-gap-3 column-gap-lg-40p row-gap-30p row-gap-lg-35p justify-content-center">

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-01"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Excelente clima de trabajo</h3>
                                <p className="fs-16 fs-lg-20 lh-13">En todas nuestras mediciones de clima interno fue lo que más valorado por nuestro equipo.</p>
                            </div>

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-02"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Desarrollo profesional</h3>
                                <p className="fs-16 fs-lg-20 lh-13">Programas e iniciativas para el desarrollo de carrera.</p>
                            </div>

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-03"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Diversidad e inclusión</h3>
                                <p className="fs-16 fs-lg-20 lh-13">Quienes trabajamos en Segurarse venimos de diferentes lugares, industrias, con distintas experiencias y perspectivas.</p>
                            </div>

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-04"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Potenciamos tus skills</h3>
                                <p className="fs-16 fs-lg-20 lh-13">Oportunidades de capacitación que promueven la formación en conocimientos técnicos, habilidades blandas y de liderazgo.</p>
                            </div>

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-05"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Somos flexibles</h3>
                                <p className="fs-16 fs-lg-20 lh-13">Tenemos un modelo de trabajo híbrido.</p>
                            </div>

                            <div className="maxw-330p rounded-18 shadow-3 px-12 py-4 text-center" data-animate="zoomIn">
                                <svg className="svg-icon icon-oneteam mb-10"><use xlinkHref="#icon-oneteam-06"></use></svg>
                                <h3 className="fs-20 fs-lg-24 fw-bold lh-1 text-primary mb-10">Cultura 100% colaborativa y de puertas abiertas</h3>
                                <p className="fs-16 fs-lg-20 lh-13">Estamos convencidos de que al mejor servicio lo ofrecemos entre todos.</p>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="pt-60 pb-30 pt-lg-20">
                    <div className="container">

                        <div className="bg-f4 rounded-3 px-25 ps-lg-55 pt-90 pb-25 pt-lg-25 maxw-370p maxw-lg-100 mx-auto mx-lg-0 position-relative">
                            <img src={figura09} className="ban-empleador-badge" />
                            <div className="row align-items-center">
                                <div className="col-lg-8">
                                    <p className="fs-20 fs-lg-22 lh-13 text-black mb-15 mb-lg-0 text-center text-lg-start">Reconocidos por ser parte de los 55 Mejores Empleadores Ranking 2023, siendo la <b>única empresa del sector de seguros</b>.</p>
                                </div>
                                <div className="col-lg-4">
                                    <img className="img-fluid" src={apertura} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="bg-inst01">
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center text-lg-start mb-12 mb-lg-20">Un lugar para vos</h2>
                        <p className="fs-16 fs-lg-20 lh-13 text-white text-center text-lg-start mb-0 col-9 col-lg-6 col-xl-5 mx-auto mx-lg-0">Ofrecemos un <b>espacio de trabajo moderno y abierto, sin divisiones</b>. La interacción, el trabajo en equipo y la transparencia son los verdaderos protagonistas en nuestras oficinas.</p>

                    </div>
                </div>


                <div className="overflow-hidden bg-white">
                    <div className="container-lg pt-30 pb-40 py-lg-45 position-relative bg-white">
                        <div className="d-flex flex-column flex-lg-row align-items-center">

                            <div className="px-30 px-lg-0 text-center text-lg-start maxw-560p">

                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-15 mb-lg-30">Sumate a nuestro <span className="fw-bold text-oneteam">#OneTeam</span></h2>

                                <p className="fs-16 fs-lg-20 lh-13 text-black mb-30 mb-lg-20">Si te copan nuestro espíritu y valores, y además tenés ganas de sumarte a un equipo diferente, desafíanos a conocerte. Hacelo contándonos por qué y adjuntando tu CV a
                                    <a className="text-primary fw-bold text-decoration-underline" href="mailto:rrhh@segurarse.com.ar">
                                        rrhh@segurarse.com.ar
                                    </a>
                                </p>

                                <p className="fs-18 fs-lg-24 lh-13 text-black fw-bold mb-15 mb-lg-2">No te pierdas nada</p>

                                <div className="maxw-370p mx-auto mx-lg-0">
                                    <p className="fs-16 fs-lg-20 lh-13 text-black mb-20 mb-lg-30">
                                        ¡Seguinos en
                                        <a className="text-0D63BD fw-bold" href="https://www.linkedin.com/company/segurarse/posts/?feedView=all" target="_blank" rel='noreferrer'> LinkedIn<svg className="svg-icon mx-1 mb-1 icon-18 icon-lg-28">
                                            <use xlinkHref="#icon-linkedin-sq"></use></svg></a>!
                                        Conoce más detalles y nuestras búsquedas activas.
                                    </p>
                                </div>

                                <a href="https://www.linkedin.com/company/segurarse/jobs/" target="_blank" rel='noreferrer' className="transition-base d-inline-block w-250p py-12 rounded-3 fs-20 fw-bold lh-1 text-center bg-primary text-white">Ver búsquedas</a>
                            </div>

                            <div className="d-none d-lg-block maxw-400p fig-position-06">
                                <img className="img-fluid" src={figura07} />
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

