import Axios from 'axios'
import { getPathName } from './helper';

const guardarContactoCaucion = async (origen, tipoCotizador, landingUrl, refererUrl, nombre, apellido, codigoArea, telefono, email, tipoDoc, cuit, razonSocial, provincia, localidad, tipoCobertura, moneda, sumaAsegurada, captchaToken) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        isMobile: "false",
        rubro: "CAUCION",
        origen: origen,
        tipoCotizador: tipoCotizador,
        landingUrl: landingUrl,
        refererUrl: refererUrl,

        cuit: cuit,
        razonSocial: razonSocial,
        nombre: nombre,
        apellido: apellido,
        email: email,
        tipoDocumento: tipoDoc.toLocaleUpperCase(),
        numeroDocumento: cuit,
        codigoArea: codigoArea,
        telefono: telefono,
        tipoCobertura: tipoCobertura,
        moneda: moneda,
        sumaAsegurada: sumaAsegurada,        
        provincia: provincia,
        localidad: localidad,

        g_recaptcha_response: captchaToken
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/SetCotizacionCaucionJSON", params,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
    });
};

export default guardarContactoCaucion;