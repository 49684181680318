import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SegButton } from "../../../../SegUI/SegButton/SegButton";
import { IPasos } from "../../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../../Services/Providers/CotizacionStore";
import { SegTextarea } from "../../../../SegUI/SegTextArea/SegTextArea";
import { SegToggle } from "../../../../SegUI/SegToggle/SegToggle";

type FormValues = {
  setObservaciones: string;
};

interface ObservacionesProps {
  setSteps: Dispatch<SetStateAction<IPasos>>;
  observacionesValue: string | undefined;
}

export const Observaciones = ({ setSteps, observacionesValue, ...rest }: ObservacionesProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [isConyuge, setConyuge] = useState<boolean>(false);
  const [isHijos, setHijos] = useState<boolean>(false);


  const { handleSubmit, control, setValue, setError, formState, trigger } =
    useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const { setObservaciones } = values;
    setCotizacionValues({
      ...cotizacionValues,
      observaciones: setObservaciones.toLowerCase(),
      conyuge: isConyuge,
      hijos: isHijos,
    });
    if (isConyuge) {
      if (compania) {
        nav("/prepaga/individuo/cotizacion/" + compania + "/documento-conyuge")
      }
      else {
        nav("/prepaga/individuo/cotizacion/documento-conyuge");
      }
    }
    else {
      if (isHijos) {
        if (compania) {
          nav("/prepaga/individuo/cotizacion/" + compania + "/hijos")
        }
        else {
          nav("/prepaga/individuo/cotizacion/hijos");
        }
      }
      else {
        nav("/resultado-del-cotizador-de-salud");
      }
    }
  };

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');



  useEffect(() => {
    if (error) {
      setError("setObservaciones", {
        type: 'custom',
        message: error,
      });
    } else {
      setValue("setObservaciones", observacionesValue ? observacionesValue : "");
      observacionesValue && trigger()
    }
  }, []);

  useEffect(() => {
    !cotizacionValues.nombre && !cotizacionValues.apellido && (compania ? nav("/prepaga/individuo/cotizacion/" + compania + "/nombre-y-apellido") : nav("/prepaga/individuo/cotizacion/nombre-y-apellido"))
  }, [])

  useEffect(() => {
    if (cotizacionValues.conyuge) {
      setConyuge(cotizacionValues.conyuge)
    }
  }, []);

  useEffect(() => {
    if (cotizacionValues.hijos) {
      setHijos(cotizacionValues.hijos)
    }
  }, []);

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend className="mb-1">Observaciones médicas</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mw-sm-450 m-auto d-flex justify-content-center">
            <Controller
              name="setObservaciones"
              control={control}
              render={({ field, fieldState }) => (
                <SegTextarea
                  className="mt-1"
                  label="Opcional"
                  name="setObservaciones"
                  width={92}
                  placeHolder=""
                  rows={3}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("setObservaciones", e.target.value); // Actualiza el valor en React Hook Form
                    trigger("setObservaciones");
                  }}
                  value={field.value}
                  autoFocus={true}
                  leyendaError={fieldState?.error?.message}
                  borderColor={`${formState.errors.setObservaciones
                    ? "var(--bs-form-invalid-border-color)"
                    : ""
                    }`}
                  focusBorderColor={`${formState.errors.setObservaciones
                    ? "var(--bs-form-invalid-border-color)"
                    : "#2c7dfd"
                    }`}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-center mt-18">
            <h5 className="fw-bold pe-20">Incluir cónyuge</h5>
            <SegToggle idLabel="reciboSueldo" label="No" isChecked={isConyuge} onChange={setConyuge} />
            <div className="pe-40" style={{ marginLeft: "-8px" }}>Si</div>
            <h5 className="fw-bold pe-20">Incluir hijos</h5>
            <SegToggle idLabel="reciboSueldo" label="No" isChecked={isHijos} onChange={setHijos} />
            <div style={{ marginLeft: "-8px" }}>Si</div>
          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary mb-4"
              disabled={
                !formState.isValid // Verifica si el formulario es válido
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
