import prevencion from "../Assets/img/logos/cias/logo_prevencion_art_b.png"
import provincia from "../Assets/img/logos/cias/art/Provincia_ART.svg"
import federacion from "../Assets/img/logos/cias/art/Federacion-patronal_ART.svg"
import experta from "../Assets/img/logos/cias/art/Experta_ART.svg"
import asociart from "../Assets/img/logos/cias/art/Asociart_ART.svg"
import swissMedical from "../Assets/img/logos/cias/art/Swiss Medical_ART.svg"
import omint from "../Assets/img/logos/cias/art/Omint_ART.svg"
import galeno from "../Assets/img/logos/cias/art/Galeno_ART_.svg"
import barkley from "../Assets/img/logos/cias/art/Berckley_ART.svg"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "prevencion",
        imgRuta: prevencion,
        mostrar: true
    },
    {
        nombreCompania: "provinciaseguros",
        imgRuta: provincia,
        mostrar: true
    },
    {
        nombreCompania: "federacion patronal",
        imgRuta: federacion,
        mostrar: true
    },
    {
        nombreCompania: "experta",
        imgRuta: experta,
        mostrar: true
    },
    {
        nombreCompania: "asociart",
        imgRuta: asociart,
        mostrar: true
    },
    {
        nombreCompania: "swiss medical",
        imgRuta: swissMedical,
        mostrar: true
    },
    {
        nombreCompania: "omint",
        imgRuta: omint,
        mostrar: true
    },
    {
        nombreCompania: "galeno",
        imgRuta: galeno,
        mostrar: false
    },
    {
        nombreCompania: "barkley",
        imgRuta: barkley,
        mostrar: true
    },    
]