import React, { useState } from 'react'
import { CertificadoNoRodamiento, useEmisionStore } from '../../../../../Services/Providers/EmisionStore';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SegButton } from '../../../../SegUI/SegButton/SegButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import subirArchivo from '../../../../../Services/API/CertificadoNoRodamiento';
import { click } from '@testing-library/user-event/dist/click';

interface Props {
    es0km: boolean;
}
export const useCertificadoNoRodamiento = ({ es0km }: Props) => {

    const [certificadoNoRodamiento, setCertificadoNoRodamiento] = useState<CertificadoNoRodamiento | null>(null);
    const [falloCR, setFalloCR] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);
    const [enviando, setEnviando] = useState<boolean>(false);
    const [enviado, setEnviado] = useState<boolean>(false);
    const { emisionValues, setEmisionValues } = useEmisionStore();


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFileSizeError(false);
            const selectedFile = e.target.files[0];

            //Configuracion de tamaño maximo de archivo aceptado
            const maxSizeInMb = 5
            const maxSizeInBytes = maxSizeInMb * 1024 * 1024;

            if (selectedFile?.size > maxSizeInBytes) { return setFileSizeError(true) }

            if (selectedFile) {
                const cr: CertificadoNoRodamiento = {
                    archivo: selectedFile,
                    extension: selectedFile.name.split('.').pop()?.toLowerCase() || "",
                    nombre: selectedFile.name.split('.').shift()?.toLowerCase() || "",
                    dataBase64: ""
                }

                const reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onload = async function () {
                    const dataBase64 = reader.result as string;
                    const base64 = dataBase64?.split(',').pop();
                    if (base64) {
                        cr.dataBase64 = base64;
                        setCertificadoNoRodamiento(cr);
                        setEmisionValues({
                            ...emisionValues,
                            certificadoNoRodamiento: cr
                        });
                    } else {
                        setFalloCR(true);
                    }
                };
                reader.onerror = function (error) {
                    setFalloCR(true)
                    console.error("Error occurred while reading the file:", error);
                };
            }
        }
    };

    const handleDeleteFile = () => {
        setCertificadoNoRodamiento(null);
        setFalloCR(false)
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
        setEmisionValues({
            ...emisionValues,
            certificadoNoRodamiento: null
        })
    }

    const handleUpload = async (myCallback: () => void) => {
        if (!emisionValues.id || emisionValues.id < 0 || !emisionValues.certificadoNoRodamiento) {
            setFalloCR(true);
            setEnviado(false);
            setEnviando(false);
            return
        }
        try {
            setEnviando(true);
            const response = await subirArchivo(
                emisionValues.certificadoNoRodamiento.nombre,
                emisionValues.certificadoNoRodamiento.extension,
                emisionValues.certificadoNoRodamiento.dataBase64,
                emisionValues.id);
            setEnviando(false);
            if (response.api_status === 200) {
                setEnviado(true);
                setFalloCR(false);
                closeModalCNR();
                myCallback();
            } else {
                setFalloCR(true);
                setEnviado(false)
            }
        } catch (error) {
            console.error("Error occurred while uploading the file:", error);
        }
    }


    const validarCNR = (): boolean => {
        if (falloCR) return true;
        if (es0km && certificadoNoRodamiento) {
            return false;
        } else if (es0km && !certificadoNoRodamiento) {
            return true;
        } else {
            return false
        }
    }

    const renderInputCertificadoNoRodamiento = () => {
        if (!es0km) return <div></div>
        return (
            <div className='mt-2'>
                <p>Al ser un auto 0km necesitamos el <b>Certificado de No Rodamiento</b>,  el  documento debe tener:</p>
                <span className='d-block'>- Membrete de la concesionaria</span>
                <span className='d-block'>- Fecha del día de contratación del seguro</span>
                <span className='d-block'>- Datos del vehículo</span>
                <span className='d-block'>- Sello y firma</span>

                <div className='fs-18 pt-3'>Adjuntalo
                    <span role={true ? "" : "button"} className={`mx-2 ${certificadoNoRodamiento ? "text-secondary" : "text-primary"}`} style={{ cursor: "pointer" }}
                        onClick={() => document.getElementById('fileInput')?.click()}><b>AQUI.</b>
                    </span>
                    <input type="file" id="fileInput" style={{ display: 'none' }}
                        onChange={handleFileChange} disabled={certificadoNoRodamiento ? true : false} accept=".pdf, .docx, .doc" />
                </div>
                <div className='mb-2 fs-14'>
                    {fileSizeError ?
                        <>
                            <CancelOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }} />
                            <span style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }}> El archivo no debe superar los 5Mb </span>
                        </>
                        :
                        <span className='' style={{ verticalAlign: "-webkit-baseline-middle" }} > (Únicamente en formato pdf, doc/docx. Máx. 5 Mb) </span>
                    }

                </div>
                {certificadoNoRodamiento &&
                    (
                        <>
                            <div className={`d-flex ${(window.innerWidth < 768) && "justify-content-between"}`}>
                                <span className='fs-6 fw-bold text-primary cambiar-certificado col-lg-2 mx-2' style={{ alignSelf: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>{certificadoNoRodamiento.archivo.name}</span>
                                <div className=''><SegButton className='fs-6 certificado-button' disabled={!certificadoNoRodamiento ? true : false} activeBgColor='#2c7dfd' type={'button'} icon={DeleteOutlineIcon} label={'Eliminar'} onClick={handleDeleteFile} iconSide='right' /></div>
                                {falloCR &&
                                    <div>
                                        <CancelOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }} />
                                        <span style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }}>El archivo no es válido. Intente con otro. </span>
                                    </div>
                                }
                            </div>
                        </>
                    )
                }
            </div>
        )
    }


    const renderModalCNR = (myCallback: () => void) => {
        if (!es0km) return <></>
        return (
            <>
                <button type="button" id="btnModalCNR" className="btn btn-primary fs-15" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#modalCNR">cargar certificado</button>

                <div className="modal" id="modalCNR" tabIndex={-1} aria-labelledby="ModalLabel" style={{ display: "none" }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-se maxw-lg-400p modal-wpp">
                        <div className="modal-content bg-e6e6e6 p-2">
                            <div className="modal-header border-0 mb-4">
                                <button type="button" id="btnCloseModalCNR" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pt-0">
                                <p className='d-block fs-16' style={{ color: "#a95050" }}>
                                    <b>Ocurrió un error al subir el certificado previamente. Intentá otra vez</b>
                                </p>

                                <p className='fs-18 pt-3'>Si lo tenés a mano adjúntalo
                                    <span role={true ? "" : "button"} className={`mx-2 ${certificadoNoRodamiento ? "text-secondary" : "text-primary"}`} style={{ cursor: "pointer" }}
                                        onClick={() => document.getElementById('fileInput')?.click()}><b>AQUI.</b>
                                    </span>
                                    <input type="file" id="fileInput" style={{ display: 'none' }}
                                        onChange={handleFileChange} disabled={certificadoNoRodamiento ? true : false} accept=".pdf, .docx, .doc" />
                                </p>
                                {certificadoNoRodamiento &&
                                    (
                                        <>
                                            <div className={`${window.innerWidth > 768 && "d-flex"}`}>
                                                <span className='fs-6 fw-bold text-primary cambiar-certificado col-lg-2' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: "100%" }}>{certificadoNoRodamiento.nombre}</span>
                                                <div className='cambiar-certificado '><SegButton className='fs-5 certificado-button' disabled={enviado ? true : false} activeBgColor='#2c7dfd' type={'button'} icon={DeleteOutlineIcon} label={'Eliminar'} onClick={handleDeleteFile} iconSide='right' /></div>
                                                <div className='enviar-certificado '>
                                                    <SegButton className='fs-5 certificado-button'
                                                        disabled={enviado || falloCR ? true : false} activeBgColor='#2c7dfd' type={'button'} icon={SendIcon}
                                                        label={enviando ? "" : 'Enviar'} iconSide='right' loading={enviando ? true : false} spinnerSize='small'
                                                        onClick={() => handleUpload(myCallback)}
                                                    />
                                                </div>
                                            </div>
                                            {enviado &&
                                                <div>
                                                    <CheckOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "green" }} />
                                                    <span style={{ verticalAlign: "-webkit-baseline-middle", color: "green" }}> Documento enviado </span>
                                                </div>
                                            }
                                            {falloCR &&
                                                <div>
                                                    <CancelOutlinedIcon style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }} />
                                                    <span style={{ verticalAlign: "-webkit-baseline-middle", color: "red" }}> Falló el envío. Volvé a intentar. </span>
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

    const openModalCNR = () => {
        const button = document.getElementById('btnModalCNR');
        if (button) {
            button.click();
        }
    }

    const closeModalCNR = () => {
        const button = document.getElementById('btnCloseModalCNR');
        if (button) {
            button.click();
        }
    }



    return { renderInputCertificadoNoRodamiento, validarCNR, renderModalCNR, openModalCNR }
}
