import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

interface Props {
  items: {
    titulo?: string;
    descripcion: string;
    link: string;
    img?: string;
  }[]
}
export const NoticiasSlider = ({ items }: Props) => {
  return (
    <div className="container" style={{padding: "30px 0"}}>
      <Splide
        options={{
          type: 'loop',
          focus: 0,
          drag: true,
          flickMaxPages: 1,
          flickPower: 50,
          perPage: 1,
          perMove: 1,
          autoplay: true,
          interval: 4000,
          arrows: false,
          mediaQuery: 'min',
          gap: '0.5rem',
          padding: '2rem',
          breakpoints: {
            576: {
              perPage: 1,
              padding: '0',
            },
            768: {
              perPage: 2,
              gap: '1.5rem',
            },
            992: {
              perPage: 3,
            },
          }
        }}
      >

        {items
          .map((item, index) => (
            <SplideSlide key={index} style={{marginTop: "30px"}} className='pb-4'>
              <div className="news-wrapper">
                <div>
                <h3 className={`fs-16 fs-lg-18 fw-bold news-title`}
                      style={{ color: !item.titulo ? "transparent" : "" }}
                    >{item.titulo + ""}</h3>
                    {
                      item.img &&
                      <div className="text-start mb-16">
                        <img height="20" src={item.img} />
                      </div>
                    }
                    <p className="fs-15 fs-lg-18 lh-sm news-copy">
                      {item.descripcion}
                    </p>
                </div>
                <a className="fs-15 fs-lg-18 fw-bold" href={item.link} target="_blank" rel='noreferrer' tabIndex={-1}>Leer más</a>
              </div>
            </SplideSlide>
          ))}
      </Splide>
    </div>
  )
}