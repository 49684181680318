import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { SegTooltip } from "../../../SegUI/SegTooltip/SegTooltip";
import { IPasos } from "../../../../Pages/Auto/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { EmpresaProps } from "../../../../Services/Providers/useConfiguracionEmpresa";

type FormValues = {
  setDia: string;
  setMes: string;
  setAnio: string;
};

interface FechaNacProps {
  config: EmpresaProps;
  setSteps: Dispatch<SetStateAction<IPasos>>;
  fechaNacValue: { dia: string; mes: string; año: string } | undefined;
}

const validateAge = (year: string, month: string, day: string) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Meses en JavaScript son 0-indexados
  const currentDay = new Date().getDate();

  const enteredYear = parseInt(year, 10);
  const enteredMonth = parseInt(month, 10);
  const enteredDay = parseInt(day, 10);

  if (currentYear - enteredYear > 20) {
    return true; // Mayor de 20 años
  } else if (currentYear - enteredYear === 20) {
    // Mismo año, verifica mes y día
    if (
      currentMonth > enteredMonth ||
      (currentMonth === enteredMonth && currentDay >= enteredDay)
    ) {
      return true; // Mayor de 20 años
    }
  }
  return false;
};

export const FechaNacimiento = ({
  config,
  setSteps,
  fechaNacValue,
  ...rest
}: FechaNacProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { handleSubmit, control, setValue, formState, trigger, watch, setFocus } = useForm<FormValues>();

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [initialValidationDone, setInitialValidationDone] = React.useState(false);
  const setAnioValue = watch("setAnio");
  const setMesValue = watch("setMes");
  const setDiaValue = watch("setDia");


  const mesFocus = useRef<HTMLInputElement | null>(null);
  const anioFocus = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const validateYear = async () => {
      // Validar la edad en tiempo real al ingresar el cuarto carácter del año
      if (initialValidationDone && setDiaValue !== "" && setMesValue !== "" && setAnioValue !== "") {
        const ageValidationResult = validateAge(setAnioValue, setMesValue, setDiaValue);
        if (!ageValidationResult) {
          setErrorMessage("Sólo aseguramos a mayores de 20 años.");
          setIsButtonEnabled(false);
        } else {
          setErrorMessage(null);
          setIsButtonEnabled(true);
        }
      }
    };

    validateYear();
  }, [initialValidationDone, setAnioValue, setMesValue, setDiaValue, setValue, trigger, watch]);

  useEffect(() => {
    // Realizar la validación inicial solo después de que se haya cargado la página
    setInitialValidationDone(true);
  }, []);


  useEffect(() => {
    if (setDiaValue && setDiaValue.length === 2 && mesFocus.current) {
      mesFocus.current.focus();
    }
  }, [setDiaValue]);

  useEffect(() => {
    if (setMesValue && setMesValue.length === 2 && anioFocus.current) {
      anioFocus.current.focus();
    }
  }, [setMesValue]);

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    if (values.setDia.length === 1) {
      values.setDia = "0" + values.setDia
    }
    if (values.setMes.length === 1) {
      values.setMes = "0" + values.setMes
    }

    const { setDia, setMes, setAnio } = values;

    setCotizacionValues({
      ...cotizacionValues,
      fechaNac: {
        dia: setDia,
        mes: setMes,
        año: setAnio,
      },
    });
    setSteps((prev) => {
      return {
        ...prev,
        step: 1,
        subStep: 8,
      };
    });
    compania
      ? nav("/auto/cotizacion/" + compania + "/genero")
      : nav("/auto/cotizacion/genero");
  };

  useEffect(() => {
    setValue("setDia", fechaNacValue?.dia ? fechaNacValue.dia : ""); // Actualiza el valor en React Hook Form
    setValue("setMes", fechaNacValue?.mes ? fechaNacValue.mes : "");
    setValue("setAnio", fechaNacValue?.año ? fechaNacValue.año : "");
    fechaNacValue?.dia && trigger();
    fechaNacValue?.mes && trigger();
    fechaNacValue?.año && trigger();
  }, [fechaNacValue]);

  useEffect(() => {
    !cotizacionValues.telefono && (compania ? nav("/auto/cotizacion/" + compania + "/telefono") : nav("/auto/cotizacion/telefono"))
  }, [])

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
        <legend>
          {"¿Cuál es tu fecha de nacimiento? "}
          <SegTooltip text="Influye en el precio." />
        </legend>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gx- text-start mw-sm-450 m-auto">
            <div className="col-4">
              <Controller
                name="setDia"
                control={control}
                rules={{
                  validate: {
                    customValidation: (value) => {
                      if (!value) {
                        return "Día debe incluir 2 dígitos";
                      }
                      const parsedValue = parseInt(value, 10);
                      if (isNaN(parsedValue) || parsedValue > 31) {
                        return "Día no puede ser mayor a 31";
                      }
                      if (isNaN(parsedValue) || parsedValue <= 0) {
                        return "Día no puede ser 0";
                      }
                      const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                      if (isSingleDigitWithoutZero) {
                        return "Día debe incluir 2 dígitos";
                      }
                      return true; // La validación es exitosa
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput

                    label="Día"
                    name="setDia"
                    type="number"
                    width={100}
                    placeHolder="DD"
                    value={field.value}
                    autoFocus={true}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setDia", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setDia");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setDia
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                      }`}
                    focusBorderColor={`${formState.errors.setDia
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <Controller
                name="setMes"
                control={control}
                rules={{
                  validate: {
                    customValidation: (value) => {
                      if (!value) {
                        return "Mes debe incluir 2 dígitos";
                      }
                      const parsedValue = parseInt(value, 10);
                      if (isNaN(parsedValue) || parsedValue > 12) {
                        return "Mes no puede ser mayor a 12";
                      }
                      if (isNaN(parsedValue) || parsedValue <= 0) {
                        return "Día no puede ser 0";
                      }
                      const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                      if (isSingleDigitWithoutZero) {
                        return "Mes debe incluir 2 dígitos";
                      }
                      return true; // La validación es exitosa
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    ref={mesFocus}
                    label="Mes"
                    name="setMes"
                    type="number"
                    width={100}
                    placeHolder="MM"
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setMes", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setMes");
                    }}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setMes
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                      }`}
                    focusBorderColor={`${formState.errors.setMes
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <Controller
                name="setAnio"
                control={control}
                rules={{
                  validate: {
                    required: (value) =>
                      !!value || "Ingresá el año de nacimiento",
                    pattern: (value) =>
                      /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                  },
                }}
                render={({ field, fieldState }) => (
                  <SegInput
                    ref={anioFocus}
                    label="Año"
                    name="setAnio"
                    type="number"
                    placeHolder="AAAA"
                    value={field.value}
                    autoFocus={false}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                      field.onChange(trimmedValue);
                      setValue("setAnio", trimmedValue); // Actualiza el valor en React Hook Form
                      trigger("setAnio");
                    }}
                    width={100}
                    leyendaError={fieldState?.error?.message}
                    borderColor={`${formState.errors.setAnio
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : ""
                      }`}
                    focusBorderColor={`${formState.errors.setAnio
                      ? "var(--bs-form-invalid-border-color)"
                      : config.origen !== "SEGURARSE" ? config.colors.accent : "#2c7dfd"
                      }`}
                  />
                )}
              />
            </div>
          </div>
          {errorMessage && (
            <div className="error-message" style={{ color: "red" }}>
              {errorMessage}
            </div>
          )}
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={
                !formState.isValid || !isButtonEnabled // Verifica si el formulario es válido
              }
              bgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBgColor={config.origen !== "SEGURARSE" ? config.colors.primary: undefined}
              hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent: undefined}
              hoverOpacity={config.origen !== "SEGURARSE"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
