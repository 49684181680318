import Axios from 'axios'
import { getPathName, retryRequest } from './helper';

const guardarContacto = async (rubro, origen, tipoCotizador, landingUrl, refererUrl, patente, marca, modelo, version, anio, email, codigoArea, telefono, fechaNacimiento, sexo, provincia, localidad, nombre, apellido, cuandoContrata, captchaToken) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        isMobile: "false",
        rubro: rubro,
        origen: origen,
        tipoCotizador: tipoCotizador,
        landingUrl: landingUrl,
        refererUrl: refererUrl,
        patente: patente,
        marca: marca,
        modelo: modelo,
        version: version,
        anio: anio,
        email: email,
        codigoArea: codigoArea,
        telefono: telefono,
        fechaNacimiento: fechaNacimiento,
        sexo: sexo,
        provincia: provincia,
        localidad: localidad,
        nombre: nombre,
        apellido: apellido,
        cuandoContrata: cuandoContrata,
        g_recaptcha_response: captchaToken
    }
    const headers = { 
        'content-type': 'application/json',
        "Authorization" : 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    const axiosPostRequest = () => {
        return Axios.post(getPathName() + "/setcotizacionjson", params, {
          headers: headers
        });
    };

    try {
        const response = await retryRequest(axiosPostRequest);
        return response;
      } catch (error) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
      }
};



export default guardarContacto;