import { useEffect, useState } from 'react';
import { GridCoberturas } from '../Components/GridCoberturas';
import { Header as NavBar } from '../Components/Header';
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';
import { CardIconoDetalle } from '../Components/CardIconoDetalle';
import { imagesCompanias } from '../../../Assets/imageCompania';
import { AppMiSegurarse } from '../Components/AppMiSegurarse';
import { TestimoniosSlider } from '../Components/TestimoniosSlider';
import { NoticiasSlider } from '../Components/NoticiasSlider';
import { home_coberturas, home_novedades, home_virtudes, testimonios_clientes, pymes_coberturas } from '../diccionario';
import { CountUp } from 'countup.js';
import { CompaniasSlider } from '../Components/CompaniasSlider';

const animateCSS = (element: any, animation: any, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = element;

        node.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event: any) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });

let options = {
    root: null,
    threshold: 0, //0.1 = 10% is visible. 1.0 = 100% is visible
};

let callback = (entries: any, observer: any) => {
    entries.forEach((entry: any) => {

        if (entry.isIntersecting) {
            entry.target.classList.remove('invisible');
            animateCSS(entry.target, entry.target.dataset.animate);
            observer.unobserve(entry.target); //run once
        }

    });
};

export const Home = () => {

    const [isEmpresaChecked, setIsEmpresaChecked] = useState(false);

    useEffect(() => {
        let observer = new IntersectionObserver(callback, options);

        let targets = document.querySelectorAll("[data-animate]");

        targets.forEach((target) => {
            observer.observe(target);
        });

    }, [])

    useEffect(() => {
        const countUpOptions = {
            startVal: 0,
            duration: 4,
            smartEasingAmount: 100,
            separator: '.',
            decimal: ',',
            enableScrollSpy: true,
            scrollSpyOnce: true,
        };

        // Seleccionar todos los elementos con el atributo data-counter
        const elms = document.querySelectorAll('[data-counter]');

        elms.forEach((el: any) => {
            countUpOptions.startVal = Number(el.dataset.startval) || 0;

            const numAnim = new CountUp(el, el.dataset.counter, countUpOptions);

            if (!numAnim.error) {
                numAnim.start();
            } else {
                console.error(numAnim.error);
            }
        });
    }, []);

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEmpresaChecked(e.target.checked);
    };

    const coberturas = isEmpresaChecked ? pymes_coberturas : home_coberturas;


    return (
        <>
            <SegSvg />
            <NavBar rubro='auto' />
            <main>
                <div className="bg-primary sec-hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="ff-mukta fw-bold text-white fs-30 fs-lg-50 lh-lg-1">¿Buscás un seguro?<br />
                                    Somos tu mejor opción.</h2>

                                <div className="ff-mukta fw-bold text-white fs-30 fs-lg-50 lh-lg-1 mb-4">
                                    <div className="word-slider px-2 bg-orange1 rounded text-center" style={{marginRight: "8px"}}>
                                        <ul className="word-slider__words word-slider__words--4w">
                                            <li className="word-slider__word">Cotizá</li>
                                            <li className="word-slider__word">Compará</li>
                                            <li className="word-slider__word">Asegurá</li>
                                            <li className="word-slider__word">Ahorrá</li>
                                            <li className="word-slider__word">Cotizá</li>
                                        </ul>
                                    </div>
                                    ahora
                                </div>

                                <p className="mb-0 text-white fs-16 fs-lg-20">Te acompañamos en tu búsqueda, te mostramos las mejores coberturas online del mercado y te asesoramos en todo momento.<br /><b>¡Vos decidís!</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <GridCoberturas
                    coberturas={coberturas}
                    CoberturaEmpresas={isEmpresaChecked}
                >
                    <div className="row mb-lg-5 gx-lg-5">
                        <div className="col-lg-6 text-center text-lg-end">
                            <h2 className="ff-mukta fs-30 fs-lg-40 fw-bold">¿Qué seguro buscás?</h2>
                        </div>
                        <div className="col-lg-6 text-center text-lg-start">
                            <div className="can-toggle">
                                <input
                                    id="chkSwitch"
                                    type="checkbox"
                                    checked={isEmpresaChecked}
                                    onChange={handleSwitchChange}
                                />
                                <label htmlFor="chkSwitch">
                                    <div className="can-toggle__switch" data-checked="Empresas" data-unchecked="Individuos"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                </GridCoberturas>

                <div className="py-4 bg-71 text-white text-center text-lg-start px-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <h2 className="ff-mukta fw-bold text-white fs-30 fs-lg-40 lh-1 m-0">¿Sos productor de seguros?</h2>
                                <p className="fs-18 m-0 pb-4 pb-lg-0 pt-2">Optimizá tu negocio con un plan estratégico a medida. Sumate a nuestra red de productores.</p>
                            </div>
                            <div className="col-lg-3">
                                <a className="bg-orange1 d-inline-block fs-18 fw-medium px-4 py-2 rounded-5 text-white" href="#">Saber más</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grad-01 py-5 text-center">
                    <div className="container">
                        <h2 className="ff-mukta fw-bold text-black fs-28 fs-lg-40">Lo que nos hace diferentes</h2>

                        <div className="ff-mukta text-black fs-24 fs-lg-34 mb-40 pb-5">
                            Te damos motivos para elegirnos. Conocé cómo te
                            <div className="word-slider word-slider--sm px-2 bg-primary text-white rounded" style={{marginLeft: "8px"}}>
                                <ul className="word-slider__words word-slider__words--2w" >
                                    <li className="word-slider__word word-slider__word--sm">ayudamos</li>
                                    <li className="word-slider__word word-slider__word--sm">asesoramos</li>
                                    <li className="word-slider__word word-slider__word--sm">ayudamos</li>
                                </ul>
                            </div>
                        </div>


                        <div className="row gx-3 gx-lg-4 gy-6">
                            {
                                home_virtudes.map(item => (
                                    <div className="col-6 col-lg-3" key={item.titulo}>
                                        <CardIconoDetalle
                                            titulo={item.titulo}
                                            descripcion={item.descripcion}
                                            icono={item.icono}
                                        />
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </div>

                <div className="bg-b4 pb-5 px-2 text-center text-white">
                    <div className="container">

                        <h2 className="col-11 mx-auto mb-4 ff-mukta fw-bold text-white fs-30 fs-lg-40">Los números hablan por nosotros</h2>
                        <div className="row row-gap-45">
                            <div className="col-sm-6 col-lg-3 text-center">
                                <div className="d-inline-block text-center">
                                    <span className="fw-bold fs-34 fs-lg-44 lh-1 bg-orange1 text-white d-inline-block px-2 py-1 rounded mw-190">
                                        +<span data-counter="12" data-startval="100">12</span> AÑOS
                                    </span>
                                    <span className="fw-bold fs-18 fs-lg-20 d-block">DE TRAYECTORIA</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 text-center">
                                <div className="d-inline-block text-center mt-lg-5">
                                    <span className="fw-bold fs-34 fs-lg-44 lh-1 bg-primary text-white d-inline-block px-2 py-1 rounded mw-190">
                                        <span data-counter="3" data-startval="50">3</span> PAÍSES
                                    </span>
                                    <span className="fw-bold fs-18 fs-lg-20 d-block">DE LATAM</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 text-center">
                                <div className="d-inline-block text-center">
                                    <span className="fw-bold fs-34 fs-lg-44 lh-1 bg-green text-white d-inline-block px-2 py-1 rounded mw-190">
                                        +<span data-counter="85000" data-startval="50000">85.000</span>
                                    </span>
                                    <span className="fw-bold fs-18 fs-lg-20 d-block">ASEGURADOS</span>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3 text-center">
                                <div className="d-inline-block text-center mt-lg-5">
                                    <span className="fw-bold fs-34 fs-lg-44 lh-1 bg-violet text-white d-inline-block px-2 py-1 rounded mw-190">
                                        +<span data-counter="200000" data-startval="150000">200.000</span>
                                    </span>
                                    <span className="fw-bold fs-18 fs-lg-20 d-block">COTIZACIONES POR AÑO</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-24 fs-lg-40 col-11 col-lg-9 mx-auto mb-4 mb-lg-5 text-center">Somos un bróker, trabajamos con las mejores aseguradoras del país</h2>
                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>

                <AppMiSegurarse />

                <div className="py-5 bg-f3">
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 mb-4 mb-lg-5 text-center">¿Por qué nos eligen?</h2>

                        <TestimoniosSlider
                            testimonios={testimonios_clientes}
                        />
                    </div>
                </div>
                <div className="py-5 mb-4">
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 mb-4 mg-lg-5 pb-lg-4 text-center">Novedades que te pueden interesar</h2>

                        <NoticiasSlider
                            items={home_novedades}
                        />
                    </div>
                </div>

            </main>
        </>
    )
}
