import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';
import { useNavigate, useParams } from 'react-router-dom';
import { SegSelect, SegSelectOption } from '../../SegUI/SegSelect/SegSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { IPasos } from '../../../Pages/AP/Cotizador';
import { SegButton } from '../../SegUI/SegButton/SegButton';
import { obtenerCondicionesIVA } from '../../../Services/API/CondicionesIVA';
import styled from '@emotion/styled';
import { obtenerCondicionesIIBB } from '../../../Services/API/CondicionIIBB';

const StyledDiv = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

interface ICondicionIVAIIBB {
    condicionIVA: string;
    condicionIIBB: string;
}

interface CondicionIVAIIBBProps {
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const CondicionIVA = ({ setSteps, ...rest }: CondicionIVAIIBBProps) => {

    const nav = useNavigate();
    const { compania } = useParams();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const [condicionesIvaLoading, setCondionesIvaLoading] = useState<boolean>(true);
    const [condicionesIIBBLoading, setCondionesIIBBLoading] = useState<boolean>(true);

    const [condicionesIVA, setCondicionesIVA] = useState<SegSelectOption[]>([]);
    const [condicionesIIBB, setCondicionesIIBB] = useState<SegSelectOption[]>([]);

    const [condicionIVA, setCondicionIVA] = useState<SegSelectOption>();
    const [condicionIIBB, setCondicionIIBB] = useState<SegSelectOption>();
    const [condicionIIBBDesactivado, setCondicionIIBBDesactivado] = useState<boolean>(false);

    const [openCondIVA, setOpenCondIva] = useState<boolean>(false)
    const [openCondIIBB, setOpenCondIIBB] = useState<boolean>(false)

    const btnRef = useRef<HTMLButtonElement | null>(null);
    const methods = useForm<ICondicionIVAIIBB>();
    const {
        handleSubmit,
        formState: { errors },
    } = useForm<ICondicionIVAIIBB>();


    useEffect(() => {
        getCondicionesIva();
        getCondicionesIIBB();
    }, [])

    const getCondicionesIva = async () => {
        const resp: SegSelectOption[] = await obtenerCondicionesIVA();
        setCondicionesIVA(resp)
        setCondionesIvaLoading(false)

        if (cotizacionValues.condicionIVA) {
            setCondicionIVA(resp.find(i => i.value === cotizacionValues.condicionIVA))
        } else {
            setOpenCondIva(true)
        }
    }

    const getCondicionesIIBB = async () => {
        const resp: SegSelectOption[] = await obtenerCondicionesIIBB();
        setCondicionesIIBB(resp);
        setCondionesIIBBLoading(false);

        if (cotizacionValues.condicionIIBB) {
            setCondicionIIBB(resp.find(i => i.value === cotizacionValues.condicionIIBB))
        }
    }


    const handleCondicionIVAChange = (e: any) => {
        setCondicionIVA(e)
        setOpenCondIva(false)
        if(e.label.toLocaleLowerCase() === "consumidor final"){
            const exento = condicionesIIBB.find( (cond) => cond.label === "Exento");
            setCondicionIIBB(exento)
            setCondicionIIBBDesactivado(true);
        }else{
            setCondicionIIBBDesactivado(false);
            setOpenCondIIBB(true)
        }
    }
    const handleCondicionIIBBChange = (e: any) => {
        setCondicionIIBB(e)
        setOpenCondIIBB(false)
    }

    const onSubmit = () => {
        setCotizacionValues({
            ...cotizacionValues,
            condicionIVA: condicionIVA?.value,
            condicionIIBB: condicionIIBB?.value
        })

        if (compania) {
            nav("/ap/cotizacion/" + compania + "/datos-asegurado")
        }
        else {
            nav("/ap/cotizacion/datos-asegurado")
        }
    }

    return (
        <div className="mt-4 bg-gray-light" {...rest}>
            <div className="frm01">
                <form onSubmit={handleSubmit(onSubmit)} className="frm01 gy-4">
                    <div className="cod-postal-column justify-content-center gap-4">
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Cúal es tu condición de IVA?</legend>
                                <FormProvider {...methods}>
                                    <SegSelect
                                        selected={condicionIVA}
                                        setSelected={handleCondicionIVAChange}
                                        name="condicionIVA"
                                        options={condicionesIVA}
                                        width={"100%"}
                                        loading={condicionesIvaLoading}
                                        borderColor={errors.condicionIVA ? "red" : ""}
                                        openDefault={openCondIVA}
                                    />
                                </FormProvider>
                            </fieldset>
                        </div>
                        <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                            <fieldset className="">
                                <legend className="mb-1">¿Cúal es tu condición frente a IIBB?</legend>
                                <FormProvider {...methods}>
                                    <SegSelect
                                        selected={condicionIIBB}
                                        setSelected={handleCondicionIIBBChange}
                                        name="condicionIIBB"
                                        options={condicionesIIBB}
                                        width={"100%"}
                                        loading={condicionesIIBBLoading}
                                        borderColor={errors.condicionIIBB ? "red" : ""}
                                        openDefault={openCondIIBB}
                                        disabled={condicionIIBBDesactivado}
                                    />
                                </FormProvider>
                            </fieldset>
                        </div>
                    </div>
                    <div className="form-buttons text-center">
                        <SegButton
                            label="Continuar"
                            type="submit"
                            disabled={condicionIVA?.value && condicionIIBB?.value ? false : true}
                            ref={btnRef}
                            className="mb-4"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
