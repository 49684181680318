import React from 'react';
import logoSegurarse from "../../../Assets/img/logo_segurarse_blanco.svg";
import googlePlayBadge from "../../../Assets/img/playStoreLogo.png";
import appStoreBadge from "../../../Assets/img/badge_app-store_black_es.svg";
import dataFIscal from "../../../Assets/img/DATAWEB.jpg"
import logoSNN from "../../../Assets/img/logo_ssn.svg"
import insurtech from "../../../Assets/img/logo_insurtech.png"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';

interface FooterSectionProps {
    title: string;
    items: string[];
    urls: string[];
}

const LandingFooter = () => {
    return (
        <footer className="site-footer position-relative">
            <SegSvg />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-auto text-center text-md-start">
                        <img src={logoSegurarse} className="logo-se-footer" alt="Segurarse" />

                        <ul className="list-inline mb-40">
                            <li className="list-inline-item">
                                <a href="https://www.facebook.com/segurarse.com.ar/" target="_blank" rel='noreferrer' title="Facebook">
                                    <svg className="svg-icon icon-social-footer">
                                        <use xlinkHref="#icon-facebook" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/segurarse/?hl=es" target="_blank" rel='noreferrer' title="Instagram">
                                    <svg className="svg-icon icon-social-footer">
                                        <use xlinkHref="#icon-instagram" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.linkedin.com/company/segurarse/posts/?feedView=all" target="_blank" rel='noreferrer' title="LinkedIn">
                                    <svg className="svg-icon icon-social-footer">
                                        <use xlinkHref="#icon-linkedin" />
                                    </svg>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.youtube.com/channel/UCwiQfVHEgD5BTo5cFBH-EoA#BeneficiosSegurarse:%20Desde%20un%2020%20y%20hasta%20un%2035%20de%20bonificaci%C3%B3n&utm_term=allianzben--7--none--20-30--ENVIO%20SIMPLE" target="_blank" rel='noreferrer' title="YouTube">
                                    <svg className="svg-icon icon-social-footer">
                                        <use xlinkHref="#icon-youtube" />
                                    </svg>
                                </a>
                            </li>
                        </ul>

                        <ul className="list-inline">
                            <li className="list-inline-item me-3">
                                <a href="https://segurarse.com.ar/" className="text-primary fw-bold">ARGENTINA</a>
                            </li>
                            <li className="list-inline-item me-3">
                                <a href="https://segurarse.com.br/" className="text-white fw-bold">BRASIL</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://segurarse.com.uy/cotizador-de-seguros-auto" className="text-white fw-bold">URUGUAY</a>
                            </li>
                        </ul>

                        <p className="text-primary lh-sm fs-12">
                            Av. Bartolomé Mitre 1249 P.B. "B" (B1604AKE), <br />
                            Florida Oeste, Buenos Aires, Argentina.
                        </p>
                    </div>

                    <div className="col-md-6 col-lg">
                        <div className="row">
                            <div className="d-none d-lg-block col-lg-9 col-xl-8 offset-xl-1">
                                <div className="row g-0 justify-content-between">
                                    <FooterSection title="Seguros" items={['Individuos', 'Corporativos']} urls={['/seguros-individuos', '/seguros-corporativos']} />
                                    <FooterSection title="Ayuda" items={['Preguntas Frecuentes', 'Contacto']} urls={['/preguntas-frecuentes', '/contacto']} />
                                    <FooterSection title="Nosotros" items={['Quiénes Somos', 'Sumate al #OneTeam', 'Sustentabilidad', 'Políticas de Privacidad']} urls={['/quienes-somos', '/nuestro-oneteam', '/sustentabilidad', '/politicas-privacidad']} />
                                    <div className="col-auto">
                                        <div className="text-white fw-bold fs-18">
                                            <a href="https://segurarse.com.ar/blog" className="text-white">Blog</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 mt-3 text-center mt-lg-0 text-md-end">
                                <span className="fs-18 text-white lh-1">
                                    <b>Si sos cliente</b>, descargate nuestra app
                                </span><br />
                                <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img className="mt-2 me-2 me-lg-0 badge-store-footer" alt="Disponible en Google Play" src={googlePlayBadge} />
                                </a>
                                <a href="https://itunes.apple.com/us/app/mi-segurarse/id1237342800?l=es&ls=1&mt=8">
                                    <img className="mt-2 badge-store-footer" alt="Descárgalo en el App Store" src={appStoreBadge} />
                                </a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col mt-5 text-center mt-lg-3 text-lg-end">
                                <img className="logo-ssn me-30" width="200" src={logoSNN} alt="SSN" />
                                <img className="logo-insurtech" width="140" src={insurtech} alt="Insurtech" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-start align-items-md-center pt-3 border-top border-2 border-primary">
                    <img className="icon-datafiscal me-4" src={dataFIscal} alt="Data Fiscal" />
                    <p className="mb-0 fs-10 text-9e">
                        Segurarse Asesores de Seguros S.A., desempeña las funciones de una sociedad de productores asesores de seguros conforme surge de la Ley 22.400, no revistiendo el carácter de compañía de seguros y se encuentra registrada con Matrícula 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400). Segurarse Asesores de Seguros S.A., utiliza la marca segurarse.com.ar bajo licencia de su titular, Sr. Alberto Gabriel. Todos los productos y nombres de producto mencionados de aquí en adelante son los nombres de marcas o las marcas registradas de sus respectivos dueños.
                    </p>
                </div>

                <div className="to-top">
                    <button
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' }); // Desplazar hacia arriba
                        }}
                        className="to-top-wrapper">
                        <svg className="to-top-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.429124 12.9748C0.0385995 13.3653 0.0385994 13.9985 0.429124 14.389C0.819648 14.7795 1.45281 14.7795 1.84334 14.389L12 4.23235V28.5002C12 29.0525 12.4477 29.5002 13 29.5002C13.5523 29.5002 14 29.0525 14 28.5002V4.23261L24.1564 14.389C24.5469 14.7795 25.1801 14.7795 25.5706 14.389C25.9611 13.9985 25.9611 13.3653 25.5706 12.9748L13.707 1.11116L12.9999 0.404053L12.2928 1.11116L0.429124 12.9748Z" fill="white"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </footer>
    );
};

const FooterSection: React.FC<FooterSectionProps> = ({ title, items, urls }) => (
    <div className="col-auto">
        <div className="mb-3 text-white fw-bold fs-18">{title}</div>
        <ul className="list-unstyled">
            {items.map((item: string, index: number) => (
                <li key={index} className="lh-md">
                    <a href={urls[index]} className="text-cc fw-bold fs-14 hover-white">{item}</a>
                </li>
            ))}
        </ul>
    </div>
);

export default LandingFooter;
