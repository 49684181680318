import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'
import Formulario from '../Components/Formulario';

export const Construccion = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Seguro para Construcción'
                    url='#formulario'
                    icon='#icon-seg-construccion'
                    subTitulo={{ __html: "Protegé el patrimonio de tu empresa con un seguro de construcción.<br/><br/>¿Empezamos?" }}
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Este seguro está diseñado para cubrir toda clase de obras civiles en construcción, protegiendo a tu empresa ante reclamos de terceros por daños a sus bienes o a las personas ocasionados por la ejecución de una obra de construcción o refacción. Este tipo de cobertura brinda protección tanto a los propietarios del proyecto como a los contratistas y subcontratistas involucrados en la construcción.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Daños a la propiedad:</b> cubre los daños materiales o pérdidas que puedan ocurrir durante el proceso de construcción, como incendios, explosiones, inundaciones, tormentas u otros eventos imprevistos. Esto incluye tanto los daños a la estructura en construcción como a los materiales y equipos utilizados en el proyecto.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> protege a los propietarios, contratistas y subcontratistas contra reclamaciones de terceros por lesiones personales o daños a la propiedad que puedan surgir durante la construcción.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Lesiones a los trabajadores:</b> cobertura de accidentes laborales para los trabajadores involucrados en el proyecto.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Pérdida de ingresos y gastos adicionales:</b> en caso de que la construcción se vea interrumpida debido a un evento cubierto, el seguro puede cubrir la pérdida de ingresos y los gastos adicionales incurridos durante el período de interrupción.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Cumplimiento de garantías y obligaciones contractuales:</b> cobertura de las obligaciones contractuales, garantías y garantías de calidad relacionadas con el proyecto de construcción.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Maquinarias:</b> cubre los equipos en caso de rotura, daño accidental o robo.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Formulario
                    rubro='RC'
                    titulo='Cotizá seguro para Construcción'
                />
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
