import React from "react";
import styled from "@emotion/styled";

interface SegPProps {
  text: string;
  textColor?: string;
  fontSize?: number;
  fontWeight?: "normal" | 500 | "bold";
  lineHeight?: number;
  backgroundColor?: string;
  textAlign?: "left" | "center" | "right" | "justify";
  className?: string;
  margin?: string;
}
export const SegP = ({
  text,
  margin = "",
  textColor = "#000",
  fontSize = 15,
  fontWeight = 500,
  lineHeight = 1,
  backgroundColor = "#FFFFFF",
  textAlign = "center",
  className,
}: SegPProps) => {
  const P = styled.p`
    color: ${textColor} !important;
    font-weight: ${fontWeight} !important;
    font-size: ${fontSize}px !important;
    line-height: ${lineHeight} !important;
    background-color: ${backgroundColor} !important;
    text-align: ${textAlign} !important;
    display: block;
    margin: ${margin}
  `;

  return <P className={className}>{text}</P>;
};
