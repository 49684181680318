import styled from "@emotion/styled"

interface Props {
    mostrarWpp?: boolean;
}
export const SegSinCotis = ({
    mostrarWpp = true
}: Props) => {
    const StyledNoCoti = styled.div`
    justify-content:center;
    margin: 8px 12px 8px 8px;
    padding: 20px;
    text-align: center;
    background: #FFFEFC;
    box-shadow: 0px 0px 8px rgba(164, 164, 164, 0.3);
    border-radius: 5px;
`
    return (
        <StyledNoCoti className="card3 card3-layout d-flex justify-content-center" key="no-quotes">
            <div>
                <p style={{ margin: 0 }}>En este momento no tenemos precios online para mostrarte, pero ¡no te preocupes! Nuestros asesores pueden ayudarte a encontrar el seguro que necesitás.
                    {
                        mostrarWpp &&
                        <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                            <span> Chatéanos</span>
                            <svg className="icon">
                                <use xlinkHref="#whatsapp" />
                            </svg>
                        </a>
                    }
                </p>
            </div>
        </StyledNoCoti>
    )
}