import { useEffect } from 'react'
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import { Header as Navbar } from '../Components/Header'
import shape02 from "../../../Assets/img/shape02.svg";
import figura5 from "../../../Assets/img/figura05.svg";
import figura6 from "../../../Assets/img/figura06.svg";
import figura7 from "../../../Assets/img/figura07.svg";
import shape08 from "../../../Assets/img/shape08.svg";
import shape09 from "../../../Assets/img/shape09.svg";
import shape10 from "../../../Assets/img/shape10.svg";
import shape11 from "../../../Assets/img/shape11.svg";
import { NoticiasSlider } from '../Components/NoticiasSlider';
import { quienes_somos_novedades } from '../diccionario';
import { imagesCompanias } from '../../../Assets/imageCompania';
import 'animate.css';
import { CompaniasSlider } from '../Components/CompaniasSlider';


const animateCSS = (element: any, animation: any, prefix = 'animate__') =>
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = element;

        node.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event: any) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });

let options = {
    root: null,
    threshold: 0, //0.1 = 10% is visible. 1.0 = 100% is visible
};

let callback = (entries: any, observer: any) => {
    entries.forEach((entry: any) => {

        if (entry.isIntersecting) {
            entry.target.classList.remove('invisible');
            animateCSS(entry.target, entry.target.dataset.animate);
            observer.unobserve(entry.target); //run once
        }
    });
};

export const QuienesSomos = () => {

    useEffect(() => {
        let observer = new IntersectionObserver(callback, options);

        let targets = document.querySelectorAll("[data-animate]");

        targets.forEach((target) => {
            observer.observe(target);
        });

    }, []);
    return (
        <>
            <SegSvg />
            <Navbar rubro='auto' />

            <main>

                <div className="bg-f9 pt-45 pb-240 py-lg-80 position-relative bg-hero-03">
                    <img className="d-none d-lg-block pos02" src={shape02} />

                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-10">
                            Quiénes somos
                        </h2>
                        <div className="col-11 col-md-10 col-lg-7 mx-auto mx-lg-0">
                            <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-0">
                                Somos Segurarse, <b>un bróker digital de seguros pionero en la venta online en Latam</b>.
                                Que es lo mismo que decir, somos el intermediario entre vos y más de 30 aseguradoras líderes,
                                que te ayudamos a proteger lo que más queres desde la comodidad de dónde estes.
                            </p>
                        </div>
                    </div>
                </div>



                <div className="bg-primary text-white py-30 py-lg-50 position-relative overflow-hidden">
                    <img className="d-none d-lg-block pos11" src={shape10} />
                    <img className="d-none d-lg-block pos12" src={shape11} />

                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-20 mb-lg-40">Nuestra historia</h2>

                        <div className="row gx-lg-5 mb-lg-10">
                            <div className="col-11 col-lg-6 mx-auto mb-25">
                                <div className="bg-white rounded-3 px-12 py-12 px-lg-30 py-lg-18 h-100 position-relative arrow arrow-white arrow-uptolg-bottom arrow-lg-right">
                                    <h3 className="mb-1">
                                        <svg className="svg-icon align-baseline me-1" width="21" height="19"><use xlinkHref="#icon-calendar"></use></svg>
                                        <span className="fs-20 fs-lg-25 fw-bold text-primary">2011</span>
                                    </h3>
                                    <p className="fs-16 fs-lg-20 lh-13 text-black mb-0">Tres amigos con la clara <b>visión de transformar digitalmente la industria aseguradora</b> y simplificar de contratación de un seguro, crearon <b>Segurarse</b>.</p>
                                </div>
                            </div>
                            <div className="col-11 col-lg-6 mx-auto mb-25">
                                <div className="bg-6d rounded-3 px-12 py-12 px-lg-30 py-lg-18 h-100 position-relative arrow arrow-grey arrow-bottom">
                                    <p className="fs-16 fs-lg-20 lh-12 mb-0">Siempre persiguieron, <b>la transparencia</b> para mostrar todas las opciones que hay en el mercado, <b>el profesionalismo</b> para asesorar <b>y calidad de servicio</b> para brindarle a los usuarios y clientes las herramientas necesarias para realizar una compra inteligente.</p>
                                </div>
                            </div>
                        </div>

                        <h3 className="ff-mukta fw-bold fs-26 fs-lg-36 text-white text-center mb-16 mb-lg-25">
                            Hoy 12 años después los <br className="d-lg-none" />números hablan por nosotros
                        </h3>

                        <div className="row">
                            <div className="col-lg-3 mb-25 mb-lg-0">
                                <div className="bg-white rounded-3 d-flex flex-lg-column align-items-lg-center h-100 w-250p w-lg-auto mx-auto px-3 py-3 text-lg-center position-relative arrow arrow-white arrow-uptolg-bottom arrow-lg-right">
                                    <svg className="svg-icon icon-historia me-2 me-lg-0 mb-lg-20"><use xlinkHref="#icon-shield"></use></svg>
                                    <div>
                                        <p className="fs-13 fs-lg-16 fw-bold lh-1 text-87 mb-lg-20"><span className="d-block fs-28 fs-lg-32 fw-bold text-primary mb-lg-1">+200.000</span> COTIZACIONES <br />POR AÑO</p>
                                        <p className="fs-13 fs-lg-16 fw-bold lh-1 text-87 mb-0"><span className="d-block fs-28 fs-lg-32 fw-bold text-primary mb-lg-1">+15 TIPOS</span> DE SEGUROS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-25 mb-lg-0">
                                <div className="bg-white rounded-3 d-flex flex-lg-column align-items-lg-center h-100 w-250p w-lg-auto mx-auto px-3 py-3 text-lg-center position-relative">
                                    <svg className="svg-icon icon-historia me-2 me-lg-0 mb-lg-20"><use xlinkHref="#icon-target"></use></svg>
                                    <div>
                                        <p className="fs-13 fs-lg-16 fw-bold lh-1 text-87 mb-lg-20"><span className="d-block fs-28 fs-lg-32 fw-bold text-primary mb-lg-1">+85.000</span> ASEGURADOS EN 3 <br />MERCADOS DE LATAM</p>
                                        <p className="fs-13 fs-lg-16 fw-bold lh-1 text-87 mb-0"><span className="d-block fs-28 fs-lg-32 fw-bold text-primary mb-lg-1">+82</span> <span className="text-primary">COLABORADORES</span> <br />EN LATAM</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-auto mx-auto align-self-lg-center">
                                <div className="d-flex w-250p w-lg-auto mx-auto px-3 position-relative">
                                    <svg className="svg-icon icon-tecno-propia me-12 me-lg-20"><use xlinkHref="#icon-tecno-propia"></use></svg>
                                    <p className="fs-18 fs-lg-29 fw-semibold text-white lh-13 mb-lg-0">DESARROLLAMOS<br />
                                        <span className="bg-white text-primary d-inline-block px-1 rounded-1 mb-1">NUESTRA PROPIA</span><br />
                                        <span className="bg-white text-primary d-inline-block px-1 rounded-1">TECNOLOGÍA</span></p>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 212 26" width="212" height="26" className="d-none d-lg-block position-absolute end-100 top-50" style={{ marginTop: -"20px" }}>
                                        <path fill="#fff" d="M210.417 11.775a1.058 1.058 0 0 1 0 1.832l-15.863 9.158a1.057 1.057 0 0 1-1.586-.916V3.532c0-.814.881-1.323 1.586-.916l15.863 9.159Z"></path>
                                        <path stroke="#fff" stroke-dasharray="2.12 2.12" stroke-width="2.115" d="M202.486 12.69H0"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="py-30 py-lg-50 px-1 position-relative overflow-hidden">
                    <img className="d-none d-lg-block pos10" src={shape08} />
                    <img className="d-none d-lg-block pos09" src={shape09} />

                    <div className="container">

                        <div className="rounded-18 shadow-3 bg-white px-30 py-30 px-lg-60 mb-30" data-animate="zoomIn">
                            <div className="row align-items-center">
                                <div className="col-lg-5 text-center">
                                    <img src={figura5} className="w-195p w-lg-260p mb-16 mb-lg-0" />
                                </div>
                                <div className="col-lg-6 ms-auto">
                                    <h2 className="fw-bold fs-30 fs-lg-32 text-primary text-center text-lg-start mb-16 mb-lg-1">Qué ofrecemos</h2>
                                    <p className="fw-bold fs-18 fs-lg-20 text-black text-center text-lg-start lh-13 mb-10 mb-lg-20">Seguros para individuos y corporativos.</p>
                                    <p className="fs-16 text-black text-center text-lg-start lh-13 mb-0">Pero no solo eso, nos involucramos en todo el proceso; cotizamos, comparamos, vendemos y gestionamos trámites, consultas y siniestros.</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-18 shadow-3 bg-white px-30 py-30 px-lg-60 mb-30" data-animate="zoomIn">
                            <div className="row align-items-center">
                                <div className="col-lg-5 text-center order-lg-last">
                                    <img src={figura6} className="w-195p w-lg-260p mb-16 mb-lg-0" />
                                </div>
                                <div className="col-lg-6 me-auto">
                                    <h2 className="fw-bold fs-30 fs-lg-32 text-primary text-center text-lg-start mb-16 mb-lg-1">Cómo lo hacemos</h2>
                                    <p className="fw-bold fs-18 fs-lg-20 text-black text-center text-lg-start lh-13 mb-10 mb-lg-20">Te ahorramos tiempo y dinero con nuestro <span className="text-nowrap">Multi-cotizador</span> de seguros.</p>
                                    <p className="fs-16 text-black text-center text-lg-start lh-13 mb-0">Te permite cotizar, comparar en tiempo real coberturas y precios, y elegir el seguro que más te conviene. Lo podés comprar 100% online, con asistencia telefónica o por chat.</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-18 shadow-3 bg-white px-30 py-30 px-lg-60 mb-30" data-animate="zoomIn">
                            <div className="row align-items-center">
                                <div className="col-lg-5 text-center">
                                    <img src={figura7} className="w-195p w-lg-260p mb-16 mb-lg-0" />
                                </div>
                                <div className="col-lg-6 ms-auto">
                                    <h2 className="fw-bold fs-30 fs-lg-32 text-primary text-center text-lg-start mb-16 mb-lg-1">Siempre innovamos</h2>
                                    <p className="fw-bold fs-18 fs-lg-20 text-black text-center text-lg-start lh-13 mb-10 mb-lg-20">Nuestra tecnología y vocación de servicio nos hace diferentes.</p>
                                    <p className="fs-16 text-black text-center text-lg-start lh-13 mb-0">El ADN de Segurarse es digital, nuestras herramientas son desarrolladas inhouse y pensadas para nuestros clientes. Nuestra tecnología es la gran aliada para que la experiencia de contratar un seguro sea fácil, online, rápida y segura!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-5 bg-f9">
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 mb-4 mg-lg-5 pb-lg-4 text-center">Enterate de las últimas noticias</h2>

                        <NoticiasSlider
                            items={quienes_somos_novedades}
                        />
                    </div>
                </div>

                <div className="py-30 py-lg-35 bg-green">
                    <div className="container px-30 px-lg-2">
                        <div className="row flex-lg-row-reverse align-items-center">
                            <div className="col-lg-5 mb-40 mb-lg-0 text-center text-lg-start">
                                <p className="ff-mukta fw-bold fs-30 lh-12 text-white mb-25">Más comprometidos que nunca con nuestro impacto positivo en las personas y el planeta</p>
                                <a href="#" className="transition-base d-inline-block w-250p py-12 rounded-3 bg-white text-green fs-20 fw-bold lh-1 text-center">Conocer más</a>
                            </div>
                            <div className="col-lg-7">
                                <div className="d-flex justify-content-between maxw-460p mx-auto mx-lg-0">
                                    <svg className="svg-icon icon-banner-sustentabilidad"><use xlinkHref="#icon-sust-01"></use></svg>
                                    <svg className="svg-icon icon-banner-sustentabilidad"><use xlinkHref="#icon-sust-02"></use></svg>
                                    <svg className="svg-icon icon-banner-sustentabilidad"><use xlinkHref="#icon-sust-03"></use></svg>
                                    <svg className="svg-icon icon-banner-sustentabilidad"><use xlinkHref="#icon-sust-04"></use></svg>
                                    <svg className="svg-icon icon-banner-sustentabilidad"><use xlinkHref="#icon-sust-05"></use></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-5">
                    <div className="container">
                        <div className="col-11 col-md-8 mx-auto mb-4 mb-lg-5">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center">
                                Las elegimos y nos elijen
                            </h2>
                            <div className="col-10 col-md-8 col-lg-7 col-xl-6 mx-auto">
                                <p className="fs-16 fs-lg-20 lh-12 text-center mb-5">Tenemos la mayor y mejor oferta aseguradora online conectada a nuestros servidores en real time.</p>
                            </div>
                        </div>

                        <CompaniasSlider
                            companias={imagesCompanias}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}
