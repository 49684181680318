import React from "react";
import { SegSvg } from "../../../Components/SegUI/SegSvg/SegSvg";
import logoSegurarse from "../../../Assets/img/logo_segurarse.svg";
import icoArgentina from "../../../Assets/img/ico-argentina.png";
import icoBrasil from "../../../Assets/img/ico-brasil.png";
import icoUruguay from "../../../Assets/img/ico-uruguay.png";
import googlePlayBadge from "../../../Assets/img/playStoreLogo.png";
import appStoreBadge from "../../../Assets/img/badge_app-store_black_es.svg";

interface HeaderProps {
    rubro: string;
}

export const Header = ({
    rubro,
}: HeaderProps) => {
    return (
        <header className="site-header-landing">
            <SegSvg />
            <nav className="navbar navbar--se navbar-expand-lg bg-white fixed-top">
                <div className="container g-0 gx-xl-4">
                    <a className="navbar-brand ms-4 ms-lg-0 me-0" href="https://segurarse.com.ar/">
                        <img className="logo-se-header" src={logoSegurarse} alt="Segurarse" />
                    </a>
                    <div className="dropdown ms-auto d-lg-none">
                        <a
                            className="dropdown-toggle dropdown-toggle--menu ps-10"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span>
                                <img src={icoArgentina} width="17" className="mb-1 me-1" alt="AR" />
                                AR
                            </span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu--se dropdown-menu--paises">
                            <li>
                                <a className="dropdown-item" href="https://segurarse.com.br/">
                                    <img src={icoBrasil} width="17" className="me-1" alt="BR" /> BR
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="https://segurarse.com.uy/">
                                    <img src={icoUruguay} width="17" className="me-1" alt="UY" /> UY
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button
                        className="navbar-toggler border-0 me-2 me-lg-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse navbar-collapse--se" id="navbarSupportedContent">
                        <ul className="navbar-nav navbar-nav--se justify-content-lg-end flex-grow-1 mt-2 mt-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="/seguros-individuos">Personas</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/seguros-corporativos">Empresas</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle dropdown-toggle--menu"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Ayuda
                                </a>
                                <ul className="dropdown-menu dropdown-menu--se">
                                    <li>
                                        <a className="dropdown-item" href="/preguntas-frecuentes">Preguntas Frecuentes</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/contacto">Contacto</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle dropdown-toggle--menu"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Nosotros
                                </a>
                                <ul className="dropdown-menu dropdown-menu--se">
                                    <li>
                                        <a className="dropdown-item" href="/quienes-somos">Quiénes somos</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/nuestro-oneteam">Sumate al #OneTeam</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/sustentabilidad">Sustentabilidad</a>
                                    </li>
                                    <li className="d-lg-none">
                                        <a className="dropdown-item" href="/politicas-privacidad">Políticas de Privacidad</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://segurarse.com.ar/blog/">Blog</a>
                            </li>
                            <li className="nav-item dropdown d-lg-none d-xl-block">
                                <a
                                    className="nav-link dropdown-toggle dropdown-toggle--menu"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Mi Segurarse
                                </a>
                                <ul className="dropdown-menu dropdown-menu--se p-lg-3">
                                    <li>Descargá la App!</li>
                                    <li className="list-inline-item">
                                        <a href="https://play.google.com/store/apps/details?id=com.segurarse.misegurarse&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                            <img className="mt-2 badge-store-menu" alt="Disponible en Google Play" src={googlePlayBadge} />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://itunes.apple.com/us/app/mi-segurarse/id1237342800?l=es&ls=1&mt=8">
                                            <img className="mt-2 badge-store-menu" alt="Descárgalo en el App Store" src={appStoreBadge} />
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown order-lg-last d-none d-lg-block">
                                <a
                                    className="nav-link dropdown-toggle dropdown-toggle--menu"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <span>
                                        <img src={icoArgentina} width="17" className="mb-1 me-1" alt="AR" /> AR
                                    </span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu--se">
                                    <li>
                                        <a className="dropdown-item" href="https://segurarse.com.br/">
                                            <img src={icoBrasil} width="17" className="me-1" alt="BR" /> BR
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="https://segurarse.com.uy/">
                                            <img src={icoUruguay} width="17" className="me-1" alt="UY" /> UY
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item text-center text-lg-start">
                                <a
                                    className="nav-link w-100 bg-primary bg-lg-gray-dark hover-bg-primary text-white nav-link-cta mt-4 mt-lg-0 d-inline-block d-lg-block"
                                    href={rubro === "auto" ? "/auto/cotizacion/patente" : "/prepaga/individuo"}

                                >
                                    <svg className="svg-icon nav-link-cta-icon me-2">
                                        <use xlinkHref={rubro === "auto" ? "#ico-auto" : ""} fill='white' />
                                    </svg>
                                    COTIZÁ
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};