import Axios from 'axios'
import { getPathName } from './helper';

const descargarPoliza = async (idPoliza: number) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        idPoliza: idPoliza
    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/GetImpresionPolizaJSON", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return error;
        });
};

export default descargarPoliza;