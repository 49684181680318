import React, { useEffect } from 'react'
import { GraciasAuto } from './GraciasAuto'
import { useNavigate, useParams } from 'react-router-dom';
import { useGraciasStore } from '../../Services/Providers/GraciasStore';
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';

export const GraciasAutoEmision = () => {
    // const { email, nombre, telefono, aseguradora } = useParams();

    const { aseguradora } = useGraciasStore();
    const { cotizacionValues } = useCotizacionStore();
    const nav = useNavigate()
    const { compania } = useParams()

    useEffect(() => {
        if (!aseguradora || !cotizacionValues.email || !cotizacionValues.nombre || !cotizacionValues.apellido || !cotizacionValues.telefono) {
            compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente")
        }
    }, [])


    return (
        <>
            <GraciasAuto email={cotizacionValues.email!} nombre={cotizacionValues.nombre! + " " + cotizacionValues.apellido!} telefono={cotizacionValues.telefono?.prefijo + cotizacionValues.telefono?.numero!} vehiculo={cotizacionValues.marca?.value + " " + cotizacionValues.version?.value + " " + cotizacionValues.año?.value} tipo='emision' aseguradora={aseguradora!} />
        </>
    )
}
