import { useState } from 'react'
import { IEmisionValues } from '../../Services/Providers/EmisionStore'
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';

interface SegCotizadoInfoProps {
    emisionValues: IEmisionValues
    tipo?: "AUTO" | "ACCIDENTES PERSONALES"
}

export const SegCotizadoInfo = ({ emisionValues, tipo = "AUTO" }: SegCotizadoInfoProps) => {

    const [isCollapsed, setCollapsed] = useState(true);

    const toggleCollapse = () => {
        // Invierte el estado de colapsado al hacer clic en el enlace
        setCollapsed(!isCollapsed);
    };

    const { cotizacionValues } = useCotizacionStore();

    return (
        <div className="ac-mobile-mob align-text-center">
            <div className="row">
                <a
                    className={`trigger ${isCollapsed ? 'collapsed' : ''}`}
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    aria-controls="collapseExample"
                    role="button"
                    aria-expanded="false"
                    onClick={toggleCollapse}
                >
                    <svg className="icon ico-car"><use xlinkHref={tipo === "AUTO" ? "#ico-auto" : "#ico-hombre"} /></svg> cotizado
                    <svg className="icon ico-arrow"><use xlinkHref={isCollapsed ? "#arrowright3" : "#arrowright2"} /></svg>
                </a>
                <div className={`collapse ${isCollapsed ? '' : 'show'}`} id="collapseExample">
                    <div className="detail">
                        {tipo === "AUTO"
                            &&
                            <p>
                                <b>{cotizacionValues.marca?.value + " " + cotizacionValues.version?.value + " " + cotizacionValues.año?.value}</b>
                                <br />
                                <b>{cotizacionValues.localidad?.label}</b> | GNC: <b>No</b>
                                <br />
                                {/* <b>{cotizacionValues.localidad?.label}</b> | GNC: <b>No</b> */}
                                <b>{"$" + emisionValues.cotizacionDesc?.amountMonth}<span>/mes</span></b>
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
