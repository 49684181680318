import { create } from 'zustand';
import { createJSONStorage, persist } from "zustand/middleware";


interface GraciasStore {
    aseguradora: string | null;
    setGraciasValues: (values: string) => void;
    resetGraciasValues: () => void;
}

export const useGraciasStore = create<GraciasStore>()(
    persist(
        (set) => ({
            aseguradora: null,
            setGraciasValues: (values: string) => set({ aseguradora: values }),
            resetGraciasValues: () => set({ aseguradora: null }),
        }),
        {
            name: "cotizacion",
            storage: createJSONStorage(() => sessionStorage),
        }
    ));