import clarinLogo from './img/logos/medios/logo_clarin.svg';
import eleconomistaLogo from './img/logos/medios/logo_el_economista.svg';
import forbesLogo from './img/logos/medios/logo_forbes.svg';
import noticiasargentinasLogo from './img/logos/medios/logo_noticias_argentinas.svg';
import noticiasLogo from './img/logos/medios/logo_noticias.svg';
import mercadoLogo from './img/logos/medios/logo_revista_mercado.svg';

import motor1Logo from './img/logos/medios/logo_motor1.svg';
import ambitoLogo from './img/logos/medios/logo_ambito.svg';
import cronistaLogo from './img/logos/medios/logo_cronista.svg';
import diariocarlospazLogo from './img/logos/medios/logo_diariocarlospaz.svg';
import bariloche2000Logo from './img/logos/medios/logo_bariloche2000.png';

const imageMedios = {
    clarinLogo,
    eleconomistaLogo,
    forbesLogo,
    noticiasargentinasLogo,
    noticiasLogo,
    mercadoLogo,
    motor1Logo,
    ambitoLogo,
    cronistaLogo,
    diariocarlospazLogo,
    bariloche2000Logo
};

export default imageMedios