import React from 'react'
import styled from '@emotion/styled'


interface SegH2Props {
    title: string
    textColor?: string
    fontSize?: number
    fontWeight?:   "bold" | "normal" | 500 | 700 
    className?:string
    backgroundColor?:string
    marginBottom?:number
}

export const SegH2 = ({
    title,
    textColor = "#000",
    fontSize = 22,
    fontWeight = "bold",
    className,
    backgroundColor,
    marginBottom

}: SegH2Props) => {

    const H2 = styled.h2`
    color: ${textColor} !important;
    font-weight:${fontWeight} !important;
    font-size:${fontSize}px !important;
    line-height:1.25;
    background-color: ${backgroundColor} !important;
    margin-bottom: ${marginBottom}px !important;
    `


    return (
        <H2 className={className}>{title}</H2>
    )
}
