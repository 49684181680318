import { SegHeaderLogo } from "../../../Components/SegUI/SegHeaderLogo/SegHeaderLogo";
import segurarse from "../../../Assets/img/logo_segurarse.svg";
import "@splidejs/react-splide/css";
import { imagesCompanias } from "../../../Assets/imageCompania";
import afip from "../../../Assets/img/DATAWEB.jpg";
import { SegSvg } from "../../../Components/SegUI/SegSvg/SegSvg";
import { useEffect, useMemo, useState } from "react";
import { ModalPrecios } from "../../../Components/Slides/Prepaga/Precios/ModalPrecios";
import { useNavigate, useParams } from "react-router-dom";
import { useCotizacionStore } from "../../../Services/Providers/CotizacionStore";
import { SegLoading } from "../../../Components/SegUI/SegLoading/SegLoading";
import axios from "axios";
import { useDescripcionesStore } from "../../../Services/Providers/DescripcionesStore";
import { useEmisionStore } from "../../../Services/Providers/EmisionStore";
import guardarContactoPrepaga from "../../../Services/API/GuardarContactoPrepaga";
import { Grilla } from "../../../Components/Slides/Prepaga/Precios/Grilla";
import { Cards } from "../../../Components/Slides/Prepaga/Precios/Cards";
import descripcionesPlanMedico from "../../../Services/Data/DescripcionesPrepagaMedica.json";
import cotizarPlanMedico from "../../../Services/API/CotizarPlanMedico";
import parametrosInicialesPlanMedico from "../../../Services/Data/ParametrosInicialesPlanMedico.json";
import useSEOConfig from "../../../hooks/seo/useSEOConfig";

export interface CotizacionPrepaga {
  id: null | string;
  nroCotizacion: string;
  codigoCobertura: string;
  descripcionCobertura: string;
  descripcionCoberturaOriginal: string;
  nombreCobertura: string;
  prima: number;
  franquicia: string;
  premio: string;
  columna: string;
  LogoCompania: string;
  NombreCompania: string;
  Mostrar: boolean;
  idPLan: string;
  nombrePlan: string;
  prestaciones: Prestacion[];
  prestacionesDescripciones: string[];
  icluyePrestacion: string[];
  Afip: string;
}

interface Prestacion {
  descripcion: string;
  id: number;
  incluye: string;
  titulo: string;
}

interface Plan {
  habilitado: string;
  id: string;
  nombre: string;
  prestaciones: Prestacion[];
  columna: string;
}

interface Compania {
  compania: string;
  habilitado: string;
  planes: Plan[];
}

interface DescripcionesPlanMedico {
  companias: Compania[];
}

export interface CotizacionDescPrepaga {
  id: string;
  planName: string;
  descList: any[];
  premio: string;
  companyLogo: string;
  companyName: string;
  fiscalInfoImg: string;
}

export const Precios = () => {
  const { emisionValues, setEmisionValues } = useEmisionStore();
  const {
    cotizacionValues,
    setCotizacionValues,
    vaciarCotizaciones,
  } = useCotizacionStore();
  const { descripcionesValues } = useDescripcionesStore();
  const [cotizando, setCotizando] = useState<boolean>(false);
  const [cargando, setCargando] = useState<boolean>(false);
  const nav = useNavigate();

  const [isCollapsed, setCollapsed] = useState(true);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const key = process.env.REACT_APP_RECAPTCHA_KEY;

  const handleScroll = () => {
    // Aquí puedes añadir lógica para determinar si el componente está abierto
    // y luego colapsarlo si es necesario
    if (!isCollapsed) {
      // Lógica para colapsar el componente
      setCollapsed(true);
    }
  };

  useSEOConfig({
    title: "Prepaga",
  })

  useEffect(() => {
    // Añade el event listener al objeto window
    window.addEventListener("scroll", handleScroll);
    // Limpia el event listener al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isCollapsed]);

  const toggleCollapse = () => {
    // Invierte el estado de colapsado al hacer clic en el enlace
    setCollapsed(!isCollapsed);
  };

  // const ajustarDescripciones = async (coti: any) => {
  //   try {
  //     let descripciones: Descripcion[] = [];
  //     const descripcionesGuardadas = descripcionesValues;
  //     (Object.keys(
  //       descripcionesGuardadas
  //     ) as (keyof typeof descripcionesGuardadas)[]).forEach((key, index) => {
  //       const desc = descripcionesGuardadas[index];
  //       if (
  //         desc.CompaniaDescripcion === coti.NombreCompania &&
  //         desc.CodigoCompania === coti.codigoCobertura
  //       ) {
  //         let texto;
  //         if (desc.Texto === "si") {
  //           texto = "Si";
  //         } else if (desc.Texto === "no") {
  //           texto = "No";
  //         } else {
  //           texto = desc.Texto;
  //         }
  //         let nuevaDesc: Descripcion = {
  //           Nombre: desc.ItemDescripcion2Nombre,
  //           Aclaracion: desc.Aclaraciones,
  //           Descripcion: texto,
  //         };
  //         descripciones.push(nuevaDesc);
  //       }
  //     });
  //     return descripciones;
  //   } catch (error) { }
  // };

  const handleSetCotizacionDesc = (cotizacion: CotizacionDescPrepaga) => {
    setEmisionValues({
      ...emisionValues,
      CotizacionDescPrepaga: cotizacion,
    });
  };

  const descripcionesResumen = (descripciones: any[]) => {
    // Crea un objeto que mapee los nombres de cobertura con sus descripciones
    const descripcionMap: { [nombre: string]: string } = {};
    descripciones.forEach((desc: any) => {
      descripcionMap[desc.Nombre] = desc.Descripcion || "No";
    });

    // Obtén las descripciones cortas
    //   const textosCortos = [
    //     descripcionMap["Cristales Laterales"] || "No",
    //     descripcionMap["Daño Parcial por Accidente con franquicia"] ||
    //     "No aplica",
    //     descripcionMap["Cobertura en Exterior"] || "No",
    //     descripcionMap["Auxilio Mecánico y Remolque"] || "No",
    //     descripcionMap["Granizo"] || "No",
    //   ];

    //   return textosCortos;
    // };

    // const descripcionesResumen = (descripciones: any[]) => {
    //     var textosCortos = [
    //         descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales") ? (descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Cristales Laterales").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km") ? (descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Reposición a 0km").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior") ? (descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Cobertura en Exterior").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque") ? (descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Auxilio Mecánico y Remolque").Descripcion : "No") : "No",
    //         descripciones.find((desc: any) => desc.Nombre === "Granizo") ? (descripciones.find((desc: any) => desc.Nombre === "Granizo").Descripcion ? descripciones.find((desc: any) => desc.Nombre === "Granizo").Descripcion : "No") : "No",
    //     ]
    //     return textosCortos;
  }

  const descripcionCobertura = (columna: string) => {
    switch (columna) {
      case "B":
        return "PLAN CLÁSICO";
      case "C":
        return "PLAN SEMI FULL";
      case "D":
        return "PLAN FULL";
      case "E":
        return "PLAN PREMIUM";
      default:
        return "";
    }
  };

  const obtenerTokenRecaptcha = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function () {
        grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
          resolve(token);
        });
      });
    });
  };



  const cotizarInterno = async (compania: any, indexCompania: number) => {
    let nuevoArray: CotizacionPrepaga[] = [];
    const descripcionesPlanMedicoData = descripcionesPlanMedico as DescripcionesPlanMedico;
    try {

      let token = await obtenerTokenRecaptcha();
      const response: any = await cotizarPlanMedico(

        compania.codigoCompania,
        cotizacionValues.contactoId!,
        compania.emisionOnlineHabilitada,
        cotizacionValues.token!,
        source,
        token,
      );
      if (response.data.api_status) {
        if (
          response.data.api_status === 200 &&
          response.data.api_data !== null
        ) {
          if (response.data.api_data.items.length > 0) {
            for (
              let index = 0;
              index < response.data.api_data.items.length;
              index++
            ) {
              let cotiNueva = response.data.api_data.items[index];
              let auxPrecio: number = Number.parseInt(cotiNueva.premio);
              cotiNueva.premio = auxPrecio.toLocaleString("es-AR");
              descripcionesPlanMedicoData.companias.forEach((companiaJson: Compania) => {
                if (companiaJson.habilitado === "Si") {
                  companiaJson.planes.forEach((plan: Plan) => {
                    if (plan.habilitado === "si" && cotiNueva.codigoCobertura === plan.id) {
                      cotiNueva.LogoCompania = encontrarRuta(companiaJson.compania);
                      cotiNueva.NombreCompania = companiaJson.compania;
                      cotiNueva.Mostrar = true;
                      cotiNueva.idPlan = plan.id;
                      cotiNueva.nombrePlan = plan.nombre;
                      cotiNueva.prestaciones = plan.prestaciones;
                      cotiNueva.prestacionesDescripciones = plan.prestaciones.map((prestacion: Prestacion) => prestacion.descripcion);
                      cotiNueva.incluyePrestacion = plan.prestaciones.map((prestacion: Prestacion) => prestacion.incluye);
                      cotiNueva.DescResumen = descripcionesResumen(cotiNueva.prestacionesDescripciones);
                      cotiNueva.nombreCobertura = descripcionCobertura(cotiNueva.columna);
                      cotiNueva.Afip = afip;
                      nuevoArray.push(cotiNueva);
                    }
                  });
                }
              });
              if (index === response.data.api_data.items.length - 1) {
                if (nuevoArray.length > 0) {
                  return nuevoArray;
                } else {
                  return [];
                }
              }
            }
          }
        }
        return [];
      }
    } catch (error) { }
    return nuevoArray.length > 0 ? nuevoArray : [];
  };

  const fetchData = async () => {
    const params = {
      origen: cotizacionValues?.parametrosIniciales.origen,
      tipoCotizador: cotizacionValues?.parametrosIniciales.tipoCotizador,
      landingUrl: cotizacionValues?.landingUrl,
      refererUrl: cotizacionValues?.referer,
      // 1
      tipoDoc: cotizacionValues?.tipoDoc?.value,
      cuit: cotizacionValues?.cuit,
      numeroDoc: cotizacionValues?.numeroDoc,
      razonSocial: cotizacionValues?.razonSocial,
      nombre: cotizacionValues?.nombre,
      apellido: cotizacionValues?.apellido,
      email: cotizacionValues?.email,
      codigoArea: cotizacionValues?.telefono?.prefijo,
      telefono: cotizacionValues?.telefono?.numero,
      provincia: cotizacionValues?.provincia?.value,
      localidad: cotizacionValues?.localidad?.value,
      FechaNacimiento: cotizacionValues?.fechaNac,
      DerivaAportes: cotizacionValues?.derivaAportes,
      SueldoBruto: isNaN(Number(cotizacionValues?.masaSalarial)) ? 0 : Number(cotizacionValues?.masaSalarial),

      //2
      Observaciones: cotizacionValues?.observaciones,
      Conyuge: cotizacionValues?.conyuge,
      DocumentoConyuge: cotizacionValues?.documentoConyuge,
      UnificaAportes: cotizacionValues?.unificarAportes,
      SueldoBrutoConyuge: isNaN(Number(cotizacionValues?.sueldoBrutoConyuge)) ? 0 : Number(cotizacionValues?.sueldoBrutoConyuge),
      CantidadHijos: isNaN(Number(cotizacionValues?.cantHijos?.value)) ? 0 : Number(cotizacionValues?.cantHijos?.value),
      empleados: isNaN(Number(cotizacionValues?.empleados)) ? 0 : Number(cotizacionValues?.empleados),
      captchaToken: cotizacionValues?.captchaToken,
    };
    const response: any = await guardarContactoPrepaga(params);
    return response;
  };

  useEffect(() => {
    vaciarCotizaciones();
    if (cotizacionValues.parametrosIniciales) {
      if (!esVigenciaCotizacionesValida()) {
        setTimeout(() => {
          setCargando(false);
        }, 10000);
        setCargando(true);
      }

      if (!cotizacionValues?.contactoId) {
        fetchData()
          .then((response) => {
            if (response.data) {
              if (
                response.data.api_status === 200 &&
                response.data.api_data !== null
              ) {
                setCotizacionValues({
                  ...cotizacionValues,
                  contactoId: response.data.api_data.idContacto,
                  token: response.data.api_data.token,
                  captchaToken: "",
                  parametrosIniciales: parametrosInicialesPlanMedico,
                });
              }
            }
          })
          .catch((err) => {
            if (err) {
              nav("/prepaga/error");
            }
          });
      }
    } else {
      // compania ? nav("/auto/cotizacion/" + compania + "/patente") : nav("/auto/cotizacion/patente")
    }
  }, []);

  useEffect(() => {
    if (cotizacionValues.contactoId) {
      dispararCotizacion();
    }
    return () => {
      source.cancel();
    };
  }, [cotizacionValues.contactoId]);

  const esVigenciaCotizacionesValida = (): boolean => {
    if (
      cotizacionValues.cotizaciones &&
      cotizacionValues.cotizaciones.length > 0
    ) {
      const fechaEnSesion = new Date(
        cotizacionValues.fechaConsultaCotizaciones + ""
      );
      const fechaActual = new Date();
      if (
        fechaActual.getFullYear() !== fechaEnSesion?.getFullYear() ||
        fechaActual.getMonth() !== fechaEnSesion.getMonth() ||
        fechaActual.getDate() !== fechaEnSesion.getDate()
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const dispararCotizacion = async () => {
    if (esVigenciaCotizacionesValida()) return;

    setCotizando(true);
    let nuevasCotis: CotizacionPrepaga[] = [];
    try {
      const fechaConsultaCotizaciones = new Date();

      await Promise.all(
        cotizacionValues.parametrosIniciales.companias.map(
          async (compania: any, index: number) => {
            const results = await cotizarInterno(compania, index);
            if (results && results.length > 0) {
              nuevasCotis = nuevasCotis.concat(results);
              setCotizacionValues({
                ...cotizacionValues,
                fechaConsultaCotizaciones,
                cotizacionesPrepaga: nuevasCotis,
              });
            }
          }
        )
      );

    } catch (error) {
      setCotizacionValues({
        ...cotizacionValues,
        cotizaciones: [],
      });
    } finally {
      setCotizando(false);
    }
  };

  /*const fetchCotis = async (compania: any, index: number) => {
        return await cotizarInterno(compania, index);
    }*/

  const encontrarRuta = (compania: string) => {
    if (compania) {
      let find = imagesCompanias.find(
        (comp) =>
          comp.nombreCompania.toLocaleLowerCase() ===
          compania.toLocaleLowerCase()
      );
      if (find) {
        return find.imgRuta;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const irAInicio = (event: any) => {
    event.preventDefault();
    setCotizacionValues({
      ...cotizacionValues,
      contactoId: undefined,
    });
  };

  const segLoadingComponent = useMemo(
    () => (
      <SegLoading
        loadingTitle="Cargando"
        icon="#ico-prepaga"
        product={""}
        startText="Tu cotización esta casi lista. Estamos buscando"
        middleText=" en tiempo real "
        endText="las mejores coberturas y precios para vos"
        captions={[
          {
            value: "Gratuito",
          },
          {
            value: "Online",
          },
          {
            value: "Personalizado",
          },
        ]}
      />
    ),
    []
  );

  return (
    <>
      <ModalPrecios cotizacionDesc={{ ...emisionValues.CotizacionDescPrepaga! }} />
      {
        cargando ? segLoadingComponent :
          <div>
            <SegSvg />
            <header className="site-header sticky-lg-top">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                    <SegHeaderLogo img={segurarse} alt="Logo Segurarse" width="160px" />
                  </div>
                </div>
              </div>
            </header>
            <main className="sec-precios">
              <div className="main-wrapper">
                <div className="ac-mobile d-lg-none">
                  <div className="wrapper">
                    <a
                      className={`trigger ${isCollapsed ? 'collapsed' : ''}`}
                      data-bs-toggle="collapse"
                      href="#collapseExample"
                      aria-controls="collapseExample"
                      role="button"
                      aria-expanded="false"
                      onClick={toggleCollapse}
                    >
                      <svg className="icon ico-car"><use xlinkHref="#ico-auto" /></svg> cotizado
                      <svg className="icon ico-arrow"><use xlinkHref="#arrowright2" /></svg>
                    </a>
                  </div>
                </div>
                {(window.innerWidth > 768) &&
                  <>
                    <Grilla cotizando={cotizando} cotizaciones={cotizacionValues.cotizacionesPrepaga || []} setDescripciones={handleSetCotizacionDesc} />
                  </>
                }
                <Cards cotizando={cotizando} cotizaciones={cotizacionValues.cotizacionesPrepaga || []} setDescripciones={handleSetCotizacionDesc} />
              </div>
            </main >
          </div >
      }
    </>
  );
};
