interface Props {
    titulo: string;
    descripcion: string;
    icono: string;
}

export const CardIconoDetalle = (data: Props) => {
    return (
        <div className="motivos-wrapper" data-animate="zoomIn">
            <div className="motivos-icon-wrapper">
                <svg className="svg-icon icon-motivos">
                    <use xlinkHref={data.icono}></use>
                </svg>
            </div>
            <h3 className="fs-16 fs-ss-20 fs-lg-24 fw-bold text-primary">{data.titulo}</h3>
            <p className="fs-14 fs-lg-18 lh-sm mb-0">{data.descripcion}</p>
        </div>
    )
}