import Axios from 'axios'
import { getPathName } from './helper';

type ContactoCelularParams = {
    origen?: string;
    rubro?: string;
    isMobile?: boolean;
    tipoCotizador?: string;
    landingUrl?: string;
    refererUrl?: string;
    nombre?: string;
    apellido?: string;
    codigoArea?: string;
    telefono: {
        prefijo?: string;
        numero?: string;
    }
    email?: string;
    token?: string;
    captchaToken: string;
    parametrosIniciales: {
        origen?: string,
    };
};

const guardarContactoCelular = async (params: ContactoCelularParams) => {
    let data: Record<string, any> = {
        origen: params.parametrosIniciales.origen,
        isMobile: "false",
        landingUrl: params.landingUrl,
        refererUrl: params.refererUrl,
        rubro: "CELULAR",
        hash: process.env.REACT_APP_HASH,

        nombre: params.nombre,
        apellido: params.apellido,

        codigoArea: params.telefono.prefijo ?? null,
        telefono: params.telefono.numero ?? null,

        email: params.email ?? null,
        RefererUrl: params.landingUrl,
        g_recaptcha_response: params.captchaToken
    };
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    return await Axios.post(getPathName() + "/SetCotizacionCelularJSON", data,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
};

export default guardarContactoCelular;