import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect'
import { ImageMarca, images } from '../../../../Assets/imageMarca'
import { SegH2 } from '../../../SegUI/SegH2/SegH2'
import { SegCardMarca } from '../../../SegUI/SegCardMarca/SegCardMarca'
import { IPasos } from '../../../../Pages/Auto/Cotizador'
import obtenerMarcas from '../../../../Services/API/Marcas'
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore'
import { EmpresaProps } from '../../../../Services/Providers/useConfiguracionEmpresa'

interface MarcaProps {
    config: EmpresaProps;
    setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Marca = ({ config, setSteps, ...rest }: MarcaProps) => {
    const [marcaSelected, setMarcaSelected] = useState<SegSelectOption>()
    const [openMarca, setOpenMarca] = useState<boolean>(false)
    const [marcas, setMarcas] = useState<SegSelectOption[]>();
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
    const { compania } = useParams();
    const methods = useForm();

    const onImageClick = (value: string) => {
        const marca = { value: value.toUpperCase(), label: value.toUpperCase() };
        setMarcaSelected(marca);
        handleSubmit(marca)
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const response: any = await obtenerMarcas();
            const formattedData: SegSelectOption[] = response?.map((item: any, index: any) => ({
                value: item,
                label: item,
            }));
            formattedData.splice(0, 10)
            formattedData.unshift({ value: 'separator', label: '----------' });
            formattedData.unshift({ value: 'NISSAN', label: 'NISSAN' });
            formattedData.unshift({ value: 'TOYOTA', label: 'TOYOTA' });
            formattedData.unshift({ value: 'HONDA', label: 'HONDA' });
            formattedData.unshift({ value: 'SUZUKI', label: 'SUZUKI' });
            formattedData.unshift({ value: 'CHRYSLER', label: 'CHRYSLER' });
            formattedData.unshift({ value: 'PEUGEOT', label: 'PEUGEOT' });
            formattedData.unshift({ value: 'FORD', label: 'FORD' });
            formattedData.unshift({ value: 'CHEVROLET', label: 'CHEVROLET' });
            formattedData.unshift({ value: 'VOLKSWAGEN', label: 'VOLKSWAGEN' });
            formattedData.unshift({ value: 'CITROEN', label: 'CITROEN' });
            formattedData.unshift({ value: 'FIAT ', label: 'FIAT' });
            formattedData.unshift({ value: 'RENAULT', label: 'RENAULT' });


            // Agregar separador
            setMarcas(formattedData);
        };
        fetchData().then(() => {
            setLoading(false);
            if (cotizacionValues?.marca) {
                setMarcaSelected(cotizacionValues?.marca)
                setOpenMarca(true)
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                });

            }
        })


    }, []);





    const handleSubmit = (marcaSelecte: any) => {
        setCotizacionValues({
            ...cotizacionValues,
            marca: marcaSelecte,
            modelo: marcaSelecte === cotizacionValues?.marca ? cotizacionValues?.modelo : undefined,
            version: marcaSelecte === cotizacionValues?.marca ? cotizacionValues?.version : undefined,
            año: marcaSelecte === cotizacionValues?.marca ? cotizacionValues?.año : undefined
        })
        compania ? nav("/auto/cotizacion/" + compania + "/modelo") : nav("/auto/cotizacion/modelo");
    }





    return (
        <div {...rest}>
            <div className='mt-4 d-flex flex-column pb-5'>
                <div className='text-center mb-4'>
                    <SegH2 title='¿Cuál es la marca?' />
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='col-11 col-sm-10 col-md-10 col-lg-7 columns-grid'>
                        <div className='d-flex gap-5 flex-wrap justify-content-center gap-mobile'>
                            {
                                images?.map((img: ImageMarca) => {
                                    return <SegCardMarca key={img.marca} img={img.image} 
                                    label={img.marca.charAt(0).toUpperCase() + img.marca.slice(1)} alt={img.marca} 
                                    onClick={onImageClick} 
                                    selected={marcaSelected} 
                                    selectedBackgroundColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <div className='col-8 col-sm-7 col-md-7 col-lg-3'>
                        {marcas
                            ?
                            <FormProvider {...methods}>
                                <SegSelect
                                    options={marcas} width={"100%"} height='sm'
                                    selected={marcaSelected} setSelected={setMarcaSelected}
                                    name='marca' openDefault={openMarca}
                                    handleSubmit={handleSubmit} loading={loading}
                                    hoverBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                    focusBorderColor={config.origen !== "SEGURARSE" ? config.colors.accent : undefined}
                                    empresaColor={config.origen !== "SEGURARSE" ? {
                                        selected: config.colors.accent,
                                        hover: config.colors.tertiary
                                    } : undefined}
                                />
                            </FormProvider>
                            :
                            <div className='d-flex justify-content-center'>
                                <div className="spinner-border text-center" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/* <div className='d-flex justify-content-center mt-5 mb-5'>
                    <SegButton ref={continueButtonRef} loading={loading} type='button' label='Continuar' onClick={() => { handleSubmit() }} disabled={!marcaSelected ? true : false} />
                </div> */}
            </div>

        </div>
    )
}
