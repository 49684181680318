import Axios from 'axios'
import { getPathName } from './helper';
import { ITelefono } from '../Providers/EmisionStore';


export interface SolicitudCotizacionAP {
    //rubro: string;
    //compania: string;

    // Datos Personales
    nombre: string;
    apellido: string;
    telefono: {
        CodigoArea: string;
        Numero: string;
    };
    email: string;
    localidad: string;
    provincia: string;
    departamento?: string;
    codigoPostal: string;

    // Datos Necesarios
    profesion: string;
    profesionDescripcion?: string;
    profesionDedicada?: string;

    condicionIVA: string;
    tipoPersona: string;
    condicionIB: string;
    condicionIIBB?: any;

    tipoDocumento: string;
    numeroDocumento: string;

    fechaInicio: string; // Formato de fecha: YYYY-MM-DD
    fechaFin: string;    // Formato de fecha: YYYY-MM-DD

    cantidad: number;
    sumaMuerte: number;
    sumaGastos: number;
    sumaInvalidez: number;
}

export const guardarContactoAccidentesPersonales = async (params: SolicitudCotizacionAP, captchaToken: string) => {
    let data: Record<string, any> = {
        g_recaptcha_response: captchaToken,
        hash: process.env.REACT_APP_HASH,
        cotizacion: {
            ...params,
            rubro: "AP"
        }
    }

    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    return await Axios.post(getPathName() + "/SetCotizacionAPJSON", data,
        {
            headers: headers
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
}