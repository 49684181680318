import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import { Header as NavBar } from '../Components/Header'
import hero from "../../../Assets/img/figura-hero-02.svg";
import shape from "../../../Assets/img/shape02.svg";
import Asesoramiento from '../Components/Asesoramiento';

export const Contacto = () => {
    return (
        <>
            <SegSvg />
            <NavBar rubro='auto' />
            <main>
                <div className="pt-50 pb-30 px-3 py-lg-80 position-relative">
                    <img className="d-none d-lg-block pos02" src={shape} />
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start">Contacto</h2>
                                <p className="fs-16 fs-lg-20 text-black text-center text-lg-start mb-20 mb-lg-40">Tenemos un <b>equipo de asesores</b> dispuestos a contestar todas tus consultas. <br className="d-sm-none" />
                                    Elegí el canal que se adapte a vos.
                                </p>

                                <div className="mx-auto mx-lg-0 maxw-330p mb-25 mb-lg-40">
                                    <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target="_blank" rel='noreferrer' className="d-flex align-items-center py-2 px-12 border border-1 border-878FA3 rounded-3 shadow-2">
                                        <svg className="svg-icon me-2" width="27" height="27"><use xlinkHref="#whatsapp"></use></svg>
                                        <span className="fs-19 text-black fw-bold">+54 9 11 6956 8002</span>
                                    </a>

                                    <div className="mb-20 mb-lg-30"></div>

                                    <a href="tel:0810-444-7348" target='_blank' rel="noreferrer" className="d-flex align-items-center py-2 px-12 border border-1 border-878FA3 rounded-3 shadow-2">
                                        <svg className="svg-icon me-2" width="27" height="27"><use xlinkHref="#icon-tel-circle"></use></svg>
                                        <span className="fs-19 text-black fw-bold">0810-444-SEGURO (7348)</span>
                                    </a>
                                </div>

                                <div className="fs-17 fs-lg-18">
                                    <b>Horarios de atención:</b><br />
                                    - Ventas: lunes a viernes <br className="d-sm-none" />de 08:30 a 20:30hs.<br />
                                    - Atención al cliente: lunes a viernes <br className="d-sm-none" />de 09:00 a 18:00hs.
                                </div>
                            </div>
                            <div className="col-lg-6 text-center text-lg-end pt-4 pt-lg-0">
                                <img src={hero} className="fig-hero-contacto" />
                            </div>
                        </div>
                    </div>
                </div>

                <Asesoramiento />
            </main>
        </>
    )
}
