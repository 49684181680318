import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegButton } from "../../../SegUI/SegButton/SegButton";
import { SegInput } from "../../../SegUI/SegInput/SegInput";
import { IPasos } from "../../../../Pages/Prepaga/Individuo/Cotizador";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler, FormProvider } from "react-hook-form";
import { useCotizacionStore } from "../../../../Services/Providers/CotizacionStore";
import { SegSelect, SegSelectOption } from "../../../SegUI/SegSelect/SegSelect";
import { SegSlider } from "../../../SegUI/SegSlider/SegSlider";
import { imagesCompanias } from "../../../../Assets/imageCompaniaPrepaga";
import styled from '@emotion/styled';

interface IDocumento {
  tipoDoc: string;
  numero: string;
}

interface DocumentoProps {
  setSteps: Dispatch<SetStateAction<IPasos>>
}

export const Documento = ({
  setSteps,
  ...rest
}: DocumentoProps) => {
  const nav = useNavigate();
  const { compania } = useParams();
  const { cotizacionValues, setCotizacionValues } = useCotizacionStore();
  const [errorNumero, setErrorNumero] = useState<boolean>(true)

  const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
  const [openSelect, setOpenSelect] = useState<boolean>(false)
  const numeroInputRef = useRef<HTMLInputElement>(null);

  const [numero, setNumero] = useState<string>();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<IDocumento>();
  //const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const adaptativeMargin = `${10 / (window?.devicePixelRatio || 1)}vh`

  const onSubmit = (values: IDocumento) => {
    const { numero } = values;
    setCotizacionValues({
      ...cotizacionValues,
      tipoDoc: tipoDoc,
      cuit: numero,
      numeroDoc: numero,
    });
    if (tipoDoc?.value === "dni") {
      if (compania) {
        nav("/prepaga/individuo" + compania + "/nombre-y-apellido")
      }
      else {
        nav("/prepaga/individuo/cotizacion/nombre-y-apellido")
      }
    }
    else {
      if (compania) {
        nav("/prepaga/corporativo" + compania + "/razon-social")
      }
      else {
        nav("/prepaga/corporativo/cotizacion/razon-social")
      }
    }

  };

  const methods = useForm<IDocumento>();

  useEffect(() => {
    if (numero) {
      setErrorNumero(false)
    }
  }, [numero])

  useEffect(() => {
    if (cotizacionValues?.tipoDoc) {
      setTipoDoc(cotizacionValues?.tipoDoc)
      setValue("tipoDoc", cotizacionValues.tipoDoc.value);
      setOpenSelect(false)
    } else {
      setOpenSelect(true)
    }
    if (cotizacionValues.cuit) {
      setNumero(cotizacionValues.cuit)
      setValue("numero", cotizacionValues.cuit)
      setErrorNumero(false)
    }
  }, []);

  useEffect(() => {
    setValue("tipoDoc", tipoDoc ? tipoDoc.value : "");
    setValue("numero", numero ? numero : "");
    // tipoDoc && trigger();
    // numero && trigger();
  }, [tipoDoc, numero]);

  useEffect(() => {
    if (tipoDoc?.value !== cotizacionValues.tipoDoc?.value) {
      setErrorNumero(true)
      setValue("numero", "");
      numero && trigger()
      numeroInputRef.current?.focus();
    }
  }, [tipoDoc]);

  //Tipos de cobertura
  const tiposDoc = [
    { value: 'dni', label: 'Individual' },
    { value: 'cuit', label: 'Corporativo' }
  ];

  return (
    <div className="mt-4 bg-gray-light text-center" {...rest}>
      <div className="frm01">
      <legend style={{ fontWeight: "bold" }}>Cotizá prepaga médica y encontrá el mejor precio online.</legend>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center">

            <div className="mt-2">
              <div className="d-flex justify-content-center">
                <div className="row gx-3 text-start mw-sm-450 m-1">
                  <legend className="mb-4 text-center" style={{fontSize: "20px"}}>Elegí el plan que querés cotizar</legend>
                  <FormProvider {...methods}>
                    <SegSelect
                      selected={tipoDoc}
                      setSelected={setTipoDoc}
                      name="tipoDoc"
                      options={tiposDoc}
                      isGrupable
                      width={"100%"}
                      borderColor={errors.tipoDoc ? "red" : ""}
                      openDefault={openSelect}
                      autoFoco={true}
                      placeholder=""
                      handleSubmit={() => {
                        if (cotizacionValues.razonSocial !== '')
                          setCotizacionValues({
                            ...cotizacionValues,
                            nombre: '',
                            apellido: '',
                          })
                        setNumero('')
                      }}
                    />
                  </FormProvider>

                  <div style={tipoDoc ? {} : { display: "none" }}>
                    <legend className="mt-4 text-center">{`Ingresá tu ${tipoDoc?.value === 'dni' ? "CUIL" : "CUIT"}`}</legend>
                    <Controller
                      name="numero"
                      control={control}
                      rules={{
                        validate: {
                          required: (value) => {
                            if (!value) {
                              setErrorNumero(true)
                              return "Ingresá el Número"
                            } else {
                              setErrorNumero(false)
                            }
                          },
                          pattern: (value) => {
                            if (tipoDoc?.value === 'dni') {
                              if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                setErrorNumero(false)
                              } else {
                                setErrorNumero(true)
                                return "CUIL inválido"
                              }
                            } else if (tipoDoc?.value === 'cuit') {
                              if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                setErrorNumero(false)
                              } else {
                                setErrorNumero(true)
                                return "CUIT inválido"
                              }
                            }
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <SegInput
                          label={`Sólo numeros, sin puntos ni guiones ni espacios`}
                          name="setNumero"
                          type="number"
                          width={100}
                          placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                          value={field.value}
                          autoFocus={false}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue("numero", e.target.value); // Actualiza el valor en React Hook Form
                            trigger("numero");
                          }}
                          leyendaError={fieldState?.error?.message}
                          borderColor={`${errors.numero
                            ? "var(--bs-form-invalid-border-color)"
                            : ""
                            }`}
                          focusBorderColor={`${errors.numero
                            ? "var(--bs-form-invalid-border-color)"
                            : "#2c7dfd"
                            }`}
                          ref={numeroInputRef}

                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="form-buttons">
            <SegButton
              label="Continuar"
              type="submit"
              className="btn btn-primary"
              disabled={errorNumero}
            />
          </div>
        </form>
      </div>
      <div className="slide-cias" style={{ marginTop: adaptativeMargin }}>
        <SegSlider companias={imagesCompanias} />
      </div>
    </div>
  );
};