import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Companias } from '../../../Assets/imageCompania';
import '@splidejs/splide/dist/css/splide.min.css';

interface Props {
    companias: Companias[];
}

export const CompaniasSlider = ({ companias }: Props) => {
    return (
        <div className="container">
            <Splide
                options={{
                    type: 'loop',
                    drag: 'free',
                    snap: true,
                    perMove: 1,
                    autoplay: true,
                    interval: 2000,
                    arrows: false,
                    pagination: false,
                    mediaQuery: 'min',
                    perPage: 2,
                    breakpoints: {
                        576: {
                            perPage: 2,
                        },
                        768: {
                            perPage: 4,
                        },
                        992: {
                            perPage: 5,
                        },
                        1200: {
                            perPage: 6,
                        },
                    }
                }}
            >
                {companias.filter(compania => compania.mostrar)
                    .map((compania, index) => (
                        <SplideSlide key={index}>
                            <img className="img-fluid logo-aseguradora" src={compania.imgRuta}></img>
                        </SplideSlide>
                    ))}

            </Splide>
        </div>
    )
}
