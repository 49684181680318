import { useEffect, useState } from "react";
import { CotizacionDesc } from "../../../../Pages/Auto/Precios";
import { SegTooltip } from "../../../SegUI/SegTooltip/SegTooltip";
//import { useGraciasStore } from "../../../../Services/Providers/GraciasStore";
import { ModalDescripcion } from "./ModalDescripcion";
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore'
import { OffCanvas } from "./OffCanvas";

interface ModalPreciosEmisionProps {
    cotizacionDesc: CotizacionDesc
    isEmision?: boolean
}

export const ModalPreciosEmision = ({ cotizacionDesc, isEmision = false }: ModalPreciosEmisionProps) => {
    const { emisionValues } = useEmisionStore();
    //const { setGraciasValues } = useGraciasStore();
    //const { compania } = useParams();
    const [indiceAbierto, setIndiceAbierto] = useState<number | null>(null);

    /*useEffect(() => {
        const handlePopState = () => {
            // Detectar cuando el usuario navega hacia atrás en el historial
            // y mostrar un alert
            setIsModalOpen(false);

            const offcanvasElement = document.getElementById("offcanvas");
            if (offcanvasElement) {
                offcanvasElement.classList.add("collapse");
                offcanvasElement.classList.remove("show");
            }
        };

        // Agregar un listener para detectar el evento 'popstate'
        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);*/


    useEffect(() => {
        setIndiceAbierto(-1)
    }, [cotizacionDesc]);

    const handleClick = (indice: number) => {
        setIndiceAbierto(prevIndice => (prevIndice === indice ? null : indice));
    };

    return (
        <OffCanvas className={isEmision === false ? "offcanvas offcanvas-end oc-cob" : "offcanvas offcanvas-end oc-cob oc-cob1"}>
            {isEmision === false ? <div className="oc-sum ">
                <div className="cobertura">{cotizacionDesc?.planName}</div>
                <div className="empresa">
                    <img src={cotizacionDesc?.companyLogo} alt={cotizacionDesc?.companyName} />
                </div>
                <div className="descuento">
                    <span className="antes">{"$" + cotizacionDesc?.discountAmount}</span>
                    {/* <span className="ahora">{"-" + discountPerc + "%"}</span> */}
                </div>
                <div className="precio">
                    <span className="monto">{"$" + cotizacionDesc?.amountMonth}</span>
                    <span className="periodo"> /mes</span>
                </div>
                {/*isEmision === false ? (
                    <div className="cta">
                        {cotizacionDesc?.id === null ?
                            <Link to={compania ? "/auto/gracias/" + compania : "/auto/gracias/"} className="btn btn-primary" onClick={() => { setGraciasValues(cotizacionDesc?.companyName) }} >Contratar</Link>
                            :
                            <div>
                                {process.env.REACT_APP_EMISION_TEST === "true" ? <a className="btn btn-primary" type="button" onClick={() => handleSubmit("/auto/emision/revision")}>Contratar</a> : <a
                                    href={`${process.env.REACT_APP_URL_EMISION}${cotizacionDesc?.companyName}/${cotizacionDesc?.id}${process.env.REACT_APP_URL_PREFIX}`} className="btn btn-primary" >
                                    Contratar
                                </a>}
                            </div>
                        }
                    </div>
                ) : ""*/}
                <div className="cta-wa d-none d-lg-block">
                    <p>¿Tenés dudas sobre esta cobertura?</p>
                    <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                </div>
                <div className="datafiscal d-none d-lg-flex">
                    <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt='Información fiscal' /></div>
                    <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                </div>
            </div> : ""}
            <div className={isEmision === false ? "oc-det" : "oc-det oc-det1"}>

                <div className='row align-items-center justify-content-center text-center'>
                    {/* <div className="justify-content-center align-text-center">
                            <span className="mb-3 me-lg-4 fs-16 fw-bold text-primary border-bottom border-2 align-text-center">Cobertura</span>
                        </div> */}

                    <span className="mt-4 me-lg-4 fs-16 fw-bold text-gray-aux align-text-center">{emisionValues.cotizacionDesc?.planName}</span>

                    <div>
                        <img className="align-text-center" src={emisionValues.cotizacionDesc?.companyLogo} alt={emisionValues.cotizacionDesc?.companyName} loading="lazy" style={{ height: "auto", width: "80%", margin: "1rem 0" }} />
                    </div>

                    <div className="mb-4 mb-lg-0 me-lg-4 fs-32 fw-bold lh-1 align-text-center">{"$" + emisionValues.cotizacionDesc?.amountMonth}<span className="fs-14 fw-bold text-gray-dark d-inline-block pt-1 align-top">/mes</span></div>
                </div>


                <div className="suma mt-5">
                    <div>
                        La cobertura incluye <br />
                    </div>
                    <div className="fs-20">
                        <span>Suma asegurada: ${cotizacionDesc?.sumaAsegurada}</span>
                        <SegTooltip text="Es el monto máximo de indemnización. En caso de un siniestro total (por ejemplo, ante el robo del vehículo).​" placement="bottom-end" />
                    </div>
                </div>
                <div className="accordion accordion-flush acc-01" id="accDetalle">
                    {cotizacionDesc?.descList && cotizacionDesc?.descList.map((desc, index) =>
                        <ModalDescripcion
                            key={index}
                            nombre={desc.Nombre}
                            descripcion={desc.Descripcion}
                            isOpen={index === indiceAbierto}
                            toggleDesplegable={() => handleClick(index)}
                        />
                    )}
                </div>
                <div className="cta-wa d-lg-none">
                    <p>¿Tenés dudas sobre esta cobertura?</p>
                    <a href={encodeURI("https://wa.me/5491169568002?text=¡Hola! Me interesa saber más de la cobertura " + (cotizacionDesc?.planName! || null) + " de la Aseguradora " + (cotizacionDesc?.companyName! || null) + ". Quiero hablar con un asesor.")} target='_blank' rel='noreferrer'><span>Consultanos</span> <svg className="icon"><use xlinkHref="#whatsapp" /></svg></a>
                </div>
                <div className="datafiscal d-lg-none">
                    <div><img src={cotizacionDesc?.fiscalInfoImg} width="30" alt="Información fiscal" /></div>
                    <p>Segurarse Asesores de Seguros S.A., Matrícula registrada 1174, expedida por la Superintendencia de Seguros de la Nación (www.ssn.gov.ar 4338-4000 o 0800-666-8400)</p>
                </div>
            </div>
        </OffCanvas>
    );
};

export default ModalPreciosEmision;
