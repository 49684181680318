import { Splide, SplideSlide } from '@splidejs/react-splide';

interface Props {
  title?: string;
  slides?: {
    icon: string;
    title: string;
    description: string;
  }[];
}

const SLIDES_GENERICAS = [
  {
    icon: "#icon-motivo-rapido",
    title: "Respuesta inmediata",
    description: "Soluciones corporativas a medida y con el respaldo de aseguradoras de primer nivel."
  },
  {
    icon: "#icon-motivo-g-siniestros",
    title: "Gestión de siniestros",
    description: "Gestiones legales ante las aseguradoras para agilizar el pago de siniestros propios y de terceros."
  },
  {
    icon: "#icon-motivo-experiencia",
    title: "Experiencia y profesionalismo",
    description: "Contamos con un equipo de ejecutivos exclusivos por riesgo y mercado."
  },
  {
    icon: "#icon-motivo-g-comercial",
    title: "Gestiones comerciales",
    description: "Asesoramiento y seguimiento de trámites, actualizaciones de sumas aseguradas, renovaciones y cobranzas."
  },
];

const PropuestaValor = ({ title = "Nuestra propuesta de valor", slides = SLIDES_GENERICAS }: Props) => {


  return (
    <div className='bg-grad-03 pt-40'>
      <div className='container dots-white' style={{ paddingBottom: "60px" }}>
        <div className="col-9 mx-auto">
          <h2 className="ff-mukta fw-bold text-black fs-30 fs-lg-40 text-center mb-20 mb-lg-40">{title}</h2>
        </div>
        <Splide
          options={{
            type: 'loop',
            focus: 0,
            drag: true,
            flickMaxPages: 1,
            flickPower: 50,
            perPage: 1,
            perMove: 1,
            // autoplay: true,
            // interval: 4000,
            arrows: false,
            mediaQuery: 'min',
            gap: '30px',
            padding: '55px',
            breakpoints: {
              576: {
                perPage: 1,
                padding: '0',
              },
              768: {
                perPage: 2,
              },
              992: {
                perPage: 4,
                drag: false,
              },
            }
          }}
          style={window.innerWidth < 786 ? { width: "100vw", marginLeft: "-10px" } : {}}
        >
          {
            slides.map((prop, index) => (
              <SplideSlide key={index} style={{
                marginTop: "48px"
              }} >
                <div className="pt-10 px-10 px-lg-19 pb-30 bg-white border border-3 border-primary rounded-18 shadow-3 text-center h-100">
                  <div className="mb-16 mt-n50 mt-lg-n60"><svg className="svg-icon w-90p h-90p w-lg-105p h-lg-105p rounded-circle shadow-3"><use href={prop.icon}></use></svg></div>
                  <h3 className="fs-21 fs-lg-24 fw-bold text-primary lh-11 mb-12 mb-lg-20">{prop.title}</h3>
                  <p className="fs-16 fs-lg-18 lh-sm mb-0">{prop.description}</p>
                </div>
              </SplideSlide>
            ))
          }
        </Splide>
      </div>
    </div>
  );
};

export default PropuestaValor;
