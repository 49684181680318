import Axios from 'axios'
import { ITelefono } from '../Providers/EmisionStore';
import { getPathName, retryRequest } from './helper';

const EmisionAuto = async (idItemCotizacion: number, tipoDePersona: string, condicionIVA: string, tipoUso: string, tieneGNC: boolean, nombre: string, apellido: string, email: string, nroDocumento: string,
    cuil: string, fechaNacimiento: string, telefono: ITelefono, provincia: string, localidad: string, calle: string, patente: string, nroMotor: string, nroChasis: string, nroTarjeta: string, tipoTarjeta: string,
    bancoTarjeta: string, titularTarjeta: string, mesVencimientoTarjeta: string, anioVencimientoTarjeta: string, telefonoAdicional?: ITelefono, departamento?: string,
    nroCalle?: string, piso?: string) => {
    let telefonoAdicionalData = null;
    if (telefonoAdicional && telefonoAdicional.cod && telefonoAdicional.numero) {
        telefonoAdicionalData = {
            CodigoArea: telefonoAdicional.cod,
            Numero: telefonoAdicional.numero,
            TelefonoEsCelular: true, // SETEADO FIJO
        };
    }

    if (nroCalle === "") {
        nroCalle = "S/N"
    }


    const separarTitular = () => {
        const titularArray = titularTarjeta?.split(' ')

        const ultimoDato = titularArray[titularArray.length - 1];
        const resto = titularArray.slice(0, -1);

        const apellido = String(ultimoDato);
        const nombre = resto.join(' ');

        return { nombre, apellido };
    }


    const params = {
        hash: process.env.REACT_APP_HASH,
        emision: {
            idItemCotizacion: idItemCotizacion,
            TipoDePersona: tipoDePersona,
            CondicionIVA: condicionIVA,
            TipoUso: tipoUso,
            TieneGNC: tieneGNC,
            Nombre: nombre,
            Apellido: apellido,
            Email: email,
            TipoDocumento: "1",
            NroDocumento: nroDocumento,
            Cuil: cuil,
            FechaNacimiento: fechaNacimiento,
            Telefono: {
                CodigoArea: telefono.cod,
                Numero: telefono.numero,
                TelefonoEsCelular: true // SETEADO FIJO
            },
            TelefonoAdicional: telefonoAdicionalData,
            Provincia: provincia,
            Localidad: localidad,
            Calle: calle,
            NumeroCalle: nroCalle,
            Piso: piso,
            Departamento: departamento,
            CodigoPostal: null,
            Patente: patente,
            NroMotor: nroMotor,
            NroChasis: nroChasis,
            NumeroTarjeta: nroTarjeta,
            MedioPago: "2",  // SETEADO FIJO
            TipoTarjetaCredito: tipoTarjeta,
            BancoTarjetaCredito: bancoTarjeta,
            NombreTitularTarjeta: separarTitular().nombre,
            ApellidoTitularTarjeta: separarTitular().apellido,
            MesVencimientoTarjeta: mesVencimientoTarjeta,
            AnioVencimientoTarjeta: anioVencimientoTarjeta
        }

    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }

    const axiosPostRequest = () => {
        return Axios.post(getPathName() + "/SetEmisionVehiculoJSON", params, {
            headers: headers
        });
    };

    try {
        const response = await retryRequest(axiosPostRequest);
        return response;
    } catch (error: any) {
        console.error('Fallo de hacer varios intentos: ' + error.message);
        return error;
    }
};

export default EmisionAuto;