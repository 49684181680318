import React from "react";
import styled from "@emotion/styled";

interface SegHeaderLogoProps {
  img: string;
  alt: string;
  width?: string;
  className?: string;
  style?:any;
}

export const SegHeaderLogo = ({
  img,
  alt,
  width = "100%",
  className,
  style
}: SegHeaderLogoProps) => {
  const Img = styled.img`
    width: ${width};
  `;

  return <Img src={img} alt={alt} width={width} className={className} style={style}/>;
};
