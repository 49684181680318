import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import Skeleton from 'react-loading-skeleton';
import { ModalDescripcion } from '../../Prepaga/Precios/ModalDescripcion';
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import { Link } from 'react-router-dom';
import { SegCardPriceMobile } from '../../../SegCardPriceMobile/SegCardPriceMobile';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';
import { formatearMoneda } from '../../../../helpers/formatearMoneda';
import descripcionesAP from "../../../../Services/Data/DescripcionesAP.json";

const CardMob = styled.div`
width: 100%;
max-width: 600px;
margin-bottom: 20px;
text-align: center;
font-weight: 500;
font-size: 12px;
color: #363636;
background: #fff;
box-shadow: 0px 0px 4px #DCE0E2;
border-radius: 5px;
padding: 16px 8px;
display: block;
@media (min-width: 1340px) {
    max-width: 48%;
    margin: 1em 0 1em 0;
}
@media (max-width: 1340px) {
    max-width: 48%;
}
@media (max-width: 1145px) {
    max-width: 400px;
}
@media (max-width: 992px) {
    max-width: 350px;
}
@media (max-width: 805px) {
    max-width: 320px;
}

@media (max-width: 778px) {
    max-width: 320px;
}
@media (max-width: 745px) {
    max-width: 500px;
}
@media (max-width: 340px) {
    max-width: 280px;
    margin: 2px 2px 1.5em 2px
}`;
const StyledLinkMob = styled(Link)`
   padding: 10px 26px;
   width: fit-content;
   color: #fff;
   background-color: var(--bs-primary);
   display: inline-block;
   font-size: 15px;
   font-weight: 700;
   line-height: 1;
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   -webkit-user-select: none;
   border: 0;
   border-radius: 5px;

   @media (max-width: 768px) {
       padding: 10px 15px;
   }
   @media (max-width: 299px) {
       padding: 8px 10px;
   }
   @media (max-width: 278px) {
       padding: 5px 8px;
   }
   `;
const StyledSvg = styled.svg`
 display: inline-block;
 fill: currentcolor;
 width: 10px;
 height: 10px;
 margin: 0 0 2px 12px;`;
interface CardProps {
    cotizaciones: any[];
    setDescripciones: (descripciones: any) => void;
    cotizando: boolean
}


export const Cards = ({
    cotizaciones,
    cotizando,
    setDescripciones
}: CardProps) => {

    const [isAseguradoraFilter, setIsAseguradoraFilter] = useState<boolean>(false);
    const [isPrecioFilter, setIsPrecioFilter] = useState<boolean>(false);
    const { emisionValues, setEmisionValues } = useEmisionStore();
    const { cotizacionValues } = useCotizacionStore();

    const cotizacionesAjustadas = useMemo(() => {
        if (isPrecioFilter) {
            return cotizaciones.sort((a, b) => parseInt(a.premio) - parseInt(b.premio));
        }
        if (isAseguradoraFilter) {
            return cotizaciones.sort((a, b) => a.NombreCompania.localeCompare(b.NombreCompania));
        }
        return cotizaciones

    }, [isAseguradoraFilter, isPrecioFilter])



    const handlePrecioFilterChange = () => {
        setIsPrecioFilter(!isPrecioFilter);
        if (isAseguradoraFilter) {
            setIsAseguradoraFilter(!isAseguradoraFilter)
        }

    };

    const handleAseguradoraFilterChange = () => {
        setIsAseguradoraFilter(!isAseguradoraFilter);
        if (isPrecioFilter) {
            setIsPrecioFilter(!isPrecioFilter)
        }
    };

    const handleDescripcion = (event: React.MouseEvent, coti: any) => {
        if (coti) {
            const nuevaDesc: any = {
                id: coti.id,
                amountMonth: coti.premio,
                companyLogo: coti.LogoCompania,
                descList: coti.descripciones,
                nombreCompania: coti.NombreCompania,
                planName: coti.nombrePlan,
                premio: coti.premio,
                //fiscalInfoImg: coti.Afip
            }
            setDescripciones(nuevaDesc);
        }
    }

    const formatearSumaAsegurada = (value: string = "") => {
        const numericValue = parseInt(value.replace(/[^0-9]/g, ""), 10);
        return `$ ${new Intl.NumberFormat("es-AR").format(numericValue)}`;
    };

    return (
        <>
            <div className="mobile-filter bg-d9d9d9 d-lg-none sticky-top">
                <div className="d-flex align-items-center p-2" style={{ background: "#D9D9D9" }}>
                    <label className="col-4 col-form-label">Ordenar Por:</label>
                    <label className="form-check-label fs-14 mx-1" htmlFor="chkAseguradora">Aseguradora</label>
                    <label className="form-switch">
                        <input onChange={handleAseguradoraFilterChange} checked={isAseguradoraFilter} className="form-check-input" type="checkbox" id="chkAseguradora" />
                    </label>
                    <label className="form-check-label fs-14 mx-1" htmlFor="chkPrecio">Precio</label>
                    <label className="form-switch">
                        <input onChange={handlePrecioFilterChange} checked={isPrecioFilter} className="form-check-input" type="checkbox" id="chkPrecio" />
                    </label>
                </div>
            </div>
            <div className="cards2-wrapper">
                {(window.innerWidth > 768) ?

                    <>
                        {
                            cotizacionesAjustadas.map((coti, i) => (
                                <CardDesktop
                                    key={i}
                                    cotizacion={coti}
                                    handleDescripcion={(e:any) => handleDescripcion(e, coti)}
                                />
                            ))
                        }
                    </>
                    :
                    <>
                        {
                            cotizacionesAjustadas.map((coti, i) => (
                                <SegCardPriceMobile rubro={"ACCIDENTES PERSONALES"} amountMonth={coti.premio} companyLogo={coti.LogoCompania} companyName={coti.NombreCompania} descList={coti.incluyePrestacion} discountAmount={""} discountPerc={""} fiscalInfoImg={coti.Afip} planName={"Accidentes Personales"} amountInsured={formatearSumaAsegurada(cotizacionValues.sumaMuerte?.toString())}
                                    onClick={(e) => handleDescripcion(e, coti)} key={i} />
                            ))
                        }
                    </>
                }

                {
                    cotizando &&
                    <CardMob>
                        <Skeleton
                            containerClassName="flex-1"
                            count={1}
                            style={{ borderRadius: "100%", width: "50px", height: "50px" }} />
                        <Skeleton
                            containerClassName="flex-1"
                            count={4}
                            circle={false}
                            style={{ height: "15px", margin: "10px 0" }}
                        />
                    </CardMob>
                }
            </div>
        </>
    )
}

const getDescripcionCobertura = (idDesc: string):string => {
    const descripcion = descripcionesAP.find( desc => desc.id === idDesc);
    return descripcion?.descripcion || ""
}

const CardDesktop = ({ cotizacion, handleDescripcion }: any) => {

    const [indiceAbierto, setIndiceAbierto] = useState<number | null>(null);

    useEffect(() => {
        setIndiceAbierto(-1)
    }, [cotizacion.descripciones]);


    const handleClick = (indice: number) => {
        setIndiceAbierto(prevIndice => (prevIndice === indice ? null : indice));
    };


    return (
        <CardMob>
            <img
                alt={cotizacion.NombreCompania}
                src={cotizacion.LogoCompania}
                style={{ width: "150px" }}
                className='mt-2 mb-0'
            />

            <div className='oc-cob'>
                <div className='oc-det' style={{ width: "100%", padding: "10px 16px" }}>
                    <div className="accordion accordion-flush acc-01" id="accDetalle">
                        {cotizacion.descripciones && cotizacion.descripciones.map((desc: any, index: number) =>
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" style={{ borderRadius: "8px" }}>
                                    <button
                                        className={`accordion-button ${index === indiceAbierto ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleClick(index)}
                                    >
                                        <div className='d-flex justify-content-between w-100'>
                                            <span className='fs-13'>{desc.descripcion}</span>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <span style={{ color: "#979797" }} className='fs-14'>{"Hasta"}</span>
                                                <span style={{ marginRight: "8px" }} className='fs-16'>{
                                                    formatearMoneda(desc.monto + "")}</span>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    className={`accordion-collapse collapse ${index === indiceAbierto ? 'show' : ''}`}
                                    data-bs-parent="#accDetalle"
                                >
                                    <div className="accordion-body">
                                        <p className='text-start fw-light mt-2'>{getDescripcionCobertura(desc.id)}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <StyledLinkMob
                onClick={(e) => handleDescripcion(e)}
                to={"/ap/emision/"}
                role="button">
                CONTRATAR
                <StyledSvg>
                    <use xlinkHref="#arrowright2"></use>
                    <symbol id="arrowright2" viewBox="0 0 10 10">
                        <path d="M3.7124 8.29995L6.42907 5.58328C6.7499 5.26245 6.7499 4.73745 6.42907 4.41662L3.7124 1.69995" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
                    </symbol>
                </StyledSvg>
            </StyledLinkMob>
        </CardMob>
    )

}