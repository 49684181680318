import React from "react";
import { SegH2 } from "../SegH2/SegH2";
import { SegSvg } from "../SegSvg/SegSvg";
import styled from "@emotion/styled";
import Lottie from "lottie-react";

interface SegLoadingProps {
  loadingTitle: string;
  captions?: { value: string }[];
  startText?: string;
  middleText?: string;
  endText?: string;
  backgroundColor?: string;
  product?: string;
  productTextColor?: string;
  textColor?: string;
  dotsColors?: string[];
  fontSize?: number;
  fontSizeP?: number;
  fontSizeProd?: number;
  fontWeight?: "bold" | "normal" | 500 | 700;
  className?: string;
  icon?:
  | "#ico-auto"
  | "#ico-moto"
  | "#ico-prepaga"
  | "#ico-hombre"
  | "#ico-art";
  lottie?: boolean;
  lottieJson?: any; 
}

export const SegLoading = ({
  loadingTitle,
  captions = [],
  icon = "#ico-auto",
  startText = "Tu cotización está casi lista. Estamos buscando en ",
  middleText = "30 aseguradoras en tiempo real ",
  endText = "las mejores coberturas y precios para tu",
  backgroundColor = "#2C7DFD",
  textColor = "#FFFFFF",
  fontSize = 40,
  fontWeight = 700,
  fontSizeP = 25,
  fontSizeProd = 28,
  product = "Citroen Berlingo",
  productTextColor = "#fff",
  dotsColors,
  className,
  lottie = false,
  lottieJson = "",
  ...rest
}: SegLoadingProps) => {
  const StyledDiv = styled.div`
    background-color: ${backgroundColor} !important;
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20000;
  `;

  const StyledDot = styled.div<any>`
    background-color: ${({ position }) => dotsColors ? dotsColors[position] + " !important" : textColor + " !important"};
  `;

  const StyledTitleDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  `;

  const H2 = styled.legend`
    width: auto;
  `;

  const StyledUl = styled.ul`
    justify-content: center;
    display: flex;
    color: ${textColor};
    margim-bottom: 16px;
  `;
  const StyledDivP = styled.div`
    text-align: center;
    font-size: 19px;
    color: ${textColor};

    @media (min-width: 768px) {
      max-width: 795px;
      margin: 0 auto;
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      font-size: ${fontSizeP}px;
    }
  `;

  const StyledSpan = styled.span`
    display: block;
    margin-top: 14px;
    font-size: ${fontSizeProd}px;
    font-weight: 700;
  `;

  return (
    <StyledDiv className={`cot-cargando ${className}`} {...rest}>
      <StyledTitleDiv className="test">
        <H2>
          <SegH2
            title={loadingTitle}
            textColor={textColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
          />
        </H2>
        <div className="anim-dots">
          <StyledDot className="dot" position={0} />
          <StyledDot className="dot" position={1} />
          <StyledDot className="dot" position={2} />
        </div>
      </StyledTitleDiv>
      <StyledUl className="lst01">
        {captions.map(({ value }, index) => (
          <li key={index}>{value}</li>
        ))}
      </StyledUl>
      <div className="track">
        {!lottie ?
          <svg className="objs obj-1">
            <use xlinkHref={icon} />
          </svg>
          :
          <Lottie 
          className="objs obj-1"
          animationData={lottieJson} 
          loop={true} 
          style={{ width: 80, height: 80, }} 
        />
        }
      </div>
      <StyledDivP className="ps-1 pe-1 text-center">
        {startText}
        <b>{middleText}</b>
        {endText}
        <StyledSpan style={{ color: productTextColor }}>{product}</StyledSpan>
      </StyledDivP>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="tel" viewBox="0 0 9 12" fill="#A5A5A5">
          <path d="M8.75346 10.2814C8.70437 10.4479 8.61676 10.6087 8.48851 10.7519C8.35702 10.9002 8.20411 11.028 8.03484 11.1311C7.734 11.314 7.43115 11.4203 7.11316 11.4528C6.80134 11.4872 6.48253 11.458 6.15868 11.3626C5.68576 11.2232 5.21369 10.9634 4.74677 10.58C4.25476 10.1692 3.81084 9.70397 3.4235 9.19327C3.00115 8.64149 2.62084 8.05878 2.28576 7.45004C1.95616 6.84126 1.67315 6.20839 1.43916 5.55685C1.22351 4.96015 1.07459 4.3414 0.995119 3.71191C0.929095 3.117 0.966529 2.58051 1.10803 2.10039C1.20075 1.7858 1.34474 1.50195 1.53758 1.25709C1.73194 1.00709 1.98513 0.800128 2.29901 0.641223C2.68115 0.436508 3.04712 0.389048 3.3905 0.490248C3.52004 0.528426 3.6414 0.594362 3.74121 0.684117C3.84514 0.775085 3.92649 0.889569 3.97534 1.0347L4.60472 2.86499C4.65303 3.00437 4.6832 3.12947 4.69433 3.24338C4.70697 3.35214 4.70183 3.45677 4.67608 3.54416C4.64037 3.66038 4.57298 3.76434 4.48145 3.84437C4.37459 3.94368 4.25496 4.0283 4.12571 4.09597L3.66521 4.35804C3.63361 4.37482 3.60585 4.398 3.58371 4.42611C3.56158 4.45421 3.54555 4.48663 3.53665 4.52129C3.52604 4.55727 3.52149 4.59168 3.51983 4.63142C3.52229 4.67237 3.5285 4.70437 3.52958 4.73486C3.56668 4.91228 3.65213 5.15312 3.78582 5.45399C3.92362 5.75608 4.07327 6.06724 4.24173 6.38282C4.42017 6.70247 4.59409 6.99955 4.77829 7.28067C4.95917 7.5541 5.11772 7.75168 5.25048 7.88131L5.32357 7.9442C5.35661 7.96825 5.39379 7.98603 5.43326 7.99664C5.4685 8.00785 5.50569 8.01159 5.54246 8.00761C5.57923 8.00363 5.61476 7.99202 5.64679 7.97353L6.10081 7.72966C6.25092 7.64875 6.38771 7.59409 6.51129 7.56905C6.62808 7.53537 6.75203 7.53548 6.86876 7.56937C6.96565 7.59949 7.05565 7.64842 7.13361 7.71335C7.22327 7.78447 7.31262 7.87562 7.40444 7.98871L8.61778 9.52851C8.70761 9.63357 8.76586 9.76192 8.78579 9.89871C8.80017 10.0272 8.78918 10.1572 8.75346 10.2814Z" />
        </symbol>

        <symbol id="info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </symbol>
      </svg>
      <SegSvg />
    </StyledDiv>
  );
};
