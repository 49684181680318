import { Header } from '../Components/Header';
import shape02 from "../../../Assets/img/shape02.svg";
import shape05 from "../../../Assets/img/shape05.svg";
import shape06 from "../../../Assets/img/shape06.svg";
import { useNavigate } from 'react-router-dom';

export const FormularioArrepentimientoEnviado = () => {
    return (
        <>
            <Header rubro='auto' />
            <main className='bg-white'>
                <div className="position-relative pt-45 pb-25 pt-lg-80 pb-lg-35">
                    <img className="d-none d-lg-block pos02" src={shape02} />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-36 lh-lg-1 text-black text-center text-lg-start mb-10">Formulario de arrepentimiento</h2>
                        <div className="col-md-10 col-lg-8 mx-auto mx-lg-0">
                            <p className="fs-14 fs-lg-16 text-black text-center text-lg-start mb-0">Conforme Resolución 424/2020, de la secretaria de Comercio Interior, las y los clientes pueden solicitar la revocación de la aceptación durante el plazo de DIEZ (10) días corridos contados a partir de la fecha de la celebración del seguro contratado únicamente mediante el canal ecommerce de la web (www.segurarse.com.ar).</p>
                        </div>
                    </div>
                </div>

                <div className="pt-25 pb-80">
                    <div className="container">
                        <div className="bg-f8 rounded-3 px-3 px-sm-5 py-25 py-lg-30 mb-30">
                            <div className="text-center fw-medium fs-18 fs-lg-25 lh-13 text-primary">Recibimos la solicitud, la estaremos analizando y a la brevedad nos estaremos contactando con vos.</div>
                        </div>
                        <div className="py-3">
                            <div className="fs-17">
                                <b>Horarios de atención:</b><br />
                                - Ventas: lunes a viernes <br className="d-sm-none" />de 08:30 a 20:30hs.<br />
                                - Atención al cliente: lunes a viernes <br className="d-sm-none" />de 09:00 a 18:00hs.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-f4 py-30 py-lg-50 position-relative">
                    <img className="d-none d-lg-block pos07 bottom-0" src={shape05} />
                    <img className="d-none d-lg-block pos06" src={shape06} />
                    <div className="container px-lg-5">
                        <div className="row justify-content-evenly">
                            <div className="col-lg-auto text-center text-lg-start">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-lg-1">¿No resolvimos<br className="d-sm-none" /> tu consulta?</h2>
                                <p className="fw-bold fs-25 fs-lg-25 text-primary mb-18 mb-lg-0">¡Estamos para vos!</p>
                            </div>
                            <div className="col-lg-auto text-center text-lg-left">
                                <a href="#" className="transition-base d-inline-block w-250p py-12 rounded-3 fs-22 fs-lg-24 fw-bold lh-1 text-center bg-primary bg-lg-gray-dark hover-bg-primary text-white">CONTACTO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
