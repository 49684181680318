import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import logoblue from "../../Assets/img/logo_segurarse.svg"
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useConfiguracionEmpresa } from '../../Services/Providers/useConfiguracionEmpresa'
import { useEffect } from 'react'

interface GraciasProps {
    nombre?: string;
    telefono?: string;
    email?: string;
    vehiculo?: string;
    aseguradora?: string;
    tipo?: "emision" | "vehiculoantiguo";
}

const StyledDiv = styled.div`
height: 63vh;
@media (max-width: 458px) {
    height: 85vh;
}
@media (max-width: 278px) {
    height: 90vh;
}
`

export const GraciasAuto = ({ nombre, telefono, email, vehiculo, aseguradora, tipo }: GraciasProps) => {

    const { state } = useLocation();
    const { config, origen } = useConfiguracionEmpresa();
    nombre = nombre ? nombre : state.nombre;
    telefono = telefono ? telefono : state.telefono;
    email = email ? email : state.email;
    vehiculo = vehiculo ? vehiculo : state.vehiculo;
    aseguradora = aseguradora ? aseguradora : state !== null ? state.asegurada : "";
    tipo = tipo ? tipo : state.tipo;

    useEffect(() => {
        if (origen === "SEGURARSE") {
            document.body.style.background = "#2c7dfd";
        } else {
            document.body.style.background = config.colors.primary;
        }
        return () => {
            document.body.style.background = "";
        };
    }, [config]);

    return (
        <div>
            <SegSvg />
            <header className="site-header sticky-lg-top">
                <div className="container">
                    {
                        origen !== "SEGURARSE" ?
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center d-flex justify-content-center gap-3">
                                    {" "}
                                    {/* Centra el contenido en la columna */}
                                    <SegHeaderLogo
                                        img={config.images.logo}
                                        alt="Logo Segurarse"
                                        width={window.innerWidth > 768 ? "150px" : "120px"}
                                    />
                                    <div style={{ height: "100%", width: "2px", backgroundColor: "gray" }}></div>
                                    <SegHeaderLogo
                                        img={logoblue}
                                        alt="Logo Segurarse"
                                        width={window.innerWidth > 768 ? "140px" : "120px"}
                                        style={{ filter: "grayscale(100%)" }}
                                    />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-6 offset-md-3 text-center">
                                    {" "}
                                    {/* Centra el contenido en la columna */}
                                    <SegHeaderLogo
                                        img={logoblue}
                                        alt="Logo Segurarse"
                                        width="160px"
                                    />
                                </div>
                            </div>
                    }

                </div>
            </header>
            <div style={origen !== "SEGURARSE" ? { background: config.colors.primary } : {}}>
                <div className="text-center pt-5" style={{ height: "25vh" }}>
                    <svg className="" style={{ width: "150px" }}>
                        <use href="#ico-auto" fill='white' />
                    </svg>
                </div>
                <div className='text-center d-flex flex-column'>
                    <h1 className='fs-2 text-white'>¡Recibimos tu solicitud!</h1>
                    <p className='mt-3 text-white fs-5'>{tipo === "vehiculoantiguo" ? "En breve nos comunicaremos con vos para asesorarte en la mejor cobertura para tu auto." : tipo === "emision" ? "En breve nos comunicaremos con vos para continuar con la emisión online de tu seguro." : ""}</p>
                    <div className='mb-5 mt-4 ps-3 pe-3'>
                        <div className='bg-white rounded container' style={origen !== "SEGURARSE" ? { backgroundColor: config.colors.tertiary } : {}}>
                            <div>
                                <div className='d-flex flex-wrap'>
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#card-lin" fill={origen !== "SEGURARSE" ? config.colors.secondary : undefined} />
                                                </svg>
                                            </div>
                                            <span>Nombre:</span>
                                            <span className='fw-bold'>{nombre}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#tel-lin" fill={origen !== "SEGURARSE" ? config.colors.secondary : undefined} />
                                                </svg>
                                            </div>
                                            <span>Telefono:</span>
                                            <span className='fw-bold'>{telefono}</span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-11 col-md-6 col-lg-4  pt-4'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.5em" }}>
                                                    <use xlinkHref="#envelope-lin" fill={origen !== "SEGURARSE" ? config.colors.secondary : undefined} />
                                                </svg>
                                            </div>
                                            <span>Email:</span>
                                            <span className='fw-bold text-truncate'>{email}</span>
                                        </div>
                                    </div>
                                    <div className='col-8 col-sm-8 col-md-11 col-lg-11  pt-4 pb-5'>
                                        <div className="d-flex align-items-start gap-2">
                                            <div className=''>
                                                <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                                    <use xlinkHref={tipo === "vehiculoantiguo" ? "#ico-auto" : tipo === "emision" ? "#card-lin" : ""}
                                                        fill={origen !== "SEGURARSE" ? config.colors.secondary : undefined} />
                                                </svg>
                                            </div>
                                            <span>{tipo === "vehiculoantiguo" ? "Vehiculo cotizado:" : tipo === "emision" && "Aseguradora:"}</span>
                                            <span className='fw-bold '>{tipo === "vehiculoantiguo" ? vehiculo : tipo === "emision" && aseguradora}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                origen === "SEGURARSE" &&
                                <a href="https://wa.me/5491169568002" target='_blank' rel='noreferrer' className='text-black' >
                                    <div className='d-flex justify-content-center gap-2 align-items-start'>
                                        <div>
                                            <p className='fw-bold'>¿Querés continuar por WhatsApp? <span className='fw-normal'>Chatéanos</span></p>
                                        </div>
                                        <div>
                                            <svg className="" style={{ width: "1.5em", height: "1.8em" }}>
                                                <use xlinkHref="#whatsapp" />
                                            </svg>
                                        </div>
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
