import Axios, { CancelTokenSource } from 'axios'
import { getPathName } from './helper';

const cotizar = async (compania: string, idContacto: string, emisionHabilitada: string, token: string, cancelTokenSource: CancelTokenSource) => {
    const params = {
        hash: process.env.REACT_APP_HASH,
        compania: compania,
        idContacto: idContacto,
        emisionHabilitada: emisionHabilitada,
        token: token
    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getproductosjson?random=" + (Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000) , params,
        {
            headers: headers,
            cancelToken: cancelTokenSource.token,
            //timeout: process.env.REACT_APP_TIMEOUT
        }).then(function (response) {
            return response;
        }).catch(function (error) {
            // Manejar el error aquí
            if (Axios.isCancel(error)) {
                // La solicitud fue cancelada
            } else {
                // Otro error de la solicitud
                console.error('Error en la solicitud', error);
            }
            return error
        });
};

export default cotizar;