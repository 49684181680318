import Axios from 'axios';
import { getPathName } from './helper';

//TODO: JSON TEMPORAL
const ARR_PROFESIONES: {value: string; label: string}[] = [
    {
        "value": "1",
        "label": "Acompañante terapéutico"
    },
    {
        "value": "2",
        "label": "Administrativo"
    },
    {
        "value": "3",
        "label": "Agricultura en general"
    },
    {
        "value": "5",
        "label": "Armado stand 4 metros de altura"
    },
    {
        "value": "6",
        "label": "Arquitecto dirigiendo obras"
    },
    {
        "value": "7",
        "label": "Arquitecto en oficina"
    },
    {
        "value": "8",
        "label": "Bares - Atención al Público"
    },
    {
        "value": "9",
        "label": "Carpintero con máquinas"
    },
    {
        "value": "10",
        "label": "Carpintero sin máquinas"
    },
    {
        "value": "11",
        "label": "Chofer de camión"
    },
    {
        "value": "12",
        "label": "Chofer de Uber/Cabify (Auto)"
    },
    {
        "value": "13",
        "label": "Chofer Taxi o Remis"
    },
    {
        "value": "14",
        "label": "Cocinero"
    },
    {
        "value": "15",
        "label": "Construcción/Albañilería hasta 10 metros de altura"
    },
    {
        "value": "17",
        "label": "Construcción/Albañilería hasta 20 metros de altura"
    },
    {
        "value": "16",
        "label": "Construcción/Refacciones hasta 8 metros de altura"
    },
    {
        "value": "18",
        "label": "Doméstica"
    },
    {
        "value": "19",
        "label": "Electricista hasta 10 metros de altura"
    },
    {
        "value": "21",
        "label": "Electricista hasta 20 metros de altura"
    },
    {
        "value": "20",
        "label": "Electricista y Albañil entre 8 y 20 metros de altura"
    },
    {
        "value": "22",
        "label": "Enfermero"
    },
    {
        "value": "23",
        "label": "Herrería hasta 7 metros de altura"
    },
    {
        "value": "24",
        "label": "Jardinero con poda árboles"
    },
    {
        "value": "25",
        "label": "Mecánico de automóviles"
    },
    {
        "value": "26",
        "label": "Metalúrgico con maquinarias"
    },
    {
        "value": "27",
        "label": "Mozos"
    },
    {
        "value": "28",
        "label": "Panadería - Confitería Elaboración"
    },
    {
        "value": "29",
        "label": "Pintura de 10 a 20 metros de altura"
    },
    {
        "value": "30",
        "label": "Pintura hasta 10 metros de altura"
    },
    {
        "value": "31",
        "label": "Pintura y Refacciones Edilicia hasta 8 metros de altura"
    },
    {
        "value": "32",
        "label": "Plomero Trabajos hasta 6 metros de altura"
    },
    {
        "value": "33",
        "label": "Portero"
    },
    {
        "value": "34",
        "label": "Programador de Sistemas"
    },
    {
        "value": "4",
        "label": "Service/Mantenimiento aire acondicionado hasta max. 6 metros de altura"
    },
    {
        "value": "35",
        "label": "Técnico en Seguridad e Higiene"
    },
    {
        "value": "36",
        "label": "Vendedores en local"
    },
    {
        "value": "37",
        "label": "Venta y Colocación de vidrios hasta 6 metros de altura"
    },
    {
        "value": "38",
        "label": "Vigilancia sin armas sin seguimiento"
    }
];


export const obtenerProfesiones = async () => {
    return ARR_PROFESIONES;
    /*const params = {
        hash: process.env.REACT_APP_HASH,
    }
    const headers = {
        'content-type': 'application/json',
        "Authorization": 'Bearer ' + process.env.REACT_APP_TOKEN
    }
    return await Axios.post(getPathName() + "/getactividadesjson", params,
        {
            headers: headers
        }).then(function (response) {
            return response.data.api_data.map( function(cond:any) {
                return {
                    value: cond.codigo,
                    label: cond.descripcion
                }
            })
           return []
        }).catch(function (error) {
            //return error;
            return ARR_PROFESIONES
        });*/
}