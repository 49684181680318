import { formatearMoneda } from "../../../../helpers/formatearMoneda";
import descripcionesAP from "../../../../Services/Data/DescripcionesAP.json";
interface ModalDescripcionProps {
    id:string;
    monto: string;
    descripcion: string;
    isOpen: boolean;
    toggleDesplegable: () => void;
}

export const ModalDescripcion = ({ id, monto, descripcion, isOpen, toggleDesplegable }: ModalDescripcionProps) => {
   
    const getDescripcionCobertura = (idDesc: string):string => {
        const descripcion = descripcionesAP.find( desc => desc.id === idDesc);
        return descripcion?.descripcion || ""
    }
    
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                    className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
                    type="button"
                    onClick={toggleDesplegable}
                >
                    {descripcion}
                </button>
            </h2>
            <div
                className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                data-bs-parent="#accDetalle"
            >
                <div className="accordion-body">
                    <p className="fs-14 fw-semibold">{`Hasta ${formatearMoneda(monto + "")}`}</p>
                    <span>{getDescripcionCobertura(id)}</span>
                </div>
            </div>
        </div>
    );
};