import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SegInput } from '../../../Components/SegUI/SegInput/SegInput';
import CodigosDeArea from '../../../Assets/CodigosDeArea.json'
import { SegButton } from '../../../Components/SegUI/SegButton/SegButton';
import { useCotizacionStore } from '../../../Services/Providers/CotizacionStore';
import guardarContactoGenerico from '../../../Services/API/GuardarContactoGenerico';

interface FormularioProps {
    titulo: string;
    rubro: string;
}

type FormValues = {
    setNombre: string;
    setApellido: string;
    setPrefijo: string;
    setNumero: string;
    setEmail: string;
    setCodigoPostal: string;
    setLocalidad: string;
};

const validarTelefono = (prefijo: string, numero: string) => {
    const numeroCompleto = prefijo + numero;

    if (numeroCompleto.length === 10) {
        return true;
    } else {
        return false;
    }
};

const FormularioIndividuo = ({
    titulo,
    rubro
}: FormularioProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [formularioEnviado, setFormularioEnviado] = React.useState<boolean>(false);
    const [isTelefonoValido, setIsTelefonoValido] = React.useState<boolean>(false);
    const { handleSubmit, control, setValue, getValues, formState, trigger, setError, clearErrors, watch } =
        useForm<FormValues>();
    const { cotizacionValues, setCotizacionValues } = useCotizacionStore();

    const validarYActualizarTelefono = () => {
        const { setPrefijo, setNumero } = getValues();
        const resultadoValidarTelefono = validarTelefono(setPrefijo, setNumero);
        setIsTelefonoValido(resultadoValidarTelefono);
    };

    const obtenerTokenRecaptcha = (key: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
                    setCotizacionValues({
                        ...cotizacionValues,
                        captchaToken: token,
                    })
                    resolve(token)
                });
            });
        });
    };

    const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
        setIsLoading(true)

        const { setNombre, setApellido, setPrefijo, setNumero, setEmail, setCodigoPostal, setLocalidad } = values;
        setCotizacionValues({
            ...cotizacionValues,
            nombre: setNombre,
            apellido: setApellido,
            telefono: {
                prefijo: setPrefijo,
                numero: setNumero,
            },
            email: setEmail.toLowerCase(),
            codigoPostal: setCodigoPostal,
            localidadLanding: setLocalidad,
            rubro: rubro.toLocaleUpperCase(),
            tipoDoc: {
                value: "CUIT",
                label: "CUIT"
            }
        });

        const key = process.env.REACT_APP_RECAPTCHA_KEY
        obtenerTokenRecaptcha(key).then((token: string) => {
            if (!cotizacionValues?.contactoId) {
                const fetchData = async () => {
                    const response: any = await guardarContactoGenerico({ ...cotizacionValues, captchaToken: token });
                    console.log(response)
                    if (response?.data) {
                        if (response.data.api_status === 200 && response.data.api_data !== null) {
                            setCotizacionValues({
                                ...cotizacionValues,
                                contactoId: response.data.api_data.idContacto,
                                token: response.data.api_data.token,
                                captchaToken: token,
                            });
                            setFormularioEnviado(true);
                            setIsLoading(false)
                        } else if (response.data.api_data === "El Captcha no es válido") {
                            setIsLoading(false)
                            console.log('Error en el captcha')
                        } else {
                            console.error("Error al guardar contacto")
                            setIsLoading(false)
                        }
                    } else {
                        setIsLoading(false)
                    }
                }

                fetchData();
            }
        })


    };

    return (
        <>
            <div id='formulario'></div>
            <div>
                {!formularioEnviado && (
                    <div className="py-40 py-lg-50 bg-f3">
                        <div className="container px-18">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center mb-18 mb-lg-40">
                                {titulo}
                            </h2>
                            <div className="bg-white rounded-18 shadow-4 px-30 px-lg-60 py-30 py-lg-40">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row gx-lg-5 align-items-end">
                                        {/* Nombre */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Nombre</div>
                                                <Controller
                                                    name="setNombre"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => !!value || "Ingresá el nombre",
                                                            pattern: (value) =>
                                                                /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                                "Nombre inválido",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            className="fs-16"
                                                            label=""
                                                            name="setNombre"
                                                            type="text"
                                                            width={100}
                                                            placeHolder="Ej: Juan"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("setNombre", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("setNombre");
                                                            }}
                                                            value={field.value}
                                                            autoFocus={false}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.setNombre
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.setNombre
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                            absolut
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Apellido */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Apellido</div>
                                                <Controller
                                                    name="setApellido"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => !!value || "Ingresá el apelldio",
                                                            pattern: (value) =>
                                                                /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                                "Nombre inválido",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            className="fs-16"
                                                            label=""
                                                            name="setApellido"
                                                            type="text"
                                                            width={100}
                                                            placeHolder="Ej: García"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("setApellido", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("setApellido");
                                                            }}
                                                            value={field.value}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.setApellido
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.setApellido
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                            absolut
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Teléfono celular */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Teléfono celular</div>
                                                <div className="row g-2">
                                                    <div className="col-4">
                                                        <div className="fs-16 text-6e mb-1">Cód. área</div>
                                                        <Controller
                                                            name="setPrefijo"
                                                            control={control}
                                                            rules={{
                                                                validate: {
                                                                    required: (value) => !!value || "Ingresá el Cód. Área",
                                                                    isInValidCodeArea: (value) =>
                                                                        CodigosDeArea.includes(value) ||
                                                                        "Cód. Área inválido",
                                                                },
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <SegInput

                                                                    isGroup={true}
                                                                    labelGroup="0"
                                                                    className="fs-16"
                                                                    label=""
                                                                    name="setPrefijo"
                                                                    type="number"
                                                                    width={100}
                                                                    placeHolder="11"
                                                                    value={field.value}
                                                                    autoFocus={false}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue("setPrefijo", e.target.value); // Actualiza el valor en React Hook Form
                                                                        trigger("setPrefijo");
                                                                        if (getValues("setNumero") !== "" && !validarTelefono(getValues("setPrefijo"), getValues("setNumero"))) {
                                                                            setError("setNumero", { type: "validate", message: "Número inválido" })
                                                                        } else {
                                                                            clearErrors("setNumero")
                                                                        }
                                                                        validarYActualizarTelefono();

                                                                    }}
                                                                    leyendaError={fieldState?.error?.message}
                                                                    borderColor={`${formState.errors.setPrefijo
                                                                        ? "var(--bs-form-invalid-border-color)"
                                                                        : ""
                                                                        }`}
                                                                    focusBorderColor={`${formState.errors.setPrefijo
                                                                        ? "var(--bs-form-invalid-border-color)"
                                                                        : "#2c7dfd"
                                                                        }`}
                                                                    absolut
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="fs-16 text-6e mb-1">Número de teléfono</div>
                                                        <Controller
                                                            name="setNumero"
                                                            control={control}
                                                            rules={{
                                                                validate: {
                                                                    required: (value) => !!value || "Ingresá Número de Celular",
                                                                    pattern: (value) =>
                                                                        /^[0-9]{6,9}$/.test(value) || "Número inválido",
                                                                    validateCombination: (value) => {
                                                                        const prefijo = watch("setPrefijo");
                                                                        if ((prefijo + value).length !== 10)
                                                                            return "Número inválido"
                                                                    }
                                                                },
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <SegInput
                                                                    isGroup={true}
                                                                    labelGroup="15"
                                                                    className="fs-16"
                                                                    label=""
                                                                    name="setNumero"
                                                                    type="number"
                                                                    width={100}
                                                                    placeHolder="33669955"
                                                                    value={field.value}
                                                                    autoFocus={false}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue("setNumero", e.target.value); // Actualiza el valor en React Hook Form
                                                                        trigger("setNumero");
                                                                        validarYActualizarTelefono();
                                                                    }}
                                                                    leyendaError={fieldState?.error?.message}
                                                                    borderColor={`${formState.errors.setNumero
                                                                        ? "var(--bs-form-invalid-border-color)"
                                                                        : ""
                                                                        }`}
                                                                    focusBorderColor={`${formState.errors.setNumero
                                                                        ? "var(--bs-form-invalid-border-color)"
                                                                        : "#2c7dfd"
                                                                        }`}
                                                                    absolut
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Email */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Email</div>
                                                <Controller
                                                    name="setEmail"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => (!!value || "Ingresá el email"),
                                                            pattern: (value) =>
                                                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                                                    value
                                                                ) || "Email inválido",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput

                                                            className="fs-16"
                                                            label=""
                                                            name="setEmail"
                                                            type="text"
                                                            width={100}
                                                            placeHolder="Ej: nombre@dominio.com.ar"
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("setEmail", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("setEmail");
                                                            }}
                                                            value={field.value}
                                                            autoFocus={false}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.setEmail
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.setEmail
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                            absolut
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Código Postal */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Código Postal</div>
                                                <Controller
                                                    name="setCodigoPostal"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => !!value || "Ingresá el Código Postal",
                                                            pattern: (value) =>
                                                                /^[0-9]{4}$/.test(value) || "Número inválido",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            isGroup={false}
                                                            className="fs-16"
                                                            label=""
                                                            name="setCodigoPostal"
                                                            type="number"
                                                            width={100}
                                                            placeHolder=""
                                                            value={field.value}
                                                            autoFocus={false}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("setCodigoPostal", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("setCodigoPostal");
                                                            }}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.setCodigoPostal
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.setCodigoPostal
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                            absolut
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Localidad */}
                                        <div className="col-lg-6">
                                            <div className="mb-20 mb-lg-35">
                                                <div className="fs-20 fw-bold text-black mb-1">Localidad</div>
                                                <Controller
                                                    name="setLocalidad"
                                                    control={control}
                                                    rules={{
                                                        validate: {
                                                            required: (value) => !!value || "Ingresá la localidad",
                                                            pattern: (value) =>
                                                                /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                                                "Localidad inválido",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <SegInput
                                                            className="fs-16"
                                                            label=""
                                                            name="setLocalidad"
                                                            type="text"
                                                            width={100}
                                                            placeHolder=""
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue("setLocalidad", e.target.value); // Actualiza el valor en React Hook Form
                                                                trigger("setLocalidad");
                                                            }}
                                                            value={field.value}
                                                            autoFocus={false}
                                                            leyendaError={fieldState?.error?.message}
                                                            borderColor={`${formState.errors.setLocalidad
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : ""
                                                                }`}
                                                            focusBorderColor={`${formState.errors.setLocalidad
                                                                ? "var(--bs-form-invalid-border-color)"
                                                                : "#2c7dfd"
                                                                }`}
                                                            absolut
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* Cotizar button */}
                                        <div className="text-center pt-3">
                                            <SegButton
                                                label="Cotizar"
                                                type="submit"
                                                className="btn btn-primary fs-20 fs-lg-25 fw-bold w-200p w-lg-250p"
                                                disabled={
                                                    !formState.isValid // Verifica si el formulario es válido
                                                }
                                                loading={isLoading}
                                                spinnerSize='small'
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                {formularioEnviado && (
                    <div className="py-40 py-lg-50 bg-f3">
                        <div className="container px-18">
                            <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-primary text-center mb-7 mb-lg-12">
                                ¡Recibimos tu solicitud!
                            </h2>
                            <div className="col-10 col-lg-12 mx-auto">
                                <p className="fs-16 fs-lg-20 fw-medium lh-13 text-black text-center mb-25 mb-lg-30">
                                    En breve nos comunicaremos con vos para asesorarte en la mejor cobertura para tu empresa.
                                </p>
                            </div>
                            <div className="bg-white rounded-18 shadow-4 px-30 px-lg-60 py-30 py-lg-40">
                                <dl className="row fs-16 fs-lg-18 mb-25">
                                    <div className="col-md-6">
                                        <dt className="fw-normal">
                                            <svg className="svg-icon text-primary me-10" width="27" height="27">
                                                <use xlinkHref="#tel-lin"></use>
                                            </svg>
                                            Teléfono:
                                        </dt>
                                        <dd className="fw-bold lh-12 ps-40 mb-20 mb-lg-30">
                                            {cotizacionValues.telefono?.prefijo && cotizacionValues.telefono?.numero ? cotizacionValues.telefono?.prefijo + cotizacionValues.telefono?.numero : ""}
                                        </dd>
                                    </div>
                                    <div className="col-md-6">
                                        <dt className="fw-normal">
                                            <svg className="svg-icon text-primary me-10" width="27" height="27">
                                                <use xlinkHref="#envelope-lin"></use>
                                            </svg>
                                            Email:
                                        </dt>
                                        <dd className="fw-bold lh-12 ps-40 mb-20 mb-lg-30">
                                            {cotizacionValues.email}
                                        </dd>
                                    </div>
                                    <div className="col-md-6">
                                        <dt className="fw-normal">
                                            <svg className="svg-icon text-primary me-10" width="27" height="27">
                                                <use xlinkHref="#location-lin"></use>
                                            </svg>
                                            Localidad:
                                        </dt>
                                        <dd className="fw-bold lh-12 ps-40 mb-20 mb-lg-30">
                                            {cotizacionValues.localidadLanding}
                                        </dd>
                                    </div>
                                </dl>

                                <p className="fs-17 fw-medium text-center mb-0">
                                    ¿Querés continuar por WhatsApp?
                                    <span> </span>
                                    <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" className="d-inline-flex align-items-center fs-17 text-black">
                                        <span>Chatéanos</span>
                                        <svg className="svg-icon ms-2 w-12em">
                                            <use xlinkHref="#whatsapp"></use>
                                        </svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FormularioIndividuo;