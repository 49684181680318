import { useEffect, useMemo, useState } from 'react'
import { SegSvg } from '../../Components/SegUI/SegSvg/SegSvg'
import { SegHeaderLogo } from '../../Components/SegUI/SegHeaderLogo/SegHeaderLogo'
import segurarse from "../../Assets/img/logo_segurarse.svg";
import { useEmisionStore } from '../../Services/Providers/EmisionStore';
import { useCotizacionStore } from '../../Services/Providers/CotizacionStore';
import { useDescripcionesStore } from '../../Services/Providers/DescripcionesStore';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useSEOConfig from '../../hooks/seo/useSEOConfig';
import { Cards } from '../../Components/Slides/AP/precios/Cards';
import { ModalPrecios } from '../../Components/Slides/AP/precios/ModalPrecios';
import { Grilla } from '../../Components/Slides/AP/precios/Grilla';
import { SegLoading } from '../../Components/SegUI/SegLoading/SegLoading';
import animationData from "../../Assets/hombre-caminando.json";
import cotizarAP from '../../Services/API/cotizarAP';
import { imagesCompanias } from "../../Assets/imageCompaniaAP";


export interface CotizacionesAP {

}
const COTIZACION_MOCK: CotizacionesAP[] = [
    {
        id: 656726,
        nroCotizacion: "68785477",
        codigoCobertura: "AUTO MAX 1",
        descripciones: [
            {
                descripcion: "Muerte por accidente",
                monto: "39000000",
                id: "muerte"
            },
        ],
        prima: 38878.18,
        premio: "89.200",
        LogoCompania: "https://segurarse.com.ar/ContentS/react/static/media/logo_sancor.94b867e8e23b4c2aa1a81c950fe0bc4f.svg",
        NombreCompania: "Sancor",
        SumaAsegurada: "47.419.000",
    },
]
export const Precios = () => {

    const { emisionValues, setEmisionValues } = useEmisionStore();
    const {
        cotizacionValues,
        setCotizacionValues,
        vaciarCotizaciones,
    } = useCotizacionStore();
    const { descripcionesValues } = useDescripcionesStore();
    const [cotizando, setCotizando] = useState<boolean>(false);
    const [cargando, setCargando] = useState<boolean>(false);
    const nav = useNavigate();
    const { compania } = useParams();

    const [isCollapsed, setCollapsed] = useState(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const key = process.env.REACT_APP_RECAPTCHA_KEY;

    const handleScroll = () => {
        if (!isCollapsed) {
            setCollapsed(true);
        }
    };

    useSEOConfig({
        title: "Accidentes Personales",
    })

    useEffect(() => {
        // Añade el event listener al objeto window
        window.addEventListener("scroll", handleScroll);
        // Limpia el event listener al desmontar el componente
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isCollapsed]);

    useEffect(() => {
        if (cotizacionValues.parametrosIniciales && cotizacionValues.contactoId) {
            if (!esVigenciaCotizacionesValida()) {
            dispararCotizacion();
            setCargando(true);
            setTimeout(() => {
                setCargando(false);
            }, 10000);
            }
        } else {
            //TODO: navegar a cotizador
        }
    }, [])

    const obtenerTokenRecaptcha = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(key!, { action: 'submit' }).then(function (token) {
                    resolve(token);
                });
            });
        });
    };


    const encontrarRuta = (compania: string) => {
        if (compania) {
            let find = imagesCompanias.find(
                (comp) =>
                    comp.nombreCompania.toLocaleLowerCase() ===
                    compania.toLocaleLowerCase()
            );
            if (find) {
                return find.imgRuta;
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    const cotizarInterno = async (compania: any) => {
        let token = await obtenerTokenRecaptcha();
        const response = await cotizarAP(
            compania.codigoCompania,
            cotizacionValues.contactoId!,
            compania.emisionOnlineHabilitada,
            cotizacionValues.token!,
            source,
            token,
        )
        const arrResultados: CotizacionesAP[] = [];
        if (response.data.api_status == 200 && response.data.api_data.length > 0) {
            const data: any[] = response.data.api_data;

            data.forEach(element => {
                const cobertura: CotizacionesAP = {
                    id: element.Id,
                    nroCotizacion: element.NroCotizacion,
                    codigoCobertura: element.Codigo,
                    descripciones: parseDescriptions(element.Descripcion),
                    prima: element.Prima,
                    premio: element.Premio,
                    LogoCompania: encontrarRuta(compania.nombreCompania),
                    NombreCompania: compania.nombreCompania,
                    SumaAsegurada: element.SumaAsegurada,
                    franquicia: element.Franquicia
                }

                arrResultados.push(cobertura);
            });
        }

        return arrResultados;
    }

    function parseDescriptions(value: string) {
        const result = [];
        const regex = /([^(]+)\(Suma:\s*\$(\d+)\)/g;
        let match;

        while ((match = regex.exec(value)) !== null) {
            const descripcion = match[1].replace(/^\.\s*/, '');
            const monto = parseInt(match[2], 10);
            const id = descripcion.split(" ")[0].toLocaleLowerCase();
            result.push({ descripcion, monto, id});
        }

        return result;
    }

    const dispararCotizacion = async () => {
        if (esVigenciaCotizacionesValida()) return;

        setCotizando(true);
        let nuevasCotis: CotizacionesAP[] = [] //COTIZACION_MOCK;
        try {
            const fechaConsultaCotizaciones = new Date();
            await Promise.all(
                cotizacionValues.parametrosIniciales.companias.map(
                    async (compania: any, index: number) => {
                        const resultados = await cotizarInterno(compania)
                        nuevasCotis = nuevasCotis.concat(resultados);
                        setCotizacionValues({
                            ...cotizacionValues,
                            fechaConsultaCotizaciones,
                            cotizacionesAP: nuevasCotis
                        })
                    }
                )
            );
            setCotizando(false)
        } catch (error) {
            setCotizacionValues({
                ...cotizacionValues,
                cotizacionesAP: [],
            });
        } finally {
            setTimeout(() => {
                setCotizando(false);
            }, 12000)
        }
    };

    const esVigenciaCotizacionesValida = (): boolean => {
        if (
            cotizacionValues.cotizacionesAP &&
            cotizacionValues.cotizacionesAP.length > 0
        ) {
            const fechaEnSesion = new Date(
                cotizacionValues.fechaConsultaCotizaciones + ""
            );
            const fechaActual = new Date();
            if (
                fechaActual.getFullYear() !== fechaEnSesion?.getFullYear() ||
                fechaActual.getMonth() !== fechaEnSesion.getMonth() ||
                fechaActual.getDate() !== fechaEnSesion.getDate()
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const handleSetCotizacionDesc = (cotizacion: any) => {
        setEmisionValues({
            ...emisionValues,
            cotizacionDescAP: cotizacion,
        });
    };

    const renderFecha = (fecha: string = "") => {
        const date = new Date(fecha)

        return `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    const formatearSumaAsegurada = (value: string = "") => {
        const numericValue = parseInt(value.replace(/[^0-9]/g, ""), 10);
        return `$ ${new Intl.NumberFormat("es-AR").format(numericValue)}`;
    };

    const segLoadingComponent = useMemo(
        () => (
            <SegLoading
                lottie
                lottieJson={animationData}
                loadingTitle="Cargando"
                icon="#ico-hombre"
                product={""}
                startText="Tu cotización esta casi lista. Estamos buscando"
                middleText=" en tiempo real "
                endText="las mejores coberturas y precios para vos"
                captions={[
                    {
                        value: "Gratuito",
                    },
                    {
                        value: "Online",
                    },
                    {
                        value: "Personalizado",
                    },
                ]}
            />
        ),
        []
    );

    return (
        <>
            {cargando ? segLoadingComponent
                :
                <>
                    <ModalPrecios sumaAsegurada={formatearSumaAsegurada(cotizacionValues.sumaMuerte?.toString())} cotizacionDesc={emisionValues.cotizacionDescAP} />
                    <div>
                        <SegSvg />
                        <header className="site-header sticky-lg-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                                        <SegHeaderLogo img={segurarse} alt="Logo Segurarse" width="160px" />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="sec-precios">
                            <div className="main-wrapper">
                                <div className="ac-desktop">
                                    <div>
                                        <b>{cotizacionValues.profesion?.label} &gt; </b>
                                        <b>{`${renderFecha(cotizacionValues.fechaInicio)} al ${renderFecha(cotizacionValues.fechaFin)} | `}</b>
                                        <span>{"Provincia: "}</span>
                                        <b>{cotizacionValues.provincia?.label}</b>
                                        <span>{" Localidad: "}</span>
                                        <b>{cotizacionValues.localidad?.label}</b>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <span>Suma asegurada: <b>{formatearSumaAsegurada(cotizacionValues!.sumaMuerte?.toString())}</b></span>
                                        <a href="/#" className="btn1 btn1-light">Modificar datos</a>
                                    </div>
                                </div>

                                <div className="ac-mobile d-lg-none"><div className="wrapper"><a className="trigger collapsed" data-bs-toggle="collapse" href="#collapseExample" aria-controls="collapseExample" role="button" aria-expanded="false">
                                    <svg className="icon ico-hombre">
                                        <use href="#ico-hombre"></use>
                                    </svg>
                                    cotizado
                                    <svg className="icon ico-arrow">
                                        <use href="#arrowright2">
                                        </use>
                                    </svg>
                                </a>
                                    <div className="collapse" id="collapseExample">
                                        <div className='detail text-center'>
                                            <span>{"Profesion: "}<b>{cotizacionValues.profesion?.label}</b></span><br />
                                            <b>{`${renderFecha(cotizacionValues.fechaInicio)} al ${renderFecha(cotizacionValues.fechaFin)}`}</b><br />
                                            <span>{"Provincia: "}</span>
                                            <b>{cotizacionValues.provincia?.label}</b><br />
                                            <span>{" Localidad: "}</span>
                                            <b>{cotizacionValues.localidad?.label}</b>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {(window.innerWidth > 768) &&
                                    <>
                                        <Grilla cotizando={cotizando} cotizaciones={cotizacionValues.cotizacionesAP || []} setDescripciones={handleSetCotizacionDesc} />
                                    </>
                                }
                                <Cards
                                    cotizando={cotizando}
                                    cotizaciones={cotizacionValues.cotizacionesAP || []}
                                    setDescripciones={handleSetCotizacionDesc}
                                />
                            </div>
                        </main >
                    </div >
                </>
            }
        </>
    )
}
