import imgMobile from "../../../Assets/img/figura_08.png";
import imgDesktop from "../../../Assets/img/figura_09.png";

interface Props {
    background?: string;
}
const Asesoramiento = ({background = "bg-fa"}:Props) => {
    return (
        <div className={`overflow-hidden ${background}`}>
            <div className="container-lg pt-30 pt-lg-70 pb-lg-50 position-relative">
                <div className="d-flex flex-column flex-lg-row align-items-center">

                    <div className="col-10 col-sm-8 col-md-7 col-lg-8 text-center text-lg-start maxw-560p mb-40 mb-lg-0">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-16 mb-lg-25">¿Querés recibir asesoramiento?</h2>
                        <p className="fs-16 fs-lg-20 lh-13 text-black mb-25 mb-lg-30">Estamos a un chat de distancia. Contános que seguro estas buscando y un ejecutivo estará a tu disposición.</p>

                        <a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" className="d-inline-flex align-items-center fs-18 fs-lg-20">
                            <span className="fs-18 fs-lg-20 text-black fw-bold">Chatéanos</span>
                            <svg className="svg-icon ms-2 w-12em"><use xlinkHref="#whatsapp"></use></svg>
                        </a>

                    </div>

                    <div className="d-lg-none maxw-330p">
                        <img className="img-fluid" src={imgMobile} alt="Figura 08" />
                    </div>

                    <div className="d-none d-lg-block fig-position-09">
                        <img className="img-fluid" src={imgDesktop} alt="Figura 09" />
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Asesoramiento;
