
export const ModalSinEOL = () => {
    return (
        <div className="modal" id="modal3" tabIndex={-1} aria-labelledby="ModalLabel" style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-se maxw-lg-400p modal-wpp">
                <div className="modal-content bg-e6e6e6 p-2">
                    <div className="modal-header border-0 mb-4">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-0 text-center">

                        <p className="fs-18 fs-lg-15 fw-bold lh-sm">La opción seleccionada no tiene emisión online, para continuar contactarse con un asesor.</p>

                        <svg className="icon mb-2" style={{width:30, height:30}}><use xlinkHref="#whatsapp"></use></svg>

                        <p className="fs-17 fw-medium mb-0">¿Querés continuar por WhatsApp?</p>
                        <p className="fs-17 fw-medium mb-0"><a href="https://api.whatsapp.com/send/?phone=5491169568002&text&type=phone_number&app_absent=0" target='_blank' rel="noreferrer" className="text-black hover-border-underline">Chatéanos</a></p>

                    </div>
                </div>
            </div>
        </div>
    )
}
