import { useEffect, useRef, useState } from 'react';

interface Props {
    children: any;
    className?: string;
    abrirPrimeraCarga?: boolean;
}
export const OffCanvas = ({ children, className, abrirPrimeraCarga = false }: Props) => {

    const [primeraCarga, setPrimeraCarga] = useState(abrirPrimeraCarga);
    const offcanvasRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (primeraCarga) {
            const button = document.getElementById('btn-open-offcanvas');
            if (button) {
                button.click();
            }
            setPrimeraCarga(false);
        }
    }, []);

    return (
        <>
            <a style={{display: "none"}} id="btn-open-offcanvas" data-bs-toggle="offcanvas" href={"#offcanvas"} role="button">open</a>
            <div
                ref={offcanvasRef}
                className={`${className ? className : "offcanvas offcanvas-end oc-cob oc-cob1"}`} id="offcanvas" tab-index="-1">
               
                <div className="offcanvas-body">
                     <button type="button" className="btn-close" data-bs-toggle="offcanvas" 
                     data-bs-target="#offcanvas" aria-controls="offcanvas"></button>
                    {children}
                </div>
            </div>
        </>
    )
}
