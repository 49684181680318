import hero from "../../../Assets/img/bg-hero-individuos-desktop.png";
import figura12 from "../../../Assets/img/figura12.svg";
import { CardIconoDetalle } from '../Components/CardIconoDetalle';
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg';
import { GridCoberturas } from '../Components/GridCoberturas';
import Asesoramiento from '../Components/Asesoramiento';
import { TestimoniosSlider } from '../Components/TestimoniosSlider';
import { Header as NavBar } from '../Components/Header';
import { individuos_beneficios, individuos_coberturas, testimonios_clientes } from "../diccionario";
import PropuestaValor from "../Components/PropuestaValor";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useRef } from "react";


export const Individuos = () => {
    return (
        <>
            <NavBar rubro='auto' />
            <main className="bg-white">
                <SegSvg />
                <div className="overflow-hidden pb-lg-20">
                    <div className="bg-primary">
                        <div className="container-lg pt-40 pb-280 px-20 px-lg-12 position-relative bg-hero-individuos z-1">
                            <div className="d-flex flex-column flex-lg-row">

                                <div className="col-10 col-sm-8 col-lg-9 col-xl-7">
                                    <h2 className="ff-mukta fw-bold fs-30 fs-lg-50 lh-11 text-white mb-25 mb-lg-32">Para cada persona tenemos una solución a medida.</h2>

                                    <p className="fs-16 fs-lg-20 lh-12 text-white mb-22 mb-lg-32">Somos transparentes, hacemos visibles y accesibles las oportunidades de protección que vos necesitás.</p>
                                    <a href="#coberturas" className="transition-base d-inline-block w-180p w-lg-235p py-10 py-lg-3 rounded-2 fs-15 fs-lg-22 fw-bold lh-1 text-center bg-white text-primary">Cotizar gratis</a>
                                </div>

                                <div className="d-none d-lg-block fig-position-individuos">
                                    <img className="img-fluid" src={hero} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-40 py-lg-70">
                    <div className="container px-25 px-lg-12">

                        <div className="row">
                            <div className="d-none d-lg-block col-lg-5">
                                <img className="img-fluid" src={figura12} />
                            </div>
                            <div className="col-lg-6 ms-auto">
                                <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black text-center text-lg-start mb-35 mb-lg-50">¿Por qué elegirnos?</h2>
                                <ul className="list-unstyled fs-16 fs-lg-22 lh-12">
                                    <li className="bullet-circle-primary ps-4 mb-25">Te acercamos diversas <b>coberturas y precios a tu medida</b>, ¡no pagués de más!</li>
                                    <li className="bullet-circle-primary ps-4 mb-25">Trabajamos con las <b>compañías aseguradoras líderes del país</b>.</li>
                                    <li className="bullet-circle-primary ps-4 mb-25">Más de 10 años vendiendo seguros online, <b>nuestra experiencia nos hace únicos</b>.</li>
                                    <li className="bullet-circle-primary ps-4 mb-0"><b>Ofrecemos servicios durante y post contratación</b>, ¡olvídate de los trámites!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-grad-03 pt-40" style={{ paddingBottom: "50px" }}>
                    <div className="container dots-white">
                        <div className="col-9 mx-auto">
                            <h2 className="ff-mukta fw-bold text-black fs-30 fs-lg-40 text-center mb-20 mb-lg-40">
                                Beneficios para nuestros clientes
                            </h2>
                        </div>
                        <Slider />
                    </div>
                </div>

                <div id="coberturas">
                    <GridCoberturas
                        CoberturaEmpresas={false}
                        coberturas={individuos_coberturas}
                        titulo='Coberturas para todos los riesgos'
                    />
                </div>
                <Asesoramiento />


                <div className="bg-f3 py-45">
                    <div className="container">

                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-black mb-30 mb-lg-40 text-center">Nuestros <br className="d-md-none" /> clientes dicen...</h2>

                        <div className="row mb-50 align-items-center">
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">85.4%</span>
                                    <span className="fs-18 text-black lh-11">Están <b>muy satisfechos</b><br /> con el servicio recibido</span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">8 <span className="w-40p ms-2 me-1 fs-15 fw-bold text-start">de cada</span> 10</span>
                                    <span className="fs-18 text-black lh-11"><b>Recomendarían</b><br /> Segurarse</span>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="d-flex flex-column flex-xxl-row align-items-center mb-22 text-center text-xxl-start">
                                    <span className="fs-52 fw-bold text-primary lh-1 d-inline-flex align-items-center me-xl-3">9 <span className="w-40p ms-2 me-1 fs-15 fw-bold text-start">de cada</span> 10</span>
                                    <span className="fs-18 text-black lh-11"><span>Reconocen el <b>profesionalismo</b><br /> del ejecutivo a cargo</span>
                                    </span></p>
                            </div>
                        </div>

                        <TestimoniosSlider testimonios={testimonios_clientes} />
                    </div>
                </div>
            </main>
        </>
    )
}


const Slider = () => {

    return (
        <Splide
            options={{
                type: 'loop',
                focus: 0,
                drag: true,
                flickMaxPages: 1,
                flickPower: 50,
                perPage: 1,
                perMove: 1,
                // autoplay: true,
                // interval: 4000,
                arrows: false,
                mediaQuery: 'min',
                gap: '30px',
                padding: '55px',
                breakpoints: {
                    576: {
                        perPage: 1,
                        padding: '0',
                    },
                    768: {
                        perPage: 2,
                    },
                    992: {
                        perPage: 4,
                        drag: false,
                    },
                }
            }}
            style={window.innerWidth < 786 ? { width: "100vw", marginLeft: "-10px" } : {}}
        >
            {
                individuos_beneficios.map((beneficio, i) => (
                    <SplideSlide key={i} style={{
                        marginTop: "48px"
                    }}
                    >
                        <div className="pt-10 px-10 px-lg-19 pb-30 bg-white border border-3 border-primary rounded-18 shadow-3 text-center h-100">
                            <div className="mb-16 mt-n50 mt-lg-n60"><svg className="svg-icon w-90p h-90p w-lg-105p h-lg-105p rounded-circle shadow-3"><use href={beneficio.icono}></use></svg></div>
                            <h3 className="fs-21 fs-lg-24 fw-bold text-primary lh-11 mb-12 mb-lg-20 minh-lg-80p">{beneficio.titulo}</h3>
                            <p className="fs-16 fs-lg-18 lh-sm mb-0">{beneficio.descripcion}</p>
                        </div>
                    </SplideSlide>
                ))
            }

        </Splide>
    )
}