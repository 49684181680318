import React, { CSSProperties, forwardRef } from 'react'
import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { hover } from '@testing-library/user-event/dist/hover'



interface SegButtonProps {
  type: "button" | "submit"
  label: string,
  icon?: React.ElementType<SvgIconProps>,
  onClick?: () => void,
  disabled?: boolean
  bgColor?: string
  textColor?: string
  borderColor?: string
  hoverBgColor?: string
  hoverTextColor?: string
  hoverBorderColor?: string
  disabledBgColor?: string
  disabledTextColor?: string
  activeBgColor?: string
  activeTextColor?: string
  className?: string
  loading?: boolean
  spinnerSize?: "normal" | "small"
  lineHeight?: string
  height?: string
  iconSide?: "right" | "left"
  idButton?: string
  style?: CSSProperties | undefined
  hoverOpacity?: boolean
}

const Button = styled.button<{ bgColor: string, textColor: string, borderColor: string, hoverBgColor: string, hoverTextColor: string, hoverBorderColor: string, disabledBgColor: string, disabledTextColor: string, activeBgColor: string, activeTextColor: string, lineHeight: string, height: string, hoverOpacity:boolean }>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  border-color: ${({ borderColor }) => borderColor};
  min-width: 172px;
  line-height: ${({ lineHeight }) => lineHeight};
  height: ${({ height }) => height};


  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
    ${({hoverOpacity}) => hoverOpacity ? "opacity: 0.7;" : ""}
  }


  &:disabled {
    background-color: ${({ disabledBgColor }) => disabledBgColor};
    color: ${({ disabledTextColor }) => disabledTextColor};
    border-color: ${({ disabledBgColor }) => disabledBgColor};
  }


  &:active {
    background-color: ${({ activeBgColor }) => activeBgColor} !important;
    color: ${({ activeTextColor }) => activeTextColor} !important;
    border-color: ${({ activeBgColor }) => activeBgColor} !important;
  }

  &:focus {
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ textColor }) => textColor};
    border-color: ${({ borderColor }) => borderColor};
  }

  &:focus:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor};
    color: ${({ hoverTextColor }) => hoverTextColor};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor};
  }

`;

export const SegButton = forwardRef<HTMLButtonElement, SegButtonProps>(
  (
    {
      type,
      label,
      icon: Icon,
      onClick,
      disabled = true,
      bgColor = '#2C7DFD',
      textColor = '#fff',
      borderColor = '#2C7DFD',
      hoverBgColor = '#0b5ed7',
      hoverTextColor = '#fff',
      hoverBorderColor = '#0a53be',
      disabledBgColor = '#a5a5a5',
      disabledTextColor = '#fff',
      activeBgColor = '#0a58ca',
      activeTextColor = '#fff',
      className,
      loading = false,
      spinnerSize,
      lineHeight = spinnerSize === "small" ? "" : "",
      height = spinnerSize === "small" ? "100%" : "",
      iconSide = "left",
      idButton,
      style,
      hoverOpacity = false,
      ...rest
    },
    ref) => {

    return (
      <Button id={idButton} ref={ref} disabled={disabled || loading} type={type} onClick={() => { onClick && onClick() }}
        className={`btn ${className}`} bgColor={bgColor} textColor={textColor}
        borderColor={borderColor} hoverBgColor={hoverBgColor} hoverTextColor={hoverTextColor}
        hoverBorderColor={hoverBorderColor} disabledBgColor={disabledBgColor} disabledTextColor={disabledTextColor}
        activeBgColor={activeBgColor} activeTextColor={activeTextColor}
        lineHeight={lineHeight}
        height={height}
        style={style}
        hoverOpacity={hoverOpacity}>

        {(!loading && Icon && iconSide === "left") &&

          <Icon className="buttonIcon" style={{ marginRight: '5px', verticalAlign: 'sub' }} />
        }
        {label}

        {(!loading && Icon && iconSide === "right") &&

          <Icon className="buttonIcon" style={{ marginLeft: '15px', verticalAlign: 'sub' }} />
        }

        {loading &&
          <span className="spinner-border text-light" role="status" style={spinnerSize === "small" ? { height: "1.5rem", width: "1.5rem", borderWidth: "0.20rem", marginLeft: "1rem" } : {}}>
            <span className="visually-hidden">Loading...</span>
          </span>
        }
      </Button>
    )
  }
)