export interface TelefonosCompañia {
    aseguradora: string,
    txtMostrar:string,
    clave: string
    telefono: string
}


export const telefonos: TelefonosCompañia[] = [
    {
        aseguradora: "Allianz",
        txtMostrar: "Allianz Seguros",
        clave: "allianz",
        telefono: "0810-220-7348"
    },
    {
        aseguradora: "Berkley",
        txtMostrar: "Berkley Seguros",
        clave: "berkley",
        telefono: "0810-220-7348"
    },
    {
        aseguradora: "Integrity",
        txtMostrar: "Integrity Seguros",
        clave: "liberty",
        telefono: "0810-220-7348"
    },
    {
        aseguradora: "Mapfre",
        txtMostrar: "Mapfre Seguros",
        clave: "mapfre",
        telefono: "0810-220-7348"
    },
    {
        aseguradora: "Meridional",
        txtMostrar: "Meridional Seguros",
        clave: "meridional",
        telefono: "0810-220-0165"
    },
    {
        aseguradora: "Nación Seguros",
        txtMostrar: "Nación Seguros",
        clave: "nacion",
        telefono: "0810-220-9230"
    },
    {
        aseguradora: "Provincia",
        txtMostrar: "Provincia Seguros",
        clave: "provincia",
        telefono: "0810-220-2010"
    },
    {
        
        aseguradora: "Rivadavia",
        txtMostrar: "Seguros Rivadavia",
        clave: "rivadavia",
        telefono: "0810-220-4014",
    },
    {
        
        aseguradora: "Rivadavia",
        txtMostrar: "Seguros Rivadavia",
        clave: "rivadavia-seguros",
        telefono: "0810-220-4014",
    },
    {
        aseguradora: "Sura",
        txtMostrar: "Seguros Sura",
        clave: "rsa",
        telefono: "0810-220-6794"
    },
    {
        aseguradora: "San Cristóbal",
        txtMostrar: "Seguros San Cristóbal",
        clave: "san-cristobal",
        telefono: "0810-220-7412"
    },
    {
        aseguradora: "SanCor",
        txtMostrar: "Sancor Seguros",
        clave: "sancor",
        telefono: "0810-220-5015"
    },
    {
        aseguradora: "SanCor",
        txtMostrar: "Sancor Seguros",
        clave: "sancor-seguros",
        telefono: "0810-220-5015"
    },
    {
        aseguradora: "San Cristóbal",
        txtMostrar: "Seguros San Cristóbal",
        clave: "sancristobal",
        telefono: "0810-220-7412"
    },
    {
        aseguradora: "La Segunda",
        txtMostrar: "Seguros La Segunda",
        clave: "segunda",
        telefono: "0810-220-9020"
    },
    {
        aseguradora: "Zurich",
        txtMostrar: "Zurich Seguros",
        clave: "zurich",
        telefono: "0810-220-0205"
    },
    {
        aseguradora: "La nueva Seguros",
        txtMostrar: "La nueva Seguros",
        clave: "nueva",
        telefono: "0810-220-7348"
    },
]