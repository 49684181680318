import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SegSelect, SegSelectOption } from "../../SegUI/SegSelect/SegSelect";
import obtenerProvincias from "../../../Services/API/Provincias";
import obtenerLocalidaXProvincia from "../../../Services/API/Localidades";
import { IPasos } from "../../../Pages/ART/Cotizador";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { RubroTypes } from "../../../types";

type ReactFormData = {
  control: ReturnType<typeof useForm>['control'];
  setValue: ReturnType<typeof useForm>['setValue'];
  formState: ReturnType<typeof useForm>['formState'];
  trigger: ReturnType<typeof useForm>['trigger'];
  getValues: ReturnType<typeof useForm>['getValues']
  setError: ReturnType<typeof useForm>['setError'];
  watch: ReturnType<typeof useForm>['watch'];
  clearErrors: ReturnType<typeof useForm>['clearErrors'];
};

type InputProps = {
  inputData: {
    stepId: string;
    title?: string;
    subtitle?: string;
    section?: string;
    fields: RubroTypes.Field[];
  };
  ReactFormData?: ReactFormData;
  HandleInputChange?: (value: any) => any;
  currentValues: any;
}

interface ICodigoPostalManual {
  provincia: string;
  localidad: string;
  cuandoContrata: string;
}

// interface CodigoPostalManualProps {
//   setSteps: Dispatch<SetStateAction<IPasos>>
// }

export const ProvinciaLocalidadInput: React.FC<InputProps> = ({ inputData: { stepId, title, subtitle, section, fields, ...rest }, ReactFormData, HandleInputChange, currentValues }) => {
  // const nav = useNavigate();

  const [localidadDis, setLocalidadDis] = useState<boolean>(false);
  const [provinciaDis, setProvinciaDis] = useState<boolean>(false);

  const [localidadLoad, setLocalidadLoad] = useState<boolean>(false);
  const [provinciaLoad, setProvinciaLoad] = useState<boolean>(false);

  const [provincias, setProvincias] = useState<SegSelectOption[]>([]);
  const [localidades, setLocalidades] = useState<SegSelectOption[]>([]);

  const [provincia, setProvincia] = useState<SegSelectOption>();
  const [localidad, setLocalidad] = useState<SegSelectOption>();

  const [provinciaInput, setProvinciaInput] = useState<RubroTypes.Field>();
  const [localidadInput, setLocalidadInput] = useState<RubroTypes.Field>();

  const [openProvincia, setOpenProvincia] = useState<boolean>(false)
  const [openLocalidad, setOpenLocalidad] = useState<boolean>(false)

  const btnRef = useRef<HTMLButtonElement | null>(null);


  const methods = useForm<ICodigoPostalManual>();

  useEffect(() => {
    if (fields) {
      setProvinciaInput(fields.find((field) => field.name === "provincia"))
      setLocalidadInput(fields.find((field) => field.name === "localidad"))
    }
  }, [fields])


  const fetchProvincias = async () => {
    setProvinciaDis(true);
    setProvinciaLoad(true);
    const response: any = await obtenerProvincias();
    setProvinciaDis(false);
    setProvinciaLoad(false);
    const formattedData = response.map((item: any) => ({
      value: item,
      label: item,
    }));
    setProvincias(formattedData)
  }




  const fetchLocalidades = async () => {
    if (ReactFormData) {
      if (provincia !== null && provincia !== undefined) {

        setLocalidadLoad(true);
        setLocalidadDis(true);
        const response: any = await obtenerLocalidaXProvincia(provincia?.value);
        setLocalidadDis(false);
        setLocalidadLoad(false);
        const formattedData = response.map((item: any) => ({
          value: item,
          label: item,
        }));
        setLocalidades(formattedData);
        if (localidad) {
          if (
            !formattedData.some((loc: any) => {
              return loc.value === localidad?.value;
            })
          ) {
            ReactFormData.setValue("localidad", "");
            setLocalidad({
              value: "",
              label: ""
            })
          } else {
            setLocalidad(currentValues.localidad)
            // ReactFormData.setValue("localidad", localidad?.value);
          }
        }
        if (currentValues?.localidad) {
          if (
            !formattedData.some((loc: any) => {
              return loc.value === currentValues.localidad?.value;
            })
          ) {
            // ReactFormData.setValue("localidad", "");
            setLocalidad({
              value: "",
              label: ""
            })
          } else {
            setLocalidad(currentValues?.localidad)
            // ReactFormData.setValue("localidad", currentValues?.localidad?.value);
          }
        }

      }
    }
  };

  useEffect(() => {
    fetchProvincias().then(() => {
      if (ReactFormData && currentValues?.provincia) {
        setProvincia(currentValues?.provincia)
        ReactFormData.setValue("provincia", { label: currentValues.provincia?.value, value: currentValues.provincia?.value, });
        setOpenProvincia(false)
      } else {
        setOpenProvincia(true)
      }
    });
    if (currentValues.provincia && currentValues.localidad && currentValues.cuandoContrata) {
      setOpenProvincia(false)
      setOpenLocalidad(false)
    }
  }, []);

  useEffect(() => {
    fetchLocalidades();
  }, [provincia]);

  // Obtén el valor del error desde la URL
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('error');


  return (
    <div className="mt-4 bg-gray-light" {...rest}>
      <div className="frm01">
        {ReactFormData ?
          <>
            <div className="cod-postal-column justify-content-center gap-4">
              <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                <fieldset className="">
                  <legend>Seleccioná tu provincia</legend>
                  <FormProvider {...methods}>
                    <Controller
                      name="provincia"
                      control={ReactFormData?.control}
                      rules={provinciaInput?.rules}
                      render={({ field, fieldState }) => (
                        <SegSelect
                          selected={field.value}
                          setSelected={(option: SegSelectOption) => {
                            // handleProvinciaChange(option)
                            ReactFormData.setValue("provincia", option)
                            ReactFormData.trigger("provincia");
                            setProvincia(option)
                            setOpenLocalidad(true)
                            if (HandleInputChange && ReactFormData) {
                              const targetValues = {
                                target: { name: "provincia", value: option }
                              };
                              HandleInputChange(targetValues);
                              //   if (localidad?.value) {
                              //     const resetValues = {
                              //       target: { name: "localidad", value: undefined }
                              //     };
                              //     HandleInputChange(resetValues);
                              //     ReactFormData.setError("localidad", {
                              //       type: "required",
                              //       message: "Seleccione una localidad"
                              //     })
                              //     ReactFormData.trigger("localidad");

                              //   }
                              //   ReactFormData.setValue("provincia", option)
                              //   ReactFormData.trigger("provincia");
                            }
                            // field.onChange()
                          }}
                          name="provincia"
                          options={provincias}
                          width={"100%"}
                          disabled={provinciaDis}
                          loading={provinciaLoad}
                          borderColor={ReactFormData?.formState.errors.provincia ? "red" : ""}
                          openDefault={openProvincia}
                          autoFoco={true}
                        />
                      )} />
                  </FormProvider>
                  {/* <p>{errors.provincia?.message}</p> */}
                </fieldset>
              </div>
              <div className="col-10 col-sm-10 col-md-5 col-lg-3">
                <fieldset className="">
                  <legend>Seleccioná tu localidad</legend>
                  <FormProvider {...methods}>
                    <Controller
                      name="localidad"
                      control={ReactFormData?.control}
                      rules={localidadInput?.rules}
                      render={({ field, fieldState }) => (
                        <SegSelect
                          selected={field.value}
                          setSelected={(option: SegSelectOption) => {

                            // handleLocalidadChange(option)
                            ReactFormData.setValue("localidad", option)
                            ReactFormData.trigger("localidad");
                            setLocalidad(option)
                            setOpenLocalidad(false)
                            if (HandleInputChange && ReactFormData) {
                              const targetValues = {
                                target: { name: "localidad", value: option }
                              };
                              HandleInputChange(targetValues);
                            }
                            // field.onChange()
                          }
                          }
                          name="localidad"
                          options={localidades}
                          width={"100%"}
                          disabled={localidadDis}
                          loading={localidadLoad}
                          borderColor={ReactFormData?.formState.errors.localidad ? "red" : ""}
                          openDefault={openLocalidad}
                          autoFoco={false}
                        />)}
                    />
                  </FormProvider>
                  {/* <p>{errors.localidad?.message}</p> */}
                </fieldset>
              </div>
            </div>
          </>
          : <></>}
      </div>
    </div>
  );
};
