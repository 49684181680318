import sancor from "../Assets/img/logos/cias/logo_sancor_b.png"

export interface Companias {
    nombreCompania: string,
    imgRuta: string,
    mostrar: boolean
}

export const imagesCompanias: Companias[] = [
    {
        nombreCompania: "Sancor",
        imgRuta: sancor,
        mostrar: true
    },
]