import { Header } from "../Components/Header"
import { SegSvg } from '../../../Components/SegUI/SegSvg/SegSvg'
import decorativo from '../../../Assets/img/shape13.svg'
import { Hero } from '../Components/Hero'
import { PorQueElegirnos } from '../Components/PorQueElegirnos'
import PropuestaValor from '../Components/PropuestaValor'
import Asesoramiento from '../Components/Asesoramiento'


export const IntegralComercio = () => {
    return (
        <>
            <SegSvg />
            <Header
                rubro='auto'
            />
            <div style={{ backgroundColor: "white" }}>
                <Hero
                    titulo='Integral de Comercio'
                    subTitulo={{ __html: 'Protegé tu comercio ante contingencias que podrían ocasionar pérdidas financieras.<br /><br />¿Empezamos?' }}
                    url='/integral-comercio/cotizacion'
                    icon='#icon-seg-comercio'
                />
                <div className="pt-70 pt-lg-80 pb-40 bg-primary position-relative">
                    <img
                        className="d-none d-lg-block pos16 blend-multipy"
                        src={decorativo}
                        alt="Decorative Shape"
                    />
                    <div className="container">
                        <h2 className="ff-mukta fw-bold fs-30 fs-lg-40 text-white text-center mb-16 mb-lg-25">
                            ¿Qué cubre?
                        </h2>
                        <div className="col-10 col-lg-12 mx-auto">
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Este seguro tiene como finalidad proteger a tu comercio con una amplia gama de coberturas.  Diseñamos planes a medida para brindar una protección adecuada contra los riesgos que puedan     surgir de su actividad comercial.
                            </p>
                            <p className="fs-17 fs-lg-20 lh-13 text-white text-center mb-16 mb-lg-20">
                                Dentro de las principales coberturas, se incluyen:
                            </p>
                        </div>

                        <div className="px-20 px-lg-0 maxw-lg-920p mx-auto">
                            <div className="row gx-lg-80">
                                <div className="col">
                                    <ul className="list-unstyled list-bullet-md fs-16 lh-14 mb-0 text-white">
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Incendio y daños materiales:</b> cubre daños ocasionados por incendios, explosiones, inundaciones, terremotos u otros eventos similares que puedan afectar el lugar físico del comercio, como el edificio, el inventario, el mobiliario y los equipos.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Robo y hurto:</b> protege contra el robo o hurto de bienes, ya sea en el local comercial, en tránsito o en otros lugares relacionados con el negocio.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25">
                                            <b>Responsabilidad civil:</b> cubre los daños o lesiones causados a terceros debido a la actividad comercial de la empresa.
                                        </li>
                                        <li className="bullet-check-primary ps-35 ps-lg-45 border-bottom border-2 border-white border-opacity-25 border-lg-none">
                                            <b>Mobiliario, Maquinaria y electrónica (robo e incendio)</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PorQueElegirnos />
                <PropuestaValor />
                <Asesoramiento />
            </div>
        </>
    )
}
