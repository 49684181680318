import { useState } from 'react'
import { useEmisionStore } from '../../../../Services/Providers/EmisionStore';
import subirArchivo from '../../../../Services/API/CertificadoNoRodamiento';

export const CertificadoNoRodamiento = () => {
    const [file, setFile] = useState<File | null>(null);
    const { emisionValues } = useEmisionStore();
    const [extensionArchivo, setExtensionArchivo] = useState<string>("");
    const [nombreArchivo, setNombreArchivo] = useState<string>("");
    const [dataBase64Archivo, setDataBase64Archivo] = useState<string>("");

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            const extension = selectedFile.name.split('.').pop()?.toLowerCase();
            if (extension) {
                setExtensionArchivo(extension);
            }
            const nombre = selectedFile.name.split('.').shift()?.toLowerCase();
            if (nombre) {
                setNombreArchivo(nombre);
            }
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = async function () {
                const dataBase64 = reader.result as string;
                const base64 = dataBase64?.split(',').pop();
                if (base64) {
                    setDataBase64Archivo(base64);
                }
            };
            reader.onerror = function (error) {
                console.error("Error occurred while reading the file:", error);
            };
        }
    };

    const handleUpload = async () => {
        if (file && emisionValues.id) {
            console.log("Uploading file...");
            // console.log(JSON.stringify({ hash: process.env.REACT_APP_HASH, idPoliza: emisionValues.id, archivo: { nombre: nombreArchivo, extencion: extensionArchivo, dataBase64: dataBase64Archivo } }),)
            try {
                const response: any = await subirArchivo(nombreArchivo, extensionArchivo, dataBase64Archivo, emisionValues.id);
            } catch (error) {
                console.error("Error occurred while uploading the file:", error);
            }
        }
    };

    return (
        <>
            <div>
                <label htmlFor="file" className="sr-only">
                    Choose a file
                </label>
                <input id="file" type="file" onChange={handleFileChange} />
            </div>
            {file && (
                <section>
                    File details:
                    <ul>
                        <li>Name: {file.name}</li>
                        <li>Type: {file.type}</li>
                        <li>Size: {file.size} bytes</li>
                    </ul>
                </section>
            )}
            {file && <button onClick={handleUpload}>Upload a file</button>}
        </>
    );
};
