import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { IEmisionValues, IFechaNacimiento, ITelefono } from '../../../../Services/Providers/EmisionStore'
import { SegFormEmisionContainer } from '../../../SegFormEmisionContainer/SegFormEmisionContainer';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SegInput } from '../../../SegUI/SegInput/SegInput';
import { SegSelect, SegSelectOption } from '../../../SegUI/SegSelect/SegSelect';
import CodigosDeArea from '../../../../Assets/CodigosDeArea.json';
import { SegButton } from '../../../SegUI/SegButton/SegButton';
import { useNavigate, useParams } from 'react-router-dom';
import { SegCoberturaInfo } from '../../../SegCoberturaInfo/SegCoberturaInfo';
import { useCotizacionStore } from '../../../../Services/Providers/CotizacionStore';


const DivError = styled.div`
  display: block;
  text-align: left;
  font-size: 1rem;
  color: var(--bs-form-invalid-color);
  margin: 0 auto;
  margin-top: 0.25rem;
`;

const CoberturaTitle = styled.p`
    text-align:center;
    font-size: 18px;
    font-weight: 800;
    color: #2C7DFD;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
    margin: auto;
    width: 80%;
    margin-bottom: 5px;
`

interface Props {
    emisionValues: IEmisionValues;
    setEmisionValues: (values: IEmisionValues) => void;
}

interface IDatosDelTomador {
    nombre: string;
    apellido: string;
    numDoc: string;
    cuil: string;
    email: string;
    fechaNac: IFechaNacimiento;
    celular: ITelefono;
    telAdicional: ITelefono;
    calle: string;
    departamento: string;
    piso: string;
    altura: string;
}

interface IDocumento {
    tipoDoc: string;
    numero: string;
}

const tiposDoc = [
    { value: 'dni', label: 'DNI' },
    { value: 'cuil', label: 'CUIL' },
    { value: 'cuit', label: 'CUIT' },
];


export const DatosTomador = ({ emisionValues, setEmisionValues }: Props) => {

    const { handleSubmit, control, setValue, formState, trigger, watch, getValues, setFocus, setError, clearErrors } = useForm<IDatosDelTomador>();
    const { cotizacionValues } = useCotizacionStore()
    const [tipoDoc, setTipoDoc] = useState<SegSelectOption>();
    const [errorNumero, setErrorNumero] = useState<boolean>(true)
    const [isCelularValido, setIsCelularValido] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const nav = useNavigate();
    const { compania } = useParams();

    const methods = useForm<IDocumento>();

    useEffect(() => {
        /*emisionValues.nombre && setValue("nombre", emisionValues.nombre)
        emisionValues.apellido && setValue("apellido", emisionValues.apellido)
        if(emisionValues.numDoc){
            setValue("numDoc", emisionValues.numDoc);
            setTipoDoc( tiposDoc.find( t => t.value === emisionValues.tipoDoc) )
        }
        if (emisionValues.fechaNac) {
            setValue("fechaNac.dia", emisionValues.fechaNac.dia)
            setValue("fechaNac.mes", emisionValues.fechaNac.mes)
            setValue("fechaNac.anio", emisionValues.fechaNac.anio)
        }
        emisionValues.email && setValue("email", emisionValues.email)
        if (emisionValues.celular) {
            setValue("celular.cod", emisionValues.celular?.cod)
            setValue("celular.numero", emisionValues.celular?.numero)
        }
        emisionValues.calle && setValue("calle", emisionValues.calle)
        emisionValues.altura && setValue("altura", emisionValues.altura)
        emisionValues.piso && setValue("piso", emisionValues.piso)
        emisionValues.departamento && setValue("departamento", emisionValues.departamento)
        if(emisionValues.numDoc){
            trigger()
        }*/
        cotizacionValues.nombre && setValue("nombre", cotizacionValues.nombre)
        cotizacionValues.apellido && setValue("apellido", cotizacionValues.apellido)
        if (cotizacionValues.numeroDoc) {
            setValue("numDoc", cotizacionValues.numeroDoc);
            setTipoDoc(tiposDoc.find(t => t.value === cotizacionValues.tipoDoc?.value))
        }
        if (emisionValues.fechaNac) {
            setValue("fechaNac.dia", emisionValues.fechaNac.dia)
            setValue("fechaNac.mes", emisionValues.fechaNac.mes)
            setValue("fechaNac.anio", emisionValues.fechaNac.anio)
        }
        cotizacionValues.email && setValue("email", cotizacionValues.email)
        if (cotizacionValues.telefono) {
            setValue("celular.cod", cotizacionValues.telefono?.prefijo)
            setValue("celular.numero", cotizacionValues.telefono?.numero)
        }
        emisionValues.calle && setValue("calle", emisionValues.calle)
        emisionValues.altura && setValue("altura", emisionValues.altura)
        emisionValues.piso && setValue("piso", emisionValues.piso)
        emisionValues.departamento && setValue("departamento", emisionValues.departamento)
      
    }, [])

    const validarTelefono = (prefijo: string, numero: string) => {
        const numeroCompleto = prefijo + numero;
        if (numeroCompleto.length === 10) {
            return true;
        } else {
            return false;
        }
    };

    const validarYActualizarTelefono = (val: string, setter: (value: React.SetStateAction<boolean>) => void) => {
        const { celular, telAdicional } = getValues();
        if (val === "celular") {
            const resultadoValidarTelefono = validarTelefono(celular.cod, celular.numero);
            setter(resultadoValidarTelefono);
        }
        else {
            if (telAdicional.cod || telAdicional.numero) {
                const resultadoValidarTelefono = validarTelefono(telAdicional.cod, telAdicional.numero);
                setter(resultadoValidarTelefono);
            } else {
                setter(true);
            }
        }
    };

    /*useEffect(() => {
        setValue("calleSinNum", isChecked);
        if (initialValidationDone)
            trigger("altura");
    }, [isChecked]);*/

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onSubmit: SubmitHandler<IDatosDelTomador> = (values: IDatosDelTomador) => {
        const { nombre, altura, apellido, calle, celular, fechaNac, cuil, departamento, email, piso, numDoc } = values;
        //ap/emision/:compania?/datos-asegurado
        //TODO: Set imision values  tipo doc y doc
        setEmisionValues({
            ...emisionValues,
            nombre,
            apellido,
            altura,
            calle,
            celular,
            fechaNac,
            cuil,
            departamento,
            email,
            piso,
            tipoDoc: tipoDoc?.value,
            numDoc
        })
        compania
            ? nav("/ap/emision/" + compania + "/datos-asegurado") : nav("/ap/emision/datos-asegurado");
    }

    return (
        <div>
            {window.innerWidth < 768 &&
                <CoberturaTitle>
                    Cobertura
                </CoberturaTitle>
            }
            <div className='px-20 maxw-1170p mx-auto'>
                <SegCoberturaInfo emisionValues={emisionValues} tipo='ACCIDENTES PERSONALES' />
            </div>
            <div className='px-20 pb-40 pb-lg-60 maxw-1170p mx-auto'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SegFormEmisionContainer title='Datos del tomador'>
                        <div className="col-md-4">
                            <Controller
                                name="nombre"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el nombre",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Nombre inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Nombre"
                                        name="nombre"
                                        disabled
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Juan"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("nombre", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("nombre");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.nombre
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="apellido"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el apellido",
                                        pattern: (value) =>
                                            /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(value) ||
                                            "Apellido inválido",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Apellido"
                                        name="apellido"
                                        disabled
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: López"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("apellido", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("apellido");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.apellido
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-md-4">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá el email",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Correo electrónico"
                                        disabled={true}
                                        name="email"
                                        type="email"
                                        width={100}
                                        placeHolder="Ej: juan@ejemplo.com"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("email", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("email");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.email
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.email
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-md-4">
                            <label className='form-label'>Tipo documento</label>
                            <FormProvider {...methods}>
                                <SegSelect
                                    label=""
                                    selected={tipoDoc}
                                    setSelected={setTipoDoc}
                                    name="tipoDoc"
                                    options={tiposDoc}
                                    isGrupable
                                    width={"100%"}
                                    //borderColor={errors.tipoDoc ? "red" : ""}
                                    openDefault={false}
                                    autoFoco={true}
                                    placeholder=""
                                    disabled
                                />
                            </FormProvider>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Número de documento</label>
                            <Controller
                                name="numDoc"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => {
                                            if (!value) {
                                                setErrorNumero(true)
                                                return "Ingresá el Número"
                                            } else {
                                                setErrorNumero(false)
                                            }
                                        },
                                        pattern: (value) => {
                                            if (tipoDoc?.value === 'dni') {
                                                if (/^\d{7,8}$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return "DNI inválido"
                                                }
                                            } else if (tipoDoc?.value === 'cuit' || tipoDoc?.value === 'cuil') {
                                                if (/^(20|2[3-7]|30|3[3-4])\d{8}[0-9]$/.test(value)) {
                                                    setErrorNumero(false)
                                                } else {
                                                    setErrorNumero(true)
                                                    return `${tipoDoc?.label} inválido`
                                                }
                                            }
                                        }
                                    },
                                }}
                                render={({ field, fieldState }) => (

                                    <SegInput
                                        disabled={true}
                                        className="col-8"
                                        label=""
                                        name="setNumero"
                                        type="number"
                                        width={100}
                                        placeHolder={tipoDoc?.value === 'cuit' ? "27354678940" : "20354678948"}
                                        value={field.value}
                                        autoFocus={false}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("numDoc", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("numDoc");
                                        }}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors?.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.numDoc
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-md-4">
                            {
                                window.innerWidth < 778 && <label className='form-label fw-bold'>Celular</label>
                            }
                            <div className='d-flex gap-1'>
                                <Controller
                                    name="celular.cod"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá el Cód. Área",
                                            isInValidCodeArea: (value) =>
                                                CodigosDeArea.includes(value) ||
                                                "Cód. Área inválido",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            disabled
                                            labelGroup="0"
                                            className="col-4"
                                            label="Cód. Área"
                                            name="codigo"
                                            type="number"
                                            width={33}
                                            placeHolder="11"
                                            value={field.value}
                                            autoFocus={true}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("celular.cod", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("celular.cod");
                                                if (getValues("celular.numero") !== "" && !validarTelefono(getValues("celular.cod"), getValues("celular.numero"))) {
                                                    setError("celular.numero", { type: "validate", message: "Número inválido" })
                                                } else {
                                                    clearErrors("celular.numero")
                                                }
                                                validarYActualizarTelefono("celular", setIsCelularValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.celular?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.celular?.cod
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                                <Controller
                                    name="celular.numero"
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => !!value || "Ingresá Número de Celular",
                                            pattern: (value) =>
                                                /^[0-9]{6,9}$/.test(value) || "Número inválido",
                                            validateCombination: (value) => {
                                                const prefijo = watch("celular.cod");
                                                if ((prefijo + value).length !== 10)
                                                    return "Número inválido"
                                            }
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <SegInput
                                            isGroup={true}
                                            labelGroup="15"
                                            disabled
                                            className="col-8"
                                            label="Número de Celular"
                                            name="numeroCel"
                                            type="number"
                                            width={66}
                                            placeHolder="33669955"
                                            value={field.value}
                                            autoFocus={false}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue("celular.numero", e.target.value); // Actualiza el valor en React Hook Form
                                                trigger("celular.numero");
                                                validarYActualizarTelefono("celular", setIsCelularValido);
                                            }}
                                            leyendaError={fieldState?.error?.message}
                                            borderColor={`${formState.errors.celular?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : ""
                                                }`}
                                            focusBorderColor={`${formState.errors.celular?.numero
                                                ? "var(--bs-form-invalid-border-color)"
                                                : "#2c7dfd"
                                                }`}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label'>Fecha de nacimiento</label>
                            <div className='d-flex align-items-end gap-1 justify-content-between'>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.dia"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    if (!value) {
                                                        return "Día debe incluir 2 dígitos";
                                                    }
                                                    const parsedValue = parseInt(value, 10);
                                                    if (isNaN(parsedValue) || parsedValue > 31) {
                                                        return "Día no puede ser mayor a 31";
                                                    }
                                                    if (isNaN(parsedValue) || parsedValue <= 0) {
                                                        return "Día no puede ser 0";
                                                    }
                                                    const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                    if (isSingleDigitWithoutZero) {
                                                        return "Día debe incluir 2 dígitos";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Dia"
                                                type="number"
                                                width={100}
                                                placeHolder="Día"
                                                value={field.value}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.dia", trimmedValue);
                                                    trigger("fechaNac.dia");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.mes"
                                        control={control}
                                        rules={{
                                            validate: {
                                                customValidation: (value) => {
                                                    if (!value) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    const parsedValue = parseInt(value, 10);
                                                    if (isNaN(parsedValue) || parsedValue > 12) {
                                                        return "Mes no puede ser mayor a 12";
                                                    }
                                                    if (isNaN(parsedValue) || parsedValue <= 0) {
                                                        return "Día no puede ser 0";
                                                    }
                                                    const isSingleDigitWithoutZero = /^[0-9]$/.test(value);
                                                    if (isSingleDigitWithoutZero) {
                                                        return "Mes debe incluir 2 dígitos";
                                                    }
                                                    return true;
                                                },
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Mes"
                                                type="number"
                                                width={100}
                                                placeHolder="Mes"
                                                value={field.value}
                                                autoFocus={true}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 2).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.mes", trimmedValue);
                                                    trigger("fechaNac.mes");
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-4'>
                                    <Controller
                                        name="fechaNac.anio"
                                        control={control}
                                        rules={{
                                            validate: {
                                                required: (value) =>
                                                    !!value || "Ingresá el año de nacimiento",
                                                pattern: (value) =>
                                                    /^(19\d{2}|20\d{2})$/.test(value) || "Año debe incluir 4 dígitos",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label=""
                                                name="Anio"
                                                type="number"
                                                placeHolder="Año"
                                                value={field.value}
                                                autoFocus={false}
                                                onChange={(e) => {
                                                    const trimmedValue = e.target.value.slice(0, 4).replace(/[^0-9]/g, '');
                                                    field.onChange(trimmedValue);
                                                    setValue("fechaNac.anio", trimmedValue);
                                                    trigger("fechaNac.anio");
                                                }}
                                                width={100}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {formState.errors.fechaNac &&
                                <DivError>
                                    {[formState.errors.fechaNac.dia?.message, formState.errors.fechaNac.mes?.message, formState.errors.fechaNac.anio?.message]
                                        .filter(Boolean) // Filtramos valores nulos o indefinidos
                                        .join(', ')}
                                </DivError>
                            }

                        </div>
                        <div className="col-12">
                            <div className="fs-18 fs-lg-24 fw-bold text-primary border-bottom border-2 border-primary">
                                Dirección del tomador </div>
                        </div>
                        <div className="col-md-4">
                            <Controller
                                name="calle"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => !!value || "Ingresá la calle",
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <SegInput
                                        label="Calle"
                                        name="calle"
                                        type="text"
                                        width={100}
                                        placeHolder="Ej: Thames"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setValue("calle", e.target.value); // Actualiza el valor en React Hook Form
                                            trigger("calle");
                                        }}
                                        value={field.value}
                                        autoFocus={true}
                                        leyendaError={fieldState?.error?.message}
                                        borderColor={`${formState.errors.calle
                                            ? "var(--bs-form-invalid-border-color)"
                                            : ""
                                            }`}
                                        focusBorderColor={`${formState.errors.calle
                                            ? "var(--bs-form-invalid-border-color)"
                                            : "#2c7dfd"
                                            }`}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className='d-flex align-items-start gap-1 justify-content-between'>
                                <div className='col-md-3'>
                                    <Controller
                                        name="altura"
                                        control={control}
                                        rules={{
                                            validate: {
                                                isNumeric: (value) => value === '' || (!isNaN(Number(value)) && parseInt(value) > 0) || "El Nº de Calle debe ser un número mayor a 0",
                                                required: (value) => {
                                                    if (!isChecked) {
                                                        return !!value || "El Nº es obligatorio";
                                                    } else {
                                                        return true
                                                    }
                                                }
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="N°"
                                                name="altura"
                                                type="number"
                                                width={100}
                                                placeHolder="Ej: 125"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("altura", e.target.value);
                                                    trigger("altura");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.altura ? "var(--bs-form-invalid-border-color)" : ""}`}
                                                focusBorderColor={`${formState.errors.altura ? "var(--bs-form-invalid-border-color)" : "#2c7dfd"}`}
                                                min={"1"}
                                            />
                                        )}
                                    />
                                    {/*<div style={{ display: "flex", alignItems: "baseline", position: "absolute" }}>
                                        <input
                                            className='checkbox-container'
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="label-color">Sin número</label>
                                    </div>*/}
                                </div>
                                <div className='col-md-3'>
                                    <Controller
                                        name="piso"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Piso"
                                                name="piso"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: PB, 1, ..."
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("piso", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("piso");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.piso
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.piso
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='col-md-5'>
                                    <Controller
                                        name="departamento"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <SegInput
                                                label="Depto."
                                                name="departamento"
                                                type="text"
                                                width={100}
                                                placeHolder="Ej: D"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue("departamento", e.target.value); // Actualiza el valor en React Hook Form
                                                    trigger("departamento");
                                                }}
                                                value={field.value}
                                                autoFocus={true}
                                                leyendaError={fieldState?.error?.message}
                                                borderColor={`${formState.errors.departamento
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : ""
                                                    }`}
                                                focusBorderColor={`${formState.errors.departamento
                                                    ? "var(--bs-form-invalid-border-color)"
                                                    : "#2c7dfd"
                                                    }`}
                                            />
                                        )}
                                    />
                                    {/* <SegInput label='Piso' name='piso' type='number' width={100} /> */}
                                </div>
                            </div>
                        </div>
                        <div className={window.innerWidth < 768 ? "col-12" : "col-3"}>
                            <SegButton label='Siguiente' type='submit' disabled={!formState.isValid} className={window.innerWidth < 768 ? 'w-100' : 'fs-15'} />
                        </div>
                    </SegFormEmisionContainer>
                </form>
            </div>
        </div>
    )
}