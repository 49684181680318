import { create } from 'zustand';
import rubrosData from '../Data/rubros';
import { RubroTypes } from '../../types';

export interface RubroStore {
  rubros: RubroTypes.Rubro[];
  getRubroPorNombre: (nombre: string) => RubroTypes.Rubro | undefined;
  getRubrosPorTipoPersona: (tipoPersona: 'FISICA' | 'JURIDICA') => RubroTypes.Rubro[];
  getFormularioPorRubroYTipoPersona: (id: string, tipoPersona: string | undefined) => RubroTypes.Step[];
  // agregarRubro: (nuevoRubro: RubroTypes.Rubro) => void;
  // actualizarRubro: (id: string, rubroActualizado: Partial<RubroTypes.Rubro>) => void;
  // eliminarRubro: (id: string) => void;
}

const useRubroStore = create<RubroStore>((set, get) => ({
  rubros: rubrosData as RubroTypes.Rubro[],

  getRubroPorNombre: (nombre: string) => {
    return nombre ?
      get().rubros.find(rubro => rubro.id === nombre.toLocaleUpperCase()) : undefined
  },

  getRubrosPorTipoPersona: (tipoPersona: 'FISICA' | 'JURIDICA') => {
    return get().rubros.filter(rubro =>
      rubro.tipoPersona === tipoPersona || rubro.tipoPersona === 'AMBOS'
    );
  },

  getFormularioPorRubroYTipoPersona: (id: string, tipoPersona: string | undefined) => {
    const rubro = get().rubros.find(rubro => rubro.id === id.toLocaleUpperCase());
    if (!rubro) return [];

    const validaOpcion = (tipo: string | undefined): "FISICA" | "JURIDICA" | undefined => {
      if (!tipo) return 'FISICA';
      switch (tipo.toLowerCase()) {
        case "individuo":
        case "":
          return "FISICA";
        case "corporativo":
          return "JURIDICA";
        default:
          return undefined;
      }
    };

    const tipoPersonaFiltro = validaOpcion(tipoPersona);

    if (tipoPersonaFiltro === undefined) {
      return [];
    }

    return rubro.steps[tipoPersonaFiltro] || [];
  },
  agregarRubro: (nuevoRubro: RubroTypes.Rubro) => set((state) => ({
    rubros: [...state.rubros, nuevoRubro]
  })),

  actualizarRubro: (id: string, rubroActualizado: Partial<RubroTypes.Rubro>) => set((state) => ({
    rubros: state.rubros.map(rubro =>
      rubro.id === id ? { ...rubro, ...rubroActualizado } : rubro
    )
  })),

  eliminarRubro: (id: string) => set((state) => ({
    rubros: state.rubros.filter(rubro => rubro.id !== id)
  }))
}));

export default useRubroStore;